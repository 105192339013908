import React from 'react';
import { Select, Divider, Input, Typography } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

const { Text } = Typography;
const { Option } = Select;

class LabelsDropdown extends React.Component {
  state = {
    name: '',
    touched: false,
  };

  handleSearch = (value) => {
    if (value) {
      this.props.onSearch(value);
    } else {
      this.props.onSearch('');
    }
  };

  onNameChange = (event) => {
    this.setState({
      name: event.target.value,
      touched: true,
      isEmpty: event.target.value.length === 0,
    });
  };

  addItem = () => {
    const { name } = this.state;
    let touched = false;

    if (name.length > 0) {
      this.props.onAddItem(name);
    } else {
      touched = true;
    }

    this.setState({
      name: '',
      touched,
    });
  };

  render() {
    const { touched, name } = this.state;
    const {
      value = '',
      onChange,
      onClear,
      initialValue = '',
      placeholder,
      className,
    } = this.props;

    const valueIsObject = value instanceof Object;
    const sanitizedValue = value && !valueIsObject ? value : initialValue;

    return (
      <Select
        value={sanitizedValue}
        defaultValue={initialValue}
        onChange={onChange}
        onSearch={this.handleSearch}
        onClear={onClear}
        placeholder={placeholder}
        showSearch
        allowClear
        showArrow={false}
        filterOption={false}
        defaultActiveFirstOption={false}
        style={{ width: '100%' }}
        className={className}
        dropdownRender={(menu) => (
          <div>
            {menu}
            <Divider style={{ margin: '4px 0' }} />
            <div style={{ padding: 8 }}>
              <Input
                style={{ flex: 'auto' }}
                value={name}
                onChange={this.onNameChange}
              />
              <Link
                to="#"
                style={{
                  padding: '8px',
                  display: 'flex',
                  alignItems: 'center',
                  cursor: 'pointer',
                }}
                onClick={this.addItem}
              >
                <PlusOutlined />
                <span style={{ paddingLeft: '5px' }}>Create New Label</span>
              </Link>

              {name.length === 0 && touched && (
                <Text style={{ display: 'block' }} type="danger">
                  Please enter a name
                </Text>
              )}
            </div>
          </div>
        )}
      >
        {this.props.options.map((item) => (
          <Option key={item._id} value={item.name}>
            {item?.name}
          </Option>
        ))}
      </Select>
    );
  }
}

LabelsDropdown.defaultProps = {
  value: {},
  className: '',
  onSearch: () => {},
  onAddItem: () => {},
  onChange: () => {},
};

LabelsDropdown.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  className: PropTypes.string,
  onSearch: PropTypes.func,
  onAddItem: PropTypes.func,
  onChange: PropTypes.func,
};

export default LabelsDropdown;
