import * as externalAxios from 'axios';
import { axios } from 'services/axios';
import { handleServerError } from 'utils';
import { storeImageRequests } from 'utils/files';
import { show_error } from 'utils/consts';

import legalActions from './actionTypes';

export const getInfringements = (params) => {
  return async (dispatch) => {
    dispatch(legalActions.getInfringements());

    try {
      const { currentPage, pageSize, status } = params;
      const response = await axios.get('/infringements/', {
        params: {
          page: currentPage + 1,
          pageSize,
          status,
        },
      });

      dispatch(
        legalActions.getInfringementsSuccess({
          infringements: response.data.infringements,
          totalInfringements: Number(response.data.totalInfringements),
        })
      );
    } catch (error) {
      dispatch(legalActions.getInfringementsFailed(error));

      const errorMessage = handleServerError(error?.response?.data);

      show_error({ message: errorMessage });

      throw error;
    }
  };
};

export const getInfringement = (id) => {
  return async (dispatch) => {
    dispatch(legalActions.getInfringement());

    try {
      const response = await axios.get(`/infringements/${id}`);

      dispatch(
        legalActions.getInfringementSuccess({
          infringement: response.data.infringement,
        })
      );
    } catch (error) {
      dispatch(legalActions.getInfringementFailed(error));

      const errorMessage = handleServerError(error?.response?.data);

      show_error({ message: errorMessage });

      throw error;
    }
  };
};

export const updateInfringement = (id, fields) => {
  return async (dispatch) => {
    dispatch(legalActions.updateInfringement());

    try {
      const response = await axios.patch('/infringements/', {
        id,
        ...fields,
      });

      dispatch(
        legalActions.updateInfringementSuccess({
          infringement: response.data.infringement,
        })
      );
    } catch (error) {
      dispatch(legalActions.updateInfringementFailed(error));

      const errorMessage = handleServerError(error?.response?.data);

      show_error({ message: errorMessage });
    }
  };
};

export const updateInfringementPaid = (id, isPaid) => {
  return async (dispatch) => {
    dispatch(legalActions.updateInfringementPaid());

    try {
      const response = await axios.patch(`/infringements/paid`, {
        id,
        paid: isPaid,
      });

      dispatch(
        legalActions.updateInfringementPaidSuccess({
          infringement: response.data.infringement,
        })
      );
    } catch (error) {
      dispatch(legalActions.updateInfringementPaidFailed(error));

      const errorMessage = handleServerError(error?.response?.data);

      show_error({ message: errorMessage });
    }
  };
};

export const createSigning = (fields, callback) => {
  return async (dispatch) => {
    dispatch(legalActions.createSigning());

    try {
      const response = await axios.post('/signings/create', fields);

      if (!callback && response.data.signing.contractUrl) {
        window.open(response.data.signing.contractUrl, '_blank', 'noopener');
      }

      if (callback) callback(response.data.signing);

      dispatch(
        legalActions.createSigningSuccess({
          signing: response.data.signing,
        })
      );
    } catch (error) {
      dispatch(legalActions.createSigningFailed(error));

      const errorMessage = handleServerError(error?.response?.data);

      show_error({ message: errorMessage });
    }
  };
};

export const buildInfringement = (fields) => {
  return async (dispatch) => {
    dispatch(legalActions.buildInfringement());

    try {
      const createInfringementResponse = await axios.post(
        '/infringements/',
        fields
      );

      dispatch(
        legalActions.buildInfringementSuccess({
          createInfringementResponse,
        })
      );
    } catch (error) {
      dispatch(legalActions.buildInfringementFailed(error));

      const errorMessage = handleServerError(error?.response?.data);

      show_error({ message: errorMessage });
    }
  };
};

export const getRequests = (params) => {
  return async (dispatch) => {
    dispatch(legalActions.getRequests());

    try {
      const { currentPage, pageSize, status, type } = params;
      const response = await axios.get(`/requests`, {
        params: {
          page: currentPage + 1,
          pageSize,
          status,
          type,
        },
      });

      const { requests, totalRequests, totalNewRequests } = response.data;

      dispatch(
        legalActions.getRequestsSuccess({
          requests,
          totalRequests,
          totalNewRequests,
          type,
        })
      );
    } catch (error) {
      dispatch(legalActions.getRequestsFailed(error));

      return show_error({ message: error.response.data });
    }
  };
};

export const getNewRequests = () => {
  return (dispatch) => {
    dispatch(legalActions.getNewRequests());

    axios
      .get(`requests-new`)
      .then((response) => {
        dispatch(
          legalActions.getNewRequestsSuccess({
            newRequests: response.data.newRequests,
            totalNewRequests: response.data.totalNewRequests,
          })
        );
      })
      .catch((err) => {
        dispatch(legalActions.getNewRequestsFail(err));

        return show_error({ message: err.response.data });
      });
  };
};

export const getRequest = (id) => {
  return async (dispatch, getState) => {
    const username = getState().auth.user.username;

    dispatch(legalActions.getRequest());

    try {
      const response = await axios.get(`request/${id}/${username}`);
      const { license, request } = response.data;

      dispatch(legalActions.getRequestSuccess({ request, license }));
    } catch (error) {
      dispatch(legalActions.getRequestFailed(error));

      return show_error({ message: error.response.data });
    }
  };
};

export const clearRequest = () => {
  return async (dispatch) => {
    dispatch(legalActions.clearRequest());
  };
};

export const clearInfringement = () => {
  return async (dispatch) => {
    dispatch(legalActions.clearInfringement());
  };
};

export const updateStatus = (id, status) => {
  return (dispatch) => {
    axios
      .post(`request/update_status`, { id, status })
      .then((response) => {
        dispatch(legalActions.updateStatusStart({ id, status }));
      })
      .catch((err) => {
        return show_error({ message: err.response.data });
      });
  };
};

export const addNoteRequest = ({ id, notes, files }, cb) => {
  const username = localStorage.getItem('username');
  const filesKey = files.map((file) => file.signedUrl.key);

  return (dispatch) => {
    dispatch(legalActions.addNoteRequestStart());

    externalAxios
      .all(storeImageRequests(files))
      .then(
        externalAxios.spread((data) => {
          axios
            .post(`request/notes`, { id, notes, username, files: filesKey })
            .then((response) => {
              cb();

              const { request = {} } = response.data;

              dispatch(
                legalActions.addNoteRequestSuccess({ notes: request.notes })
              );
            });
        })
      )
      .catch((err) => {
        dispatch(legalActions.addNoteRequestFailed(err));
        return show_error({ message: err.response.data });
      });
  };
};

export const addNoteSigning = ({ id, notes, files }, cb) => {
  const username = localStorage.getItem('username');
  const filesKey = files.map((file) => file.signedUrl.key);

  return (dispatch) => {
    dispatch(legalActions.addNoteSigningStart());

    externalAxios
      .all(storeImageRequests(files))
      .then(
        externalAxios.spread((data) => {
          axios
            .post(`signings/notes`, { id, notes, username, files: filesKey })
            .then((response) => {
              cb();

              const { signing = {} } = response.data;

              dispatch(
                legalActions.addNoteSigningSuccess({ notes: signing.notes })
              );
            });
        })
      )
      .catch((err) => {
        dispatch(legalActions.addNoteSigningFailed(err));
        return show_error({ message: err.response.data });
      });
  };
};

export const addNoteInfringement = ({ id, notes, files }, cb) => {
  const username = localStorage.getItem('username');
  const filesKey = files.map((file) => file.signedUrl.key);

  return (dispatch) => {
    dispatch(legalActions.addNoteInfringementStart());

    externalAxios
      .all(storeImageRequests(files))
      .then(
        externalAxios.spread((data) => {
          axios
            .post(`infringements/notes`, {
              id,
              notes,
              username,
              files: filesKey,
            })
            .then((response) => {
              cb();

              const { infringement = {} } = response.data;

              dispatch(
                legalActions.addNoteInfringementSuccess({
                  notes: infringement.notes,
                })
              );
            });
        })
      )
      .catch((err) => {
        dispatch(legalActions.addNoteInfringementFailed(err));
        return show_error({ message: err.response.data });
      });
  };
};

export const getSignings = (pagination, callback) => {
  let defaultOptions = {
    current: 1,
    limit: 'full',
  };
  let queryOptions = pagination;

  if (!pagination) {
    queryOptions = defaultOptions;
  }

  return async (dispatch) => {
    dispatch(legalActions.getSignings());

    try {
      const response = await axios.get('/signings', {
        params: {
          page: queryOptions.currentPage,
          pageSize: queryOptions.pageSize,
          status: queryOptions.status,
        },
      });

      if (callback) callback();

      dispatch(
        legalActions.getSigningsSuccess({
          signings: response.data.signings,
          totalSignings: response.data.totalSignings,
        })
      );
    } catch (error) {
      if (callback) callback();

      dispatch(legalActions.getSigningsFailed(error));

      throw error;
    }
  };
};

export const getSigning = (signingId) => {
  return async (dispatch) => {
    dispatch(legalActions.getSigning());

    try {
      const response = await axios.get(`/signings/${signingId}`);

      dispatch(
        legalActions.getSigningSuccess({
          signing: response.data.signing,
        })
      );
    } catch (error) {
      dispatch(legalActions.getSigningFailed(error));

      throw error;
    }
  };
};

export const updateSigningPaid = (signingId, isPaid) => {
  return async (dispatch) => {
    dispatch(legalActions.updateSigningPaid());

    try {
      const response = await axios.patch(`/signings/paid`, {
        signingId,
        paid: isPaid,
      });

      dispatch(
        legalActions.updateSigningPaidSuccess({
          signing: response.data.signing,
        })
      );
    } catch (error) {
      dispatch(legalActions.updateSigningPaidFailed(error));

      const errorMessage = handleServerError(error?.response?.data);

      show_error({ message: errorMessage });
    }
  };
};

//LINCESES
export const getLicenses = (params) => {
  return async (dispatch) => {
    dispatch(legalActions.getLicenses());

    try {
      const { currentPage, pageSize, status } = params;
      const response = await axios.get(`/licenses/all`, {
        params: {
          page: currentPage + 1,
          pageSize,
          status,
        },
      });

      const { licenses, totalLicenses } = response.data;

      dispatch(
        legalActions.getLicensesSuccess({
          licenses,
          totalLicenses,
        })
      );
    } catch (error) {
      dispatch(legalActions.getLicensesFailed(error));

      return show_error({ message: error.response.data });
    }
  };
};

export const getLicense = (id, byRequest = true) => {
  return async (dispatch) => {
    dispatch(legalActions.getLicense());

    try {
      let response = {};
      if (byRequest) {
        response = await axios.get(`/licensesByRequest?requestId=${id}`);
      } else {
        response = await axios.get(`/licenses?id=${id}`);
      }

      dispatch(
        legalActions.getLicenseSuccess({
          license: response.data.license,
        })
      );
    } catch (error) {
      dispatch(legalActions.getLicenseFailed(error));

      const errorMessage = handleServerError(error?.response?.data);

      show_error({ message: errorMessage });
    }
  };
};

export const deleteLicense = (id) => {
  return async (dispatch) => {
    dispatch(legalActions.deleteLicense());

    try {
      const response = await axios.delete(`/licenses/${id}`);

      dispatch(
        legalActions.deleteLicenseSuccess({
          license: response.data.license,
        })
      );
    } catch (error) {
      dispatch(legalActions.deleteLicenseFailed(error));

      const errorMessage = handleServerError(error?.response?.data);

      show_error({ message: errorMessage });
    }
  };
};

export const startLicense = (fields) => {
  return async (dispatch) => {
    dispatch(legalActions.startLicense());

    try {
      const response = await axios.post('/licenses/start', fields);

      dispatch(
        legalActions.startLicenseSuccess({
          license: response.data.license,
        })
      );
    } catch (error) {
      dispatch(legalActions.startLicenseFailed(error));

      const errorMessage = handleServerError(error?.response?.data);

      show_error({ message: errorMessage });
    }
  };
};

export const cancelRequest = (requestId, fileId) => {
  return async (dispatch) => {
    dispatch(legalActions.cancelRequest());

    try {
      const response = await axios.post('/request/cancel', {
        requestId,
        fileId,
      });

      dispatch(
        legalActions.cancelRequestSuccess({
          request: response.data.request,
        })
      );
    } catch (error) {
      dispatch(legalActions.cancelRequestFailed(error));

      const errorMessage = handleServerError(error?.response?.data);

      show_error({ message: errorMessage });
    }
  };
};

export const updateLicenseStatus = (id, { paid, status, paidDate }) => {
  return async (dispatch) => {
    dispatch(legalActions.updateLicenseStatus());

    try {
      const response = await axios.patch(`/licenses/status`, {
        id,
        paid,
        status,
        paidDate,
      });

      dispatch(
        legalActions.updateLicenseStatusSuccess({
          license: response.data.license,
        })
      );
    } catch (error) {
      dispatch(legalActions.updateLicenseStatusFailed(error));

      const errorMessage = handleServerError(error?.response?.data);

      show_error({ message: errorMessage });
    }
  };
};

export const startInterpolation = (fields) => {
  return async (dispatch) => {
    dispatch(legalActions.startInterpolation());

    try {
      const response = await axios.post('/interpolations/start', fields);

      dispatch(
        legalActions.startInterpolationSuccess({
          interpolation: response.data.interpolation,
        })
      );
    } catch (error) {
      dispatch(legalActions.startInterpolationFailed(error));

      const errorMessage = handleServerError(error?.response?.data);

      show_error({ message: errorMessage });
    }
  };
};

export const getInterpolations = (params) => {
  return async (dispatch) => {
    dispatch(legalActions.getInterpolations());

    try {
      const { currentPage, pageSize, status } = params;
      const response = await axios.get(`/interpolations/all`, {
        params: {
          page: currentPage + 1,
          pageSize,
          status,
        },
      });

      const { interpolations, totalInterpolations } = response.data;

      dispatch(
        legalActions.getInterpolationsSuccess({
          interpolations,
          totalInterpolations,
        })
      );
    } catch (error) {
      dispatch(legalActions.getInterpolationsFailed(error));

      return show_error({ message: error.response.data });
    }
  };
};

export const getInterpolation = (id, byRequest = true) => {
  return async (dispatch) => {
    dispatch(legalActions.getInterpolation());

    try {
      let response = {};
      if (byRequest) {
        response = await axios.get(`/interpolationsByRequest?requestId=${id}`);
      } else {
        response = await axios.get(`/interpolations?id=${id}`);
      }

      dispatch(
        legalActions.getInterpolationSuccess({
          interpolation: response.data.interpolation,
        })
      );
    } catch (error) {
      dispatch(legalActions.getInterpolationFailed(error));

      const errorMessage = handleServerError(error?.response?.data);

      show_error({ message: errorMessage });
    }
  };
};

export const deleteInterpolation = (id) => {
  return async (dispatch) => {
    dispatch(legalActions.deleteInterpolation());

    try {
      const response = await axios.delete(`/interpolations/${id}`);

      dispatch(
        legalActions.deleteInterpolationSuccess({
          interpolation: response.data.interpolation,
        })
      );
    } catch (error) {
      dispatch(legalActions.deleteInterpolationFailed(error));

      const errorMessage = handleServerError(error?.response?.data);

      show_error({ message: errorMessage });
    }
  };
};

export const updateInterpolationStatus = (id, { paid, status }) => {
  return async (dispatch) => {
    dispatch(legalActions.updateInterpolationStatus());

    try {
      const response = await axios.patch(`/interpolations/status`, {
        id,
        paid,
        status,
      });

      dispatch(
        legalActions.updateInterpolationStatusSuccess({
          interpolation: response.data.interpolation,
        })
      );
    } catch (error) {
      dispatch(legalActions.updateInterpolationStatusFailed(error));

      const errorMessage = handleServerError(error?.response?.data);

      show_error({ message: errorMessage });
    }
  };
};

//SAMPLES
export const getSamples = (params) => {
  return async (dispatch) => {
    dispatch(legalActions.getSamples());

    try {
      const { currentPage, pageSize, status } = params;
      const response = await axios.get(`/samples/all`, {
        params: {
          page: currentPage + 1,
          pageSize,
          status,
        },
      });

      const { samples, totalSamples } = response.data;

      dispatch(
        legalActions.getSamplesSuccess({
          samples,
          totalSamples,
        })
      );
    } catch (error) {
      dispatch(legalActions.getSamplesFailed(error));

      return show_error({ message: error.response.data });
    }
  };
};

export const getSample = (id, byRequest = true) => {
  return async (dispatch) => {
    dispatch(legalActions.getSample());

    try {
      let response = {};
      if (byRequest) {
        response = await axios.get(`/samplesByRequest?requestId=${id}`);
      } else {
        response = await axios.get(`/samples?id=${id}`);
      }

      dispatch(
        legalActions.getSampleSuccess({
          sample: response.data.sample,
        })
      );
    } catch (error) {
      dispatch(legalActions.getSampleFailed(error));

      const errorMessage = handleServerError(error?.response?.data);

      show_error({ message: errorMessage });
    }
  };
};

export const deleteSample = (id) => {
  return async (dispatch) => {
    dispatch(legalActions.deleteSample());

    try {
      const response = await axios.delete(`/samples/${id}`);

      dispatch(
        legalActions.deleteSampleSuccess({
          sample: response.data.sample,
        })
      );
    } catch (error) {
      dispatch(legalActions.deleteSampleFailed(error));

      const errorMessage = handleServerError(error?.response?.data);

      show_error({ message: errorMessage });
    }
  };
};

export const startSample = (fields) => {
  return async (dispatch) => {
    dispatch(legalActions.startSample());

    try {
      const response = await axios.post('/samples/start', fields);

      dispatch(
        legalActions.startSampleSuccess({
          sample: response.data.sample,
        })
      );
    } catch (error) {
      dispatch(legalActions.startSampleFailed(error));

      const errorMessage = handleServerError(error?.response?.data);

      show_error({ message: errorMessage });
    }
  };
};

export const updateSampleStatus = (id, { paid, status, paidDate }) => {
  return async (dispatch) => {
    dispatch(legalActions.updateSampleStatus());

    try {
      const response = await axios.patch(`/samples/status`, {
        id,
        paid,
        status,
        paidDate,
      });

      dispatch(
        legalActions.updateSampleStatusSuccess({
          sample: response.data.sample,
        })
      );
    } catch (error) {
      dispatch(legalActions.updateSampleStatusFailed(error));

      const errorMessage = handleServerError(error?.response?.data);

      show_error({ message: errorMessage });
    }
  };
};

export const updateSamplePeriod = (
  id,
  { year, limitDate, paid, amount, paymentDate }
) => {
  return async (dispatch) => {
    dispatch(legalActions.updateSamplePeriod());

    try {
      const response = await axios.patch(`/samples/period`, {
        id,
        paid,
        year,
        amount,
        limitDate,
        paymentDate,
      });

      dispatch(
        legalActions.updateSamplePeriodSuccess({
          sample: response.data.sample,
        })
      );
    } catch (error) {
      dispatch(legalActions.updateSamplePeriodFailed(error));

      const errorMessage = handleServerError(error?.response?.data);

      show_error({ message: errorMessage });
    }
  };
};

export const sendContract = (signingId, contract, callback) => {
  return async (dispatch) => {
    dispatch(legalActions.sendContract());

    try {
      const response = await axios.patch(`/signings/updateSigningContract`, {
        signingId,
        contract,
      });

      if (callback) callback(response.data.signing, false);

      dispatch(
        legalActions.sendContractSuccess({
          signing: response.data.signing,
        })
      );
    } catch (error) {
      if (callback) callback(error, true);
      dispatch(legalActions.sendContractFailed(error));

      const errorMessage = handleServerError(error?.response?.data);

      show_error({ message: errorMessage });
    }
  };
};
