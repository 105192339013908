import { handleActions } from 'redux-actions';
import labelsActions from '../actions/actionTypes';

const initialState = {
  labels: [],
  labelTracks: [],
  totalLabelTracks: 0,
  totalLabels: 0,
  unregisteredLabels: [],
  totalUnregisteredLabels: 0,
  label: { logo: {} },
  logoUploading: false,
  nonRegisteredLabels: [],
  isFetching: false,
  isFetchingSingle: false,
  isCreating: false,
  isUpdating: false,
  isSavingImage: false,
  selectedLabel: {},
  panelPage: 1,
  sidebarTotal: 0,
};

const labelsReducer = handleActions(
  {
    [labelsActions.setSelectedLabel.toString()]: (state, action) => {
      return {
        ...state,
        selectedLabel: action.payload.label,
      };
    },
    [labelsActions.setEditMode.toString()]: (state, action) => {
      return {
        ...state,
        editMode: action.payload.isEditMode,
      };
    },
    [labelsActions.setPanelPage.toString()]: (state, action) => {
      return {
        ...state,
        panelPage: action.payload.page,
      };
    },
    [labelsActions.setTotalLabels.toString()]: (state, action) => {
      return {
        ...state,
        sidebarTotal: action.payload.totalLabels,
      };
    },
    [labelsActions.getLabels.toString()]: (state, action) => {
      return {
        ...state,
        isFetching: true,
      };
    },
    [labelsActions.getLabelsSuccess.toString()]: (state, action) => {
      return {
        ...state,
        isFetching: false,
        labels: action.payload.labels,
        totalLabels: action.payload.totalLabels,
        unregisteredLabels: action.payload.unregisteredLabels,
        totalUnregisteredLabels: action.payload.totalUnregisteredLabels,
        sidebarTotal: action.payload.totalLabels,
      };
    },
    [labelsActions.getLabelsFail.toString()]: (state, action) => {
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };
    },
    [labelsActions.quickSearchLabel.toString()]: (state, action) => {
      return {
        ...state,
        isFetching: true,
      };
    },
    [labelsActions.quickSearchLabelSuccess.toString()]: (state, action) => {
      return {
        ...state,
        isFetching: false,
        labels: action.payload.labels,
        totalLabels: action.payload.totalLabels,
      };
    },
    [labelsActions.quickSearchLabelFail.toString()]: (state, action) => {
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };
    },
    [labelsActions.getLabelTracks.toString()]: (state, action) => {
      return {
        ...state,
        isFetchingSingle: true,
      };
    },
    [labelsActions.getLabelTracksSuccess.toString()]: (state, action) => {
      return {
        ...state,
        isFetchingSingle: false,
        labelTracks: action.payload.tracks,
        totalLabelTracks: action.payload.totalLabelTracks,
      };
    },
    [labelsActions.getLabelTracksFail.toString()]: (state, action) => {
      return {
        ...state,
        isFetchingSingle: false,
        error: action.error,
      };
    },
    [labelsActions.createLabel.toString()]: (state, action) => {
      return {
        ...state,
        isCreating: true,
      };
    },
    [labelsActions.createLabelSuccess.toString()]: (state, action) => {
      const newLabels = [action.payload.label, ...state.labels];

      const totalLabels = state.totalLabels;

      return {
        ...state,
        isCreating: false,
        labels: newLabels,
        newLabel: action.payload.project,
        totalLabels: totalLabels + 1,
        sidebarTotal: state.sidebarTotal + 1,
      };
    },
    [labelsActions.createLabelFail.toString()]: (state, action) => {
      return {
        ...state,
        isCreating: false,
        error: action.error,
      };
    },
    [labelsActions.updateLabel.toString()]: (state, action) => {
      return {
        ...state,
        isUpdating: true,
      };
    },
    [labelsActions.updateLabelSuccess.toString()]: (state, action) => {
      const updatedLabels = [...state.labels];
      let labelIndex;
      const label = state.labels.find((label, index) => {
        if (label._id === action.payload.label._id) {
          labelIndex = index;

          return label;
        }

        return null;
      });

      const updatedLabel = {
        ...label,
        ...action.payload.label,
      };

      updatedLabels[labelIndex] = updatedLabel;

      return {
        ...state,
        isUpdating: false,
        labels: updatedLabels,
      };
    },
    [labelsActions.updateLabelFail.toString()]: (state, action) => {
      return {
        ...state,
        isUpdating: false,
        error: action.error,
      };
    },
    [labelsActions.deleteLabel.toString()]: (state, action) => {
      return {
        ...state,
        isDeleting: true,
      };
    },
    [labelsActions.deleteLabelSuccess.toString()]: (state, action) => {
      const updatedLabels = state.labels.filter(
        (label) => label._id !== action.payload.deletedLabel
      );

      const totalLabels = state.totalLabels;

      return {
        ...state,
        labels: updatedLabels,
        totalLabels: totalLabels - 1,
        sidebarTotal: state.sidebarTotal - 1,
        isDeleting: false,
      };
    },
    [labelsActions.deleteLabelFail.toString()]: (state, action) => {
      return {
        ...state,
        isDeleting: false,
        error: action.error,
      };
    },
    [labelsActions.registerLabel.toString()]: (state, action) => {
      return {
        ...state,
        isRegistering: true,
      };
    },
    [labelsActions.registerLabelSuccess.toString()]: (state, action) => {
      const newLabels = [...state.labels, action.payload.label];
      // search by name is done because the name is a unique field
      const updatedUnregisteredLabels = state.unregisteredLabels.filter(
        (label) => label.name !== action.payload.label.name
      );

      const totalLabels = state.totalLabels;
      const totalUnregisteredLabels = state.totalUnregisteredLabels;

      return {
        ...state,
        labels: newLabels,
        isRegistering: false,
        newLabel: action.payload.label,
        totalLabels: totalLabels + 1,
        unregisteredLabels: updatedUnregisteredLabels,
        totalUnregisteredLabels: totalUnregisteredLabels - 1,
        sidebarTotal: state.sidebarTotal + 1,
      };
    },
    [labelsActions.registerLabelFail.toString()]: (state, action) => {
      return {
        ...state,
        isRegistering: false,
        error: action.error,
      };
    },
    [labelsActions.saveLabelLogo.toString()]: (state, action) => {
      return {
        ...state,
        isSavingImage: true,
      };
    },
    [labelsActions.saveLabelLogoSuccess.toString()]: (state, action) => {
      return {
        ...state,
        isSavingImage: false,
      };
    },
    [labelsActions.saveLabelLogoFail.toString()]: (state, action) => {
      return {
        ...state,
        isSavingImage: false,
        error: action.error,
      };
    },
    [labelsActions.deleteLabelLogo.toString()]: (state, action) => {
      return {
        ...state,
        isSavingImage: true,
      };
    },
    [labelsActions.deleteLabelLogoSuccess.toString()]: (state, action) => {
      return {
        ...state,
        isSavingImage: false,
      };
    },
    [labelsActions.deleteLabelLogoFail.toString()]: (state, action) => {
      return {
        ...state,
        isSavingImage: false,
        error: action.error,
      };
    },
  },
  initialState
);

export default labelsReducer;
