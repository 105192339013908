import { handleActions } from 'redux-actions';
import tagActions from '../actions/actionTypes';

const initialState = {
  tags: [],
  tagCategories: [],
  totalTags: 0,
  totalTagCategories: 0,
  sidebarTotalTags: 0,
  sidebarTotalTagCategories: 0,
  selectedTag: {},
  isFetching: false,
  isFetchingCategories: false,
  isUpdating: false,
  isDeleting: false,
  isSavingImage: false,
};

const tagsReducer = handleActions(
  {
    [tagActions.setTag.toString()]: (state, action) => {
      return {
        ...state,
        selectedTag: action.payload.tag,
      };
    },
    [tagActions.setTotalTags.toString()]: (state, action) => {
      return {
        ...state,
        sidebarTotalTags: action.payload.totalTags,
      };
    },
    [tagActions.setTotalTagCategories.toString()]: (state, action) => {
      return {
        ...state,
        sidebarTotalTagCategories: action.payload.totalTagCategories,
      };
    },
    [tagActions.getTags.toString()]: (state, action) => {
      return {
        ...state,
        isFetching: true,
      };
    },
    [tagActions.getTagsSuccess.toString()]: (state, action) => {
      return {
        ...state,
        tags: action.payload.tags,
        totalTags: action.payload.totalTags,
        sidebarTotalTags: action.payload.totalTags,
        isFetching: false,
      };
    },
    [tagActions.getTagsFail.toString()]: (state, action) => {
      return {
        ...state,
        isFetching: false,
      };
    },
    [tagActions.quickSearchTag.toString()]: (state, action) => {
      return {
        ...state,
        isFetching: true,
      };
    },
    [tagActions.quickSearchTagSuccess.toString()]: (state, action) => {
      return {
        ...state,
        tags: action.payload.tags,
        totalTags: action.payload.totalTags,
        isFetching: false,
      };
    },
    [tagActions.quickSearchTagFail.toString()]: (state, action) => {
      return {
        ...state,
        isFetching: false,
      };
    },
    [tagActions.getTagCategories.toString()]: (state, action) => {
      return {
        ...state,
        isFetchingCategories: true,
      };
    },
    [tagActions.getTagCategoriesSuccess.toString()]: (state, action) => {
      return {
        ...state,
        tagCategories: action.payload.tagCategories,
        totalTagCategories: action.payload.totalTagCategories,
        sidebarTotalTagCategories: action.payload.totalTagCategories,
        isFetchingCategories: false,
      };
    },
    [tagActions.getTagCategoriesFail.toString()]: (state, action) => {
      return {
        ...state,
        isFetchingCategories: false,
      };
    },
    [tagActions.quickSearchTagCategory.toString()]: (state, action) => {
      return {
        ...state,
        isFetchingCategories: true,
      };
    },
    [tagActions.quickSearchTagCategorySuccess.toString()]: (state, action) => {
      return {
        ...state,
        tagCategories: action.payload.tagCategories,
        totalTagCategories: action.payload.totalTagCategories,
        isFetchingCategories: false,
      };
    },
    [tagActions.quickSearchTagCategoryFail.toString()]: (state, action) => {
      return {
        ...state,
        isFetchingCategories: false,
      };
    },
    [tagActions.createTag.toString()]: (state, action) => {
      return {
        ...state,
        isUpdating: true,
      };
    },
    [tagActions.createTagSuccess.toString()]: (state, action) => {
      const newTagsArray = [action.payload.tag, ...state.tags];

      const totalTags = state.totalTags;

      return {
        ...state,
        tags: newTagsArray,
        totalTags: totalTags + 1,
        sidebarTotalTags: state.sidebarTotalTags + 1,
        isUpdating: false,
      };
    },
    [tagActions.createTagFail.toString()]: (state, action) => {
      return {
        ...state,
        isUpdating: false,
      };
    },
    [tagActions.createTagCategory.toString()]: (state, action) => {
      return {
        ...state,
        isUpdating: true,
      };
    },
    [tagActions.createTagCategorySuccess.toString()]: (state, action) => {
      const newTagCategoriesArray = [
        action.payload.tagCategory,
        ...state.tagCategories,
      ];

      const totalTagCategories = state.totalTagCategories;

      return {
        ...state,
        tagCategories: newTagCategoriesArray,
        totalTagCategories: totalTagCategories + 1,
        sidebarTotalTagCategories: state.sidebarTotalTagCategories + 1,
        isUpdating: false,
      };
    },
    [tagActions.createTagCategoryFail.toString()]: (state, action) => {
      return {
        ...state,
        isUpdating: false,
      };
    },
    [tagActions.updateTag.toString()]: (state, action) => {
      return {
        ...state,
        isUpdating: true,
      };
    },
    [tagActions.updateTagSuccess.toString()]: (state, action) => {
      let tagIndex;
      const tag = state.tags.find((tag, index) => {
        tagIndex = index;
        return tag._id === action.payload.tag._id;
      });
      const updatedTag = {
        ...tag,
        ...action.payload.tag,
      };

      const updatedTags = [...state.tags];

      updatedTags[tagIndex] = updatedTag;

      return {
        ...state,
        tags: updatedTags,
        isUpdating: false,
      };
    },
    [tagActions.updateTagFail.toString()]: (state, action) => {
      return {
        ...state,
        isUpdating: false,
      };
    },
    [tagActions.updateTagCategory.toString()]: (state, action) => {
      return {
        ...state,
        isUpdating: true,
      };
    },
    [tagActions.updateTagCategorySuccess.toString()]: (state, action) => {
      let tagIndex;
      const tag = state.tagCategories.find((tag, index) => {
        tagIndex = index;
        return tag._id === action.payload.tagCategory._id;
      });
      const updatedTag = {
        ...tag,
        ...action.payload.tagCategory,
      };

      const updatedTags = [...state.tagCategories];

      updatedTags[tagIndex] = updatedTag;

      return {
        ...state,
        tagCategories: updatedTags,
        isUpdating: false,
      };
    },
    [tagActions.updateTagCategoryFail.toString()]: (state, action) => {
      return {
        ...state,
        isUpdating: false,
      };
    },
    [tagActions.deleteTag.toString()]: (state, action) => {
      return {
        ...state,
        isDeleting: true,
      };
    },
    [tagActions.deleteTagSuccess.toString()]: (state, action) => {
      const updatedTagsArray = state.tags.filter(
        (tag) => tag._id !== action.payload.id
      );

      const totalTags = state.totalTags;

      return {
        ...state,
        tags: updatedTagsArray,
        totalTags: totalTags - 1,
        sidebarTotalTags: state.sidebarTotalTags - 1,
        isDeleting: false,
      };
    },
    [tagActions.deleteTagFail.toString()]: (state, action) => {
      return {
        ...state,
        isDeleting: false,
      };
    },
    [tagActions.deleteTagCategory.toString()]: (state, action) => {
      return {
        ...state,
        isDeleting: true,
      };
    },
    [tagActions.deleteTagCategorySuccess.toString()]: (state, action) => {
      const updatedTagCategoriesArray = state.tagCategories.filter(
        (tag) => tag._id !== action.payload.id
      );

      const totalTagCategories = state.totalTagCategories;

      return {
        ...state,
        tagCategories: updatedTagCategoriesArray,
        totalTagCategories: totalTagCategories - 1,
        sidebarTotalTagCategories: state.sidebarTotalTagCategories - 1,
        isDeleting: false,
      };
    },
    [tagActions.deleteTagCategoryFail.toString()]: (state, action) => {
      return {
        ...state,
        isDeleting: false,
      };
    },
    [tagActions.saveTagImage.toString()]: (state, action) => {
      return {
        ...state,
        isSavingImage: true,
      };
    },
    [tagActions.saveTagImageSuccess.toString()]: (state, action) => {
      return {
        ...state,
        isSavingImage: false,
      };
    },
    [tagActions.saveTagImageFail.toString()]: (state, action) => {
      return {
        ...state,
        isSavingImage: false,
        error: action.error,
      };
    },
    [tagActions.deleteTagImage.toString()]: (state, action) => {
      return {
        ...state,
        isSavingImage: true,
      };
    },
    [tagActions.deleteTagImageSuccess.toString()]: (state, action) => {
      return {
        ...state,
        isSavingImage: false,
      };
    },
    [tagActions.deleteTagImageFail.toString()]: (state, action) => {
      return {
        ...state,
        isSavingImage: false,
        error: action.error,
      };
    },
  },
  initialState
);

export default tagsReducer;
