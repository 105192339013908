import React from 'react';
import { Collapse } from 'antd';
import { NavLink } from 'react-router-dom';
import sprite from 'images/sprite.svg';
import './MenuCollapse.scss';
import { FolderFilled } from '@ant-design/icons';

const { Panel } = Collapse;

const Item = ({ item }) => {
  return (
    <li key={item.title} className={`side-nav__item ${item.isActive}`}>
      <NavLink
        to={item.link}
        exact={true}
        className="side-nav__link "
        style={{ padding: 10 }}
      >
        <svg className="side-nav__icon">
          <use href={sprite + '#icon-beamed-note'}></use>
        </svg>

        <span style={{ fontSize: 12, textTransform: 'capitalize' }}>
          {item.title}
        </span>
      </NavLink>
      <label className="track-item-total">{item.total || '...'}</label>
    </li>
  );
};

const MenuCollapse = ({ childrens = [], title, total, isActive }) => {
  return (
    <Collapse
      expandIcon={() => <FolderFilled />}
      className="menucollapse"
      bordered={false}
    >
      <Panel
        className={isActive ? 'ant-collapse-active' : ''}
        header={
          <div className="item-menu">
            <label>{title}</label>
            <label>{total}</label>
          </div>
        }
      >
        <>
          {childrens.map((item) => (
            <div key={item.title}>
              {item.isSubMenu && (
                <Collapse
                  expandIcon={() => <FolderFilled />}
                  className="menucollapse"
                  bordered={false}
                >
                  <Panel
                    header={
                      <div className="item-menu">
                        <label>{item.title}</label>
                        <label>{item.total}</label>
                      </div>
                    }
                    className={item.isActive ? 'ant-collapse-active' : ''}
                  >
                    {item.subChildrens.map((subItem) => (
                      <Item item={subItem} />
                    ))}
                  </Panel>
                </Collapse>
              )}

              {!item.isSubMenu && <Item item={item} />}
            </div>
          ))}
        </>
      </Panel>
    </Collapse>
  );
};

export default MenuCollapse;
