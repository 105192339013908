import { createActions } from 'redux-actions';

export default createActions({
  GET_TRACK_EXPENSES: undefined,
  GET_TRACK_EXPENSES_SUCCESS: undefined,
  GET_TRACK_EXPENSES_FAILED: undefined,

  GET_EXPENSES_DETAILS: undefined,
  GET_EXPENSES_DETAILS_SUCCESS: undefined,
  GET_EXPENSES_DETAILS_FAILED: undefined,

  SAVE_TRACK_EXPENSES: undefined,
  SAVE_TRACK_EXPENSES_SUCCESS: undefined,
  SAVE_TRACK_EXPENSES_FAILED: undefined,

  UPDATE_TRACK_EXPENSES: undefined,
  UPDATE_TRACK_EXPENSES_SUCCESS: undefined,
  UPDATE_TRACK_EXPENSES_FAILED: undefined,

  DELETE_TRACK_EXPENSE: undefined,
  DELETE_TRACK_EXPENSE_SUCCESS: undefined,
  DELETE_TRACK_EXPENSE_FAILED: undefined,

  DELETE_TRACK_EXPENSES: undefined,
  DELETE_TRACK_EXPENSES_SUCCESS: undefined,
  DELETE_TRACK_EXPENSES_FAILED: undefined,

  ADD_VENDOR: undefined,
  ADD_VENDOR_SUCCESS: undefined,
  ADD_VENDOR_FAILED: undefined,

  REQUEST_EXPENSES_FILE: undefined,
  REQUEST_EXPENSES_FILE_SUCCESS: undefined,
  REQUEST_EXPENSES_FILE_FAILED: undefined,

  DOWNLOAD_EXPENSES: undefined,
  DOWNLOAD_EXPENSES_SUCCESS: undefined,
  DOWNLOAD_EXPENSES_FAILED: undefined,

  // VENDORS
  GET_VENDORS: undefined,
  GET_VENDORS_SUCCESS: undefined,
  GET_VENDORS_FAILED: undefined,

  SAVE_VENDORS: undefined,
  SAVE_VENDORS_SUCCESS: undefined,
  SAVE_VENDORS_FAILED: undefined,

  UPDATE_VENDORS: undefined,
  UPDATE_VENDORS_SUCCESS: undefined,
  UPDATE_VENDORS_FAILED: undefined,

  DELETE_VENDORS: undefined,
  DELETE_VENDORS_SUCCESS: undefined,
  DELETE_VENDORS_FAILED: undefined,

  SAVE_VENDOR_CRITERIA: undefined,
  SAVE_VENDOR_CRITERIA_SUCCESS: undefined,
  SAVE_VENDOR_CRITERIA_FAILED: undefined,

  DELETE_VENDOR_CRITERIA: undefined,
  DELETE_VENDOR_CRITERIA_SUCCESS: undefined,
  DELETE_VENDOR_CRITERIA_FAILED: undefined,

  RESTORE_VENDOR_CRITERIA: undefined,
  RESTORE_VENDOR_CRITERIA_SUCCESS: undefined,
  RESTORE_VENDOR_CRITERIA_FAILED: undefined,

  // VENDORS EXPENSE
  GET_VENDOR_EXPENSES: undefined,
  GET_VENDOR_EXPENSES_SUCCESS: undefined,
  GET_VENDOR_EXPENSES_FAILED: undefined,

  SAVE_VENDOR_EXPENSES: undefined,
  SAVE_VENDOR_EXPENSES_SUCCESS: undefined,
  SAVE_VENDOR_EXPENSES_FAILED: undefined,

  UPDATE_VENDOR_EXPENSES: undefined,
  UPDATE_VENDOR_EXPENSES_SUCCESS: undefined,
  UPDATE_VENDOR_EXPENSES_FAILED: undefined,

  DELETE_VENDOR_EXPENSES: undefined,
  DELETE_VENDOR_EXPENSES_SUCCESS: undefined,
  DELETE_VENDOR_EXPENSES_FAILED: undefined,

  SAVE_VENDOR_EXPENSE_CRITERIA: undefined,
  SAVE_VENDOR_EXPENSE_CRITERIA_SUCCESS: undefined,
  SAVE_VENDOR_EXPENSE_CRITERIA_FAILED: undefined,

  DELETE_VENDOR_EXPENSE_CRITERIA: undefined,
  DELETE_VENDOR_EXPENSE_CRITERIA_SUCCESS: undefined,
  DELETE_VENDOR_EXPENSE_CRITERIA_FAILED: undefined,

  ADD_VENDOR_EXPENSE: undefined,
  ADD_VENDOR_EXPENSE_SUCCESS: undefined,
  ADD_VENDOR_EXPENSE_FAILED: undefined,

  // SALES
  GET_SALES_SUMMARY: undefined,
  GET_SALES_SUMMARY_SUCCESS: undefined,
  GET_SALES_SUMMARY_FAILED: undefined,

  GET_SALES_FILTERS: undefined,
  GET_SALES_FILTERS_SUCCESS: undefined,
  GET_SALES_FILTERS_FAILED: undefined,

  GET_SALES_DETAILS: undefined,
  GET_SALES_DETAILS_SUCCESS: undefined,
  GET_SALES_DETAILS_FAILED: undefined,

  STORE_STATEMENT: undefined,
  STORE_STATEMENT_SUCCESS: undefined,
  STORE_STATEMENT_FAILED: undefined,

  START_UPLOAD_PROGRESS: undefined,
  UPDATE_UPLOAD_PROGRESS: undefined,

  UPDATE_SALES: undefined,
  UPDATE_SALES_SUCCESS: undefined,
  UPDATE_SALES_FAILED: undefined,

  ALLOCATE_ALBUM_SALES: undefined,
  ALLOCATE_ALBUM_SALES_SUCCESS: undefined,
  ALLOCATE_ALBUM_SALES_FAILED: undefined,

  DELETE_SONG_SALES: undefined,
  DELETE_SONG_SALES_SUCCESS: undefined,
  DELETE_SONG_SALES_FAILED: undefined,

  GET_STATEMENT: undefined,
  GET_STATEMENT_SUCCESS: undefined,
  GET_STATEMENT_FAILED: undefined,

  GET_STATEMENTS: undefined,
  GET_STATEMENTS_SUCCESS: undefined,
  GET_STATEMENTS_FAILED: undefined,

  GET_STATEMENT_SALES: undefined,
  GET_STATEMENT_SALES_SUCCESS: undefined,
  GET_STATEMENT_SALES_FAILED: undefined,

  GET_STATEMENTS_PENDING: undefined,
  GET_STATEMENTS_PENDING_SUCCESS: undefined,
  GET_STATEMENTS_PENDING_FAILED: undefined,

  DELETE_STATEMENT: undefined,
  DELETE_STATEMENT_SUCCESS: undefined,
  DELETE_STATEMENT_FAILED: undefined,

  REQUEST_SALES_FILE: undefined,
  REQUEST_SALES_FILE_SUCCESS: undefined,
  REQUEST_SALES_FILE_FAILED: undefined,

  DOWNLOAD_SALES: undefined,
  DOWNLOAD_SALES_SUCCESS: undefined,
  DOWNLOAD_SALES_FAILED: undefined,

  REQUEST_STATEMENT_FILE: undefined,
  REQUEST_STATEMENT_FILE_SUCCESS: undefined,
  REQUEST_STATEMENT_FILE_FAILED: undefined,

  DOWNLOAD_STATEMENT: undefined,
  DOWNLOAD_STATEMENT_SUCCESS: undefined,
  DOWNLOAD_STATEMENT_FAILED: undefined,

  QUICK_SEARCH_SALES: undefined,
  QUICK_SEARCH_SALES_SUCCESS: undefined,
  QUICK_SEARCH_SALES_FAILED: undefined,

  GET_EXPENSE_STATEMENTS: undefined,
  GET_EXPENSE_STATEMENTS_SUCCESS: undefined,
  GET_EXPENSE_STATEMENTS_FAILED: undefined,

  GET_STATEMENT_EXPENSES: undefined,
  GET_STATEMENT_EXPENSES_SUCCESS: undefined,
  GET_STATEMENT_EXPENSES_FAILED: undefined,

  REFRESH_TOTAL: undefined,
  REFRESH_TOTAL_SUCCESS: undefined,
  REFRESH_TOTAL_FAILED: undefined,

  GET_LAST_TOTAL_REFRESH: undefined,
  GET_LAST_TOTAL_REFRESH_SUCCESS: undefined,
  GET_LAST_TOTAL_REFRESH_FAILED: undefined,
});
