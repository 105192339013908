import React, { useCallback, useEffect } from 'react';
import Proptypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  Typography,
  Form,
  Input,
  Button,
  notification,
  Radio,
  Upload,
  Switch,
} from 'antd';
import {
  CloseOutlined,
  MinusCircleOutlined,
  PlusOutlined,
  UploadOutlined,
} from '@ant-design/icons';

import { hideAllPanes } from 'store/modules/panes/actions';

import { updateArticle, createArticle } from '../../actions';

const layout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
};

const formDefaultValues = {
  title: '',
  content: '',
  // image: '',
  source: 'all',
  type: 'articles',
  links: [],
  visible: true,
};

const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 2,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 20,
    },
  },
};
const formItemLayoutWithOutLabel = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 20,
      offset: 2,
    },
  },
};

const { Title } = Typography;
const { TextArea } = Input;

const EditPress = ({
  selectedArticle: article,
  hideAllPanes,
  isUpdating,
  createArticle,
  updateArticle,
}) => {
  const [form] = Form.useForm();

  const createMode = article.new;

  const onFinish = async (values) => {
    const sanitizedValues = {
      ...values,
    };

    if (!createMode && sanitizedValues.image[0]?.status === 'done') {
      try {
        sanitizedValues._id = article._id;
        delete sanitizedValues['image'];

        await updateArticle(sanitizedValues);

        notification.success({
          message: `Article Updated`,
          description: 'List of articles was updated',
          duration: 4,
        });
        onClose();
      } catch (error) {
        notification.warning({
          message: `Article update Failed`,
          description:
            'Information was not updated. Please try again, if the problem persist please contact support',
          duration: 4,
        });
      }
      return 0;
    }

    // return 0;
    const reader = new FileReader();

    reader.addEventListener('load', async () => {
      const base64Image = reader.result.split(',')[1];

      sanitizedValues.image = base64Image;

      try {
        if (createMode) {
          await createArticle(sanitizedValues);
        } else {
          sanitizedValues._id = article._id;

          await updateArticle(sanitizedValues);
        }

        notification.success({
          message: `Article Updated`,
          description: 'List of articles was updated',
          duration: 4,
        });
        onClose();
      } catch (error) {
        notification.warning({
          message: `Article update Failed`,
          description:
            'Information was not updated. Please try again, if the problem persist please contact support',
          duration: 4,
        });
      }
    });
    reader.readAsDataURL(sanitizedValues?.image[0].originFileObj);
  };

  const getFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  const onClose = () => {
    hideAllPanes();
    cleanState();
  };

  const cleanState = () => {
    form.resetFields();
  };

  // FORM
  const prepareFormValues = useCallback(
    (article) => {
      const {
        title,
        content,
        imageUrl: image,
        type,
        links,
        visible,
        source,
      } = article;
      const imageObject = {
        uid: '4xx',
        name: 'image.png',
        status: 'done',
        url: image,
      };
      const fields = {
        title,
        content,
        image: [imageObject],
        type,
        links,
        visible,
        source,
      };


      form.setFieldsValue(fields);
    },
    [form]
  );

  useEffect(() => {
    if (createMode) {
      form.setFieldValue({ ...formDefaultValues });
    } else {
      prepareFormValues(article);
    }
  }, [prepareFormValues, article, form]);

  return (
    <div className="vw-module edit-module content-module">
      <div className="content-title view-module-title">
        <CloseOutlined onClick={onClose} />

        <Title level={3}>{createMode ? 'Create' : 'Edit'} Article/News</Title>

        <span />
      </div>

      <div className="module-container">
        <Form
          {...layout}
          form={form}
          name="control-hooks-edit-organization"
          initialValues={{ ...formDefaultValues }}
          onFinish={onFinish}
          autoComplete="off"
        >
          <Form.Item name="title" label="Title" rules={[{ required: true }]}>
            <Input placeholder="Article Title" />
          </Form.Item>

          <Form.Item
            name="content"
            label="Content"
            rules={[{ required: true }]}
          >
            <TextArea rows={4} placeholder="Article Content" />
          </Form.Item>

          <Form.Item
            name="image"
            label="Upload Image"
            rules={[{ required: true }]}
            getValueFromEvent={getFile}
            valuePropName="fileList"
          >
            <Upload accept="image/*" listType="picture" maxCount={1}>
              <Button icon={<UploadOutlined />}>Click to Upload</Button>
            </Upload>
          </Form.Item>

          <Form.Item
            name="type"
            label="Article Type"
            rules={[{ required: true }]}
          >
            <Radio.Group>
              <Radio value="articles">Articles</Radio>
              <Radio value="news">News</Radio>
            </Radio.Group>
          </Form.Item>

          <Form.Item
            name="source"
            label="Websites"
            rules={[{ required: true }]}
          >
            <Radio.Group>
              <Radio value="all">All</Radio>
              <Radio value="phaseonenetwork">PhaseOne Network</Radio>
              <Radio value="theembassystudios">Embassy Studios</Radio>
            </Radio.Group>
          </Form.Item>

          <Form.Item
            name="visible"
            label="Public"
            rules={[{ required: true }]}
            valuePropName="checked"
          >
            <Switch />
          </Form.Item>

          <Form.List
            name="links"
            rules={[
              {
                validator: async (_, links) => {
                  if (!links || links.length < 1) {
                    return Promise.reject(
                      new Error('Please add at least 1 link.')
                    );
                  }
                },
              },
            ]}
          >
            {(fields, { add, remove }, { errors }) => (
              <>
                {fields.map((field, index) => (
                  <Form.Item
                    {...(index === 0
                      ? formItemLayout
                      : formItemLayoutWithOutLabel)}
                    label={index === 0 ? 'Links' : ''}
                    required={false}
                    key={field.key}
                  >
                    <Form.Item
                      {...field}
                      validateTrigger={['onChange', 'onBlur']}
                      rules={[
                        {
                          required: true,
                          whitespace: true,
                          message: 'Please input a link or delete this field.',
                        },
                      ]}
                      noStyle
                    >
                      <Input
                        placeholder=""
                        style={{
                          width: '60%',
                        }}
                      />
                    </Form.Item>
                    {fields.length > 1 ? (
                      <MinusCircleOutlined
                        style={{
                          margin: '0 8px',
                          color: '#999',
                          fontSize: '24px',
                        }}
                        className="dynamic-delete-button"
                        onClick={() => remove(field.name)}
                      />
                    ) : null}
                  </Form.Item>
                ))}
                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => add()}
                    style={{
                      width: '40%',
                    }}
                    icon={<PlusOutlined />}
                  >
                    Add link
                  </Button>
                  <Form.ErrorList errors={errors} />
                </Form.Item>
              </>
            )}
          </Form.List>

          <Form.Item>
            <Button
              loading={isUpdating}
              type="primary"
              htmlType="submit"
              className="btn-primary"
            >
              {createMode ? 'Create' : 'Edit'}
            </Button>

            <Button htmlType="button" onClick={onClose}>
              Cancel
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

const mapStateToProps = ({ press }) => {
  return {
    selectedArticle: press.selectedArticle,
    isUpdating: press.isUpdating,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      hideAllPanes,
      createArticle,
      updateArticle,
    },
    dispatch
  );

EditPress.defaultProps = {
  selectedArticle: {},
  hideAllPanes: () => {},
  isUpdating: false,
  createArticle: () => {},
  updateArticle: () => {},
};

EditPress.propTypes = {
  selectedArticle: Proptypes.object,
  hideAllPanes: Proptypes.func,
  isUpdating: Proptypes.bool,
  createArticle: Proptypes.func,
  updateArticle: Proptypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(EditPress);
