import React from 'react';
import { Select, Divider, Input, Typography } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import Proptypes from 'prop-types';

const { Text } = Typography;
const { Option } = Select;

class TagsDropdown extends React.Component {
  state = {
    selectedItems: [],
    name: '',
    touched: false,
  };

  componentDidUpdate(prevProps) {
    if (prevProps.value !== this.props.value) {
      this.setState({ selectedItems: this.props.value });
    }
  }

  handleChange = (selectedItems) => {
    this.props.onChangeOption(selectedItems);
    this.setState({ selectedItems });
  };

  onNameChange = (event) => {
    this.setState({
      name: event.target.value,
      touched: true,
      isEmpty: event.target.value.length === 0,
    });
  };

  addItem = () => {
    const {
      // items,
      name,
    } = this.state;
    let touched = false;

    if (name.length > 0) {
      this.props.onAddItem(name);
    } else {
      touched = true;
    }

    this.setState({
      name: '',
      touched,
    });
  };

  render() {
    const { selectedItems, touched, name } = this.state;
    const filteredOptions = this.props.options.filter(
      (o) => !selectedItems.includes(o)
    );

    return (
      <Select
        mode="multiple"
        placeholder={this.props.placeholder}
        value={selectedItems}
        onChange={this.handleChange}
        style={{ width: '100%' }}
        dropdownRender={(menu) => (
          <div>
            {menu}
            <Divider style={{ margin: '4px 0' }} />
            <div style={{ padding: 8 }}>
              <Input
                style={{ flex: 'auto' }}
                value={name}
                onChange={this.onNameChange}
              />
              <Link
                to="#"
                style={{
                  padding: '8px',
                  display: 'flex',
                  alignItems: 'center',
                  cursor: 'pointer',
                }}
                onClick={this.addItem}
              >
                <PlusOutlined />
                <span style={{ paddingLeft: '5px' }}>Create New Item</span>
              </Link>

              {name.length === 0 && touched && (
                <Text style={{ display: 'block' }} type="danger">
                  Please enter a name
                </Text>
              )}
            </div>
          </div>
        )}
      >
        {filteredOptions.map((item) => (
          <Option key={item} value={item}>
            {item}
          </Option>
        ))}
      </Select>
    );
  }
}

TagsDropdown.defaultProps = {
  value: [],
  onAddItem: () => {},
  onChangeOption: () => {},
};

TagsDropdown.propTypes = {
  value: Proptypes.array,
  onAddItem: Proptypes.func,
  onChangeOption: Proptypes.func,
};

export default TagsDropdown;
