import store from '../store/setupStore';
import notificationsActions from '../store/modules/notifications/actions/actionTypes';

export const Api = process.env.REACT_APP_API_ENDPOINT;
export const RendererApi = 'https://renderer.phaseonenetwork.net';
export const UrlAfterLogin = '/tracks';
export const UrlLogin = '/login';
export const CDN_URL = 'https://ponimages.sfo2.cdn.digitaloceanspaces.com';
export const S3_FILE_BUCKET_ENDPOINT =
  'https://phaseonenetwork-cloud.s3.us-east-2.amazonaws.com';
export const MAX_PANES = 2;

export const filePaths = {
  audio: 'https://ponaudio.sfo2.cdn.digitaloceanspaces.com',
};

// Show error
// Message => string
export const show_error = ({ message }) => {
  store.dispatch(
    notificationsActions.showError(
      message && typeof message === 'string' ? message : 'Something went wrong'
    )
  );
};

export const ISRC_MAX_CAT_NUMBER = 99999;
export const ISRC_MIN_LENGTH = 5;
export const ISRC_CAT_NUMBER_LENGTH = 5;

export const UPC_MAX_CAT_NUMBER = 9999;
export const UPC_MIN_LENGTH = 6;
export const UPC_CAT_NUMBER_LENGTH = 4;
export const UPC_ASSET_FORMAT = 9;

export const artworkConfig = {
  small: {
    width: 125,
    height: 125,
  },
  medium: {
    width: 750,
    height: 750,
  },
  large: {
    width: 1250,
    height: 1250,
  },
};

export const SERVER_ERRORS = {
  DUPLICATED_KEY:
    'A Record with the same Key or Name already exists. Please double check your data.',
  DUPLICATED_EMAIL: 'A Record with the same email already exists.',
  INVALID_SONG_CODE: 'The song code for your query is invalid.',
  NO_SONG_FOUND: 'Song was not found in Spotify Data',
  VALIDATION_ERROR:
    'A required field is empty or it is invalid, please check your data and try again.',
  INVALID_ID: 'The Id provided is invalid.',
  DEFAULT_CODE_INVALID_ACTION: 'Default codes can not be deleted.',
  UNAUTHORIZED: 'The password or username is incorrect.',
  ALREADY_REQUESTED_FILE: 'File was already requested by another user.',
  MAX_DOCUMENTS_PER_FILE_EXCEEDED: 'Max documents limit exceeded.',
  CREDENTIALS_ALREADY_CREATED:
    'Credentials for this partner were already generated.',
  INVALID_EMAIL: 'Please provide a valid email for this partner.',
  INVALID_USERNAME: 'Please provide a valid username for this partner.',
  DUPLICATED_USERNAME: 'A Record with the same username already exists.',
};

export const partnerStatus = {
  UNREGISTERED: 'UNREGISTERED',
  PENDING: 'PENDING',
  REGISTERED: 'REGISTERED',
  DISABLED: 'DISABLED',
};

export const PAGE_SIZE_OPTIONS = [20, 50, 100, 250];
