import * as externalAxios from 'axios';
import { push } from 'react-router-redux';
import queryString from 'query-string';
import store from 'store/setupStore';

import { handleServerError } from 'utils';
import { show_error } from 'utils/consts';
import { axios } from 'services/axios';

import trackActions from './actionTypes';
import projectsActions from '../../projects/actions/actionTypes';
import labelsActions from '../../labels/actions/actionTypes';
import { notification } from 'antd';

const songEntities = {
  projects: {
    method: projectsActions.getProjectTracksSuccess,
    field: 'project_title',
    tracksField: 'projectTracks',
    totalKey: 'totalProjectTracks',
  },
  labels: {
    method: labelsActions.getLabelTracksSuccess,
    field: 'label',
    tracksField: 'labelTracks',
    totalKey: 'totalLabelTracks',
  },
};

export const setTracks = (tracks) => trackActions.setTracks({ tracks });

export const setTotalTracks = (totalTracks) =>
  trackActions.setTotalTracks({ totalTracks });

export const clearTracks = () => trackActions.clearTracks();

const push_query = () => {
  return push({
    search: queryString.stringify(store.getState().tracks.filters),
  });
};

export const getSongs = (query) => {
  let filters = Object.assign(store.getState().tracks.filters || {}, query);
  return async (dispatch) => {
    dispatch(trackActions.addFilters({ filters }));
    dispatch(trackActions.setFetching());

    try {
      const response = await axios.get(`songs`, {
        params: { ...filters },
      });

      const { songs, filters: responseFilters } = response.data;

      dispatch(
        trackActions.getSongs({
          songs: songs,
          filters: responseFilters,
        })
      );
    } catch (error) {
      dispatch(trackActions.setNotFetching());
      dispatch(trackActions.getSongsFailed(error));

      return show_error({ message: error?.response?.data });
    }
  };
};

export const quickSearch = (string) => {
  return async (dispatch) => {
    try {
      const response = await axios.get(`quick_search/${string}`);

      dispatch(trackActions.quickSearchTrack({ searchResults: response.data }));
    } catch (error) {
      dispatch(trackActions.quickSearchTrackFailed(error));

      return show_error({ message: error?.response?.data });
    }
  };
};

export const deleteSong = (id) => {
  return (dispatch) => {
    axios
      .post(`songs/delete`, { id })
      .then((response) => {
        dispatch(trackActions.deleteSong({ id }));
      })
      .catch((error) => {
        dispatch(trackActions.deleteSongFailed(error));

        return show_error({ message: error?.response?.data });
      });
  };
};

export const getDistincts = (revenueType, vendor) => {
  return (dispatch) => {
    axios
      .get(`distincts?revenueType=${revenueType}&vendor=${vendor}`)
      .then((response) => {
        dispatch(trackActions.getDistincts(response.data));
      })
      .catch((error) => {
        dispatch(trackActions.getDistinctsFailed(error));

        return show_error({ message: error?.response?.data });
      });
  };
};

export const addFilter = (filter) => {
  return (dispatch) => {
    dispatch(trackActions.addFilter(filter));
    dispatch(trackActions.setFetching());

    axios
      .get(`songs`, { params: store.getState().tracks.filters })
      .then((response) => {
        const { songs, filters } = response.data;

        dispatch(
          trackActions.getSongs({
            songs,
            filters,
          })
        );
        dispatch(push_query());
      })
      .catch((error) => {
        dispatch(trackActions.setNotFetching());
        dispatch(trackActions.addFilterFailed(error));

        return show_error({ message: error?.response?.data });
      });
  };
};

export const addArrayFilter = (filter) => {
  return (dispatch) => {
    dispatch(trackActions.addArrayFilter(filter));
  };
};

export const removeFilter = (key) => {
  return (dispatch) => {
    dispatch(trackActions.removeFilter(key));
    dispatch(trackActions.setFetching());
    axios
      .get(`songs`, { params: store.getState().tracks.filters })
      .then((response) => {
        const { songs, filters } = response.data;

        dispatch(
          trackActions.getSongs({
            songs,
            filters,
          })
        );
        dispatch(push_query());
      })
      .catch((error) => {
        dispatch(trackActions.setNotFetching());
        dispatch(trackActions.removeFilterFailed(error));

        return show_error({ message: error?.response?.data });
      });
  };
};

export const changePage = (page_number) => {
  return (dispatch) => {
    dispatch(trackActions.updatePage({ page_number }));
    dispatch(trackActions.setFetching());

    axios
      .get(`songs`, { params: store.getState().tracks.filters })
      .then((response) => {
        dispatch(trackActions.setNotFetching());
        const { songs, filters } = response.data;

        dispatch(
          trackActions.getSongs({
            songs,
            filters,
          })
        );
        dispatch(push_query());
      })
      .catch((error) => {
        dispatch(trackActions.updatePageFailed(error));

        return show_error({ message: error?.response?.data });
      });
  };
};

export const resetFilters = () => {
  return (dispatch) => {
    dispatch(trackActions.resetFilters());
    dispatch(trackActions.setFetching());
    axios
      .get(`songs`, { params: store.getState().tracks.filters })
      .then((response) => {
        dispatch(trackActions.setNotFetching());
        const { songs, filters } = response.data;

        dispatch(
          trackActions.getSongs({
            songs,
            filters,
          })
        );
        dispatch(push_query());
      })
      .catch((error) => {
        dispatch(trackActions.resetFiltersFailed(error));

        return show_error({ message: error?.response?.data });
      });
  };
};

export const updateFavorite = (id) => {
  return (dispatch) => {
    axios
      .post(`update_favorite`, { id })
      .then((response) =>
        dispatch(trackActions.updateFavorite({ id, value: response.data }))
      )
      .catch((error) => {
        dispatch(trackActions.updateFavoriteFailed(error));

        return show_error({ message: error?.response?.data });
      });
  };
};

export const getTrack = (id) => {
  return (dispatch) => {
    dispatch(trackActions.getTrackStart());

    axios
      .get(`/songs/${id}`)
      .then((response) => {
        dispatch(
          trackActions.getTrack({
            track: response.data.song,
          })
        );
      })
      .catch((error) => {
        dispatch(trackActions.getTrackFail(error));

        return show_error({
          message: `Couldn't find the track, please try again.`,
        });
      });
  };
};

export const clearTrack = () => {
  return (dispatch) => {
    dispatch(trackActions.clearTrack());
  };
};

export const addTrack = (track, redirect = true) => {
  return (dispatch) => {
    dispatch(trackActions.addTrack());

    axios
      .post(`/track`, track)
      .then((response) => {
        if (redirect) {
          dispatch(push(`/tracks/${response.data._id}`));
        }
        dispatch(trackActions.addTrackSuccess());
      })
      .catch((error) => {
        dispatch(trackActions.addTrackFail(error));

        const errorMessage = handleServerError(error.response.data);

        show_error({ message: errorMessage });
      });
  };
};

export const updateTrack = (track) => {
  return async (dispatch) => {
    dispatch(trackActions.updateTrack());

    try {
      const response = await axios.patch(`/track`, track);

      dispatch(trackActions.updateTrackSuccess({ track: response.data }));
    } catch (error) {
      dispatch(trackActions.updateTrackFail(error));

      const errorMessage = handleServerError(error.response.data);

      return show_error({ message: errorMessage });
    }
  };
};

export const uploadAudio = (id, file) => {
  const data = new FormData();

  data.append('audio', file);

  return (dispatch) => {
    dispatch(trackActions.toggleBool({ key: 'uploadingAudio', value: true }));
    axios
      .post(`/songs/audio/${id}?fileType=${file.type}`, data)
      .then((response) => dispatch(trackActions.uploadAudio(response.data)))
      .catch((error) => {
        dispatch(trackActions.uploadAudioFailed(error));
      });
  };
};

export const removeAudio = (id, file) => {
  return (dispatch) => {
    axios
      .post(`remove/mp3`, { id, file })
      .then((respone) => dispatch(trackActions.removeAudio()))
      .catch((error) => {
        dispatch(trackActions.removeAudioFailed(error));
      });
  };
};

/// up done
export const saveTrackArtwork = (signedUrl, file) => {
  return async (dispatch) => {
    dispatch(trackActions.saveTrackArtwork());

    try {
      await externalAxios.put(signedUrl, file, {
        headers: {
          'x-amz-acl': 'public-read',
          'Content-Type': file.type,
        },
      });

      dispatch(trackActions.saveTrackArtworkSuccess());
    } catch (error) {
      dispatch(trackActions.saveTrackArtworkFail(error));

      throw error;
    }
  };
};

export const deleteTrackArtwork = (image) => {
  return async (dispatch) => {
    dispatch(trackActions.deleteTrackArtwork());
    try {
      await axios.post('/songs/image/delete', { image });

      dispatch(trackActions.deleteTrackArtworkSuccess());
    } catch (error) {
      dispatch(trackActions.deleteTrackArtworkFail(error));

      throw error;
    }
  };
};

// NEEDS REFACTOR
export const addTracksToEntity = (
  songsIds = [],
  entityName = '',
  type = '',
  limit = 10
) => {
  return async (dispatch, getState) => {
    dispatch(trackActions.addTracksToEntity());
    try {
      const panelPage = getState()[type].panelPage;

      const response = await axios.patch('/songs-to-entity', {
        songsIds,
        entityName,
        type,
        limit,
        page: panelPage,
      });

      dispatch(trackActions.addTracksToEntitySuccess());
      const entityMethod = songEntities[type].method;

      dispatch(
        entityMethod({
          tracks: response.data.songs,
          [songEntities[type].totalKey]: response.data.totalEntitySongs,
        })
      );
    } catch (error) {
      dispatch(trackActions.addTracksToEntityFail(error));

      throw error;
    }
  };
};

// NEEDS REFACTOR

export const removeTracksFromEntity = (songsIds, entity) => {
  return async (dispatch, getState) => {
    dispatch(trackActions.removeTracksFromEntity());

    try {
      await axios.patch('/songs/remove-entity', {
        songsIds,
        entity,
      });

      dispatch(trackActions.removeTracksFromEntitySuccess());

      const tracksField = songEntities[entity].tracksField;
      const totalField = songEntities[entity].totalKey;

      const entityTracks = getState()[entity][tracksField];
      const entityTotalTracks = getState()[entity][totalField];

      const entityMethod = songEntities[entity].method;

      const tracks = entityTracks.filter(
        (track) => !songsIds.includes(track._id)
      );

      dispatch(
        entityMethod({
          tracks,
          [songEntities[entity].totalKey]: entityTotalTracks - songsIds.length,
        })
      );
    } catch (error) {
      dispatch(trackActions.removeTracksFromEntityFail(error));

      throw error;
    }
  };
};

// NEEDS REFACTOR
export const updateSelectedTracks = (
  tracksIds,
  fields,
  { entity, originalEntityValue }
) => {
  return async (dispatch, getState) => {
    dispatch(trackActions.updateSelectedTracks());
    try {
      const response = await axios.patch('/songs/updateAll', {
        songsIds: tracksIds,
        ...fields,
      });

      dispatch(trackActions.updateSelectedTracksSuccess());

      // after editing actions
      if (entity !== 'content-libraries' && entity !== 'tracks') {
        const entityMethod = songEntities[entity].method;
        const field = songEntities[entity].field;
        const totalKey = songEntities[entity].totalKey;

        const entityState = getState()[entity];
        const tracks = entityState[songEntities[entity].tracksField];
        let totalTracks = entityState[totalKey];

        const responseTracks = response.data.songs;

        const ids = new Set(responseTracks.map((d) => d._id));
        let updatedTracks = [
          ...responseTracks,
          ...tracks.filter((d) => !ids.has(d._id)),
        ];

        // removing track that its entity name (label, project) has changed
        updatedTracks = updatedTracks.filter((track) => {
          if (track[field] === originalEntityValue) {
            return track;
          }

          return null;
        });

        dispatch(
          entityMethod({ tracks: updatedTracks, [totalKey]: totalTracks })
        );
      }
    } catch (error) {
      dispatch(trackActions.updateSelectedTracksFail(error));

      const errorMessage = handleServerError(error.response.data);

      show_error({ message: errorMessage });
    }
  };
};

export const associateTrack = (targetSong, sourceSong) => {
  return async (dispatch) => {
    dispatch(trackActions.associateTrack());

    try {
      const response = await axios.patch('/songs/associate', {
        targetSong,
        sourceSong,
      });

      const { associatedSong } = response.data;

      dispatch(
        trackActions.associateTrackSuccess({
          associatedSong,
          index: targetSong.index,
        })
      );
    } catch (error) {
      dispatch(trackActions.associateTrackFail(error));

      throw error;
    }
  };
};

export const massAssociateTrack = (sourceSong) => {
  return async (dispatch) => {
    dispatch(trackActions.associateTrack());

    try {
      const response = await axios.patch('/songs/mass-associate', {
        sourceSong,
      });

      const { associationDetails } = response.data;

      dispatch(
        trackActions.massAssociateTrackSuccess({
          associationDetails,
        })
      );
    } catch (error) {
      dispatch(trackActions.massAssociateTrackFail(error));

      throw error;
    }
  };
};

export const generateCSVFile = () => {
  return async (dispatch) => {
    dispatch(trackActions.generateCsvData());

    try {
      const response = await axios.get('/songs/csv-data');

      const { songs } = response.data;

      dispatch(trackActions.generateCsvDataSuccess({ songs }));
    } catch (error) {
      dispatch(trackActions.generateCsvDataFail(error));

      throw error;
    }
  };
};

export const setCopySplit = (id) => {
  return async (dispatch) => {
    dispatch(trackActions.setCopySplit(id));
  };
};

export const clearCopySplit = () => {
  return async (dispatch) => {
    dispatch(trackActions.clearCopySplit());
  };
};

export const pasteSplits = ({ source, target, targets }) => {
  return async (dispatch) => {
    dispatch(trackActions.pasteSplits());

    try {
      await axios.patch('/songs/copySplits', {
        source,
        target,
        targets,
      });

      notification.success({
        message: `Success`,
        description: 'Splits successfully copied!',
        duration: 5,
      });

      dispatch(trackActions.pasteSplitsSuccess());
    } catch (error) {
      dispatch(trackActions.pasteSplitsFail(error));

      throw error;
    }
  };
};

export const copySongData = ({ source, target }) => {
  return async (dispatch) => {
    dispatch(trackActions.copySongData());

    try {
      const { data } = await axios.patch('/songs/copySongData', {
        source,
        target,
      });

      notification.success({
        message: `Success`,
        description: 'Data successfully copied!',
        duration: 5,
      });

      dispatch(trackActions.copySongDataSuccess({ song: data.song }));
    } catch (error) {
      dispatch(trackActions.copySongDataFail(error));

      throw error;
    }
  };
};

export const getSimilarSongs = (filters) => {
  return async (dispatch) => {
    dispatch(trackActions.getSimilarSongs());

    try {
      const response = await axios.get(`songs`, {
        params: { ...filters },
      });

      const { songs } = response.data;

      dispatch(trackActions.getSimilarSongsSuccess({ songs }));
    } catch (error) {
      dispatch(trackActions.getSimilarSongsFailed(error));

      return show_error({ message: error?.response?.data });
    }
  };
};

export const setCopySong = (song) => {
  return async (dispatch) => {
    dispatch(trackActions.setCopySong(song));
  };
};

export const clearCopySong = () => {
  return async (dispatch) => {
    dispatch(trackActions.clearCopySong());
  };
};

export const deleteSelectedTracks = (songsIds) => {
  return async (dispatch) => {
    dispatch(trackActions.deleteSelectedTracks());

    try {
      const response = await axios.delete('/songs/deleteAll', {
        data: {
          songsIds,
        },
      });

      const { songsDeleted } = response.data;

      dispatch(trackActions.deleteSelectedTracksSuccess(songsDeleted));
    } catch (error) {
      dispatch(trackActions.deleteSelectedTracksFail(error));

      throw error;
    }
  };
};
