//
//  Checks if the element is visible on the view
//
export const isElementVisible = el => {
    if(el) {
        var rect     = el.getBoundingClientRect()
        var vWidth   = window.innerWidth || document.documentElement.clientWidth
        var vHeight  = window.innerHeight || document.documentElement.clientHeight
        var efp      = function (x, y) { return document.elementFromPoint(x, y) };     

        if (rect.right < -20 || rect.bottom < -20
                || rect.left > vWidth || rect.top > vHeight)
            return false;

        return (
            el.contains(efp(rect.left,  rect.top))
        ||  el.contains(efp(rect.right, rect.top))
        ||  el.contains(efp(rect.right, rect.bottom))
        ||  el.contains(efp(rect.left,  rect.bottom))
        );
    } else {
        return
    }
}

//
//   Gets the width by element id
//
export const getElementWidth = id => {
    if(!id) {
        return false
    }
    let el = document.getElementById(id)
    if(el) {
       return el.clientWidth
    } else if(el === undefined) {
        return false
    }
}