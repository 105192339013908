import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Select, Divider, Input, Typography } from 'antd';
import { PlusOutlined, MinusCircleOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';

const { Text } = Typography;
const { Option } = Select;

class SelectWithInput extends Component {
  state = {
    items: [],
    name: '',
    touched: false,
    bulkId: '',
  };

  async componentDidMount() {
    const { items, isMultiple } = this.props;

    this.props.onLoad();

    this.setState({
      items: this.buildOptionsArray(items),
      selectValue: isMultiple ? [] : '',
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.items !== this.props.items) {
      this.setState({ items: this.buildOptionsArray(this.props.items) });
    }

    if (prevProps.initialValue !== this.props.initialValue) {
      return this.setState({
        selectValue: this.buildInitialValue(this.props.items),
      });
    }
  }

  buildInitialValue = (items) => {
    const { isMultiple, initialValue, labelKey } = this.props;

    const reducer = (acc, currentValue) => {
      if (initialValue.includes(currentValue.name)) {
        acc.push(currentValue._id);
      }

      return acc;
    };

    // value already is an array
    if (isMultiple && initialValue) {
      return items.reduce(reducer, []);
    }

    // build custom array for non multi selects
    const value = items.find((item) => item._id === initialValue);

    if (value) {
      return value[labelKey];
    }
  };

  buildOptionsArray = (items) => {
    return items.map((item) => ({
      title: item[this.props.labelKey],
      id: item._id,
    }));
  };

  onNameChange = (event) => {
    this.setState({
      name: event.target.value,
      touched: true,
      isEmpty: event.target.value.length === 0,
    });
  };

  onSelectChangeHandler = (value, fields) => {
    const { isMultiple } = this.props;
    const item = this.state.items.find((item) => item.id === value);

    let selectedOption = {
      value,
      label: item.title || item.name,
      touched: value.length > 0,
    };

    if (isMultiple) {
      const multipleOption = fields.map((field) => ({
        label: item.title || item.name,
        value: field.value,
      }));
      selectedOption.label = multipleOption;
      this.props.onChangeOption(multipleOption);
    } else {
      this.props.onChangeOption(selectedOption);
    }

    this.setState({
      selectValue: isMultiple ? value : selectedOption.label,
      name: '',
      touched: false,
    });
  };

  addItem = async () => {
    try {
      const { items, name } = this.state;
      let updatedItems;
      let touched = false;

      if (name.length > 0) {
        await this.props.onAddItem(name);
        updatedItems = [...items, { name, id: name }];
      } else {
        updatedItems = [...items];
        touched = true;
      }

      this.setState({
        items: updatedItems,
        name: '',
        touched,
      });
    } catch (error) {}
  };

  render() {
    const { items = [], name, touched, selectValue } = this.state;
    const { selectPlaceholder, isMultiple, customClass, showSearch, onSearch } =
      this.props;

    return (
      <Select
        className={customClass}
        mode={isMultiple ? 'multiple' : ''}
        onChange={this.onSelectChangeHandler}
        placeholder={selectPlaceholder}
        showSearch={showSearch}
        onSearch={onSearch}
        value={selectValue}
        dropdownRender={(menu) => (
          <div>
            {menu}
            <Divider style={{ margin: '4px 0' }} />
            <div style={{ padding: 8 }}>
              <Input
                style={{ flex: 'auto' }}
                value={name}
                onChange={this.onNameChange}
              />
              <Link
                to="#"
                style={{
                  padding: '8px',
                  display: 'flex',
                  alignItems: 'center',
                  cursor: 'pointer',
                }}
                onClick={this.addItem}
              >
                <PlusOutlined />{' '}
                <span style={{ paddingLeft: '5px' }}>Create New Item</span>
              </Link>

              {name.length === 0 && touched && (
                <Text style={{ display: 'block' }} type="danger">
                  Please enter a name
                </Text>
              )}
            </div>
          </div>
        )}
      >
        {items.map((item, index) => (
          <Option key={`${item.id}${index}`} value={item.id}>
            {item.title || item.name}

            {this.props.delete && (
              <MinusCircleOutlined
                style={{
                  right: 10,
                  position: 'absolute',
                  marginRight: 0,
                  color: '#db6f93',
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  this.props.delete(item.id);
                }}
              />
            )}
          </Option>
        ))}
      </Select>
    );
  }
}

SelectWithInput.defaultProps = {
  customClass: '',
  labelKey: 'title',
  initialValue: '',
  items: [],
  isMultiple: false,
  onLoad: () => {},
  onAddItem: () => {},
  onChangeOption: () => {},
  selectPlaceholder: 'Please select...',
  showSearch: false,
};

SelectWithInput.propTypes = {
  customClass: PropTypes.string,
  labelKey: PropTypes.string,
  initialValue: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  items: PropTypes.array,
  isMultiple: PropTypes.bool,
  onLoad: PropTypes.func,
  onAddItem: PropTypes.func,
  onChangeOption: PropTypes.func,
  selectPlaceholder: PropTypes.string,
  showSearch: PropTypes.bool,
};

export default SelectWithInput;
