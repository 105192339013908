import { createActions } from 'redux-actions';

export default createActions({
  SET_TAG: undefined,
  SET_TOTAL_TAGS: undefined,
  SET_TOTAL_TAG_CATEGORIES: undefined,
  GET_TAGS: undefined,
  GET_TAGS_SUCCESS: undefined,
  GET_TAGS_FAIL: undefined,
  QUICK_SEARCH_TAG: undefined,
  QUICK_SEARCH_TAG_SUCCESS: undefined,
  QUICK_SEARCH_TAG_FAIL: undefined,
  GET_TAG_CATEGORIES: undefined,
  GET_TAG_CATEGORIES_SUCCESS: undefined,
  GET_TAG_CATEGORIES_FAIL: undefined,
  GET_TAG_SUB_CATEGORIES: undefined,
  GET_TAG_SUB_CATEGORIES_SUCCESS: undefined,
  GET_TAG_SUB_CATEGORIES_FAIL: undefined,
  QUICK_SEARCH_TAG_CATEGORY: undefined,
  QUICK_SEARCH_TAG_CATEGORY_SUCCESS: undefined,
  QUICK_SEARCH_TAG_CATEGORY_FAIL: undefined,
  CREATE_TAG: undefined,
  CREATE_TAG_SUCCESS: undefined,
  CREATE_TAG_FAIL: undefined,
  CREATE_TAG_CATEGORY: undefined,
  CREATE_TAG_CATEGORY_SUCCESS: undefined,
  CREATE_TAG_CATEGORY_FAIL: undefined,
  CREATE_TAG_SUB_CATEGORY: undefined,
  CREATE_TAG_SUB_CATEGORY_SUCCESS: undefined,
  CREATE_TAG_SUB_CATEGORY_FAIL: undefined,
  UPDATE_TAG: undefined,
  UPDATE_TAG_SUCCESS: undefined,
  UPDATE_TAG_FAIL: undefined,
  UPDATE_TAG_CATEGORY: undefined,
  UPDATE_TAG_CATEGORY_SUCCESS: undefined,
  UPDATE_TAG_CATEGORY_FAIL: undefined,
  DELETE_TAG: undefined,
  DELETE_TAG_SUCCESS: undefined,
  DELETE_TAG_FAIL: undefined,
  DELETE_TAG_CATEGORY: undefined,
  DELETE_TAG_CATEGORY_SUCCESS: undefined,
  DELETE_TAG_CATEGORY_FAIL: undefined,
  DELETE_TAG_SUB_CATEGORY: undefined,
  DELETE_TAG_SUB_CATEGORY_SUCCESS: undefined,
  DELETE_TAG_SUB_CATEGORY_FAIL: undefined,
  SAVE_TAG_IMAGE: undefined,
  SAVE_TAG_IMAGE_SUCCESS: undefined,
  SAVE_TAG_IMAGE_FAIL: undefined,
  DELETE_TAG_IMAGE: undefined,
  DELETE_TAG_IMAGE_SUCCESS: undefined,
  DELETE_TAG_IMAGE_FAIL: undefined,
});
