export const VIEW_PROJECT = 'VIEW_PROJECT';
export const EDIT_PROJECT = 'EDIT_PROJECT';
export const VIEW_LABEL = 'VIEW_LABEL';
export const EDIT_LABEL = 'EDIT_LABEL';
export const SPOTIFY_IMPORTER = 'SPOTIFY_IMPORTER';
export const EDIT_TRACKS = 'EDIT_TRACKS';
export const EDIT_TAG = 'EDIT_TAG';
export const EDIT_TAG_CATEGORY = 'EDIT_TAG_CATEGORY';
export const ADD_PARTNER = 'ADD_PARTNER';
export const PARTNERS_VIEW = 'PARTNERS_VIEW';
export const VENDORS = 'VENDORS';
export const EDIT_ORGANIZATION = 'EDIT_ORGANIZATION';
export const EDIT_ARTICLE = 'EDIT_ARTICLE';
