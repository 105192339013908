import React, { useState, useEffect } from 'react';
import { Upload } from 'antd';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';

import { beforeUpload, buildTrackArtworkURL, getBase64 } from '../../../utils';
import { CDN_URL } from '../../../utils/consts';

import './ImageUploader.scss';

const ImageUploader = ({
  onImageSelect,
  section,
  endpoint,
  entity,
  imageField,
}) => {
  const [loadingImage, setLoadingImage] = useState(false);
  const [imageUrl, setImageUrl] = useState('');
  let imagePreview;

  const onActionHandler = async (fileInfo) => {
    return `${endpoint}&fileType=${fileInfo.type}&section=${section}`;
  };

  const handleImageChange = async (info) => {
    if (info.file.status === 'uploading') {
      setLoadingImage(true);
      return;
    }

    if (info.file.status === 'done') {
      getBase64(info.file.originFileObj, (imageUrl) => {
        setLoadingImage(false);
        setImageUrl(imageUrl);
        onImageSelect({
          signedUrlSettings: info.file.response,
          file: info.file.originFileObj,
        });
      });
    }
  };

  const uploadButton = (
    <div>
      {loadingImage ? <LoadingOutlined /> : <PlusOutlined />}
      <div className="ant-upload-text">
        Click or drag file to this area to upload
      </div>
    </div>
  );

  if (!imageUrl && entity[imageField]) {
    if (entity.hasOwnProperty('isrc')) {
      imagePreview = buildTrackArtworkURL(entity[imageField], 'large');
    } else if (entity[imageField].hasOwnProperty('small')) {
      imagePreview = `${CDN_URL}/${entity[imageField].small}`;
    } else {
      imagePreview = `${CDN_URL}/${entity[imageField]}`;
    }
  } else {
    imagePreview = imageUrl;
  }

  useEffect(() => {
    return () => {
      setImageUrl('');
      setLoadingImage(false);
    };
  }, []);

  return (
    <Upload
      name="image"
      listType="picture-card"
      className="avatar-uploader"
      showUploadList={false}
      beforeUpload={beforeUpload}
      onChange={handleImageChange}
      action={onActionHandler}
      headers={{
        Authorization: localStorage.getItem('token')
          ? localStorage.getItem('token')
          : '',
      }}
    >
      {imagePreview ? (
        <img
          src={imagePreview}
          alt={`${section} logo`}
          style={{ width: '100%' }}
        />
      ) : (
        uploadButton
      )}
    </Upload>
  );
};

ImageUploader.defaultProps = {
  onImageSelect: () => {},
  entity: {},
  imageField: 'imageUrl',
};

ImageUploader.propTypes = {
  onImageSelect: PropTypes.func,
  endpoint: PropTypes.string.isRequired,
  section: PropTypes.string.isRequired,
  entity: PropTypes.object,
  imageField: PropTypes.string.isRequired,
};

export default ImageUploader;
