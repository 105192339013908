import { createActions } from 'redux-actions';

export default createActions({
  GET_CATALOG_METADATA: undefined,
  GET_CATALOG_METADATA_SUCCESS: undefined,
  GET_CATALOG_METADATA_FAIL: undefined,
  GET_CATALOGS: undefined,
  GET_CATALOGS_SUCCESS: undefined,
  GET_CATALOGS_FAIL: undefined,
  CREATE_CATALOG: undefined,
  CREATE_CATALOG_SUCCESS: undefined,
  CREATE_CATALOG_FAIL: undefined,
});
