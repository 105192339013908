import { axios } from 'services/axios';

import actionTypes from './actionTypes';

const {
  getSpreadsheetStart,
  getSpreadsheetSuccess,
  getSpreadsheetFail,
  getSpreadsheetsStart,
  getSpreadsheetsSuccess,
  getSpreadsheetsFail,
  createSpreadsheetStart,
  createSpreadsheetSuccess,
  createSpreadsheetFail,
  saveMasterSpreadsheetDataStart,
  saveMasterSpreadsheetDataSuccess,
  saveMasterSpreadsheetDataFail,
  savePublisherSpreadsheetDataStart,
  savePublisherSpreadsheetDataSuccess,
  savePublisherSpreadsheetDataFail,
  updateSpreadsheetStart,
  updateSpreadsheetSuccess,
  updateSpreadsheetFail,
  deleteSpreadsheetStart,
  deleteSpreadsheetSuccess,
  deleteSpreadsheetFail,
} = actionTypes;

export const getSpreadsheet = (id) => {
  return async (dispatch) => {
    dispatch(getSpreadsheetStart());
    try {
      const response = await axios.get(`/spreadsheet/${id}`);

      dispatch(
        getSpreadsheetSuccess({ spreadsheet: response.data.spreadsheet })
      );
    } catch (error) {
      dispatch(getSpreadsheetFail(error));

      throw error;
    }
  };
};

export const getSpreadsheets = (params) => {
  return async (dispatch) => {
    dispatch(getSpreadsheetsStart());
    try {
      const { page, limit } = params;
      const response = await axios.get('/spreadsheets/', {
        params: { currentPage: page, limit },
      });

      const { spreadsheets, totalSheets } = response.data;

      dispatch(getSpreadsheetsSuccess({ spreadsheets, totalSheets }));
    } catch (error) {
      dispatch(getSpreadsheetsFail(error));

      throw error;
    }
  };
};

export const createSpreadsheet = (properties) => {
  return async (dispatch, getState) => {
    const author = getState().auth.user.username;

    dispatch(createSpreadsheetStart());

    try {
      const response = await axios.post('/spreadsheet', {
        createdAt: new Date().toISOString(),
        latestModified: new Date().toISOString(),
        author,
        ...properties,
      });

      const spreadsheetId = response.data.spreadsheet._id;

      dispatch(
        createSpreadsheetSuccess({ spreadsheet: response.data.spreadsheet })
      );

      // ugly approach due to push issue
      window.open(
        `/spreadsheets/${properties.type}/${spreadsheetId}`,
        '_blank'
      );
    } catch (error) {
      dispatch(createSpreadsheetFail(error));

      throw error;
    }
  };
};

export const updateSpreadsheet = (id, fields) => {
  return async (dispatch) => {
    dispatch(updateSpreadsheetStart());

    try {
      const response = await axios.patch(`/spreadsheet/${id}`, {
        ...fields,
        latestModified: new Date().toISOString(),
      });

      dispatch(
        updateSpreadsheetSuccess({ currentSheet: response.data.spreadsheet })
      );
    } catch (error) {
      dispatch(updateSpreadsheetFail(error));

      throw error;
    }
  };
};

export const saveMasterSpreadsheetData = (fields) => {
  return async (dispatch, getState) => {
    const author = getState().auth.user.username;

    dispatch(saveMasterSpreadsheetDataStart());

    try {
      const response = await axios.post('/spreadsheets/master-data', {
        ...fields,
        author,
        createdAt: new Date().toISOString(),
        latestModified: new Date().toISOString(),
      });

      dispatch(
        saveMasterSpreadsheetDataSuccess({ spreadsheetData: response.data })
      );
    } catch (error) {
      dispatch(saveMasterSpreadsheetDataFail(error));

      throw error;
    }
  };
};

export const savePublisherSpreadsheetData = (fields) => {
  return async (dispatch, getState) => {
    const author = getState().auth.user.username;

    dispatch(savePublisherSpreadsheetDataStart());

    try {
      const response = await axios.post('/spreadsheets/publisher-data', {
        ...fields,
        author,
        latestModified: new Date().toISOString(),
      });

      dispatch(
        savePublisherSpreadsheetDataSuccess({ spreadsheetData: response.data })
      );
    } catch (error) {
      dispatch(savePublisherSpreadsheetDataFail(error));

      throw error;
    }
  };
};

export const deleteSpreadsheet = (sheetId) => {
  return async (dispatch) => {
    dispatch(deleteSpreadsheetStart());
    try {
      const response = await axios.delete(`/spreadsheet?sheetId=${sheetId}`);

      dispatch(
        deleteSpreadsheetSuccess({ deletedSheet: response.data.deletedSheet })
      );
    } catch (error) {
      dispatch(deleteSpreadsheetFail(error));

      throw error;
    }
  };
};
