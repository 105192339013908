import React from 'react';
import { Select as AntdSelect } from 'antd';

const Select = ({
  allowClear,
  disabled,
  options,
  labelKey,
  valueKey,
  title,
  onChange,
  value,
  style = {},
  mode = '',
  defaultValue,
}) => (
  <div>
    {title && <label>{title}</label>}

    <AntdSelect
      allowClear={allowClear}
      mode={mode}
      disabled={disabled}
      onChange={onChange}
      value={value}
      defaultValue={defaultValue}
      style={style}
    >
      {options.map((option) => (
        <AntdSelect.Option key={option[valueKey]} value={option[valueKey]}>
          {option[labelKey]}
        </AntdSelect.Option>
      ))}
    </AntdSelect>
  </div>
);

export default Select;
