import React, { useEffect, useState, useCallback } from 'react';
import List from 'react-list-select';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import {
  Input as AntInput,
  Col,
  Row,
  Button,
  message,
  notification,
  Radio,
} from 'antd';
import { PlusOutlined } from '@ant-design/icons';

import ContentHeader from '../ContentHeader/ContentHeader';
import Spinner from '../Spinner/Spinner';

import {
  getSongsFromSpotify,
  storeSpotifySongs,
} from 'store/modules/thirdParties/spotify/actions';
import { getSongs } from 'containers/tracks/actions';
import { hidePane } from 'store/modules/panes/actions';

import { SPOTIFY_IMPORTER } from 'containers/app/components/AppPanes/paneTypes';
import sprite from 'images/sprite.svg';

import './SpotifyImporter.scss';

const { Search } = AntInput;

const comp = (title, artist, image, total_tracks = null) => {
  return (
    <div className="item">
      <Row>
        <Col md={{ span: 4 }}>
          <LazyLoadImage className="img-responsive" src={image} />
        </Col>

        {total_tracks ? (
          <>
            <Col md={{ span: 10 }}>
              <div className="title">{title}</div>
            </Col>
            <Col md={{ span: 8 }}>
              <div className="artist">{artist}</div>
            </Col>
            <Col md={{ span: 2 }}>
              <div className="artist">{total_tracks}</div>
            </Col>
          </>
        ) : (
          <>
            {' '}
            <Col md={{ span: 10 }}>
              <div className="title">{title}</div>
            </Col>
            <Col md={{ span: 10 }}>
              <div className="artist">{artist}</div>
            </Col>
          </>
        )}
      </Row>
    </div>
  );
};

let selectedSongs = [];

const SpotifyImporter = ({
  getSongs,
  getSongsFromSpotify,
  tracks,
  isFetching,
  isCreating,
  storeSpotifySongs,
  hidePane,
}) => {
  const [trackList, setTrackList] = useState([]);
  const [searchValue, setsearchValue] = useState('');
  const [searchType, setSearchType] = useState('track');
  const [, rerender] = useState(0);
  const hasTracks = tracks?.items?.length > 0;
  // const isProject = searchType === 'album';

  const renderTracksList = useCallback(
    (tracks) => {
      const tracksList = tracks?.map((track) => {
        if (searchType === 'track') {
          return comp(
            track?.name,
            track?.artists[0]?.name,
            track?.album?.images[2]?.url
          );
        }

        return comp(
          track?.name,
          track?.artists[0]?.name,
          track?.images && track?.images[2]?.url,
          track?.total_tracks
        );
      });
      setTrackList(tracksList);
    },
    [searchType]
  );

  const importSelectedSongs = async () => {
    const importedSongs = selectedSongs.map((song) => tracks.items[song]);

    if (selectedSongs.length === 0) {
      message.warning('Please select at least one song.');

      return;
    }

    try {
      await storeSpotifySongs(importedSongs, searchType);

      const args = {
        message: `Import of selected tracks has succesfully started`,
        description: 'Check inserted tracks in the catalog',
        duration: 3,
      };

      selectedSongs = [];
      getSongs();
      rerender({});

      openNotification(args, 'success');
    } catch (error) {
      const args = {
        message: 'Something went wrong.',
        description: 'Check inserted tracks in the catalog',
        duration: 3,
      };

      openNotification(args, 'error');
    }
  };

  const onSearchHandler = async (value) => {
    if (value.length === 0) {
      message.warning('Please enter a name', 2);

      return;
    }

    try {
      await getSongsFromSpotify(value, searchType);

      message.info('Tracks Fetched', 2);
    } catch (error) {
      message.error('We could not fetch your data. Please try again.', 2);
    }
  };

  const onRadioChangeHandler = (e) => {
    setSearchType(e.target.value);

    getSongsFromSpotify(searchValue, e.target.value);

    if (!searchValue) {
      setTrackList([]);
    }
  };

  const openNotification = (args, type) => {
    notification[type](args);
  };

  const onClose = () => {
    hidePane(SPOTIFY_IMPORTER);
  };

  useEffect(() => {
    renderTracksList(tracks.items);
  }, [renderTracksList, tracks]);

  return (
    <div className="vw-sptf-importer">
      <ContentHeader>
        <svg className="importer-icon" onClick={onClose}>
          <use href={sprite + '#icon-menu-cancel'}></use>
        </svg>
        <h3 className="importer-header">Spotify Track Importer</h3>

        <Button
          onClick={importSelectedSongs}
          type="primary"
          icon={<PlusOutlined />}
          size="large"
          loading={isCreating}
          className="header-button blue import-btn"
        >
          Import Selected
        </Button>
      </ContentHeader>

      <div className="importer">
        <div className="search-bar">
          <Row>
            <Col md={{ span: 20, offset: 2 }} lg={{ span: 22, offset: 1 }}>
              <Search
                placeholder="Project Name, Project Name..."
                enterButton="Search"
                className="search-bar"
                loading={isFetching}
                size="large"
                onSearch={onSearchHandler}
                onChange={(e) => setsearchValue(e.target.value)}
              />
            </Col>
            <Col md={{ span: 20, offset: 2 }} lg={{ span: 22, offset: 1 }}>
              <Radio.Group
                className="search-type"
                onChange={onRadioChangeHandler}
                value={searchType}
              >
                <Radio value="track">Track</Radio>
                <Radio value="album">Project</Radio>
              </Radio.Group>
            </Col>
          </Row>
        </div>
        {hasTracks && (
          <div className="list-header">
            <Row>
              <Col md={{ span: 4 }}>
                <h3>Artwork</h3>
              </Col>

              <Col md={{ span: 10 }}>
                <h3>Title</h3>
              </Col>
              <Col md={{ span: 10 }}>
                <h3>Main Artist</h3>
              </Col>
            </Row>
          </div>
        )}

        {isFetching ? (
          <Spinner className="blueOnGray search-spinner" />
        ) : (
          <List
            items={trackList}
            multiple={true}
            selected={selectedSongs}
            onChange={(selected) => {
              selectedSongs = selected;
            }}
          />
        )}
      </div>
    </div>
  );
};

const mapStateToProps = ({ spotify }) => {
  return {
    tracks: spotify.tracks,
    isFetching: spotify.isFetching,
    isCreating: spotify.isCreating,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getSongsFromSpotify,
      storeSpotifySongs,
      getSongs,
      hidePane,
    },
    dispatch
  );

SpotifyImporter.defaultProps = {
  getSongs: () => {},
  getSongsFromSpotify: () => {},
  tracks: [],
  isFetching: false,
  isCreating: false,
  storeSpotifySongs: () => {},
};

SpotifyImporter.propTypes = {
  getSongs: PropTypes.func,
  getSongsFromSpotify: PropTypes.func,
  tracks: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  isFetching: PropTypes.bool,
  isCreating: PropTypes.bool,
  storeSpotifySongs: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(SpotifyImporter);
