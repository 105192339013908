import React from 'react';
import PropTypes from 'prop-types';
import './Spinner.scss';

const Spinner = ({ style, className }) => (
  <div className={`spinner ${className}`} style={style}></div>
);

Spinner.propTypes = {
  style: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  className: PropTypes.string,
};

export default Spinner;
