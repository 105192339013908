import { handleActions } from 'redux-actions';
import accountingActions from '../actions/actionTypes';

const initialState = {
  summary: {},
  vendorsRevenue: {},
  labelsSummary: {},
  stats: {},
  topChartsFilters: {},
  isFetching: true,
  isCreating: false,
  isUpdating: false,
  isDeleting: false,
};

const accountingReducer = handleActions(
  {
    [accountingActions.getAnalyticsSummary.toString()]: (state, action) => {
      return {
        ...state,
        isFetchingSummary: true,
      };
    },
    [accountingActions.getAnalyticsSummarySuccess.toString()]: (
      state,
      action
    ) => {
      return {
        ...state,
        summary: action.payload.summary,
        isFetchingSummary: false,
      };
    },
    [accountingActions.getAnalyticsSummaryFailed.toString()]: (
      state,
      action
    ) => {
      return {
        ...state,
        isFetchingSummary: false,
        error: action.error,
      };
    },
    [accountingActions.getVendorsRevenue.toString()]: (state, action) => {
      return {
        ...state,
        isFetchingVendorsRevenue: true,
      };
    },
    [accountingActions.getVendorsRevenueSuccess.toString()]: (
      state,
      action
    ) => {
      return {
        ...state,
        vendorsRevenue: action.payload.revenue,
        isFetchingVendorsRevenue: false,
      };
    },
    [accountingActions.getVendorsRevenueFailed.toString()]: (state, action) => {
      return {
        ...state,
        isFetchingVendorsRevenue: false,
        error: action.error,
      };
    },
    [accountingActions.getVendorTopCharts.toString()]: (state, action) => {
      return {
        ...state,
        isFetchingTopCharts: true,
      };
    },
    [accountingActions.getVendorTopChartsSuccess.toString()]: (
      state,
      action
    ) => {
      return {
        ...state,
        topCharts: action.payload.charts,
        isFetchingTopCharts: false,
      };
    },
    [accountingActions.getVendorTopChartsFailed.toString()]: (
      state,
      action
    ) => {
      return {
        ...state,
        isFetchingTopCharts: false,
        error: action.error,
      };
    },
    [accountingActions.getTerritoriesTopRevenue.toString()]: (
      state,
      action
    ) => {
      return {
        ...state,
        isFetchingTopTerritories: true,
      };
    },
    [accountingActions.getTerritoriesTopRevenueSuccess.toString()]: (
      state,
      action
    ) => {
      return {
        ...state,
        summary: {
          ...state.summary,
          territories: action.payload.territories,
        },
        isFetchingTopTerritories: false,
      };
    },
    [accountingActions.getTerritoriesTopRevenueFailed.toString()]: (
      state,
      action
    ) => {
      return {
        ...state,
        isFetchingTopTerritories: false,
        error: action.error,
      };
    },
    [accountingActions.getTopChartsFilters.toString()]: (state, action) => {
      return {
        ...state,
        isFetchingFilters: true,
      };
    },
    [accountingActions.getTopChartsFiltersSuccess.toString()]: (
      state,
      action
    ) => {
      return {
        ...state,
        topChartsFilters: {
          ...state.topChartsFilters,
          ...action.payload.filters,
        },
        isFetchingFilters: false,
      };
    },
    [accountingActions.getTopChartsFiltersFailed.toString()]: (
      state,
      action
    ) => {
      return {
        ...state,
        isFetchingFilters: false,
        error: action.error,
      };
    },
    [accountingActions.getLabelsRevenue.toString()]: (state, action) => {
      return {
        ...state,
        isFetchingLabelsSummary: true,
      };
    },
    [accountingActions.getLabelsRevenueSuccess.toString()]: (state, action) => {
      return {
        ...state,
        labelsSummary: {
          ...state.labelsSummary,
          revenue: action.payload.revenue,
        },
        isFetchingLabelsSummary: false,
      };
    },
    [accountingActions.getLabelsRevenueFailed.toString()]: (state, action) => {
      return {
        ...state,
        isFetchingLabelsSummary: false,
        error: action.error,
      };
    },
    [accountingActions.getAssetsStats.toString()]: (state, action) => {
      return {
        ...state,
        stats: action.payload,
      };
    },
    [accountingActions.getAssetsStatsFailed.toString()]: (state, action) => {
      return {
        ...state,
        error: action.error,
      };
    },
  },
  initialState
);

export default accountingReducer;
