import React, { useEffect, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Droppable } from 'react-beautiful-dnd';
import Proptypes from 'prop-types';
import {
  Typography,
  Avatar,
  message,
  Dropdown,
  Tabs,
  notification,
  Modal,
  Pagination,
} from 'antd';
import {
  CloseOutlined,
  MoreOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons';

import EntitySpinner from 'components/Spinner/EntitySpinner';

import { SongListTable } from 'components/TableElements';
import ModuleDropdown from 'components/ModuleDropdown';
import routes from 'containers/app/components/AppRoutes/routes';

import ProjectMetadata from './ProjectMetadata/ProjectMetadata';

import { CDN_URL } from 'utils/consts';

import { hidePane, showPane, hideAllPanes } from 'store/modules/panes/actions';

import {
  EDIT_PROJECT,
  VIEW_PROJECT,
  EDIT_TRACKS,
} from 'containers/app/components/AppPanes/paneTypes';

import {
  clearTracks,
  setTracks,
  removeTracksFromEntity,
} from 'containers/tracks/actions';

import {
  getProjectTracks,
  setSelectedProject,
  deleteProjectImage,
  deleteProject,
  setEditMode,
  setPanelPage,
} from '../../actions';

const { Title, Text } = Typography;
const { TabPane } = Tabs;
const { confirm } = Modal;

const limit = 10;

const ViewProject = ({
  tracks,
  getProjectTracks,
  project,
  panelPage,
  totalProjectTracks,
  setSelectedProject,
  setEditMode,
  setPanelPage,
  deleteProject,
  deleteProjectImage,
  hidePane,
  showPane,
  setTracks,
  clearTracks,
  hideAllPanes,
  removeTracksFromEntity,
  validRoutes,
  isFetchingSingle,
}) => {
  const { pathname } = useLocation();
  const isEditSection = pathname.includes(routes.projects.path);
  const isValidPaneRoute = validRoutes.includes(pathname);

  const onProjectLoad = useCallback(async () => {
    const { project_title } = project;

    if (project_title) {
      try {
        await getProjectTracks(project_title, {
          page: 1,
          limit,
        });
      } catch (error) {
        message.error('We could not fetch your data. Please try again.', 2);
      }
    }
  }, [getProjectTracks, project]);

  const getProjectArtwork = (size) => {
    if (project.hasOwnProperty('artwork') && project.artwork?.[size]) {
      return `${CDN_URL}/${project.artwork[size]}`;
    }
  };

  // PROJECT PANE
  const toggleEditPaneHandler = (show) => {
    if (show) {
      showPane(EDIT_PROJECT);
    } else {
      hidePane(EDIT_PROJECT);
    }
    hidePane(VIEW_PROJECT);
    hidePane(EDIT_TRACKS);
  };

  const onClose = () => {
    hideAllPanes();
  };

  const onCreateItem = () => {
    toggleEditPaneHandler(true);
    setSelectedProject({});

    setEditMode(false);
  };

  const onEditItem = () => {
    toggleEditPaneHandler(true);
    setEditMode(true);
  };

  const onEditAllHandler = (selectedTracks = [], source = 'list') => {
    if (source === 'menu') {
      showPane(EDIT_TRACKS);
    }

    setTracks(selectedTracks.map((trackIndex) => tracks[trackIndex]));
  };

  const onChangeTrackPageHandler = async (pageNumber) => {
    try {
      await getProjectTracks(project.project_title, {
        page: pageNumber,
        limit,
      });
      setPanelPage(pageNumber);
    } catch (error) {
      message.error('We could not fetch your data. Please try again.', 2);
    }
  };

  const onRemoveHandler = async (selectedTracks) => {
    try {
      await removeTracksFromEntity(selectedTracks, 'projects');

      notification.success({
        message: `Project Updated`,
        description: 'Tracks list was updated.',
        duration: 3,
      });
    } catch (error) {
      message.error('Tracks were not removed. Please Contact Support.', 4);
    }
  };

  const onDeleteItem = async (projectId, imageKey) => {
    if (imageKey) {
      try {
        await deleteProjectImage(imageKey);
      } catch (error) {
        message.error('Image was not deleted. Please Contact Support.', 4);
      }
    }

    try {
      await deleteProject(projectId);

      notification.success({
        message: `Project Deleted`,
        description: 'Project list was updated.',
        duration: 3,
      });

      hidePane(EDIT_TRACKS);
    } catch (error) {
      notification.warning({
        message: `Project Delete Failed`,
        description: 'Please contact support.',
        duration: 3,
      });
    }

    onClose();
  };

  const onDeleteHandler = async () => {
    const projectId = project._id;
    const projectImage = project?.artwork?.small;

    confirm({
      title: 'Are you sure you want to delete this project?',
      icon: <ExclamationCircleOutlined />,
      content: 'This action is irreversible.',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        onDeleteItem(projectId, projectImage);
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  };

  useEffect(() => {
    if (!isValidPaneRoute) {
      hideAllPanes();
    }
  }, [isValidPaneRoute, hideAllPanes]);

  useEffect(() => {
    onProjectLoad();

    return () => {
      clearTracks();
    };
  }, [onProjectLoad, clearTracks]);

  return (
    <div className="vw-module content-module">
      <div className="content-title view-module-title">
        <CloseOutlined onClick={onClose} />

        <Title level={3}>PROJECT</Title>

        {isEditSection ? (
          <Dropdown
            overlay={ModuleDropdown.bind(this, {
              onCreateItem,
              onEditItem,
              onDeleteItem: onDeleteHandler,
              onRefresh: () => {},
              type: 'single',
            })}
            trigger={['click']}
          >
            <MoreOutlined />
          </Dropdown>
        ) : (
          <span />
        )}
      </div>

      <div className="module-container">
        <div className="module-image">
          <Avatar shape="square" size={100} src={getProjectArtwork('small')} />

          <div className="module-titles">
            <Title level={4}>{project.project_title}</Title>
            <Text>{project?.main_artist?.name}</Text>
          </div>
        </div>

        <Tabs>
          <TabPane tab="Track Listing" key="1">
            {isFetchingSingle ? (
              <EntitySpinner message="" />
            ) : (
              <Droppable droppableId="projects">
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                    style={{ minHeight: '20vh' }}
                  >
                    <SongListTable
                      tracks={tracks}
                      isEditSection={isEditSection}
                      isDraggingOver={snapshot.isDraggingOver}
                      onEditAll={onEditAllHandler}
                      onRemoveOne={onRemoveHandler}
                      onRemoveAll={onRemoveHandler}
                    />
                    {provided.placeholder}

                    <div className="instructions">
                      <Text>
                        Drag and drop tracks here to add to the <u>project</u>.
                      </Text>
                    </div>
                  </div>
                )}
              </Droppable>
            )}
            <Pagination
              onChange={onChangeTrackPageHandler}
              current={panelPage}
              pageSize={limit}
              total={totalProjectTracks}
              defaultCurrent={1}
              showSizeChanger={false}
              style={{
                padding: '40px',
                textAlign: 'center',
                float: 'inherit',
                borderTop: '1px solid #e2e2e2',
              }}
            />
          </TabPane>
          <TabPane tab="Metadata" key="2">
            <ProjectMetadata project={project} totalTracks={tracks.length} />
          </TabPane>
        </Tabs>
      </div>
    </div>
  );
};

ViewProject.defaultProps = {
  tracks: [],
  getProjectTracks: () => {},
  project: {},
  setSelectedProject: () => {},
  setEditMode: () => {},
  deleteProject: () => {},
  deleteProjectImage: () => {},
  hidePane: () => {},
  showPane: () => {},
  isFetchingSingle: false,
  totalProjectTracks: 0,
  panelPage: 0,
  setPanelPage: () => {},
};

ViewProject.propTypes = {
  tracks: Proptypes.array,
  getProjectTracks: Proptypes.func,
  project: Proptypes.object,
  setSelectedProject: Proptypes.func,
  setEditMode: Proptypes.func,
  deleteProject: Proptypes.func,
  deleteProjectImage: Proptypes.func,
  hidePane: Proptypes.func,
  showPane: Proptypes.func,
  isFetchingSingle: Proptypes.bool,
  totalProjectTracks: Proptypes.number,
  panelPage: Proptypes.number,
  setPanelPage: Proptypes.func,
};

const mapStateToProps = ({ projects }) => {
  return {
    editMode: projects.editMode,
    isFetchingSingle: projects.isFetchingSingle,
    project: projects.selectedProject,
    tracks: projects.projectTracks,
    totalProjectTracks: projects.totalProjectTracks,
    panelPage: projects.panelPage,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getProjectTracks,
      setSelectedProject,
      deleteProjectImage,
      deleteProject,
      setEditMode,
      setPanelPage,
      showPane,
      hidePane,
      setTracks,
      clearTracks,
      hideAllPanes,
      removeTracksFromEntity,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(ViewProject);
