import React from 'react';
import Spinner from '../Spinner/Spinner';
import Emoji from '../Emoji/Emoji';
import './LoadingComponent.scss';

const LoadingComponent = ({ isLoading, error, retry, pastDelay }) => {
  if (pastDelay) {
    // 200ms
    return (
      <div className="loading-component">
        <Spinner style={{ width: '2rem', height: '2rem' }} />
      </div>
    );
  } else if (error) {
    return (
      <div className="loading-component">
        <Emoji type="heartbreak" />
        <h1>
          Sorry, there was a problem <br></br> loading the page.
        </h1>
        <button onClick={retry}>Reload &#8635;</button>
      </div>
    );
  } else {
    return null;
  }
};

export default LoadingComponent;
