import { handleActions } from 'redux-actions';
import marketingActions from '../actions/actionTypes';

const trackTypeInitialState = {
  tracks: [],
  total: 0,
  ids: {},
};

const initialState = {
  scheduled: trackTypeInitialState,
  released: trackTypeInitialState,
  upcoming: trackTypeInitialState,
  pending: trackTypeInitialState,
  archived: trackTypeInitialState,
  tasks: [],
  labels: [],
  taskFilter: null,
  filters: {
    scheduled: {
      label: undefined,
      task: undefined,
      text: undefined,
    },
    released: {
      label: undefined,
      task: undefined,
      text: undefined,
    },
    upcoming: {
      label: undefined,
      task: undefined,
      text: undefined,
    },
    pending: {
      label: undefined,
      task: undefined,
      text: undefined,
    },
    archived: {
      label: undefined,
      task: undefined,
      text: undefined,
    },
  },
  isFetching: true,
  isArchiving: false,
  isUpdating: false,
  searchMode: false,
  track: {},
  album: { items: [], info: { artwork: {} } },
  status: {},
  uploadProgress: 0,
  wav: {},
  videos: [],
  totalVideos: 0,
};

const buildTracksDictionary = (tracks, ids = {}) => {
  const formattedTracks = tracks.filter((track) => {
    if (!ids[track._id]) {
      ids[track._id] = track._id;

      return track;
    }

    return null;
  });

  return formattedTracks;
};

const marketingReducer = handleActions(
  {
    [marketingActions.getMarketingTracks.toString()]: (state, action) => {
      return {
        ...state,
        isFetching: true,
      };
    },
    [marketingActions.getMarketingTracksSuccess.toString()]: (
      state,
      action
    ) => {
      const { tracks, type } = action.payload;
      const newTracks = tracks.length ? tracks[0].paginatedResults : [];
      const total =
        tracks.length && tracks[0].totalCount.length
          ? tracks[0].totalCount[0].count
          : 0;
      const trackType = type.toLowerCase();
      const trackIds = { ...state[trackType].ids };

      const formattedTracks = buildTracksDictionary(newTracks, trackIds);

      return {
        ...state,
        [trackType]: {
          ...state[trackType],
          tracks: [...state[trackType].tracks, ...formattedTracks],
          total,
          ids: trackIds,
        },
        isFetching: false,
      };
    },
    [marketingActions.getMarketingTracksFailed.toString()]: (state, action) => {
      return {
        ...state,
        isFetching: false,
      };
    },
    [marketingActions.clearMarketingTracks.toString()]: (state, action) => {
      const { type } = action.payload;
      const trackType = type.toLowerCase();
      return {
        ...state,
        [trackType]: {
          ...state[trackType],
          tracks: [],
          total: 0,
          ids: [],
        },
      };
    },
    [marketingActions.deleteMarketingTrack.toString()]: (state, action) => {
      const type = action.payload.type;
      let updatedTracks = state[type].tracks;

      const albumIndex = updatedTracks.findIndex((track) => {
        return track.items.some(
          (trackItem) => action.payload.id === trackItem._id
        );
      });

      if (albumIndex !== -1) {
        console.log(albumIndex);
        updatedTracks[albumIndex].items = updatedTracks[
          albumIndex
        ].items.filter((track) => track._id !== action.payload.id);
      }

      return {
        ...state,
        [type]: {
          ...state[type],
          tracks: updatedTracks,
        },
      };
    },
    [marketingActions.deleteMarketingTrackFailed.toString()]: (
      state,
      action
    ) => {
      return {
        ...state,
        error: action.error,
      };
    },
    [marketingActions.getPendingReleases.toString()]: (state, action) => {
      return {
        ...state,
        isFetching: true,
      };
    },
    [marketingActions.getPendingReleasesSuccess.toString()]: (
      state,
      action
    ) => {
      const { pending } = action.payload;

      return {
        ...state,
        pending: {
          ...state.pending,
          tracks: pending,
          total: pending.length,
        },
        isFetching: false,
      };
    },
    [marketingActions.getPendingReleases.toString()]: (state, action) => {
      return {
        ...state,
        isFetching: true,
      };
    },
    [marketingActions.getArchivedReleasesSuccess.toString()]: (
      state,
      action
    ) => {
      const { tracks } = action.payload;
      const newTracks = tracks.length ? tracks[0].paginatedResults : [];
      const total =
        tracks.length && tracks[0].totalCount.length
          ? tracks[0].totalCount[0].count
          : 0;
      const trackIds = { ...state.archived.ids };

      const formattedTracks = buildTracksDictionary(newTracks, trackIds);

      return {
        ...state,
        archived: {
          ...state.archived,
          tracks: [...state.archived.tracks, ...formattedTracks],
          total,
          ids: trackIds,
        },
        isFetching: false,
      };
    },
    [marketingActions.getArchivedReleasesFailed.toString()]: (
      state,
      action
    ) => {
      return {
        ...state,
        isFetching: false,
      };
    },
    [marketingActions.updateMarketingTrack.toString()]: (state, action) => {
      return {
        ...state,
        isUpdating: true,
      };
    },
    [marketingActions.updateMarketingTrackSuccess.toString()]: (
      state,
      action
    ) => {
      return {
        ...state,
        track: {
          ...state.track,
          ...action.payload.track,
        },
        isUpdating: false,
      };
    },
    [marketingActions.updateMarketingTrackFailed.toString()]: (
      state,
      action
    ) => {
      return {
        ...state,
        isUpdating: false,
      };
    },
    [marketingActions.updateTask.toString()]: (state, action) => {
      return {
        ...state,
        upcoming: action.payload,
      };
    },
    [marketingActions.getMarketingTrack.toString()]: (state, action) => {
      return {
        ...state,
        isFetching: true,
      };
    },
    [marketingActions.getMarketingTrackSuccess.toString()]: (state, action) => {
      return {
        ...state,
        track: action.payload.track,
        isFetching: false,
      };
    },
    [marketingActions.getMarketingTrackFailed.toString()]: (state, action) => {
      return {
        ...state,
        isFetching: false,
      };
    },
    [marketingActions.getMarketingAlbum.toString()]: (state, action) => {
      return {
        ...state,
        isFetching: true,
      };
    },
    [marketingActions.getMarketingAlbumSuccess.toString()]: (state, action) => {
      return {
        ...state,
        album: action.payload.album,
        isFetching: false,
      };
    },
    [marketingActions.getMarketingAlbumFailed.toString()]: (state, action) => {
      return {
        ...state,
        isFetching: false,
      };
    },
    [marketingActions.clearMarketingAlbum.toString()]: (state, action) => {
      return {
        ...state,
        album: {},
      };
    },
    [marketingActions.getMarketingTrackBanner.toString()]: (state, action) => {
      return {
        ...state,
        isFetching: true,
      };
    },
    [marketingActions.getMarketingTrackBannerSuccess.toString()]: (
      state,
      action
    ) => {
      const { banner } = action.payload;

      return {
        ...state,
        track: {
          ...state.track,
          banner,
        },
        isFetching: false,
      };
    },
    [marketingActions.getMarketingTrackBannerFailed.toString()]: (
      state,
      action
    ) => {
      return {
        ...state,
        isFetching: false,
      };
    },
    [marketingActions.submitMarketingTrack.toString()]: (state, action) => {
      return {
        ...state,
        isCreating: true,
      };
    },
    [marketingActions.submitMarketingTrackSuccess.toString()]: (
      state,
      action
    ) => {
      return {
        ...state,
        isCreating: false,
      };
    },
    [marketingActions.submitMarketingTrackFailed.toString()]: (
      state,
      action
    ) => {
      return {
        ...state,
        isCreating: false,
      };
    },
    [marketingActions.submitMarketingAlbum.toString()]: (state, action) => {
      return {
        ...state,
        isCreating: true,
      };
    },
    [marketingActions.submitMarketingAlbumSuccess.toString()]: (
      state,
      action
    ) => {
      return {
        ...state,
        album: {
          _id: action.payload.album.upc,
          info: action.payload.album,
          items: [],
        },
        isCreating: false,
      };
    },
    [marketingActions.submitMarketingAlbumFailed.toString()]: (
      state,
      action
    ) => {
      return {
        ...state,
        isCreating: false,
      };
    },
    [marketingActions.updateTask.toString()]: (state, action) => {
      return {
        ...state,
        isUpdating: true,
      };
    },
    [marketingActions.updateTaskSuccess.toString()]: (state, action) => {
      return {
        ...state,
        track: {
          ...state.track,
          ...action.payload.track,
        },
        isUpdating: false,
      };
    },
    [marketingActions.updateTaskFailed.toString()]: (state, action) => {
      return {
        ...state,
        error: action.error,
        isUpdating: false,
      };
    },
    [marketingActions.updateMarketingTrackStatus.toString()]: (
      state,
      action
    ) => {
      return {
        ...state,
        isUpdating: true,
      };
    },
    [marketingActions.updateMarketingTrackStatusSuccess.toString()]: (
      state,
      action
    ) => {
      const { track, type } = action.payload;
      const updatedState = {
        ...state,
        isUpdating: false,
        track,
      };

      if (type) {
        updatedState[type].tracks = state[type].tracks.filter(
          (typeTrack) => typeTrack._id !== track._id
        );
      }

      return updatedState;
    },
    [marketingActions.updateMarketingTrackStatusFailed.toString()]: (
      state,
      action
    ) => {
      return {
        ...state,
        error: action.error,
        isUpdating: false,
      };
    },
    [marketingActions.updateQuickTask.toString()]: (state, action) => {
      return {
        ...state,
        isUpdating: true,
      };
    },
    [marketingActions.updateQuickTaskSuccess.toString()]: (state, action) => {
      const { type, id, title, upc } = action.payload;

      return {
        ...state,
        isUpdating: false,
        [type]: {
          ...state[type],
          tracks: state[type].tracks.filter((album) => {
            if (album._id === upc) {
              album.items.filter((release) => {
                if (release._id === id) {
                  release.quick_tasks.filter((task) => {
                    if (task.title === title) {
                      task.done = !task.done;
                      return task;
                    }
                    return task;
                  });
                }
                return release;
              });
            }
            return album;
          }),
        },
      };
    },
    [marketingActions.updateQuickTaskFailed.toString()]: (state, action) => {
      return {
        ...state,
        error: action.error,
        isUpdating: false,
      };
    },
    [marketingActions.getTasks.toString()]: (state, action) => {
      return {
        ...state,
        isFetching: false,
      };
    },
    [marketingActions.getTasksSuccess.toString()]: (state, action) => {
      const { tasks } = action.payload;

      return {
        ...state,
        track: {
          ...state.track,
          quick_tasks: tasks,
        },
        tasks,
        isFetching: false,
      };
    },
    [marketingActions.getTasksFailed.toString()]: (state, action) => {
      return {
        ...state,
        isFetching: false,
      };
    },
    [marketingActions.createTask.toString()]: (state, action) => {
      return {
        ...state,
        isCreating: true,
      };
    },
    [marketingActions.createTaskSuccess.toString()]: (state, action) => {
      const { task } = action.payload;
      const newTask = {
        title: task.title,
        done: false,
        order: task.order,
      };
      return {
        ...state,
        tasks: [...state.tasks, newTask],
        isCreating: false,
      };
    },
    [marketingActions.createTaskFailed.toString()]: (state, action) => {
      return {
        ...state,
        isCreating: false,
      };
    },
    [marketingActions.restoreTask.toString()]: (state, action) => {
      return {
        ...state,
        isUpdating: false,
      };
    },
    [marketingActions.restoreTaskSuccess.toString()]: (state, action) => {
      const restoredTasks = state.tasks.filter(function (item) {
        if (item.title === action.payload.title) {
          item.deleted = false;
        }
        return item;
      });
      return {
        ...state,
        tasks: restoredTasks,
        isUpdating: false,
      };
    },
    [marketingActions.restoreTaskFailed.toString()]: (state, action) => {
      return {
        ...state,
        isUpdating: false,
      };
    },
    [marketingActions.deleteTask.toString()]: (state, action) => {
      return {
        ...state,
        isDeleting: true,
      };
    },
    [marketingActions.deleteTaskSuccess.toString()]: (state, action) => {
      const newTasks = state.tasks.filter(function (item) {
        if (item.title === action.payload.title) {
          item.deleted = true;
        }
        return item;
      });
      return {
        ...state,
        tasks: newTasks,
        isDeleting: false,
      };
    },
    [marketingActions.deleteTaskFailed.toString()]: (state, action) => {
      return {
        ...state,
        isDeleting: false,
      };
    },
    [marketingActions.cleanSearchResults.toString()]: (state, action) => {
      return {
        ...state,
        pending: trackTypeInitialState,
        upcoming: trackTypeInitialState,
        scheduled: trackTypeInitialState,
        released: trackTypeInitialState,
        archived: trackTypeInitialState,
      };
    },
    [marketingActions.removeTrackInfo.toString()]: (state, action) => {
      return {
        ...state,
        track: {},
      };
    },
    [marketingActions.removeTextFilter.toString()]: (state, action) => {
      return {
        ...state,
        filters: {
          ...state.filters,
          [action.payload.type]: {
            ...state.filters[action.payload.type],
            text: undefined,
          },
        },
      };
    },
    [marketingActions.uploadArtwork.toString()]: (state, action) => {
      return {
        ...state,
        isUploading: true,
      };
    },
    [marketingActions.uploadArtworkSuccess.toString()]: (state, action) => {
      const { size, artwork, isAlbum } = action.payload;
      const currentArtwork = state.album.info?.artwork || {};

      if (isAlbum) {
        return {
          ...state,
          album: {
            ...state.album,
            info: {
              ...state.album.info,
              artwork: {
                ...currentArtwork,
                [size]: artwork,
              },
            },
          },
          isUploading: false,
        };
      }
      return {
        ...state,
        track: {
          ...state.track,
          artwork: {
            ...state.track.artwork,
            [size]: artwork,
          },
        },
        isUploading: false,
      };
    },
    [marketingActions.uploadArtworkFailed.toString()]: (state, action) => {
      return {
        ...state,
        error: action.error,
        isUploading: false,
      };
    },
    [marketingActions.removeArtwork.toString()]: (state, action) => {
      return {
        ...state,
        isDeleting: true,
      };
    },
    [marketingActions.removeArtworkSuccess.toString()]: (state, action) => {
      const { size, isAlbum } = action.payload;
      const currentArtwork = state.album.info?.artwork || {};

      if (isAlbum) {
        return {
          ...state,
          album: {
            ...state.album,
            info: {
              ...state.album.info,
              artwork: {
                ...currentArtwork,
                [size]: null,
              },
            },
          },
          isDeleting: false,
        };
      }

      return {
        ...state,
        track: {
          ...state.track,
          artwork: {
            ...state.track.artwork,
            [size]: null,
          },
        },
        isDeleting: false,
      };
    },
    [marketingActions.updateArtwork.toString()]: (state, action) => {
      const { artwork } = action.payload;

      return {
        ...state,
        track: {
          ...state.track,
          artwork: artwork,
        },
      };
    },
    [marketingActions.removeArtworkFailed.toString()]: (state, action) => {
      return {
        ...state,
        isDeleting: false,
      };
    },
    [marketingActions.hideTask.toString()]: (state, action) => {
      const hiddenTasks = state.track.quick_tasks.map((task, index) => {
        if (index === action.payload.index) {
          return { ...task, hidden: action.payload.value };
        }
        return task;
      });
      return {
        ...state,
        track: {
          ...state.track,
          quick_tasks: hiddenTasks,
        },
      };
    },
    [marketingActions.getVideoStatus.toString()]: (state, action) => {
      return {
        ...state,
        isFetchingVideo: true,
      };
    },
    [marketingActions.getVideoStatusSuccess.toString()]: (state, action) => {
      return {
        ...state,
        status: action.payload.status,
        isFetchingVideo: false,
      };
    },
    [marketingActions.getVideoStatusFailed.toString()]: (state, action) => {
      return {
        ...state,
        error: action.error,
        isFetchingVideo: false,
      };
    },
    [marketingActions.startVideoRendering.toString()]: (state, action) => {
      return {
        ...state,
        isUpdatingVideo: true,
      };
    },
    [marketingActions.startVideoRenderingSuccess.toString()]: (
      state,
      action
    ) => {
      return {
        ...state,
        isUpdatingVideo: false,
      };
    },
    [marketingActions.startVideoRenderingFailed.toString()]: (
      state,
      action
    ) => {
      return {
        ...state,
        error: action.error,
        isDeletingVideo: false,
      };
    },
    [marketingActions.deleteVideoFile.toString()]: (state, action) => {
      return {
        ...state,
        isDeletingVideo: true,
      };
    },
    [marketingActions.deleteVideoFileSuccess.toString()]: (state, action) => {
      return {
        ...state,
        isDeletingVideo: false,
        videos: state.videos.map((video) => {
          if (video._id === action.payload.id) {
            video.video_deleted = true;
            return video;
          }
          return video;
        }),
      };
    },
    [marketingActions.deleteVideoFileFailed.toString()]: (state, action) => {
      return {
        ...state,
        error: action.error,
        isDeletingVideo: false,
      };
    },
    [marketingActions.getRenderedVideos.toString()]: (state, action) => {
      return {
        ...state,
        isFetchingVideo: true,
      };
    },
    [marketingActions.getRenderedVideosSuccess.toString()]: (state, action) => {
      return {
        ...state,
        videos: action.payload.videos,
        totalVideos: action.payload.totalVideos,
        isFetchingVideo: false,
      };
    },
    [marketingActions.getRenderedVideosFailed.toString()]: (state, action) => {
      return {
        ...state,
        error: action.error,
        isFetchingVideo: false,
      };
    },
    [marketingActions.uploadWav.toString()]: (state, action) => {
      return {
        ...state,
        isUploading: true,
      };
    },
    [marketingActions.updateUploadProcess.toString()]: (state, action) => {
      return {
        ...state,
        uploadProgress: action.payload.uploadProgress,
        isUploading: true,
      };
    },
    [marketingActions.uploadWavSuccess.toString()]: (state, action) => {
      return {
        ...state,
        uploadProgress: 0,
        wav: action.payload.wav,
        isUploading: false,
      };
    },
    [marketingActions.uploadWavFailed.toString()]: (state, action) => {
      return {
        ...state,
        error: action.error,
        isUploading: false,
      };
    },
    [marketingActions.toggleUploaded.toString()]: (state, action) => {
      return {
        ...state,
        isUploading: true,
      };
    },
    [marketingActions.toggleUploadedSuccess.toString()]: (state, action) => {
      return {
        ...state,
        videos: state.videos.map((video) => {
          if (video._id === action.payload.id) {
            video.uploaded = !video.uploaded;
            return video;
          }
          return video;
        }),
        isUploading: false,
      };
    },
    [marketingActions.toggleUploadedFailed.toString()]: (state, action) => {
      return {
        ...state,
        error: action.error,
        isUploading: false,
      };
    },
    [marketingActions.addNote.toString()]: (state, action) => {
      return {
        ...state,
        isCreatingNote: true,
      };
    },
    [marketingActions.addNoteSuccess.toString()]: (state, action) => {
      return {
        ...state,
        track: {
          ...state.track,
          notes: action.payload.notes,
        },
        isCreatingNote: false,
      };
    },
    [marketingActions.addNoteFailed.toString()]: (state, action) => {
      return {
        ...state,
        error: action.error,
        isCreatingNote: false,
      };
    },
    [marketingActions.uploadAudio.toString()]: (state, action) => {
      return {
        ...state,
        uploadingAudio: true,
      };
    },
    [marketingActions.uploadAudioSuccess.toString()]: (state, action) => {
      return {
        ...state,
        track: {
          ...state.track,
          audio: action.payload, // Should contain the new String for audio
        },
        uploadingAudio: false,
      };
    },
    [marketingActions.uploadAudioFailed.toString()]: (state, action) => {
      return {
        ...state,
        error: action.error,
        uploadingAudio: false,
      };
    },
    [marketingActions.removeAudio.toString()]: (state, action) => {
      return {
        ...state,
        track: {
          ...state.track,
          audio: null,
        },
      };
    },
    [marketingActions.removeAudioFailed.toString()]: (state, action) => {
      return {
        ...state,
        error: action.error,
      };
    },
  },
  initialState
);

export default marketingReducer;
