import { handleActions } from 'redux-actions';
import partnersActions from '../actions/actionTypes';

const initialState = {
  partners: [],
  totalPartners: 0,
  selectedPartner: {},
  relatedSongs: [],
  isFetching: false,
  isFetchingSongs: false,
  isCreating: false,
  isUpdating: false,
  sidebarTotal: 0,
};

const partnersReducer = handleActions(
  {
    [partnersActions.setPartner.toString()]: (state, action) => {
      return {
        ...state,
        selectedPartner: action.payload.partner,
      };
    },
    [partnersActions.setTotalPartners.toString()]: (state, action) => {
      return {
        ...state,
        sidebarTotal: action.payload.totalPartners,
      };
    },
    [partnersActions.getPartners.toString()]: (state, action) => {
      return {
        ...state,
        isFetching: true,
      };
    },
    [partnersActions.getPartnersSuccess.toString()]: (state, action) => {
      return {
        ...state,
        partners: action.payload.partners,
        sidebarTotal: action.payload.totalPartners,
        totalPartners: action.payload.totalPartners,
        isFetching: false,
      };
    },
    [partnersActions.getPartnersFail.toString()]: (state, action) => {
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };
    },
    [partnersActions.quickSearchPartner.toString()]: (state, action) => {
      return {
        ...state,
        isFetching: true,
      };
    },
    [partnersActions.quickSearchPartnerSuccess.toString()]: (state, action) => {
      return {
        ...state,
        isFetching: false,
        partners: action.payload.partners,
        totalPartners: action.payload.totalPartners,
      };
    },
    [partnersActions.quickSearchPartnerFail.toString()]: (state, action) => {
      return {
        ...state,
        isFetching: false,
      };
    },
    [partnersActions.getPartner.toString()]: (state, action) => {
      return {
        ...state,
        isFetching: true,
      };
    },
    [partnersActions.getPartnerSuccess.toString()]: (state, action) => {
      return {
        ...state,
        selectedPartner: action.payload.partner,
        isFetching: false,
      };
    },
    [partnersActions.getPartnerFail.toString()]: (state, action) => {
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };
    },
    [partnersActions.getPartnerData.toString()]: (state, action) => {
      return {
        ...state,
        isFetchingSongs: true,
      };
    },
    [partnersActions.getPartnerDataSuccess.toString()]: (state, action) => {
      return {
        ...state,
        relatedSongs: action.payload.relatedSongs,
        isFetchingSongs: false,
      };
    },
    [partnersActions.getPartnerDataFail.toString()]: (state, action) => {
      return {
        ...state,
        isFetchingSongs: false,
        error: action.error,
      };
    },
    [partnersActions.cleanPartnerData.toString()]: (state, action) => {
      return {
        ...state,
        relatedSongs: [],
        selectedPartner: {},
      };
    },
    [partnersActions.createPartner.toString()]: (state, action) => {
      return {
        ...state,
        isCreating: true,
      };
    },
    [partnersActions.createPartnerSuccess.toString()]: (state, action) => {
      const totalPartners = state.totalPartners + 1;

      return {
        ...state,
        partners: [action.payload.partner, ...state.partners],
        totalPartners,
        sidebarTotal: state.sidebarTotal + 1,
        isCreating: false,
      };
    },
    [partnersActions.createPartnerFail.toString()]: (state, action) => {
      return {
        ...state,
        isCreating: false,
        error: action.error,
      };
    },
    [partnersActions.updatePartner.toString()]: (state, action) => {
      return {
        ...state,
        isUpdating: true,
      };
    },
    [partnersActions.updatePartnerSuccess.toString()]: (state, action) => {
      const partnersCopy = [...state.partners];
      const updatedPartners = partnersCopy.map((partner) => {
        if (action.payload.partner._id === partner._id) {
          return {
            ...partner,
            ...action.payload.partner,
          };
        }

        return partner;
      });

      return {
        ...state,
        partners: updatedPartners,
        isUpdating: false,
      };
    },
    [partnersActions.updatePartnerFail.toString()]: (state, action) => {
      return {
        ...state,
        isUpdating: false,
        error: action.error,
      };
    },
    [partnersActions.deletePartner.toString()]: (state, action) => {
      return {
        ...state,
        isDeleting: true,
      };
    },
    [partnersActions.deletePartnerSuccess.toString()]: (state, action) => {
      const updatedPartnersArray = state.partners.filter(
        (partner) => partner.partnerId !== action.payload.deletedPartner
      );

      const totalPartners = state.totalPartners;

      return {
        ...state,
        partners: updatedPartnersArray,
        totalPartners: totalPartners - 1,
        sidebarTotal: state.sidebarTotal - 1,
        isDeleting: false,
      };
    },
    [partnersActions.deletePartnerFail.toString()]: (state, action) => {
      return {
        ...state,
        isDeleting: false,
      };
    },
    [partnersActions.savePartnerImage.toString()]: (state, action) => {
      return {
        ...state,
        isSavingImage: true,
      };
    },
    [partnersActions.savePartnerImageSuccess.toString()]: (state, action) => {
      return {
        ...state,
        isSavingImage: false,
      };
    },
    [partnersActions.savePartnerImageFail.toString()]: (state, action) => {
      return {
        ...state,
        isSavingImage: false,
        error: action.error,
      };
    },
    [partnersActions.deletePartnerImage.toString()]: (state, action) => {
      return {
        ...state,
        isSavingImage: true,
      };
    },
    [partnersActions.deletePartnerImageSuccess.toString()]: (state, action) => {
      return {
        ...state,
        isSavingImage: false,
      };
    },
    [partnersActions.deletePartnerImageFail.toString()]: (state, action) => {
      return {
        ...state,
        isSavingImage: false,
        error: action.error,
      };
    },
    [partnersActions.createPartnerAccount.toString()]: (state, action) => {
      return {
        ...state,
        isUpdating: true,
      };
    },
    [partnersActions.createPartnerAccountSuccess.toString()]: (
      state,
      action
    ) => {
      return {
        ...state,
        isUpdating: false,
        selectedPartner: {
          ...state.selectedPartner,
          ...action.payload.partner,
        },
      };
    },
    [partnersActions.createPartnerAccountFailed.toString()]: (
      state,
      action
    ) => {
      return {
        ...state,
        isUpdating: false,
        error: action.error,
      };
    },
    [partnersActions.activatePartnerAccount.toString()]: (state, action) => {
      return {
        ...state,
        isUpdating: true,
      };
    },
    [partnersActions.activatePartnerAccountSuccess.toString()]: (
      state,
      action
    ) => {
      return {
        ...state,
        isUpdating: false,
        selectedPartner: {
          ...state.selectedPartner,
          ...action.payload.partner,
        },
      };
    },
    [partnersActions.activatePartnerAccountFailed.toString()]: (
      state,
      action
    ) => {
      return {
        ...state,
        isUpdating: false,
        error: action.error,
      };
    },
    [partnersActions.disablePartnerAccount.toString()]: (state, action) => {
      return {
        ...state,
        isUpdating: true,
      };
    },
    [partnersActions.disablePartnerAccountSuccess.toString()]: (
      state,
      action
    ) => {
      return {
        ...state,
        isUpdating: false,
        selectedPartner: {
          ...state.selectedPartner,
          ...action.payload.partner,
        },
      };
    },
    [partnersActions.disablePartnerAccountFailed.toString()]: (
      state,
      action
    ) => {
      return {
        ...state,
        isUpdating: false,
        error: action.error,
      };
    },
    [partnersActions.setPartnerCredentials.toString()]: (state, action) => {
      return {
        ...state,
        isUpdating: true,
      };
    },
    [partnersActions.setPartnerCredentialsSuccess.toString()]: (
      state,
      action
    ) => {
      return {
        ...state,
        isUpdating: false,
        selectedPartner: {
          ...state.selectedPartner,
          ...action.payload.partner,
        },
      };
    },
    [partnersActions.setPartnerCredentialsFailed.toString()]: (
      state,
      action
    ) => {
      return {
        ...state,
        isUpdating: false,
        error: action.error,
      };
    },
  },
  initialState
);

export default partnersReducer;
