import { handleActions } from 'redux-actions';
import songsActions from '../actions/actionTypes';

const initialState = {
  songs: [],
  message: null,
  distincts: {},
  fetching: false,
  isCreating: false,
  isUpdating: false,
  isUpdatingMultiple: false,
  isRemoving: false,
  filters: {
    page: 1,
    limit: 50,
  },
  track: {},
  uploadingAudio: false,
  isSavingImage: false,
  isAddingTrack: false,
  isFetchingTracks: false,
  isFetchingSingle: false,
  isMassAssociating: false,
  selectedTracks: [],
  downloadableSongs: [],
  associationDetails: {},
  totalTracks: 0,
  sidebarTotal: 0,
  songCopySplits: null,
  similarSongs: [],
  songCopy: null,
  sidebarTotalTracksVendors: [],
};

const songsReducer = handleActions(
  {
    [songsActions.getSongs.toString()]: (state, action) => {
      return {
        ...state,
        fetching: false,
        songs: action.payload.songs,
        filters: {
          ...state.filters,
          count: action.payload.filters.count,
          pages: action.payload.filters.pages,
        },
        totalTracks: action.payload.filters.count,
      };
    },
    [songsActions.getSongsFailed.toString()]: (state, action) => {
      return {
        ...state,
        fetching: false,
        error: action.error,
      };
    },
    [songsActions.quickSearchTrack.toString()]: (state, action) => {
      return {
        ...state,
        songs: action.payload.searchResults,
      };
    },
    [songsActions.quickSearchTrackFailed.toString()]: (state, action) => {
      return {
        ...state,
        error: action.error,
      };
    },
    [songsActions.deleteSong.toString()]: (state, action) => {
      const updatedSongArray = state.songs.filter(
        (song) => song._id !== action.payload.id
      );

      const totalTracks = state.totalTracks;

      return {
        ...state,
        songs: updatedSongArray,
        totalTracks: totalTracks - 1,
        sidebarTotal: state.sidebarTotal - 1,
      };
    },
    [songsActions.deleteSongFailed.toString()]: (state, action) => {
      return {
        ...state,
        error: action.error,
      };
    },
    [songsActions.getDistincts.toString()]: (state, action) => {
      return {
        ...state,
        distincts: action.payload,
      };
    },
    [songsActions.getDistinctsFailed.toString()]: (state, action) => {
      return {
        ...state,
        error: action.error,
      };
    },
    [songsActions.addFilter.toString()]: (state, action) => {
      let newFilters = { ...state.filters, ...action.payload };
      newFilters = { ...newFilters, page: 1 }; // always reset the page to 0
      return {
        ...state,
        filters: newFilters,
      };
    },
    [songsActions.addFilterFailed.toString()]: (state, action) => {
      return {
        ...state,
        error: action.error,
      };
    },
    [songsActions.addArrayFilter.toString()]: (state, action) => {
      return {
        // todo
        // => Add filter
      };
    },
    [songsActions.addFilters.toString()]: (state, action) => {
      return {
        ...state,
        filters: action.payload.filters,
      };
    },
    [songsActions.removeFilter.toString()]: (state, action) => {
      let updatedFilters = { ...state.filters };
      delete updatedFilters[action.payload];
      return {
        ...state,
        filters: updatedFilters,
      };
    },
    [songsActions.removeFilterFailed.toString()]: (state, action) => {
      return {
        ...state,
        error: action.error,
      };
    },
    [songsActions.updatePage.toString()]: (state, action) => {
      return {
        ...state,
        filters: {
          ...state.filters,
          page: action.payload.page_number,
        },
      };
    },
    [songsActions.updatePageFailed.toString()]: (state, action) => {
      return {
        ...state,
        error: action.error,
      };
    },
    [songsActions.setTotalTracks.toString()]: (state, action) => {
      return {
        ...state,
        sidebarTotal: action.payload.totalTracks,
        sidebarClMaster: action.payload.totalClMasterTracks,
        sidebarClPublishing: action.payload.totalClPublishingTracks,
        sidebarClPhysical: action.payload.totalClPhysicalTracks,
        sidebarTotalTracksVendors: action.payload.totalVendors,
      };
    },
    [songsActions.setFetching.toString()]: (state, action) => {
      return {
        ...state,
        fetching: true,
      };
    },
    [songsActions.setNotFetching.toString()]: (state, action) => {
      return {
        ...state,
        fetching: false,
      };
    },
    [songsActions.resetFilters.toString()]: (state, action) => {
      return {
        ...state,
        filters: {
          page: 1,
          limit: 25,
        },
      };
    },
    [songsActions.resetFiltersFailed.toString()]: (state, action) => {
      return {
        ...state,
        error: action.error,
      };
    },
    [songsActions.updateFavorite.toString()]: (state, action) => {
      return {
        ...state,
        songs: state.songs.map((song) => {
          if (song._id === action.payload.id) {
            song.favorite = action.payload.value;
            return song;
          }
          return song;
        }),
      };
    },
    [songsActions.updateFavoriteFailed.toString()]: (state, action) => {
      return {
        ...state,
        error: action.error,
      };
    },
    [songsActions.getTrackStart.toString()]: (state, action) => {
      return {
        ...state,
        isFetchingSingle: true,
      };
    },
    [songsActions.getTrack.toString()]: (state, action) => {
      return {
        ...state,
        track: action.payload.track,
        isFetchingSingle: false,
      };
    },
    [songsActions.getTrackFail.toString()]: (state, action) => {
      return {
        ...state,
        isFetchingSingle: false,
        error: action.error,
      };
    },
    [songsActions.uploadAudio.toString()]: (state, action) => {
      return {
        ...state,
        track: {
          ...state.track,
          audio: action.payload, // Should contain the new String for audio
        },
        uploadingAudio: false,
      };
    },
    [songsActions.uploadAudioFailed.toString()]: (state, action) => {
      return {
        ...state,
        error: action.error,
      };
    },
    [songsActions.removeAudio.toString()]: (state, action) => {
      return {
        ...state,
        track: {
          ...state.track,
          audio: null,
        },
      };
    },
    [songsActions.removeAudioFailed.toString()]: (state, action) => {
      return {
        ...state,
        error: action.error,
      };
    },
    [songsActions.toggleBool.toString()]: (state, action) => {
      return {
        ...state,
        [action.payload.key]: action.payload.value,
      };
    },
    [songsActions.addTrack.toString()]: (state, action) => {
      return {
        ...state,
        isCreating: true,
      };
    },
    [songsActions.addTrackSuccess.toString()]: (state, action) => {
      const totalTracks = state.totalTracks;

      return {
        ...state,
        isCreating: false,
        totalTracks: totalTracks + 1,
        sidebarTotal: state.sidebarTotal + 1,
      };
    },
    [songsActions.addTrackFail.toString()]: (state, action) => {
      return {
        ...state,
        isCreating: false,
        error: action.error,
      };
    },
    [songsActions.updateTrack.toString()]: (state, action) => {
      return {
        ...state,
        isUpdating: true,
      };
    },
    [songsActions.updateTrackSuccess.toString()]: (state, action) => {
      return {
        ...state,
        isUpdating: false,
        track: action.payload.track,
      };
    },
    [songsActions.updateTrackFail.toString()]: (state, action) => {
      return {
        ...state,
        isUpdating: false,
      };
    },
    [songsActions.saveTrackArtwork.toString()]: (state, action) => {
      return {
        ...state,
        isSavingImage: true,
      };
    },
    [songsActions.saveTrackArtworkSuccess.toString()]: (state, action) => {
      return {
        ...state,
        isSavingImage: false,
      };
    },
    [songsActions.saveTrackArtworkFail.toString()]: (state, action) => {
      return {
        ...state,
        isSavingImage: false,
        error: action.error,
      };
    },
    [songsActions.deleteTrackArtwork.toString()]: (state, action) => {
      return {
        ...state,
        isSavingImage: true,
      };
    },
    [songsActions.deleteTrackArtworkSuccess.toString()]: (state, action) => {
      return {
        ...state,
        isSavingImage: false,
      };
    },
    [songsActions.deleteTrackArtworkFail.toString()]: (state, action) => {
      return {
        ...state,
        isSavingImage: false,
        error: action.error,
      };
    },
    [songsActions.addTracksToEntity.toString()]: (state, action) => {
      return {
        ...state,
        isAddingTrack: true,
      };
    },
    [songsActions.addTracksToEntitySuccess.toString()]: (state, action) => {
      return {
        ...state,
        isAddingTrack: false,
      };
    },
    [songsActions.addTracksToEntityFail.toString()]: (state, action) => {
      return {
        ...state,
        isAddingTrack: false,
        error: action.error,
      };
    },
    [songsActions.setTracks.toString()]: (state, action) => {
      return {
        ...state,
        selectedTracks: action.payload.tracks,
      };
    },
    [songsActions.clearTracks.toString()]: (state, action) => {
      return {
        ...state,
        selectedTracks: [],
      };
    },
    [songsActions.clearTrack.toString()]: (state, action) => {
      return {
        ...state,
        track: {},
      };
    },
    [songsActions.updateSelectedTracks.toString()]: (state, action) => {
      return {
        ...state,
        isUpdatingMultiple: true,
      };
    },
    [songsActions.updateSelectedTracksSuccess.toString()]: (state, action) => {
      return {
        ...state,
        isUpdatingMultiple: false,
      };
    },
    [songsActions.updateSelectedTracksFail.toString()]: (state, action) => {
      return {
        ...state,
        isUpdatingMultiple: false,
        error: action.error,
      };
    },
    [songsActions.removeTracksFromEntity.toString()]: (state, action) => {
      return {
        ...state,
        isRemoving: true,
      };
    },
    [songsActions.removeTracksFromEntitySuccess.toString()]: (
      state,
      action
    ) => {
      return {
        ...state,
        isRemoving: false,
        selectedTracks: [],
      };
    },
    [songsActions.removeTracksFromEntityFail.toString()]: (state, action) => {
      return {
        ...state,
        isRemoving: false,
        error: action.error,
      };
    },
    [songsActions.associateTrack.toString()]: (state, action) => {
      return {
        ...state,
        isAssociating: true,
      };
    },
    [songsActions.associateTrackSuccess.toString()]: (state, action) => {
      const index = action.payload.index;
      const songsCopy = [...state.songs];

      const updatedSong = {
        ...state.songs[index],
        ...action.payload.associatedSong,
      };

      songsCopy[index] = updatedSong;

      return {
        ...state,
        songs: songsCopy,
        isAssociating: false,
      };
    },
    [songsActions.associateTrackFail.toString()]: (state, action) => {
      return {
        ...state,
        isAssociating: false,
        error: action.error,
      };
    },
    [songsActions.massAssociateTrack.toString()]: (state, action) => {
      return {
        ...state,
        isMassAssociating: true,
      };
    },
    [songsActions.massAssociateTrackSuccess.toString()]: (state, action) => {
      return {
        ...state,
        associationDetails: action.payload.associationDetails,
        isMassAssociating: false,
      };
    },
    [songsActions.massAssociateTrackFail.toString()]: (state, action) => {
      return {
        ...state,
        isMassAssociating: false,
        error: action.error,
      };
    },
    [songsActions.generateCsvData.toString()]: (state, action) => {
      return {
        ...state,
        isDownloading: true,
      };
    },
    [songsActions.generateCsvDataSuccess.toString()]: (state, action) => {
      return {
        ...state,
        downloadableSongs: action.payload.songs,
        isDownloading: false,
      };
    },
    [songsActions.generateCsvDataFail.toString()]: (state, action) => {
      return {
        ...state,
        isDownloading: false,
        error: action.error,
      };
    },
    [songsActions.deleteSelectedTracks.toString()]: (state, action) => {
      return {
        ...state,
        isRemoving: true,
      };
    },
    [songsActions.deleteSelectedTracksSuccess.toString()]: (state, action) => {
      return {
        ...state,
        isRemoving: false,
      };
    },
    [songsActions.deleteSelectedTracksFail.toString()]: (state, action) => {
      return {
        ...state,
        isRemoving: false,
        error: action.error,
      };
    },
    [songsActions.setCopySplit.toString()]: (state, action) => {
      return {
        ...state,
        songCopySplits: action.payload,
      };
    },
    [songsActions.clearCopySplit.toString()]: (state, action) => {
      return {
        ...state,
        songCopySplits: null,
      };
    },
    [songsActions.pasteSplits.toString()]: (state, action) => {
      return {
        ...state,
        isUpdating: true,
      };
    },
    [songsActions.pasteSplitsSuccess.toString()]: (state, action) => {
      return {
        ...state,
        isUpdating: false,
      };
    },
    [songsActions.pasteSplitsFail.toString()]: (state, action) => {
      return {
        ...state,
        isUpdating: false,
        error: action.error,
      };
    },
    [songsActions.copySongData.toString()]: (state, action) => {
      return {
        ...state,
        isUpdating: true,
      };
    },
    [songsActions.copySongDataSuccess.toString()]: (state, action) => {
      const songsCopy = [...state.songs];

      const updatedSong = action.payload.song;

      const index = state.songs.findIndex(
        (song) => song._id === updatedSong._id
      );

      if (index !== -1) songsCopy[index] = updatedSong;

      return {
        ...state,
        songs: songsCopy,
        isUpdating: false,
      };
    },
    [songsActions.copySongDataFail.toString()]: (state, action) => {
      return {
        ...state,
        isUpdating: false,
        error: action.error,
      };
    },
    [songsActions.getSimilarSongs.toString()]: (state, action) => {
      return {
        ...state,
        isUpdating: true,
      };
    },
    [songsActions.getSimilarSongsSuccess.toString()]: (state, action) => {
      return {
        ...state,
        isUpdating: false,
        similarSongs: action.payload.songs,
      };
    },
    [songsActions.getSimilarSongsFail.toString()]: (state, action) => {
      return {
        ...state,
        isUpdating: false,
        error: action.error,
      };
    },
    [songsActions.setCopySong.toString()]: (state, action) => {
      return {
        ...state,
        songCopy: action.payload,
      };
    },
    [songsActions.clearCopySong.toString()]: (state, action) => {
      return {
        ...state,
        songCopy: null,
      };
    },
  },

  initialState
);

export default songsReducer;
