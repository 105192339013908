import { createActions } from 'redux-actions';

export default createActions({
  // SIGNINGS
  GET_SIGNINGS: undefined,
  GET_SIGNINGS_SUCCESS: undefined,
  GET_SIGNINGS_FAILED: undefined,
  GET_SIGNING: undefined,
  GET_SIGNING_SUCCESS: undefined,
  GET_SIGNING_FAILED: undefined,
  UPDATE_SIGNING_PAID: undefined,
  UPDATE_SIGNING_PAID_SUCCESS: undefined,
  UPDATE_SIGNING_PAID_FAILED: undefined,
  CREATE_SIGNING: undefined,
  CREATE_SIGNING_SUCCESS: undefined,
  CREATE_SIGNING_FAILED: undefined,
  SEND_CONTRACT: undefined,
  SEND_CONTRACT_SUCCESS: undefined,
  SEND_CONTRACT_FAILED: undefined,

  // INFRINGEMENTS
  GET_INFRINGEMENTS: undefined,
  GET_INFRINGEMENTS_SUCCESS: undefined,
  GET_INFRINGEMENTS_FAILED: undefined,
  GET_INFRINGEMENT: undefined,
  GET_INFRINGEMENT_SUCCESS: undefined,
  GET_INFRINGEMENT_FAILED: undefined,
  UPDATE_INFRINGEMENT: undefined,
  UPDATE_INFRINGEMENT_SUCCESS: undefined,
  UPDATE_INFRINGEMENT_FAILED: undefined,
  UPDATE_INFRINGEMENT_PAID: undefined,
  UPDATE_INFRINGEMENT_PAID_SUCCESS: undefined,
  UPDATE_INFRINGEMENT_PAID_FAILED: undefined,
  BUILD_INFRINGEMENT: undefined,
  BUILD_INFRINGEMENT_SUCCESS: undefined,
  BUILD_INFRINGEMENT_FAILED: undefined,
  CLEAR_INFRINGEMENT: undefined,
  // REQUESTS
  GET_REQUESTS: undefined,
  GET_REQUESTS_SUCCESS: undefined,
  GET_REQUESTS_FAILED: undefined,
  GET_REQUEST: undefined,
  GET_REQUEST_SUCCESS: undefined,
  GET_REQUEST_FAILED: undefined,
  CLEAR_REQUEST: undefined,
  GET_NEW_REQUESTS: undefined,
  GET_NEW_REQUESTS_SUCCESS: undefined,
  GET_NEW_REQUESTS_FAIL: undefined,
  CANCEL_REQUEST: undefined,
  CANCEL_REQUEST_SUCCESS: undefined,
  CANCEL_REQUEST_FAILED: undefined,
  // LICENSING
  GET_LICENSES: undefined,
  GET_LICENSES_SUCCESS: undefined,
  GET_LICENSES_FAILED: undefined,
  DELETE_LICENSE: undefined,
  DELETE_LICENSE_SUCCESS: undefined,
  DELETE_LICENSE_FAILED: undefined,
  GET_LICENSE: undefined,
  GET_LICENSE_SUCCESS: undefined,
  GET_LICENSE_FAILED: undefined,
  START_LICENSE: undefined,
  START_LICENSE_SUCCESS: undefined,
  START_LICENSE_FAILED: undefined,
  UPDATE_LICENSE_STATUS: undefined,
  UPDATE_LICENSE_STATUS_SUCCESS: undefined,
  UPDATE_LICENSE_STATUS_FAILED: undefined,
  UPDATE_LICENSE_PERIOD: undefined,
  UPDATE_LICENSE_PERIOD_SUCCESS: undefined,
  UPDATE_LICENSE_PERIOD_FAILED: undefined,
  // SAMPLE
  GET_SAMPLES: undefined,
  GET_SAMPLES_SUCCESS: undefined,
  GET_SAMPLES_FAILED: undefined,
  DELETE_SAMPLE: undefined,
  DELETE_SAMPLE_SUCCESS: undefined,
  DELETE_SAMPLE_FAILED: undefined,
  GET_SAMPLE: undefined,
  GET_SAMPLE_SUCCESS: undefined,
  GET_SAMPLE_FAILED: undefined,
  START_SAMPLE: undefined,
  START_SAMPLE_SUCCESS: undefined,
  START_SAMPLE_FAILED: undefined,
  UPDATE_SAMPLE_STATUS: undefined,
  UPDATE_SAMPLE_STATUS_SUCCESS: undefined,
  UPDATE_SAMPLE_STATUS_FAILED: undefined,
  UPDATE_SAMPLE_PERIOD: undefined,
  UPDATE_SAMPLE_PERIOD_SUCCESS: undefined,
  UPDATE_SAMPLE_PERIOD_FAILED: undefined,
  // INTERPOLATIONS
  GET_INTERPOLATIONS: undefined,
  GET_INTERPOLATIONS_SUCCESS: undefined,
  GET_INTERPOLATIONS_FAILED: undefined,
  DELETE_INTERPOLATION: undefined,
  DELETE_INTERPOLATION_SUCCESS: undefined,
  DELETE_INTERPOLATION_FAILED: undefined,
  GET_INTERPOLATION: undefined,
  GET_INTERPOLATION_SUCCESS: undefined,
  GET_INTERPOLATION_FAILED: undefined,
  START_INTERPOLATION: undefined,
  START_INTERPOLATION_SUCCESS: undefined,
  START_INTERPOLATION_FAILED: undefined,
  UPDATE_INTERPOLATION_STATUS: undefined,
  UPDATE_INTERPOLATION_STATUS_SUCCESS: undefined,
  UPDATE_INTERPOLATION_STATUS_FAILED: undefined,
  // NOTES
  UPDATE_STATUS_START: undefined,
  ADD_REPLY_START: undefined,
  ADD_NOTE_REQUEST_START: undefined,
  ADD_NOTE_REQUEST_SUCCESS: undefined,
  ADD_NOTE_REQUEST_FAILED: undefined,
  ADD_NOTE_SIGNING_START: undefined,
  ADD_NOTE_SIGNING_SUCCESS: undefined,
  ADD_NOTE_SIGNING_FAILED: undefined,
  ADD_NOTE_INFRINGEMENT_START: undefined,
  ADD_NOTE_INFRINGEMENT_SUCCESS: undefined,
  ADD_NOTE_INFRINGEMENT_FAILED: undefined,
});
