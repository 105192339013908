import React, { PureComponent } from 'react';
import './ArrayInput.scss';

// * Todo
// Documentation needed..
export default class ArrayInput extends PureComponent {
  constructor(props) {
    super(props);
    this.onKeyDown = this.onKeyDown.bind(this);
    this.onItemDelete = this.onItemDelete.bind(this);
    this.selectPresetItem = this.selectPresetItem.bind(this);
    this.updateItems = this.updateItems.bind(this);
    this.isPresetItemChecked = this.isPresetItemChecked.bind(this);
  }

  onKeyDown(e) {
    if (e.target.value && e.keyCode === 13) {
      this.updateItems(e.target.value);
      return (e.target.value = '');
    }
  }

  onItemDelete(e) {
    let updatedItems = this.props.items.filter(
      (item, index) => index !== Number(e.target.dataset.index)
    );

    return this.props.onUpdate(updatedItems);
  }

  selectPresetItem(e) {
    if (e.target.value) {
      return this.updateItems(e.target.value);
    }
  }

  updateItems(newItem) {
    if (
      !this.props.items.filter(
        (item) => item && item.toLowerCase() === newItem.toLowerCase()
      ).length
    ) {
      let updatedItems = [...this.props.items, newItem];
      return this.props.onUpdate(updatedItems);
    }
  }

  isPresetItemChecked(value) {
    if (
      !this.props.items.filter(
        (item) => item && item.toLowerCase() === value.toLowerCase()
      ).length
    ) {
      return false;
    }
    return true;
  }

  render() {
    const { title, items = [], presetList = [], presetListTitle } = this.props;
    return (
      <div className="array-input">
        <div className="array-input-items">
          {items.map((item, index) => (
            <button onClick={this.onItemDelete} key={index} data-index={index}>
              {item}
            </button>
          ))}
        </div>
        <div className="forms-input">
          <label htmlFor="item">{title}</label>
          <input
            ref="newItemInput"
            name="item"
            placeholder={`${title} (and press enter)`}
            onKeyDown={this.onKeyDown}
          />
        </div>
        <div className="array-input-list">
          <h1>{presetListTitle}</h1>
          {presetList.map((presetItem, index) => (
            <button
              key={`${index}-${presetItem}`}
              onClick={this.selectPresetItem}
              value={presetItem}
              disabled={this.isPresetItemChecked(presetItem)}
            >
              {presetItem}
            </button>
          ))}
        </div>
      </div>
    );
  }
}
