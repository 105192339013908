import React, { Component } from 'react';
import onClickOutside from 'react-onclickoutside';
import { getElementWidth } from '../../../utils/visual.js';
import PropTypes from 'prop-types';
import './Select.scss';

const initialState = {
  selected: '',
  optionsActive: false,
  options: [],
};

class Select extends Component {
  constructor(props) {
    super(props);
    this.state = initialState;
  }

  UNSAFE_componentWillReceiveProps = ({ options, selected }) => {
    this.setState({
      options,
      selected,
    });
  };

  componentDidMount() {
    this.setState({
      options: this.props.options,
      selected: this.props.selected,
    });
  }

  onFocus = () => {
    this.setState({
      optionsActive: true,
    });
  };

  onChange = ({ target: { value } }) => {
    this.setState({
      selected: value,
      options: this.props.options.filter(
        (options) =>
          options.toString().toLowerCase().indexOf(value.toLowerCase()) !== -1
      ),
    });
  };

  handleSelect = (key, value) => {
    this.props.select({
      [key]: value,
    });
    this.setState({ optionsActive: false });
  };

  handleClickOutside = (evt) => {
    if (this.state.optionsActive) {
      this.setState({
        optionsActive: false,
      });
    }
  };

  handleKey = ({ keyCode }) => {
    if (keyCode === 27 || keyCode === 9) {
      return this.reset();
    }
  };

  handleDelete = (key) => {
    this.reset();
    return this.props.remove(key);
  };

  reset = () => {
    return this.setState(initialState);
  };

  render() {
    const { placeholder, valueKey } = this.props;
    const { optionsActive, options, selected } = this.state;
    return (
      <div id="select">
        <FilterInput
          selected={selected}
          placeholder={placeholder}
          valueKey={valueKey}
          onFocus={this.onFocus}
          onChange={this.onChange}
          optionsActive={optionsActive}
          handleDelete={this.handleDelete}
          handleKey={this.handleKey}
        />
        <SelectOptions
          options={options}
          valueKey={valueKey}
          select={this.handleSelect}
          optionsActive={optionsActive}
        />
      </div>
    );
  }
}

const FilterInput = ({
  selected,
  placeholder,
  valueKey,
  onFocus,
  onChange,
  optionsActive,
  handleDelete,
  handleKey,
}) => {
  return (
    <div id="select-input" className={optionsActive ? 'focused' : ''}>
      <input
        type="text"
        value={selected || ''}
        placeholder={placeholder}
        onFocus={onFocus}
        onChange={onChange}
        onKeyDown={handleKey}
        style={{
          width: selected
            ? `calc(100% - ${getElementWidth('remove-filter')}px)`
            : `100%`,
        }}
      />
      {selected && (
        <button id="remove-filter" onClick={() => handleDelete(valueKey)}>
          &#215;
        </button>
      )}
    </div>
  );
};

const SelectOptions = ({ options = [], valueKey, select, optionsActive }) => {
  if (optionsActive) {
    return (
      <div id="select-options">
        <ul>
          {options &&
            options.map((option) => (
              <li
                key={option}
                value={option}
                onClick={() => select(valueKey, option)}
              >
                {option}
              </li>
            ))}
          {!options.length && <li id="nothing-found">No results</li>}
        </ul>
      </div>
    );
  } else {
    return null;
  }
};

Select.propTypes = {
  options: PropTypes.array,
  placeholder: PropTypes.string.isRequired,
  valueKey: PropTypes.string,
  remove: PropTypes.func.isRequired,
  select: PropTypes.func.isRequired,
};

export default onClickOutside(Select);
