import React, { useState, useEffect, useCallback } from 'react';
import { Row, Col, Dropdown } from 'antd';
import List from 'react-list-select';
import Proptypes from 'prop-types';
import ContextMenu from './ContextMenu';

import './SongListTable.scss';

let selectedTracks = [];

const SongListTable = ({
  tracks,
  isDraggingOver,
  onEditAll,
  onRemoveOne,
  onRemoveAll,
  isEditSection,
}) => {
  const [trackList, setTrackList] = useState([]);

  const buildTrackItem = useCallback(
    (
      id,
      index,
      title,
      release_year,
      label,
      onEditAll,
      onRemoveAll,
      onRemoveOne
    ) => {
      return (
        <Dropdown
          overlay={() =>
            ContextMenu({
              isEditSection,
              songId: id,
              onEditAll: () => onEditAll(selectedTracks, 'menu'),
              onRemoveOne,
              onRemoveAll: () =>
                onRemoveAll(
                  selectedTracks.map((trackIndex) => tracks[trackIndex]._id)
                ),
              checkedListLength: selectedTracks.length,
            })
          }
          trigger={['contextMenu']}
        >
          <div className="item">
            <Row>
              <Col xs={{ span: 2 }} md={{ span: 2, offset: 2 }}>
                {index}
              </Col>
              <Col xs={{ span: 8 }} md={{ span: 8 }}>
                {title || '-'}
              </Col>
              <Col xs={{ span: 5 }} md={{ span: 5 }}>
                {release_year || '-'}
              </Col>
              <Col xs={{ span: 9 }} md={{ span: 5 }}>
                {label || '-'}
              </Col>
            </Row>
          </div>
        </Dropdown>
      );
    },
    [tracks, isEditSection]
  );

  const renderProjectTracks = useCallback(
    (tracks = []) => {
      selectedTracks = [];

      const tracksList = tracks.map((track, index) => {
        return buildTrackItem(
          track._id,
          index + 1,
          track?.title,
          track.release_year,
          track.label || 'Not Set',
          onEditAll,
          onRemoveAll,
          onRemoveOne
        );
      });

      setTrackList(tracksList);
    },
    [buildTrackItem, onEditAll, onRemoveAll, onRemoveOne]
  );

  useEffect(() => {
    renderProjectTracks(tracks);
  }, [renderProjectTracks, tracks]);

  let tracksClasses = 'tracks';

  tracksClasses += isDraggingOver ? ' is-dragging' : '';

  return (
    <div className="tracks-table">
      <div className="tracks-header">
        <Row>
          <Col xs={{ span: 2 }} md={{ span: 2, offset: 2 }} />
          <Col xs={{ span: 8 }} md={{ span: 8 }}>
            Title
          </Col>
          <Col xs={{ span: 5 }} md={{ span: 5 }}>
            Year
          </Col>
          <Col xs={{ span: 9 }} md={{ span: 5 }}>
            Label
          </Col>
        </Row>
      </div>

      <div className={tracksClasses}>
        <List
          items={trackList}
          multiple={true}
          selected={selectedTracks}
          onChange={(selected) => {
            selectedTracks = selected;
            onEditAll(selectedTracks);
          }}
        />
      </div>
    </div>
  );
};

SongListTable.defaultProps = {
  tracks: [],
  onEditAll: () => {},
  onRemoveAll: () => {},
  onRemoveOne: () => {},
  isDraggingOver: false,
  isEditSection: false,
};

SongListTable.propTypes = {
  tracks: Proptypes.array,
  onEditAll: Proptypes.func,
  onRemoveAll: Proptypes.func,
  onRemoveOne: Proptypes.func,
  ContextMenu: Proptypes.element,
  isDraggingOver: Proptypes.bool,
  isEditSection: Proptypes.bool,
};

export default SongListTable;
