import React from 'react';
import PropTypes from 'prop-types';
import './ContentHeader.scss';

const ContentHeader = ({ children }) => (
  <div className="content-header">
    <div className="content-header-inner">{children}</div>
  </div>
);

ContentHeader.propTypes = {
  children: PropTypes.element.isRequired,
};

export default ContentHeader;
