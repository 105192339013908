/**
 *  FILE: REDUX ROOT REDUCER
 **/
import { reducer as formReducer } from 'redux-form';

import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';

import accounting from '../../containers/accounting/reducers';
import analytics from '../../containers/analytics/reducers';
import auth from '../../containers/auth/reducers';
import users from '../../containers/users/reducers';
import tracks from '../../containers/tracks/reducers';
import labels from '../../containers/labels/reducers';
import projects from '../../containers/projects/reducers';
import tags from '../../containers/tags/reducers';
import search from '../../containers/search/reducers';
import spreadsheets from '../../containers/spreadsheets/reducers';
import partners from '../../containers/partners/reducers';
import ingestion from '../../containers/ingestion/reducers';
import legal from '../../containers/legal/reducers';
import marketing from '../../containers/marketing/reducers';
import organizations from '../../containers/organizations/reducers';
import press from 'containers/press/reducers';

// global modules
import notifications from '../modules/notifications/reducers';
import youtube from '../modules/thirdParties/youtube/reducers';
import catalog from '../modules/catalog/reducers';
import spotify from '../modules/thirdParties/spotify/reducers';
import panes from '../modules/panes/reducers';

const rootReducer = combineReducers({
  router: routerReducer,
  accounting,
  analytics,
  auth,
  users,
  // content
  spreadsheets,
  catalog,
  tracks,
  labels,
  projects,
  tags,
  organizations,
  // song related actions
  partners,
  search,
  notifications,
  ingestion,
  legal,
  marketing,
  // global modules
  youtube,
  spotify,
  panes,
  form: formReducer,
  press,
});

export default rootReducer;
