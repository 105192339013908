import { createActions } from 'redux-actions';

export default createActions({
  // Dashboard
  GET_MARKETING_TRACKS: undefined,
  GET_MARKETING_TRACKS_SUCCESS: undefined,
  GET_MARKETING_TRACKS_FAILED: undefined,

  DELETE_MARKETING_TRACK: undefined,
  DELETE_MARKETING_TRACK_FAILED: undefined,

  GET_MARKETING_TRACK: undefined,
  GET_MARKETING_TRACK_SUCCESS: undefined,
  GET_MARKETING_TRACK_FAILED: undefined,
  CLEAR_MARKETING_TRACKS: undefined,

  GET_MARKETING_ALBUM: undefined,
  GET_MARKETING_ALBUM_SUCCESS: undefined,
  GET_MARKETING_ALBUM_FAILED: undefined,
  CLEAR_MARKETING_ALBUM: undefined,

  GET_MARKETING_TRACK_BANNER: undefined,
  GET_MARKETING_TRACK_BANNER_SUCCESS: undefined,
  GET_MARKETING_TRACK_BANNER_FAILED: undefined,

  GET_ARCHIVED_RELEASES: undefined,
  GET_ARCHIVED_RELEASES_SUCCESS: undefined,
  GET_ARCHIVED_RELEASES_FAILED: undefined,

  GET_PENDING_RELEASES: undefined,
  GET_PENDING_RELEASES_SUCCESS: undefined,
  GET_PENDING_RELEASES_FAILED: undefined,

  GET_TASKS: undefined,
  GET_TASKS_SUCCESS: undefined,
  GET_TASKS_FAILED: undefined,

  CREATE_TASK: undefined,
  CREATE_TASK_SUCCESS: undefined,
  CREATE_TASK_FAILED: undefined,

  UPDATE_TASK: undefined,
  UPDATE_TASK_SUCCESS: undefined,
  UPDATE_TASK_FAILED: undefined,

  DELETE_TASK: undefined,
  DELETE_TASK_SUCCESS: undefined,
  DELETE_TASK_FAILED: undefined,

  RESTORE_TASK: undefined,
  RESTORE_TASK_SUCCESS: undefined,
  RESTORE_TASK_FAILED: undefined,

  HIDE_TASK: undefined,

  UPDATE_MARKETING_TRACK: undefined,
  UPDATE_MARKETING_TRACK_SUCCESS: undefined,
  UPDATE_MARKETING_TRACK_FAILED: undefined,

  UPDATE_MARKETING_TRACK_STATUS: undefined,
  UPDATE_MARKETING_TRACK_STATUS_SUCCESS: undefined,
  UPDATE_MARKETING_TRACK_STATUS_FAILED: undefined,

  UPDATE_QUICK_TASK: undefined,
  UPDATE_QUICK_TASK_SUCCESS: undefined,
  UPDATE_QUICK_TASK_FAILED: undefined,

  SUBMIT_MARKETING_TRACK: undefined,
  SUBMIT_MARKETING_TRACK_SUCCESS: undefined,
  SUBMIT_MARKETING_TRACK_FAILED: undefined,

  SUBMIT_MARKETING_ALBUM: undefined,
  SUBMIT_MARKETING_ALBUM_SUCCESS: undefined,
  SUBMIT_MARKETING_ALBUM_FAILED: undefined,

  ADD_MARKETING_EXPENSE: undefined,
  ADD_MARKETING_EXPENSE_SUCCESS: undefined,
  ADD_MARKETING_EXPENSE_FAILED: undefined,

  ADD_NOTE: undefined,
  ADD_NOTE_SUCCESS: undefined,
  ADD_NOTE_FAILED: undefined,

  UPLOAD_AUDIO: undefined,
  UPLOAD_AUDIO_SUCCESS: undefined,
  UPLOAD_AUDIO_FAILED: undefined,

  REMOVE_AUDIO: undefined,
  REMOVE_AUDIO_FAILED: undefined,

  // Videos
  GET_RENDERED_VIDEOS: undefined,
  GET_RENDERED_VIDEOS_SUCCESS: undefined,
  GET_RENDERED_VIDEOS_FAILED: undefined,

  GET_VIDEO_STATUS: undefined,
  GET_VIDEO_STATUS_SUCCESS: undefined,
  GET_VIDEO_STATUS_FAILED: undefined,

  START_VIDEO_RENDERING: undefined,
  START_VIDEO_RENDERING_SUCCESS: undefined,
  START_VIDEO_RENDERING_FAILED: undefined,

  UPLOAD_WAV: undefined,
  UPLOAD_WAV_SUCCESS: undefined,
  UPLOAD_WAV_FAILED: undefined,

  DELETE_VIDEO_FILE: undefined,
  DELETE_VIDEO_FILE_SUCCESS: undefined,
  DELETE_VIDEO_FILE_FAILED: undefined,

  TOGGLE_UPLOADED: undefined,
  TOGGLE_UPLOADED_SUCCESS: undefined,
  TOGGLE_UPLOADED_FAILED: undefined,

  UPDATE_UPLOAD_PROCESS: undefined,

  // General
  SEARCH_TRACK: undefined,
  SEARCH_TRACK_SUCCESS: undefined,
  SEARCH_TRACK_FAILED: undefined,

  CLEAN_SEARCH_RESULTS: undefined,

  REMOVE_TEXT_FILTER: undefined,
  REMOVE_TRACK_INFO: undefined,

  // Artwork
  UPLOAD_ARTWORK: undefined,
  UPLOAD_ARTWORK_SUCCESS: undefined,
  UPLOAD_ARTWORK_FAILED: undefined,

  REMOVE_ARTWORK: undefined,
  REMOVE_ARTWORK_SUCCESS: undefined,
  REMOVE_ARTWORK_FAILED: undefined,

  UPDATE_ARTWORK: undefined,
});
