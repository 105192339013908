import React from 'react';
import ResizePanel from 'react-resize-panel';
import Proptypes from 'prop-types';

import './Pane.scss';

const Pane = (props) => {
  const { children, open, direction } = props;

  if (open) {
    return (
      <div className="pane" {...props}>
        <ResizePanel handleClass="handler" direction={direction}>
          {children}
        </ResizePanel>
      </div>
    );
  }

  return null;
};

Pane.defaultProps = {
  direction: 'w',
  open: false,
};

Pane.propTypes = {
  direction: Proptypes.string,
  open: Proptypes.bool,
};

export default Pane;
