import client from 'axios';
import { Api } from '../utils/consts';

export const axios = client.create({
  baseURL: Api,
  headers: {
    Authorization: localStorage.getItem('token')
      ? localStorage.getItem('token')
      : '',
  },
});
