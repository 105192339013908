import { createActions } from 'redux-actions';

export default createActions({
  SET_ORGANIZATION: undefined,
  SET_TOTAL_ORGANIZATIONS: undefined,
  GET_ORGANIZATIONS: undefined,
  GET_ORGANIZATIONS_SUCCESS: undefined,
  GET_ORGANIZATIONS_FAIL: undefined,
  QUICK_SEARCH_ORGANIZATION: undefined,
  QUICK_SEARCH_ORGANIZATION_SUCCESS: undefined,
  QUICK_SEARCH_ORGANIZATION_FAIL: undefined,
  CREATE_ORGANIZATION: undefined,
  CREATE_ORGANIZATION_SUCCESS: undefined,
  CREATE_ORGANIZATION_FAIL: undefined,
  UPDATE_ORGANIZATION: undefined,
  UPDATE_ORGANIZATION_SUCCESS: undefined,
  UPDATE_ORGANIZATION_FAIL: undefined,
  DELETE_ORGANIZATION: undefined,
  DELETE_ORGANIZATION_SUCCESS: undefined,
  DELETE_ORGANIZATION_FAIL: undefined,
});
