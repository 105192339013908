import { handleActions } from 'redux-actions';
import panesActions from '../actions/actionTypes';
import { MAX_PANES } from '../../../../utils/consts';

const initialState = {
  activePanes: [],
  maxPanes: MAX_PANES,
};

const panesReducer = handleActions(
  {
    [panesActions.showPane.toString()]: (state, action) => {
      const { activePanes } = state;
      const { paneName } = action.payload;

      const paneIndex = activePanes.indexOf(paneName);
      let updatedPanes = [...activePanes];

      if (paneIndex >= 0) {
        return {
          ...state,
        };
      }

      if (activePanes.length === state.maxPanes) {
        updatedPanes.splice(0, 1);
        updatedPanes.push(paneName);
      } else {
        updatedPanes.push(paneName);
      }

      return {
        ...state,
        activePanes: updatedPanes,
      };
    },
    [panesActions.hidePane.toString()]: (state, action) => {
      const paneToDeleteIndex = state.activePanes.indexOf(
        action.payload.paneName
      );

      if (paneToDeleteIndex < 0) {
        return {
          ...state,
        };
      }

      return {
        activePanes: [...state.activePanes].filter(
          (pane) => pane !== action.payload.paneName
        ),
      };
    },
    [panesActions.hideAllPanes.toString()]: (state, action) => {
      return {
        ...state,
        activePanes: [],
      };
    },
  },
  initialState
);

export default panesReducer;
