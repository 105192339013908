import { handleActions } from 'redux-actions';
import notificationsActions from '../actions/actionTypes';

const initialState = {
  message: '',
  type: '',
  visible: false,
};

const notificationsReducer = handleActions(
  {
    [notificationsActions.showError.toString()]: (state, action) => {
      return {
        ...state,
        message: action.payload,
        type: 'error',
        visible: true,
      };
    },
    [notificationsActions.hideNotification.toString()]: (state, action) => {
      return initialState;
    },
  },
  initialState
);

export default notificationsReducer;
