import { handleActions } from 'redux-actions';
import actionsType from '../actions/actionTypes';

const {
  getSpreadsheetStart,
  getSpreadsheetSuccess,
  getSpreadsheetFail,
  getSpreadsheetsStart,
  getSpreadsheetsSuccess,
  getSpreadsheetsFail,
  createSpreadsheetStart,
  createSpreadsheetSuccess,
  createSpreadsheetFail,
  saveMasterSpreadsheetDataStart,
  saveMasterSpreadsheetDataSuccess,
  saveMasterSpreadsheetDataFail,
  savePublisherSpreadsheetDataStart,
  savePublisherSpreadsheetDataSuccess,
  savePublisherSpreadsheetDataFail,
  updateSpreadsheetStart,
  updateSpreadsheetSuccess,
  updateSpreadsheetFail,
  deleteSpreadsheetStart,
  deleteSpreadsheetSuccess,
  deleteSpreadsheetFail,
} = actionsType;

const initialState = {
  isCreating: false,
  isFetching: false,
  isUpdating: false,
  isDeleting: false,
  isSaving: false,
  insertionDetails: {},
  spreadsheets: [],
  currentSheet: {},
  totalSheets: 0,
};

const spreadsheetsReducer = handleActions(
  {
    [getSpreadsheetStart.toString()]: (state, action) => {
      return {
        ...state,
        isFetching: true,
      };
    },
    [getSpreadsheetSuccess.toString()]: (state, action) => {
      return {
        ...state,
        currentSheet: action.payload.spreadsheet,
        isFetching: false,
      };
    },
    [getSpreadsheetFail.toString()]: (state, action) => {
      return {
        ...state,
        error: action.error,
        isFetching: false,
      };
    },
    [getSpreadsheetsStart.toString()]: (state, action) => {
      return {
        ...state,
        isFetching: true,
      };
    },
    [getSpreadsheetsSuccess.toString()]: (state, action) => {
      return {
        ...state,
        spreadsheets: action.payload.spreadsheets,
        totalSheets: action.payload.totalSheets,
        isFetching: false,
      };
    },
    [getSpreadsheetsFail.toString()]: (state, action) => {
      return {
        ...state,
        error: action.error,
        isFetching: false,
      };
    },

    [createSpreadsheetStart.toString()]: (state, action) => {
      return {
        ...state,
        isCreating: true,
      };
    },
    [createSpreadsheetSuccess.toString()]: (state, action) => {
      return {
        ...state,
        isCreating: false,
        spreadsheets: [...state.spreadsheets, action.payload.spreadsheet],
      };
    },
    [createSpreadsheetFail.toString()]: (state, action) => {
      return {
        ...state,
        isCreating: false,
        eror: action.error,
      };
    },
    [saveMasterSpreadsheetDataStart.toString()]: (state, action) => {
      return {
        ...state,
        isSaving: true,
        insertionDetails: {},
      };
    },
    [saveMasterSpreadsheetDataSuccess.toString()]: (state, action) => {
      return {
        ...state,
        currentSheet: {
          ...action.payload.spreadsheetData.spreadsheet,
        },
        isSaving: false,
        insertionDetails: action.payload.spreadsheetData.insertionDetails,
      };
    },
    [saveMasterSpreadsheetDataFail.toString()]: (state, action) => {
      return {
        ...state,
        isSaving: false,
        insertionDetails: {},
        error: action.error,
      };
    },
    [savePublisherSpreadsheetDataStart.toString()]: (state, action) => {
      return {
        ...state,
        isSaving: true,
      };
    },
    [savePublisherSpreadsheetDataSuccess.toString()]: (state, action) => {
      return {
        ...state,
        currentSheet: {
          ...action.payload.spreadsheetData.spreadsheet,
        },
        isSaving: false,
      };
    },
    [savePublisherSpreadsheetDataFail.toString()]: (state, action) => {
      return {
        ...state,
        isSaving: false,
        error: action.error,
      };
    },
    [updateSpreadsheetStart.toString()]: (state, action) => {
      return {
        ...state,
        isUpdating: true,
      };
    },
    [updateSpreadsheetSuccess.toString()]: (state, action) => {
      return {
        ...state,
        isUpdating: false,
        currentSheet: action.payload.currentSheet,
      };
    },
    [updateSpreadsheetFail.toString()]: (state, action) => {
      return {
        ...state,
        error: action.error,
        isUpdating: false,
      };
    },
    [deleteSpreadsheetStart.toString()]: (state, action) => {
      return {
        ...state,
        isDeleting: true,
      };
    },
    [deleteSpreadsheetSuccess.toString()]: (state, action) => {
      const updatedSheets = state.spreadsheets.filter(
        (sheet) => sheet._id !== action.payload.deletedSheet
      );

      return {
        ...state,
        spreadsheets: updatedSheets,
        isDeleting: false,
      };
    },
    [deleteSpreadsheetFail.toString()]: (state, action) => {
      return {
        ...state,
        isDeleting: false,
        error: action.error,
      };
    },
  },
  initialState
);

export default spreadsheetsReducer;
