import { axios } from '../../../../../services/axios';
import { updateProjectsNumber } from '../../../../../containers/projects/actions';
import { handleServerError } from '../../../../../utils';
import { show_error } from '../../../../../utils/consts';
import spotifyActions from './actionTypes';

export const getSongsFromSpotify = (query, searchType) => {
  return async (dispatch) => {
    dispatch(spotifyActions.getSongsFromSpotify());

    try {
      if (query.length > 0) {
        const response = await axios.get(
          `/spotify/songs?query=${query}&type=${searchType}`
        );

        dispatch(
          spotifyActions.getSongsFromSpotifySuccess(
            response.data.spotifyData[`${searchType}s`]
          )
        );
      } else {
        dispatch(spotifyActions.getSongsFromSpotifySuccess([]));
      }
    } catch (error) {
      dispatch(spotifyActions.getSongsFromSpotifyFailed(error));

      throw error;
    }
  };
};

export const storeSpotifySongs = (songs, source) => {
  return async (dispatch, getState) => {
    dispatch(spotifyActions.storeSpotifySongs());
    const author = getState().auth.user.username;

    try {
      const response = await axios.post('/spotify/songs', {
        songs,
        author,
        createdAt: new Date().toISOString(),
        source,
      });

      dispatch(spotifyActions.storeSpotifySongsSuccess(response.data));
      dispatch(updateProjectsNumber(response.data.totalProjects));
    } catch (error) {
      dispatch(spotifyActions.storeSpotifySongsFailed(error));

      throw error;
    }
  };
};

export const importSongDataFromSpotify = (
  code,
  codeType,
  isrc = '',
  upc = '',
  catalogue_number = ''
) => {
  return async (dispatch) => {
    dispatch(spotifyActions.importSongDataFromSpotify());

    try {
      await axios.patch(
        `/spotify/song?code=${code}&codeType=${codeType}&isrc=${isrc}&upc=${upc}&catalogue_number=${catalogue_number}`
      );

      dispatch(spotifyActions.importSongDataFromSpotifySuccess());
    } catch (error) {
      dispatch(spotifyActions.importSongDataFromSpotifyFailed(error));

      const errorMessage = handleServerError(error.response.data);

      show_error({ message: errorMessage });

      throw errorMessage;
    }
  };
};

export const importSongsDataFromSpotify = (songs) => {
  return async (dispatch) => {
    dispatch(spotifyActions.importSongsDataFromSpotify());

    try {
      await axios.patch(`/spotify/songs`, { songs });

      dispatch(spotifyActions.importSongsDataFromSpotifySuccess());
    } catch (error) {
      dispatch(spotifyActions.importSongsDataFromSpotifyFailed(error));

      throw error;
    }
  };
};
