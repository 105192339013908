import React, { useEffect, useCallback, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Droppable } from 'react-beautiful-dnd';
import Proptypes from 'prop-types';
import {
  Typography,
  Avatar,
  message,
  Dropdown,
  Tabs,
  notification,
  Modal,
  Pagination,
  Checkbox,
} from 'antd';

import {
  CloseOutlined,
  MoreOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons';

import routes from 'containers/app/components/AppRoutes/routes';
import {
  EDIT_LABEL,
  VIEW_LABEL,
  EDIT_TRACKS,
} from 'containers/app/components/AppPanes/paneTypes';
import { SongListTable } from 'components/TableElements';
import EntitySpinner from 'components/Spinner/EntitySpinner';
import ModuleDropdown from 'components/ModuleDropdown';

import LabelMetadata from './LabelMetadata/LabelMetadata';

import { hidePane, showPane, hideAllPanes } from 'store/modules/panes/actions';
import {
  clearTracks,
  setTracks,
  removeTracksFromEntity,
} from 'containers/tracks/actions';

import {
  getLabelTracks,
  setSelectedLabel,
  deleteLabelLogo,
  deleteLabel,
  setEditMode,
  updateLabel,
} from '../../actions';

import { CDN_URL } from 'utils/consts';

const { Title, Text } = Typography;
const { TabPane } = Tabs;
const { confirm } = Modal;

const limit = 10;

const ViewLabel = ({
  tracks,
  getLabelTracks,
  label,
  totalLabelTracks,
  setSelectedLabel,
  setEditMode,
  deleteLabel,
  deleteLabelLogo,
  hidePane,
  showPane,
  setTracks,
  clearTracks,
  hideAllPanes,
  removeTracksFromEntity,
  validRoutes,
  isFetchingSingle,
  updateLabel,
}) => {
  const [currentTrackPage, setCurrentTrackPage] = useState(1);
  const { pathname } = useLocation();
  const isEditSection = pathname.includes(routes.labels.path);
  const isValidPaneRoute = validRoutes.includes(pathname);

  const onLabelLoad = useCallback(async () => {
    const { name } = label;

    if (name) {
      try {
        await getLabelTracks(name);
      } catch (error) {
        message.error('We could not fetch your data. Please try again.', 2);
      }
    }
  }, [getLabelTracks, label]);

  const getLabelImage = (size) => {
    if (label.logo) {
      return `${CDN_URL}/${label.logo[size]}`;
    }
  };

  // Label PANE
  const toggleEditPaneHandler = (show) => {
    if (show) {
      showPane(EDIT_LABEL);
    } else {
      hidePane(EDIT_LABEL);
    }
    hidePane(VIEW_LABEL);
    hidePane(EDIT_TRACKS);
  };

  const onClose = () => {
    hideAllPanes();
  };

  const onCreateItem = () => {
    toggleEditPaneHandler(true);
    setSelectedLabel({});

    setEditMode(false);
  };

  const onEditItem = () => {
    toggleEditPaneHandler(true);
    setEditMode(true);
  };

  const onEditAllHandler = (selectedTracks = [], source = 'list') => {
    if (source === 'menu') {
      showPane(EDIT_TRACKS);
    }

    setTracks(selectedTracks.map((trackIndex) => tracks[trackIndex]));
  };

  const onChangeTrackPageHandler = async (pageNumber) => {
    try {
      await getLabelTracks(label.name, { page: pageNumber, limit });
      setCurrentTrackPage(pageNumber);
    } catch (error) {
      message.error('We could not fetch your data. Please try again.', 2);
    }
  };

  const onRemoveHandler = async (selectedTracks) => {
    try {
      await removeTracksFromEntity(selectedTracks, 'labels');

      notification.success({
        message: `Label Updated`,
        description: 'Tracks list was updated.',
        duration: 3,
      });
    } catch (error) {
      message.error('Tracks were not removed. Please Contact Support.', 4);
    }
  };

  const updatePublicStatusHandler = async (e) => {
    const { checked } = e.target;

    await updateLabel(label._id, { public: checked });
    setSelectedLabel({ ...label, public: checked });
  };

  const onDeleteItem = async (labelId, imageKey) => {
    if (imageKey) {
      try {
        await deleteLabelLogo(imageKey);
      } catch (error) {
        message.error('Image was not deleted. Please Contact Support.', 4);
      }
    }

    try {
      await deleteLabel(labelId);

      notification.success({
        message: `Label Deleted`,
        description: 'Label list was updated.',
        duration: 3,
      });

      hidePane(EDIT_TRACKS);
    } catch (error) {
      notification.warning({
        message: `Label Delete Failed`,
        description: 'Please contact support.',
        duration: 3,
      });
    }

    onClose();
  };

  const onDeleteHandler = async () => {
    const labelId = label._id;
    const labelImage = label?.logo?.small;
    confirm({
      title: 'Are you sure you want to delete this label?',
      icon: <ExclamationCircleOutlined />,
      content: 'This action is irreversible.',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        onDeleteItem(labelId, labelImage);
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  };

  useEffect(() => {
    if (!isValidPaneRoute) {
      hideAllPanes();
    }
  }, [isValidPaneRoute, hideAllPanes]);

  useEffect(() => {
    onLabelLoad();

    return () => {
      clearTracks();
    };
  }, [onLabelLoad, clearTracks]);

  return (
    <div className="vw-module content-module">
      <div className="content-title view-module-title">
        <CloseOutlined onClick={onClose} />

        <Title level={3}>LABEL</Title>

        {label.status === 'registered' && isEditSection ? (
          <Dropdown
            overlay={ModuleDropdown.bind(this, {
              onCreateItem,
              onEditItem,
              onDeleteItem: onDeleteHandler,
              onRefresh: () => {},
              type: 'label',
            })}
            trigger={['click']}
          >
            <MoreOutlined />
          </Dropdown>
        ) : (
          <span />
        )}
      </div>

      <div className="module-container">
        <div className="module-image">
          <Avatar shape="square" size={125} src={getLabelImage('small')} />

          <div className="module-titles">
            <Title level={4}>{label.name}</Title>
            <Text>{label.slug}</Text>
            <div>
              <Checkbox
                className="public-status-control"
                onChange={updatePublicStatusHandler}
                checked={label.public}
              >
                Public
              </Checkbox>
            </div>
          </div>
        </div>

        <Tabs>
          <TabPane tab="Track Listing" key="1">
            {isFetchingSingle ? (
              <EntitySpinner message="" />
            ) : (
              <Droppable droppableId="labels">
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                    style={{ minHeight: '20vh' }}
                  >
                    <SongListTable
                      tracks={tracks}
                      isEditSection={isEditSection}
                      isDraggingOver={snapshot.isDraggingOver}
                      onEditAll={onEditAllHandler}
                      onRemoveOne={onRemoveHandler}
                      onRemoveAll={onRemoveHandler}
                    />
                    {provided.placeholder}

                    <div className="instructions">
                      <Text>
                        Drag and drop tracks here to add to the <u>label</u>.
                      </Text>
                    </div>
                  </div>
                )}
              </Droppable>
            )}
            <Pagination
              onChange={onChangeTrackPageHandler}
              current={currentTrackPage}
              pageSize={limit}
              total={totalLabelTracks}
              defaultCurrent={1}
              showSizeChanger={false}
              style={{
                padding: '40px',
                textAlign: 'center',
                float: 'inherit',
                borderTop: '1px solid #e2e2e2',
              }}
            />
          </TabPane>
          <TabPane tab="Metadata" key="2">
            <LabelMetadata label={label} />
          </TabPane>
        </Tabs>
      </div>
    </div>
  );
};

ViewLabel.defaultProps = {
  tracks: [],
  getLabelTracks: () => {},
  label: {},
  setSelectedLabel: () => {},
  setEditMode: () => {},
  deleteLabel: () => {},
  deleteLabelImage: () => {},
  hidePane: () => {},
  showPane: () => {},
  isFetchingSingle: false,
  totalLabelTracks: 0,
};

ViewLabel.propTypes = {
  tracks: Proptypes.array,
  getLabelTracks: Proptypes.func,
  label: Proptypes.object,
  setSelectedLabel: Proptypes.func,
  setEditMode: Proptypes.func,
  deleteLabel: Proptypes.func,
  deleteLabelLogo: Proptypes.func,
  hidePane: Proptypes.func,
  showPane: Proptypes.func,
  isFetchingSingle: Proptypes.bool,
  totalLabelTracks: Proptypes.number,
  updateLabel: Proptypes.func,
};

const mapStateToProps = ({ labels }) => {
  return {
    editMode: labels.editMode,
    isFetchingSingle: labels.isFetchingSingle,
    label: labels.selectedLabel,
    tracks: labels.labelTracks,
    totalLabelTracks: labels.totalLabelTracks,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getLabelTracks,
      setSelectedLabel,
      deleteLabelLogo,
      deleteLabel,
      setEditMode,
      showPane,
      hidePane,
      setTracks,
      clearTracks,
      hideAllPanes,
      removeTracksFromEntity,
      updateLabel,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(ViewLabel);
