import { axios } from '../../../services/axios';
import { UrlLogin } from '../../../utils/consts';
import actionTypes from './actionTypes';

let tokenExpirationTimer = null;

export const checkAuthTimeout = (expirationTime) => {
  return (dispatch) => {
    tokenExpirationTimer = setTimeout(() => {
      dispatch(logout());
    }, expirationTime * 1000);
  };
};

export const login = ({ username, password }) => {
  return async (dispatch) => {
    dispatch(actionTypes.authStart());

    const authData = {
      username,
      password,
      returnSecureToken: true,
    };

    try {
      const response = await axios.post('/auth/login', authData);

      const tokenDuration = response.data.expiresIn.split('m')[0];
      const expiresIn = Number(tokenDuration) * 60 * 1000;
      const expirationDate = new Date(new Date().getTime() + expiresIn);
      const user = response.data;

      localStorage.setItem('loggedIn', true);
      localStorage.setItem('username', user.username);
      localStorage.setItem('token', user.token);
      localStorage.setItem('expirationDate', expirationDate);

      axios.interceptors.request.use(
        (config) => {
          config.headers.Authorization = response.data.token;
          return config;
        },
        (error) => {
          return Promise.reject(error);
        }
      );

      dispatch(actionTypes.authSuccess(response.data));
      dispatch(checkAuthTimeout(expiresIn));
    } catch (error) {
      dispatch(actionTypes.authFail(error?.response?.data?.message));
    }
  };
};

export const getSession = () => {
  return (dispatch) => {
    const token = localStorage.getItem('token');

    if (!token) {
      endSession();

      dispatch(actionTypes.logout());
    } else {
      axios
        .post('auth/session', { token })
        .then((response) => {
          if (response.data.case === 'jwtExpired') {
            endSession();

            return dispatch(actionTypes.logout());
          }

          const expirationDate = new Date(
            localStorage.getItem('expirationDate')
          );

          if (expirationDate <= new Date()) {
            endSession();

            dispatch(actionTypes.logout());
          } else {
            dispatch(actionTypes.authSuccess(response.data));
            dispatch(
              checkAuthTimeout(
                (expirationDate.getTime() - new Date().getTime()) / 1000
              )
            );
          }
        })
        .catch((error) => {
          endSession();

          // return show_error({ message: error?.response?.data?.message });
        });
    }
  };
};

export const logout = () => {
  return async (dispatch) => {
    try {
      await axios.post(`auth/logout`, { token: localStorage.getItem('token') });

      endSession();

      window.location.href = UrlLogin;
    } catch (error) {
      endSession();

      dispatch(actionTypes.logoutFail(error));
    }
  };
};

const endSession = () => {
  localStorage.removeItem('username');
  localStorage.removeItem('loggedIn');
  localStorage.removeItem('token');
  localStorage.removeItem('expirationDate');

  if (tokenExpirationTimer) {
    clearTimeout(tokenExpirationTimer);
  }

  tokenExpirationTimer = null;
};
