import * as externalAxios from 'axios';
import { axios } from 'services/axios';
import { cleanObject, handleServerError } from 'utils';
import { show_error } from 'utils/consts';

import tagActions from './actionTypes';

export const setTag = (tag) => tagActions.setTag({ tag });

export const setTotalTags = (totalTags) => tagActions.setTag({ totalTags });
export const setTotalTagCategories = (totalTagCategories) =>
  tagActions.setTag({ totalTagCategories });

export const getTags = (filters, pagination) => {
  return async (dispatch) => {
    dispatch(tagActions.getTags());
    let defaultOptions = {
      current: 1,
      limit: 'full',
    };
    let queryOptions = pagination;

    if (!pagination) {
      queryOptions = defaultOptions;
    }

    filters = cleanObject(filters);

    try {
      const response = await axios.post('/tags', {
        page: queryOptions.current,
        pageSize: queryOptions.limit,
        filters,
        order: queryOptions.order,
        field: queryOptions.field || '',
      });

      dispatch(
        tagActions.getTagsSuccess({
          tags: response.data.tags,
          totalTags: response.data.totalTags,
        })
      );
    } catch (error) {
      dispatch(tagActions.getTagsFail(error));

      throw error;
    }
  };
};

export const quickSearchTag = (string, type) => {
  return async (dispatch, getState) => {
    dispatch(tagActions.quickSearchTag());

    try {
      const response = await axios.get(`/tags/quick_search?string=${string}`);

      dispatch(
        tagActions.quickSearchTagSuccess({
          tags: response.data.tags,
          totalTags: response.data.totalTags,
        })
      );
    } catch (err) {
      dispatch(tagActions.quickSearchTagFail(err));

      // return show_error({ message: err?.response?.data });
    }
  };
};

export const getTagCategories = (search = '', pagination) => {
  return async (dispatch) => {
    dispatch(tagActions.getTagCategories());
    let defaultOptions = {
      current: 1,
      limit: 'full',
    };
    let queryOptions = pagination;

    if (!pagination) {
      queryOptions = defaultOptions;
    }

    try {
      const response = await axios.get('/tag-categories', {
        params: {
          page: queryOptions.current,
          pageSize: queryOptions.limit,
          search,
          order: queryOptions.order,
          field: queryOptions.field || '',
        },
      });

      dispatch(
        tagActions.getTagCategoriesSuccess({
          tagCategories: response.data.tagCategories,
          totalTagCategories: response.data.totalTagCategories,
        })
      );
    } catch (error) {
      dispatch(tagActions.getTagCategoriesFail(error));

      throw error;
    }
  };
};

export const quickSearchTagCategory = (string, type) => {
  return async (dispatch, getState) => {
    dispatch(tagActions.quickSearchTagCategory());

    try {
      const response = await axios.get(
        `/tag-categories/quick_search?string=${string}`
      );

      dispatch(
        tagActions.quickSearchTagCategorySuccess({
          tagCategories: response.data.tagCategories,
          totalTagCategories: response.data.totalTagCategories,
        })
      );
    } catch (err) {
      dispatch(tagActions.quickSearchTagCategoryFail(err));

      // return show_error({ message: err?.response?.data });
    }
  };
};

export const createTag = (fields) => {
  return async (dispatch) => {
    dispatch(tagActions.createTag());
    try {
      const response = await axios.post('/tag/', fields);

      dispatch(tagActions.createTagSuccess({ tag: response.data.tag }));
    } catch (error) {
      dispatch(tagActions.createTagFail(error));

      const errorMessage = handleServerError(error.response.data);

      show_error({ message: errorMessage });

      throw error;
    }
  };
};

export const createTagCategory = (fields) => {
  return async (dispatch) => {
    dispatch(tagActions.createTagCategory());
    try {
      const response = await axios.post('/tag-category/', fields);

      dispatch(
        tagActions.createTagCategorySuccess({
          tagCategory: response.data.tagCategory,
        })
      );
    } catch (error) {
      dispatch(tagActions.createTagCategoryFail(error));

      const errorMessage = handleServerError(error.response.data);

      show_error({ message: errorMessage });

      throw error;
    }
  };
};

export const updateTag = (fields) => {
  return async (dispatch) => {
    dispatch(tagActions.updateTag());
    try {
      const response = await axios.patch('/tag/', fields);

      dispatch(tagActions.updateTagSuccess({ tag: response.data.tag }));
    } catch (error) {
      dispatch(tagActions.updateTagFail(error));

      throw error;
    }
  };
};

export const updateTagCategory = (fields) => {
  return async (dispatch) => {
    dispatch(tagActions.updateTagCategory());
    try {
      const response = await axios.patch('/tag-category/', fields);

      dispatch(
        tagActions.updateTagCategorySuccess({
          tagCategory: response.data.tagCategory,
        })
      );
    } catch (error) {
      dispatch(tagActions.updateTagCategoryFail(error));

      throw error;
    }
  };
};

export const deleteTag = (id) => {
  return async (dispatch) => {
    dispatch(tagActions.deleteTag());
    try {
      const response = await axios.delete('/tag/', { data: { id } });

      dispatch(tagActions.deleteTagSuccess({ id: response.data.id }));
    } catch (error) {
      dispatch(tagActions.deleteTagFail(error));

      throw error;
    }
  };
};

export const deleteTagCategory = (id) => {
  return async (dispatch) => {
    dispatch(tagActions.deleteTagCategory());
    try {
      const response = await axios.delete('/tag-category/', { data: { id } });

      dispatch(tagActions.deleteTagCategorySuccess({ id: response.data.id }));
    } catch (error) {
      dispatch(tagActions.deleteTagCategoryFail(error));

      throw error;
    }
  };
};

export const saveTagImage = (signedUrl, file) => {
  return async (dispatch) => {
    dispatch(tagActions.saveTagImage());
    try {
      await externalAxios.put(signedUrl, file, {
        headers: {
          'x-amz-acl': 'public-read',
          'Content-Type': file.type,
        },
      });

      dispatch(tagActions.saveTagImageSuccess());
    } catch (error) {
      dispatch(tagActions.saveTagImageFail(error));

      throw error;
    }
  };
};

export const deleteTagImage = (image) => {
  return async (dispatch) => {
    dispatch(tagActions.deleteTagImage());
    try {
      await axios.post('/tag/image/delete', { image });

      dispatch(tagActions.deleteTagImageSuccess());
    } catch (error) {
      dispatch(tagActions.deleteTagImageFail(error));

      throw error;
    }
  };
};
