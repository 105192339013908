import React, { useState, useEffect, useRef } from 'react';
import { NavLink } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import PropTypes from 'prop-types';

import { ReactComponent as ArrowIcon } from 'images/SVG/arrow.svg';

import './MultiLevelDropdown.scss';

export default function DropdownMenu(dropdownProps) {
  const [activeMenu, setActiveMenu] = useState('main');
  const [, setMenuHeight] = useState(null);
  const dropdownRef = useRef(null);

  useEffect(() => {
    setMenuHeight(dropdownRef.current?.firstChild.offsetHeight);
  }, [activeMenu]);

  function calcHeight(el) {
    let height = el.offsetHeight + 20;

    setMenuHeight(height);
  }

  function DropdownItem(props) {
    const onClickItem = () => {
      if (props.goToMenu) {
        setActiveMenu(props.goToMenu);
      }

      if (props.onClickItem) {
        props.onClickItem();
      }

      if (props.onOutsideClick && !props.goToMenu) {
        props.onOutsideClick();
      }
    };

    if (!props.link) {
      return (
        <span className="menu-item" onClick={onClickItem}>
          <span className="icon-button">{props.leftIcon}</span>
          {props.children}
          <span className="icon-right">{props.rightIcon}</span>
        </span>
      );
    }

    return (
      <NavLink to={props.link} className="menu-item" onClick={onClickItem}>
        <span className="icon-button">{props.leftIcon}</span>
        {props.children}
        <span className="icon-right">{props.rightIcon}</span>
      </NavLink>
    );
  }

  const renderSubMenu = (options) => {
    return options.map((bigOption, index) => {
      return (
        <CSSTransition
          key={bigOption + index}
          in={activeMenu === bigOption.subMenuTitle}
          timeout={300}
          classNames="menu-secondary"
          unmountOnExit
          onEnter={calcHeight}
        >
          <div className="menu">
            <DropdownItem goToMenu="main" leftIcon={<ArrowIcon />}>
              <span>Back</span>
            </DropdownItem>

            {bigOption?.subMenuOptions?.map((option) => (
              <DropdownItem
                onOutsideClick={dropdownProps.onOutsideClick}
                onClickItem={option.onClickItem}
                key={option.title + index}
                leftIcon={option.leftIcon}
                link={option.link}
              >
                {option.title}
              </DropdownItem>
            ))}
          </div>
        </CSSTransition>
      );
    });
  };

  return (
    <div className="dropdown" ref={dropdownRef}>
      <CSSTransition
        in={activeMenu === 'main'}
        timeout={300}
        classNames={{
          enter: 'menu-primary-enter',
          enterActive: 'menu-primary-active',
          exit: 'menu-primary-exit',
          exitActive: 'menu-primary-exit-active',
        }}
        unmountOnExit
        onEnter={calcHeight}
      >
        <div className="menu">
          {dropdownProps?.options?.map((option, index) => {
            return (
              <DropdownItem
                key={option.title + index}
                leftIcon={option.leftIcon}
                link={option.link}
                goToMenu={option.subMenuTitle}
                onClickItem={option.onClickItem}
                onOutsideClick={dropdownProps.onOutsideClick}
              >
                {option.title}
              </DropdownItem>
            );
          })}
        </div>
      </CSSTransition>

      {renderSubMenu(dropdownProps.options || [])}
    </div>
  );
}

DropdownMenu.propTypes = {
  onOutsideClick: PropTypes.func,
  options: PropTypes.array,
};
