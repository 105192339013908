import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Button, message } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';

import DropdownMenu from 'components/MultiLevelDropdown/MultiLevelDropdown';
import { NavItem } from '../Header/Header';

import { showPane, hideAllPanes } from 'store/modules/panes/actions';
import { createSpreadsheet } from 'containers/spreadsheets/actions';
import {
  setEditMode as setProjectEditMode,
  setSelectedProject,
} from 'containers/projects/actions';
import {
  setEditMode as setLabelEditMode,
  setSelectedLabel,
} from 'containers/labels/actions';
import { setTag } from 'containers/tags/actions';

import sprite from 'images/sprite.svg';
import { ReactComponent as IconDrive } from 'images/SVG/google-drive.svg';
import { ReactComponent as IconLayers } from 'images/SVG/layers.svg';
import { ReactComponent as IconProjects } from 'images/SVG/folder-music.svg';
import { ReactComponent as IconTag } from 'images/SVG/price-tag.svg';
import { ReactComponent as IconSpotify } from 'images/SVG/spotify.svg';
import { ReactComponent as IconEdit } from 'images/SVG/edit.svg';
import { ReactComponent as IconMusic } from 'images/SVG/beamed-note.svg';

import { ReactComponent as IconContent } from 'images/SVG/book.svg';
import { ReactComponent as IconPublisher } from 'images/SVG/suitcase.svg';

import { FormOutlined } from '@ant-design/icons';

import {
  EDIT_PROJECT,
  EDIT_LABEL,
  EDIT_TAG,
  SPOTIFY_IMPORTER,
} from '../AppPanes/paneTypes';

import { addClass, removeClass } from 'utils';
import CratesLogo from 'components/CratesLogo/CratesLogo';
import MenuCollapse from 'containers/app/components/MenuCollapse/MenuCollapse';

const htmlBody = document.querySelector('body');

const Sidebar = ({
  totalClMaster,
  totalClPublishing,
  totalClPhysical,
  totalTracksVendors = [],
  totalLabels,
  totalProjects,
  totalTags,
  totalTracks,
  // totalTagCategories,
  totalPartners,
  totalOrganizations,
  onItemClick,
  sideMenuVisible,
  // onSpotifyImporterClick,
  createSpreadsheet,
  showPane,
  hideAllPanes,
  setSelectedProject,
  isAdmin = false,
  isOrgAdmin = false,
}) => {
  const location = useLocation();
  const { pathname, search } = location;

  const onAddSpreadSheetHandler = async (type) => {
    try {
      await createSpreadsheet({
        type,
        imported: false,
        title: 'New Spreadsheet',
      });
    } catch (error) {
      message.error(
        'We are unable to store the spreadsheet into the database',
        10
      );
    }
  };

  const onAddProjectHandler = () => {
    hideAllPanes();
    setProjectEditMode(false);
    setSelectedProject({});
    showPane(EDIT_PROJECT);
  };

  const onAddLabelHandler = () => {
    hideAllPanes();
    setLabelEditMode(false);
    setSelectedLabel({});
    showPane(EDIT_LABEL);
  };

  const onAddTagHandler = () => {
    hideAllPanes();
    setTag({ new: true });
    showPane(EDIT_TAG);
  };

  if (sideMenuVisible) {
    addClass(htmlBody, 'util-no-flow');
  } else {
    removeClass(htmlBody, 'util-no-flow');
  }

  const isSpreadsheetRouteActive = pathname.startsWith('/spreadsheet')
    ? 'side-nav__item--active'
    : '';

  //Start Content Libraries
  const isContentLibrariesActive = pathname.startsWith('/content-libraries')
    ? true
    : false;
  const isClMasterRecordingActive = pathname.startsWith(
    '/content-libraries/masterRecording'
  )
    ? true
    : false;
  const isClPublishingActive = pathname.startsWith(
    '/content-libraries/publishing'
  )
    ? true
    : false;
  const isClPhysicalActive = pathname.startsWith('/content-libraries/physical')
    ? true
    : false;
  //End Content Libraries

  const isLabelsRouteActive = pathname.startsWith('/labels')
    ? 'side-nav__item--active'
    : '';
  const isProjectsRouteActive = pathname.startsWith('/projects')
    ? 'side-nav__item--active'
    : '';
  const isTagsRouteActive = pathname.startsWith('/tags')
    ? 'side-nav__item--active'
    : '';
  // const isTagCategoriesRouteActive = pathname.startsWith('/tag-categories')
  //   ? 'side-nav__item--active'
  //   : '';
  const isPartnersRouteActive = pathname.startsWith('/partners')
    ? 'side-nav__item--active'
    : '';

  const isAdministratorsRouteActive = pathname.startsWith('/administrators')
    ? 'side-nav__item--active'
    : '';

  const isLegalRouteActive = pathname.startsWith('/legal')
    ? 'side-nav__item--active'
    : '';
  // const isIngestionRouteActive = pathname.startsWith('/ingestion')
  //   ? 'side-nav__item--active'
  //   : '';
  // const isMarketingRouteActive = pathname.startsWith('/marketing')
  //   ? 'side-nav__item--active'
  //   : '';
  const isAccountingRouteActive = pathname.startsWith('/accounting')
    ? 'side-nav__item--active'
    : '';

  const isAnalyticsRouteActive = pathname.startsWith('/analytics')
    ? 'side-nav__item--active'
    : '';

  const isUserRouteActive = pathname.startsWith('/users')
    ? 'side-nav__item--active'
    : '';

  const isLabeltoolRouteActive = pathname.startsWith('/labeltool')
    ? 'side-nav__item--active'
    : '';

  const isPressRouteActive = pathname.startsWith('/press')
    ? 'side-nav__item--active'
    : '';

  const dropdownOptions = [
    {
      title: 'Track',
      leftIcon: <IconMusic />,
      subMenuTitle: 'imports',
      subMenuOptions: [
        {
          title: 'Manual Import',
          leftIcon: <IconEdit />,
          link: '/tracks/new',
        },
        {
          title: 'From Spotify',
          leftIcon: <IconSpotify />,
          onClickItem: () => showPane(SPOTIFY_IMPORTER),
        },
      ],
    },
    {
      title: 'Label',
      leftIcon: <IconLayers />,
      link: '/labels',
      onClickItem: onAddLabelHandler,
    },
    {
      title: 'Project',
      leftIcon: <IconProjects />,
      link: '/projects',
      onClickItem: onAddProjectHandler,
    },
    {
      title: 'Tag',
      leftIcon: <IconTag />,
      link: '/tags',
      onClickItem: onAddTagHandler,
    },
    {
      title: 'Spreadsheets',
      leftIcon: <IconDrive />,
      subMenuTitle: 'sheets',
      subMenuOptions: [
        {
          title: 'Master Sheet',
          leftIcon: <IconContent />,
          onClickItem: () => onAddSpreadSheetHandler('master'),
        },
        {
          title: 'Publisher Sheet',
          leftIcon: <IconPublisher />,
          onClickItem: () => onAddSpreadSheetHandler('publisher'),
        },
      ],
    },
  ];

  const getTotalTrackVendorsFormatted = (type) => {
    return totalTracksVendors
      .filter((tracksVendor) => tracksVendor[type] > 0)
      .map((tracksVendor) => {
        const path = pathname + search;

        let isActive = '';

        if (type === 'publishing') {
          if (
            path.includes('/publishing') &&
            path.includes(`vendor=${tracksVendor._id}`)
          )
            isActive = 'side-nav__item--active';

          return {
            title: tracksVendor.name,
            link: `/content-libraries/publishing?vendor=${tracksVendor._id}`,
            isActive,
            total: tracksVendor[type],
          };
        }

        if (type === 'physical') {
          if (
            path.includes('/physical') &&
            path.includes(`vendor=${tracksVendor._id}`)
          )
            isActive = 'side-nav__item--active';

          return {
            title: tracksVendor.name,
            link: `/content-libraries/physical?vendor=${tracksVendor._id}`,
            isActive,
            total: tracksVendor[type],
          };
        }

        if (
          path.includes('/masterRecording') &&
          path.includes(`vendor=${tracksVendor._id}`)
        )
          isActive = 'side-nav__item--active';

        return {
          title: tracksVendor.name,
          link: `/content-libraries/masterRecording?vendor=${tracksVendor._id}`,
          isActive,
          total: tracksVendor[type],
        };
      });
  };

  const contentDropdownOptions = [
    {
      isSubMenu: true,
      title: 'Master Recording',
      subChildrens: getTotalTrackVendorsFormatted('master'),
      link: '/content-libraries/masterRecording',
      isActive: isClMasterRecordingActive,
      total: totalClMaster,
    },
    {
      isSubMenu: true,
      title: 'Publishing',
      subChildrens: getTotalTrackVendorsFormatted('publishing'),
      link: '/content-libraries/publishing',
      isActive: isClPublishingActive,
      total: totalClPublishing,
    },
    {
      isSubMenu: true,
      title: 'Physical',
      subChildrens: getTotalTrackVendorsFormatted('physical'),
      link: '/content-libraries/physical',
      isActive: isClPhysicalActive,
      total: totalClPhysical,
    },
  ];

  return (
    <nav className={`sidebar ${sideMenuVisible ? 'sidebar-active' : ''}`}>
      <ul className="side-nav">
        <div className="logo">
          <CratesLogo className="img-responsive" />

          {/* <h2 className="main-title"> Crates</h2> */}
        </div>

        <NavItem
          icon={
            <Button
              type="primary"
              className="btn-primary main-action"
              icon={<PlusOutlined />}
            >
              New
            </Button>
          }
        >
          <DropdownMenu
            options={dropdownOptions}
            onOutsideClick={onItemClick}
          ></DropdownMenu>
        </NavItem>

        <MenuCollapse
          childrens={contentDropdownOptions}
          title="CONTENT LIBRARIES"
          isActive={isContentLibrariesActive}
          total={totalTracks}
        />

        {/* <li className={`side-nav__item ${isTracksRouteActive}`}>
          <NavLink
            to="/tracks"
            className="side-nav__link"
            onClick={onItemClick}
          >
            <svg className="side-nav__icon">
              <use href={sprite + '#icon-beamed-note'}></use>
            </svg>

            <span>Tracks</span>
          </NavLink>

          <span className="side-nav__total">
            {totalTracks ? totalTracks : '...'}
          </span>
        </li> */}
        <li className={`side-nav__item ${isLabelsRouteActive}`}>
          <NavLink
            to="/labels"
            exact={true}
            className="side-nav__link"
            onClick={onItemClick}
          >
            <svg className="side-nav__icon">
              <use href={sprite + '#icon-layers'}></use>
            </svg>

            <span>Labels</span>
          </NavLink>
          <span className="side-nav__total">
            {totalLabels ? totalLabels : '...'}
          </span>
        </li>
        <li className={`side-nav__item ${isProjectsRouteActive}`}>
          {' '}
          <NavLink
            to="/projects"
            exact={true}
            className="side-nav__link"
            onClick={onItemClick}
          >
            <svg className="side-nav__icon">
              <use href={sprite + '#icon-folder-music'}></use>
            </svg>

            <span>Projects</span>
          </NavLink>
          <span className="side-nav__total">
            {totalProjects ? totalProjects : '...'}
          </span>
        </li>
        <li className={`side-nav__item ${isTagsRouteActive}`}>
          <NavLink
            to="/tags"
            exact={true}
            className="side-nav__link"
            onClick={onItemClick}
          >
            <svg className="side-nav__icon">
              <use href={sprite + '#icon-price-tag'}></use>
            </svg>

            <span>Tags</span>
          </NavLink>

          <span className="side-nav__total">
            {totalTags ? totalTags : '...'}
          </span>
        </li>
        {/* <li className={`side-nav__item ${isTagCategoriesRouteActive}`}>
          <NavLink
            to="/tag-categories"
            exact={true}
            className="side-nav__link"
            onClick={onItemClick}
          >
            <svg className="side-nav__icon">
              <use href={sprite + '#icon-pin'}></use>
            </svg>

            <span>Categories</span>
          </NavLink>
          <span className="side-nav__total">
            {totalTagCategories ? totalTagCategories : '...'}
          </span>
        </li> */}

        <li className={`side-nav__item ${isPartnersRouteActive}`}>
          <NavLink
            to="/partners"
            exact={true}
            className="side-nav__link"
            onClick={onItemClick}
          >
            <svg className="side-nav__icon">
              <use href={sprite + '#icon-user'}></use>
            </svg>

            <span>Rights Holders</span>
          </NavLink>
          <span className="side-nav__total">
            {totalPartners ? totalPartners : '...'}
          </span>
        </li>

        {isAdmin && (
          <li className={`side-nav__item ${isAdministratorsRouteActive}`}>
            <NavLink
              to="/administrators"
              exact={true}
              className="side-nav__link"
              onClick={onItemClick}
            >
              <svg className="side-nav__icon">
                <use href={sprite + '#icon-home'}></use>
              </svg>

              <span>Administrators</span>
            </NavLink>
            <span className="side-nav__total">
              {totalOrganizations ? totalOrganizations : '...'}
            </span>
          </li>
        )}

        <div className="side-nav__divider" />

        {/* <li className={`side-nav__item ${isIngestionRouteActive}`}>
          <NavLink
            to="/ingestion"
            exact={true}
            className="side-nav__link"
            onClick={onItemClick}
          >
            <svg className="side-nav__icon">
              <use href={sprite + '#icon-rocket'}></use>
            </svg>

            <span>Ingestion</span>
          </NavLink>
        </li> */}
        <li className={`side-nav__item ${isLegalRouteActive}`}>
          <NavLink
            to="/legal"
            exact={true}
            className="side-nav__link"
            onClick={onItemClick}
          >
            <svg className="side-nav__icon">
              <use href={sprite + '#icon-folder'}></use>
            </svg>

            <span>Legal</span>
          </NavLink>
        </li>
        {/* <li className={`side-nav__item ${isMarketingRouteActive}`}>
          <NavLink
            to="/marketing"
            exact={true}
            className="side-nav__link"
            onClick={onItemClick}
          >
            <svg className="side-nav__icon">
              <use href={sprite + '#icon-shop'}></use>
            </svg>

            <span>Marketing</span>
          </NavLink>
        </li> */}

        <li className={`side-nav__item ${isAccountingRouteActive}`}>
          <NavLink
            to="/accounting"
            exact={true}
            className="side-nav__link"
            onClick={onItemClick}
          >
            <svg className="side-nav__icon">
              <use href={sprite + '#icon-wallet'}></use>
            </svg>

            <span>Accounting</span>
          </NavLink>
        </li>

        <li className={`side-nav__item ${isPressRouteActive}`}>
          <NavLink
            to="/press"
            exact={true}
            className="side-nav__link"
            onClick={onItemClick}
          >
            <FormOutlined className="side-nav__icon" />

            <span>Press</span>
          </NavLink>
        </li>

        <li className={`side-nav__item ${isAnalyticsRouteActive}`}>
          <NavLink
            to="/analytics"
            exact={true}
            className="side-nav__link"
            onClick={onItemClick}
          >
            <svg className="side-nav__icon">
              <use href={sprite + '#icon-bar-graph'}></use>
            </svg>

            <span>Analytics (beta)</span>
          </NavLink>
        </li>

        <div className="side-nav__divider" />

        <li className={`side-nav__item ${isLabeltoolRouteActive}`}>
          <NavLink
            to="/labeltool"
            exact={true}
            className="side-nav__link"
            onClick={onItemClick}
          >
            <svg className="side-nav__icon">
              <use href={sprite + '#icon-tools'}></use>
            </svg>

            <span>Labeltool</span>
          </NavLink>
        </li>

        <li className={`side-nav__item ${isSpreadsheetRouteActive}`}>
          <NavLink
            to="/spreadsheets"
            exact={true}
            className="side-nav__link"
            onClick={onItemClick}
          >
            <svg className="side-nav__icon">
              <use href={sprite + '#icon-google-drive'}></use>
            </svg>

            <span>Spreadsheets</span>
          </NavLink>
        </li>

        {(isAdmin || isOrgAdmin) && (
          <li className={`side-nav__item ${isUserRouteActive}`}>
            <NavLink
              to="/users"
              exact={true}
              className="side-nav__link"
              onClick={onItemClick}
            >
              <svg className="side-nav__icon">
                <use href={sprite + '#icon-users'}></use>
              </svg>

              <span>Staff</span>
            </NavLink>
          </li>
        )}
      </ul>

      <div className="legal">
        &copy; 2021 By Phase One Network. All rights reserved.
      </div>
    </nav>
  );
};

const MapStateToProps = ({
  tracks,
  labels,
  projects,
  tags,
  partners,
  organizations,
}) => {
  return {
    totalClMaster: tracks.sidebarClMaster,
    totalClPublishing: tracks.sidebarClPublishing,
    totalClPhysical: tracks.sidebarClPhysical,
    totalTracks: tracks.sidebarTotal,
    totalTracksVendors: tracks.sidebarTotalTracksVendors,
    totalLabels: labels.sidebarTotal,
    totalProjects: projects.sidebarTotal,
    totalTags: tags.sidebarTotalTags,
    totalTagCategories: tags.sidebarTotalTagCategories,
    totalPartners: partners.sidebarTotal,
    totalOrganizations: organizations.sidebarTotalOrganizations,
  };
};

const MapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      createSpreadsheet,
      showPane,
      hideAllPanes,
      setProjectEditMode,
      setLabelEditMode,
      setSelectedProject,
      setSelectedLabel,
      setTag,
    },
    dispatch
  );

Sidebar.propTypes = {
  totalTracks: PropTypes.number,
  totalLabels: PropTypes.number,
  totalProjects: PropTypes.number,
  totalTags: PropTypes.number,
  totalTagCategories: PropTypes.number,
  totalPartners: PropTypes.number,
  totalOrganizations: PropTypes.number,
  sideMenuVisible: PropTypes.bool,
  isAdmin: PropTypes.bool,
  isOrgAdmin: PropTypes.bool,
  onItemClick: PropTypes.func,
  onSpotifyImporterClick: PropTypes.func,
  createSpreadsheet: PropTypes.func,
  showPane: PropTypes.func,
  hideAllPanes: PropTypes.func,
  setSelectedProject: PropTypes.func,
};

export default connect(MapStateToProps, MapDispatchToProps)(Sidebar);
