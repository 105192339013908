export const COLUMNSORDER = ['tracks-table', 'projects', 'labels'];

export const ENTITIES = {
  'tracks-table': {
    id: 'tracks-table',
    title: 'tracks',
    taskIds: [],
    field: 'title',
  },
  projects: {
    id: 'projects',
    title: 'projects',
    taskIds: [],
    field: 'project_title',
  },
  labels: {
    id: 'labels',
    title: 'labels',
    taskIds: [],
    field: 'name',
  },
};
