import { createActions } from 'redux-actions';

export default createActions({
  SET_SELECTED_LABEL: undefined,
  SET_EDIT_MODE: undefined,
  SET_PANEL_PAGE: undefined,
  SET_TOTAL_LABELS: undefined,
  GET_LABELS: undefined,
  GET_LABELS_SUCCESS: undefined,
  GET_LABELS_FAIL: undefined,
  GET_LABEL_TRACKS: undefined,
  GET_LABEL_TRACKS_SUCCESS: undefined,
  GET_LABEL_TRACKS_FAIL: undefined,
  QUICK_SEARCH_LABEL: undefined,
  QUICK_SEARCH_LABEL_SUCCESS: undefined,
  QUICK_SEARCH_LABEL_FAIL: undefined,
  CREATE_LABEL: undefined,
  CREATE_LABEL_SUCCESS: undefined,
  CREATE_LABEL_FAIL: undefined,
  UPDATE_LABEL: undefined,
  UPDATE_LABEL_SUCCESS: undefined,
  UPDATE_LABEL_FAIL: undefined,
  DELETE_LABEL: undefined,
  DELETE_LABEL_SUCCESS: undefined,
  DELETE_LABEL_FAIL: undefined,
  REGISTER_LABEL: undefined,
  REGISTER_LABEL_SUCCESS: undefined,
  REGISTER_LABEL_FAIL: undefined,
  SAVE_LABEL_LOGO: undefined,
  SAVE_LABEL_LOGO_SUCCESS: undefined,
  SAVE_LABEL_LOGO_FAIL: undefined,
  DELETE_LABEL_LOGO: undefined,
  DELETE_LABEL_LOGO_SUCCESS: undefined,
  DELETE_LABEL_LOGO_FAIL: undefined,
});
