import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { message, Modal } from 'antd';
import WebFont from 'webfontloader';

import Header from './components/Header/Header';
import Sidebar from './components/Sidebar/Sidebar';
import AppPanes from './components/AppPanes/AppPanes';
import AppRoutes from './components/AppRoutes/AppRoutes';
import { SPOTIFY_IMPORTER } from './components/AppPanes/paneTypes';

import { createSpreadsheet } from '../spreadsheets/actions';
import { showPane, hideAllPanes } from '../../store/modules/panes/actions';
import { addTracksToEntity } from '../tracks/actions';
import { isElementVisible } from '../../utils/visual';

//DND
import { ENTITIES, COLUMNSORDER } from './data/dnd';
import { multiSelectTo as multiSelect } from '../../utils/dndUtils';
import { DragDropContext } from 'react-beautiful-dnd';
import { hideNotification } from '../../store/modules/notifications/actions';
import { getCatalogMetadata } from '../../store/modules/catalog/actions';
import { getNewRequests } from '../legal/actions';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      topVisible: false,
      searched: false,
      isSpImporterOpen: false,
      sideMenuVisible: false,
      selectedTaskIds: [],
      itemIds: [],
      entities: {
        columns: { ...ENTITIES },
        columnOrder: [...COLUMNSORDER],
      },
    };
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
    window.addEventListener('click', this.onWindowClick);
    window.addEventListener('keydown', this.onWindowKeyDown);
    window.addEventListener('touchend', this.onWindowTouchEnd);

    const { getNewRequests, loggedIn } = this.props;

    if (loggedIn) {
      getNewRequests();
    }
    this.handleScroll();
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.isServerError !== this.props.isServerError &&
      this.props.isServerError
    ) {
      this.showErrorModal(this.props.serverError || '');
    }

    if (prevProps.loggedIn !== this.props.loggedIn) {
      this.getMetadata();
      this.props.getNewRequests();
    }

    WebFont.load({
      google: {
        families: ['Droid Sans', 'Chilanka'],
      },
    });
  }

  UNSAFE_componentWillMount() {
    window.removeEventListener('click', this.onWindowClick);
    window.removeEventListener('keydown', this.onWindowKeyDown);
    window.removeEventListener('touchend', this.onWindowTouchEnd);
    window.removeEventListener('scroll', this.handleScroll);
  }

  getMetadata = () => {
    this.props.getCatalogMetadata();
  };

  handleScroll = () => {
    let topVisible = isElementVisible(document.getElementById('top'));
    if (this.state.topVisible === topVisible) return;
    this.setState({
      topVisible: topVisible,
    });
  };

  toggleSearched = (boolean) => {
    return this.setState({ searched: boolean });
  };

  onImporterClickHandler = () => {
    this.props.hideAllPanes();
    this.props.showPane(SPOTIFY_IMPORTER);
  };

  showErrorModal = (content) => {
    const that = this;

    Modal.warning({
      title: 'Oops Something Went wrong!',
      content,
      maskClosable: true,
      visible: this.props.isServerError,
      onOk() {
        that.props.hideNotification();
      },
      onCancel() {
        that.props.hideNotification();
      },
    });
  };

  // DND
  onDragStart = (start) => {
    const id = start.draggableId;
    const selected = this.state.selectedTaskIds.find((taskId) => taskId === id);
    let ids = [];
    if (!selected) {
      this.unselectAll();
      ids.push(id);
    } else if (selected.length > 0) {
      ids = [...this.state.selectedTaskIds];
    }

    this.setState({
      draggingTaskId: start.draggableId,
      itemIds: ids,
    });
  };

  onDragEnd = async (result) => {
    const destination = result.destination;
    const { itemIds } = this.state;
    const { addTracksToEntity, selectedProject, selectedLabel } = this.props;

    if (!destination || result.reason === 'CANCEL') {
      this.setState({
        draggingTaskId: null,
        itemIds: [],
      });
      return;
    }

    const models = {
      projects: selectedProject,
      labels: selectedLabel,
      'tracks-table': {},
    };

    if (
      itemIds.length > 0 &&
      ENTITIES[destination.droppableId] &&
      ENTITIES[destination.droppableId].id !== 'tracks-table'
    ) {
      try {
        const model = models[destination.droppableId];
        const field = ENTITIES[destination.droppableId].field;

        await addTracksToEntity(
          itemIds,
          model[field],
          destination.droppableId,
          10
        );
      } catch (error) {
        message.error('Something Went wrong. Please try again.', 2);
      }
    }

    this.setState({
      draggingTaskId: null,
      itemIds: [],
    });
  };

  onWindowKeyDown = (event) => {
    if (event.defaultPrevented) {
      return;
    }

    if (event.key === 'Escape') {
      this.unselectAll();
    }
  };

  onWindowClick = (event) => {
    if (event.defaultPrevented) {
      return;
    }
    this.unselectAll();
  };

  onWindowTouchEnd = (event) => {
    if (event.defaultPrevented) {
      return;
    }
    this.unselectAll();
  };

  toggleSelection = (taskId) => {
    const selectedTaskIds = this.state.selectedTaskIds;
    const wasSelected = selectedTaskIds.includes(taskId);

    const newTaskIds = (() => {
      if (!wasSelected) {
        return [taskId];
      }

      if (selectedTaskIds.length > 1) {
        return [taskId];
      }

      return [];
    })();

    this.setState({
      selectedTaskIds: newTaskIds,
    });
  };

  toggleSideMenu = () => {
    if (!this.state.sideMenuVisible) {
      this.props.hideAllPanes();
    }

    this.setState({ sideMenuVisible: !this.state.sideMenuVisible });
  };

  handleOutsideClick = () => {
    this.setState({ sideMenuVisible: false });
  };

  toggleSelectionInGroup = (taskId) => {
    const selectedTaskIds = this.state.selectedTaskIds;
    const index = selectedTaskIds.indexOf(taskId);

    // if not selected - add it to the selected items
    if (index === -1) {
      this.setState({
        selectedTaskIds: [...selectedTaskIds, taskId],
      });
      return;
    }

    // it was previously selected and now needs to be removed from the group
    const shallow = [...selectedTaskIds];
    shallow.splice(index, 1);
    this.setState({
      selectedTaskIds: shallow,
    });
  };

  // This behaviour matches the MacOSX finder selection
  multiSelectTo = (newTaskId) => {
    const updated = multiSelect(
      this.state.entities,
      this.state.selectedTaskIds,
      newTaskId
    );

    if (updated == null) {
      return;
    }

    this.setState({
      selectedTaskIds: updated,
    });
  };

  unselect = () => {
    this.unselectAll();
  };

  unselectAll = () => {
    this.setState({
      selectedTaskIds: [],
    });
  };

  render() {
    const { searched, sideMenuVisible } = this.state;
    const { user, activePanes, totalNewRequests, loggedIn } = this.props;
    const selected = this.state.selectedTaskIds;
    const pathname = window.location.pathname;
    const siderView = !pathname.includes('/spreadsheets/');
    const isAdmin = user.role === 'administrator';
    const isOrgAdmin = user.role === 'organization-administrator';

    return (
      <>
        {siderView && loggedIn && (
          <Header
            user={user}
            totalNewRequests={totalNewRequests}
            toggleSearched={this.toggleSearched}
            onBurgerClick={this.toggleSideMenu}
          />
        )}

        {searched && <div className="dark-overlay"></div>}

        <div className="main-content">
          {siderView && loggedIn && (
            <Sidebar
              isAdmin={isAdmin}
              isOrgAdmin={isOrgAdmin}
              sideMenuVisible={sideMenuVisible}
              onSpotifyImporterClick={this.onImporterClickHandler}
              onItemClick={this.handleOutsideClick}
            />
          )}

          {sideMenuVisible && (
            <div
              onClick={this.handleOutsideClick}
              className="sider-dark-overlay"
            ></div>
          )}

          <DragDropContext
            onDragStart={this.onDragStart}
            onDragEnd={this.onDragEnd}
          >
            <main className={`main-view ${siderView ? '' : 'sheet-view'}`}>
              <AppRoutes
                isAdmin={isAdmin}
                isOrgAdmin={isOrgAdmin}
                selectedTaskIds={selected}
                toggleSelection={this.toggleSelection}
                toggleSelectionInGroup={this.toggleSelectionInGroup}
                multiSelectTo={this.multiSelectTo}
              />

              <AppPanes paneList={activePanes} />
            </main>
          </DragDropContext>
        </div>
      </>
    );
  }
}

const MapStateToProps = ({
  auth,
  panes,
  labels,
  projects,
  notifications,
  legal,
}) => {
  return {
    user: auth.user,
    loggedIn: auth.loggedIn,
    activePanes: panes.activePanes,
    selectedProject: projects.selectedProject,
    selectedLabel: labels.selectedLabel,
    isServerError: notifications.visible,
    serverError: notifications.message,
    totalNewRequests: legal.totalNewRequests,
  };
};

const MapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      createSpreadsheet,
      showPane,
      hideAllPanes,
      addTracksToEntity,
      hideNotification,
      getCatalogMetadata,
      getNewRequests,
    },
    dispatch
  );

export default connect(MapStateToProps, MapDispatchToProps)(App);
