import React, { Component } from 'react';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import AppError from '../AppError/AppError';

Sentry.init({
  dsn:
    'https://910f9b8724cd49b0ab474359484aafa5@o501545.ingest.sentry.io/5582795',
  autoSessionTracking: true,
  integrations: [new Integrations.BrowserTracing()],

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
});

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { error: null, eventId: null };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ error });
    Sentry.captureException(error);
  }

  render() {
    if (this.state.error) {
      //render fallback UI
      return <AppError />;
    }
    //when there's not an error, render children untouched
    return this.props.children;
  }
}

export default ErrorBoundary;
