import { handleActions } from 'redux-actions';
import articleActions from '../actions/actionTypes';

const initialState = {
  articles: [],
  totalArticles: 0,
  sidebarTotalArticles: 0,
  selectedArticle: {},
  isFetching: false,
  isUpdating: false,
};

const articlesReducer = handleActions(
  {
    [articleActions.setArticle.toString()]: (state, action) => {
      return {
        ...state,
        selectedArticle: action.payload.article,
      };
    },
    [articleActions.setTotalArticles.toString()]: (state, action) => {
      return {
        ...state,
        sidebarTotalArticles: action.payload.totalArticles,
      };
    },

    [articleActions.getArticles.toString()]: (state, action) => {
      return {
        ...state,
        isFetching: true,
      };
    },
    [articleActions.getArticlesSuccess.toString()]: (
      state,
      action
    ) => {
      return {
        ...state,
        articles: action.payload.articles,
        totalArticles: action.payload.totalArticles,
        sidebarTotalArticles: action.payload.totalArticles,
        isFetching: false,
      };
    },
    [articleActions.getArticlesFail.toString()]: (state, action) => {
      return {
        ...state,
        isFetching: false,
      };
    },
    [articleActions.quickSearchArticle.toString()]: (
      state,
      action
    ) => {
      return {
        ...state,
        isFetching: true,
      };
    },
    [articleActions.quickSearchArticleSuccess.toString()]: (
      state,
      action
    ) => {
      return {
        ...state,
        articles: action.payload.articles,
        totalArticles: action.payload.totalArticles,
        isFetching: false,
      };
    },
    [articleActions.quickSearchArticleFail.toString()]: (
      state,
      action
    ) => {
      return {
        ...state,
        isFetching: false,
      };
    },
    [articleActions.createArticle.toString()]: (state, action) => {
      return {
        ...state,
        isUpdating: true,
      };
    },
    [articleActions.createArticleSuccess.toString()]: (
      state,
      action
    ) => {
      const newArticlesArray = [
        action.payload.article,
        ...state.articles,
      ];

      const totalArticles = state.totalArticles;

      return {
        ...state,
        articles: newArticlesArray,
        totalArticles: totalArticles + 1,
        sidebarTotalArticles: state.sidebarTotalArticles + 1,
        isUpdating: false,
      };
    },
    [articleActions.createArticleFail.toString()]: (
      state,
      action
    ) => {
      return {
        ...state,
        isUpdating: false,
      };
    },
    [articleActions.updateArticle.toString()]: (state, action) => {
      return {
        ...state,
        isUpdating: true,
      };
    },
    [articleActions.updateArticleSuccess.toString()]: (
      state,
      action
    ) => {
      let articleIndex;
      const article = state.articles.find((article, index) => {
        articleIndex = index;
        return article._id === action.payload.article._id;
      });
      const updatedArticle = {
        ...article,
        ...action.payload.article,
      };

      const updatedArticles = [...state.articles];

      updatedArticles[articleIndex] = updatedArticle;

      return {
        ...state,
        articles: updatedArticles,
        isUpdating: false,
      };
    },
    [articleActions.updateArticleFail.toString()]: (
      state,
      action
    ) => {
      return {
        ...state,
        isUpdating: false,
      };
    },
    [articleActions.deleteArticle.toString()]: (state, action) => {
      return {
        ...state,
        isDeleting: true,
      };
    },
    [articleActions.deleteArticleSuccess.toString()]: (
      state,
      action
    ) => {
      const updatedArticles = state.articles.filter(
        (article) => article._id !== action.payload.id
      );

      const totalArticles = state.totalArticles;

      return {
        ...state,
        articles: updatedArticles,
        totalArticles: totalArticles - 1,
        sidebarTotalArticles: state.sidebarTotalArticles - 1,
        isDeleting: false,
      };
    },
    [articleActions.deleteArticleFail.toString()]: (
      state,
      action
    ) => {
      return {
        ...state,
        isDeleting: false,
      };
    },
  },
  initialState
);

export default articlesReducer;
