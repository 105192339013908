import { lazy } from 'react';

const Login = lazy(() => import('containers/auth/components/Login/Login'));
const Logout = lazy(() => import('containers/auth/components/Logout/Logout'));

const Spreadsheets = lazy(() => import('containers/spreadsheets'));

const Tracks = lazy(() => import('containers/tracks'));
const Track = lazy(() => import('containers/tracks/components/ViewTrack'));
const EditTrack = lazy(() =>
  import('containers/tracks/components/EditTrack/EditTrack')
);

const Labels = lazy(() => import('containers/labels'));
const Projects = lazy(() => import('containers/projects'));

const Tags = lazy(() => import('containers/tags/Tags'));
const TagCategories = lazy(() => import('containers/tags/TagCategories'));

const Partners = lazy(() => import('containers/partners'));

const Organizations = lazy(() =>
  import('containers/organizations/Organizations')
);

const Search = lazy(() => import('containers/search'));

const Ingestion = lazy(() => import('containers/ingestion'));

const Legal = lazy(() => import('containers/legal'));

const Marketing = lazy(() => import('containers/marketing'));

const Accounting = lazy(() => import('containers/accounting'));

const Analytics = lazy(() => import('containers/analytics'));

const Users = lazy(() => import('containers/users'));

const Labeltool = lazy(() => import('containers/labeltool/Labeltool'));

const Press = lazy(() => import('containers/press'));

const routes = {
  login: {
    translationKey: 'routes.login',
    exact: true,
    path: '/login',
    component: Login,
    private: false,
  },

  logout: {
    translationKey: 'routes.logout',
    exact: true,
    path: '/logout',
    component: Logout,
    private: true,
  },

  tracks: {
    translationKey: 'routes.tracks',
    exact: true,
    path: '/tracks',
    component: Tracks,
    private: true,
    dndSection: true,
  },

  contentMaster: {
    translationKey: 'routes.content',
    exact: true,
    path: '/content-libraries/:id',
    component: Tracks,
    private: true,
    dndSection: true,
  },

  editTrack: {
    translationKey: 'routes.edit-track',
    exact: true,
    path: '/tracks/edit/:id',
    component: EditTrack,
    private: true,
  },
  createTrack: {
    translationKey: 'routes.create-track',
    exact: true,
    path: '/tracks/new',
    component: EditTrack,
    private: true,
  },
  track: {
    translationKey: 'routes.track',
    exact: true,
    path: '/tracks/:id',
    component: Track,
    private: true,
  },
  spreadsheets: {
    translationKey: 'routes.spreadsheets',
    exact: false,
    path: '/spreadsheets',
    component: Spreadsheets,
    private: true,
  },
  labels: {
    translationKey: 'routes.labels',
    exact: true,
    path: '/labels',
    component: Labels,
    private: true,
  },
  projects: {
    translationKey: 'routes.projects',
    exact: true,
    path: '/projects',
    component: Projects,
    private: true,
  },
  tags: {
    translationKey: 'routes.tags',
    exact: true,
    path: '/tags',
    component: Tags,
    private: true,
  },
  tagCategories: {
    translationKey: 'routes.tagCategories',
    exact: true,
    path: '/tag-categories',
    component: TagCategories,
    private: true,
  },
  partners: {
    translationKey: 'routes.partners',
    exact: false,
    path: '/partners',
    component: Partners,
    private: true,
  },
  administrators: {
    translationKey: 'routes.administrators',
    exact: false,
    path: '/administrators',
    component: Organizations,
    private: true,
  },
  legal: {
    translationKey: 'routes.legal',
    exact: false,
    path: '/legal',
    component: Legal,
    private: true,
    admin: false,
  },
  marketing: {
    translationKey: 'routes.marketing',
    exact: false,
    path: '/marketing',
    component: Marketing,
    private: true,
    admin: false,
  },
  accounting: {
    translationKey: 'routes.accounting',
    exact: false,
    path: '/accounting',
    component: Accounting,
    private: true,
    admin: false,
  },
  analytics: {
    translationKey: 'routes.analytics',
    exact: false,
    path: '/analytics',
    component: Analytics,
    private: true,
    admin: false,
  },
  ingestion: {
    translationKey: 'routes.ingestion',
    exact: false,
    path: '/ingestion',
    component: Ingestion,
    private: true,
    admin: false,
  },
  users: {
    translationKey: 'routes.users',
    exact: false,
    path: '/users',
    component: Users,
    private: true,
    admin: true,
    orgAdmin: true,
  },
  search: {
    translationKey: 'routes.search',
    exact: true,
    path: '/search/:string',
    component: Search,
    private: true,
  },
  labeltool: {
    translationKey: 'routes.labeltool',
    exact: false,
    path: '/labeltool',
    component: Labeltool,
    private: true,
  },
  press: {
    translationKey: 'routes.press',
    exact: false,
    path: '/press',
    component: Press,
    private: true,
  },
};

export default routes;
