import React from 'react';

import Logo from 'images/logo/amass360-logo.png';

const CratesLogo = ({ className }) => {
  return (
    <img className={className} src={Logo} alt="Amass 360" />
  );
};

export default CratesLogo;
