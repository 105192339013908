import { handleActions } from 'redux-actions';
import actionTypes from '../actions/actionTypes';

const {
  searchAction,
  quickSearch,
  quickSearchFail,
  resetSearch,
  toggleSearch,
  newSearch,
  resetResults,
} = actionTypes;

const initialState = {
  results: [],
  quick_results: [],
  fetching: false,
  performedSearch: false,
};

const searchReducer = handleActions(
  {
    [searchAction.toString()]: (state, action) => {
      return {
        ...state,
        results: action.payload.results,
        fetching: false,
      };
    },
    [quickSearch.toString()]: (state, action) => {
      return {
        ...state,
        quick_results: action.payload.results,
        string: action.payload.string,
        performedSearch: true,
        fetching: false,
      };
    },
    [quickSearchFail.toString()]: (state, action) => {
      return {
        ...state,
        performedSearch: true,
        fetching: false,
        error: action.error,
      };
    },
    [resetSearch.toString()]: (state, action) => {
      return {
        ...state,
        quick_results: [],
        fetching: false,
        performedSearch: false,
      };
    },
    [toggleSearch.toString()]: (state, action) => {
      return {
        ...state,
        searchVisible: !state.searchVisible,
      };
    },
    [resetResults.toString()]: (state, action) => {
      return { ...initialState, fetching: false };
    },
    [newSearch.toString()]: (state, action) => {
      return {
        ...state,
        fetching: true,
        quick_results: [],
      };
    },
  },
  initialState
);

export default searchReducer;
