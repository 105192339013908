import React from 'react';
import PropTypes from 'prop-types';

import Pane from 'components/Pane/Pane';

import panes from './panes';

const AppPanes = ({ paneList }) => {
  return (
    <>
      {panes.map((pane) => (
        <Pane
          key={pane.title}
          open={paneList.includes(pane.openFlag)}
          direction={pane.direction}
          style={{ ...pane.style }}
        >
          {pane.component}
        </Pane>
      ))}
    </>
  );
};

AppPanes.propTypes = {
  paneList: PropTypes.array.isRequired,
};

export default AppPanes;
