import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';

import store from './store/setupStore';
import history from './history';
import App from './containers/app';
import { getSession } from './containers/auth/actions';

import ErrorBoundary from './components/ErrorBoundary/ErrorBoundary';

import 'sanitize.css/sanitize.css';
import 'antd/dist/antd.css';
import 'flag-icon-css/css/flag-icon.css';
import './styles/main.scss';

const target = document.querySelector('#root');

render(
  <Provider store={store}>
    <Router history={history}>
      <ErrorBoundary>
        <App />
      </ErrorBoundary>
    </Router>
  </Provider>,
  target
);

store.dispatch(getSession(history));
