import React from 'react';
import PropTypes from 'prop-types';
import './Emoji.scss';

/**
 * @desc Emoji component
 * @param string type - type of the emoji
 * @return element
 **/
const Emoji = ({ type }) => (
  <div className="emoji-container">
    <div className={`emoji ${type}`}></div>
  </div>
);

Emoji.propTypes = {
  type: PropTypes.string,
};

export default Emoji;
