import { handleActions } from 'redux-actions';
import actionTypes from '../actions/actionTypes';

const initialState = {
  users: [],
  totalUsers: 0,
  user: {},
  message: {},
  isFetching: true,
  isCreating: false,
  isUpdating: false,
};

const usersReducer = handleActions(
  {
    [actionTypes.getUsers.toString()]: (state, action) => {
      return {
        ...state,
      };
    },
    [actionTypes.getUsersSuccess.toString()]: (state, action) => {
      return {
        ...state,
        users: action.payload.users,
        totalUsers: action.payload.totalUsers,
        user: {},
        isFetching: false,
      };
    },
    [actionTypes.getUsersFailed.toString()]: (state, action) => {
      return {
        ...state,
        error: action.error,
        isFetching: false,
      };
    },
    [actionTypes.getUser.toString()]: (state, action) => {
      return {
        ...state,
        isFetching: true,
      };
    },
    [actionTypes.getUserSuccess.toString()]: (state, action) => {
      return {
        ...state,
        user: action.payload.user,
        isFetching: false,
      };
    },
    [actionTypes.getUserFailed.toString()]: (state, action) => {
      return {
        ...state,
        error: action.error,
        isFetching: false,
      };
    },
    [actionTypes.deleteUser.toString()]: (state, action) => {
      return {
        ...state,
        users: state.users.filter(({ _id }) => _id !== action.payload.id),
      };
    },
    [actionTypes.deleteUserFailed.toString()]: (state, action) => {
      return {
        ...state,
        error: action.error,
      };
    },
    [actionTypes.addUser.toString()]: (state, action) => {
      return {
        ...state,
        isCreating: true,
      };
    },
    [actionTypes.addUserSuccess.toString()]: (state, action) => {
      return {
        ...state,
        user: action.payload.user,
        isCreating: false,
      };
    },
    [actionTypes.addUserFailed.toString()]: (state, action) => {
      return {
        ...state,
        error: action.error,
        isCreating: false,
      };
    },
    [actionTypes.updateUser.toString()]: (state, action) => {
      return {
        ...state,
        isUpdating: true,
      };
    },
    [actionTypes.updateUserSuccess.toString()]: (state, action) => {
      return {
        ...state,
        users: state.users.map((user) => {
          if (user._id === action.payload.user._id) {
            user = action.payload.user;
            return user;
          }
          return user;
        }),
        isUpdating: false,
      };
    },
    [actionTypes.updateUserFailed.toString()]: (state, action) => {
      return {
        ...state,
        error: action.error,
        isUpdating: false,
      };
    },
    [actionTypes.saveProfileImage.toString()]: (state, action) => {
      return {
        ...state,
        isUpdating: true,
      };
    },
    [actionTypes.saveProfileImageSuccess.toString()]: (state, action) => {
      return {
        ...state,
        isUpdating: false,
      };
    },
    [actionTypes.saveProfileImageFailed.toString()]: (state, action) => {
      return {
        ...state,
        error: action.error,
        isUpdating: false,
      };
    },
    [actionTypes.updateReceiveRequests.toString()]: (state, action) => {
      return {
        ...state,
        users: state.users.map((user) => {
          if (user._id === action.payload.id) {
            user.receive_requests = !user.receive_requests;
            return user;
          }
          return user;
        }),
        isUpdating: false,
      };
    },
    [actionTypes.updateReceiveRequestsFailed.toString()]: (state, action) => {
      return {
        ...state,
        error: action.error,
        isUpdating: false,
      };
    },
    [actionTypes.updateAccountStatus.toString()]: (state, action) => {
      return {
        ...state,
        isUpdating: true,
      };
    },
    [actionTypes.updateAccountStatusSuccess.toString()]: (state, action) => {
      return {
        ...state,
        users: state.users.map((user) => {
          if (user._id === action.payload.user._id) {
            user.active = !user.active;

            return user;
          }
          return user;
        }),
        isUpdating: false,
      };
    },
    [actionTypes.updateAccountStatusFailed.toString()]: (state, action) => {
      return {
        ...state,
        error: action.error,
        isUpdating: false,
      };
    },
  },
  initialState
);

export default usersReducer;
