import React from 'react';
import { Descriptions, Tag } from 'antd';
import Proptypes from 'prop-types';

const ProjectMetadata = ({ project, totalTracks }) => {
  const projectTags = project.tags || [];

  return (
    <Descriptions bordered>
      <Descriptions.Item label="Project Title" span={3}>
        {project.project_title || ''}
      </Descriptions.Item>
      <Descriptions.Item label="Release Date" span={3}>
        {project.release_date || 'Not Set'}
      </Descriptions.Item>
      <Descriptions.Item label="Total Tracks" span={3}>
        {totalTracks || 'Not Set'}
      </Descriptions.Item>
      <Descriptions.Item label="Catalog Number" span={3}>
        {project.catalogue_number || ''}
      </Descriptions.Item>

      <Descriptions.Item label="SpotifyId" span={3}>
        {project.spotifyId || ''}
      </Descriptions.Item>
      <Descriptions.Item label="UPC" span={3}>
        {project.upc || ''}
      </Descriptions.Item>
      <Descriptions.Item label="Main Artist" span={3}>
        {project?.main_artist?.name || ''}
      </Descriptions.Item>
      <Descriptions.Item label="Tags" span={3}>
        {projectTags.map((tag, index) => (
          <Tag
            key={`${tag}-${index}`}
            style={{
              display: 'flex',
              alignItems: 'center',
              marginBottom: '5px',
              width: ' 100px',
              textTransform: 'capitalize',
              cursor: 'pointer',
            }}
            color="#1890ff"
          >
            {tag || ''}
          </Tag>
        ))}
      </Descriptions.Item>
      <Descriptions.Item label="Notes">
        {project.notes || ''}{' '}
      </Descriptions.Item>
    </Descriptions>
  );
};

ProjectMetadata.defaultProps = {
  project: {},
};

ProjectMetadata.propTypes = {
  project: Proptypes.object,
  totalTracks: Proptypes.number,
};

export default ProjectMetadata;
