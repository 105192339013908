import { createActions } from 'redux-actions';

export default createActions({
  SET_PARTNER: undefined,
  SET_TOTAL_PARTNERS: undefined,
  GET_PARTNER: undefined,
  GET_PARTNER_SUCCESS: undefined,
  GET_PARTNER_FAIL: undefined,
  GET_PARTNERS: undefined,
  GET_PARTNERS_SUCCESS: undefined,
  GET_PARTNERS_FAIL: undefined,
  QUICK_SEARCH_PARTNER: undefined,
  QUICK_SEARCH_PARTNER_SUCCESS: undefined,
  QUICK_SEARCH_PARTNER_FAIL: undefined,
  GET_PARTNER_DATA: undefined,
  GET_PARTNER_DATA_SUCCESS: undefined,
  GET_PARTNER_DATA_FAIL: undefined,
  CLEAN_PARTNER_DATA: undefined,
  CREATE_PARTNER: undefined,
  CREATE_PARTNER_SUCCESS: undefined,
  CREATE_PARTNER_FAIL: undefined,
  UPDATE_PARTNER: undefined,
  UPDATE_PARTNER_SUCCESS: undefined,
  UPDATE_PARTNER_FAIL: undefined,
  DELETE_PARTNER: undefined,
  DELETE_PARTNER_SUCCESS: undefined,
  DELETE_PARTNER_FAIL: undefined,
  SAVE_PARTNER_IMAGE: undefined,
  SAVE_PARTNER_IMAGE_SUCCESS: undefined,
  SAVE_PARTNER_IMAGE_FAIL: undefined,
  DELETE_PARTNER_IMAGE: undefined,
  DELETE_PARTNER_IMAGE_SUCCESS: undefined,
  DELETE_PARTNER_IMAGE_FAIL: undefined,

  CREATE_PARTNER_ACCOUNT: undefined,
  CREATE_PARTNER_ACCOUNT_SUCCESS: undefined,
  CREATE_PARTNER_ACCOUNT_FAILED: undefined,

  ACTIVATE_PARTNER_ACCOUNT: undefined,
  ACTIVATE_PARTNER_ACCOUNT_SUCCESS: undefined,
  ACTIVATE_PARTNER_ACCOUNT_FAILED: undefined,

  DISABLE_PARTNER_ACCOUNT: undefined,
  DISABLE_PARTNER_ACCOUNT_SUCCESS: undefined,
  DISABLE_PARTNER_ACCOUNT_FAILED: undefined,

  SET_PARTNER_CREDENTIALS: undefined,
  SET_PARTNER_CREDENTIALS_SUCCESS: undefined,
  SET_PARTNER_CREDENTIALS_FAILED: undefined,
});
