import React, { useState, useEffect, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import {
  Typography,
  Form,
  Input,
  Button,
  message,
  notification,
  Tag,
} from 'antd';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { CloseOutlined } from '@ant-design/icons';
import queryString from 'query-string';
import _ from 'lodash';

import TagsDropdown from 'containers/tags/Tags/components/TagsDropdown/TagsDropdown';
import LabelsDropdown from 'containers/labels/components/LabelsDropdown/LabelsDropdown';

import { hidePane } from 'store/modules/panes/actions';

import { EDIT_TRACKS } from 'containers/app/components/AppPanes/paneTypes';
import { ENTITIES } from 'containers/app/data/dnd';
import { getTags, createTag } from 'containers/tags/actions';
import {
  createLabel,
  getLabels,
  quick_search,
} from 'containers/labels/actions';

import { updateSelectedTracks, getSongs } from '../../actions';
import { getOrganizations } from 'containers/organizations/actions';
import Select from 'components/Select';

const { Title, Text } = Typography;
const layout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
};

const EditTracksInformation = ({
  hidePane,
  isUpdating,
  getSongs,
  getOrganizations,
  organizations,
  tags,
  getTags,
  createTag,
  selectedTracks,
  updateSelectedTracks,
  // ENTITIES
  selectedProject,
  selectedLabel,
  // Labels
  labels,
  getLabels,
  createLabel,
  quick_search,
  sessionUser,
}) => {
  const [label, setLabel] = useState('');

  const [tagSelected, setTagSelected] = useState([]);

  const performLabelsFetch = _.debounce(quick_search, 250);
  const [form] = Form.useForm();
  const { pathname, search } = useLocation();
  const entity = pathname.split('/')[1];

  const models = {
    projects: selectedProject,
    labels: selectedLabel,
  };
  const entityTitles = {
    labels: 'name',
    projects: 'project_title',
  };

  const onFinish = async (values) => {
    const sanitizedValues = {
      ...values,
      organization: values.organization,
      tags: tagSelected.length === 0 ? [] : tagSelected,
    };

    // validation to keep label value
    if (!values.label) {
      delete sanitizedValues.label;
    }

    let field;
    let originalEntityValue;

    if (entity !== 'content-libraries' && entity !== 'tracks') {
      field = ENTITIES[entity].field;
      originalEntityValue = models[entity][field];
    }

    const tracksIds = selectedTracks.map((track) => track._id || track.id);

    await updateSelectedTracks(tracksIds, sanitizedValues, {
      entity,
      originalEntityValue,
      selectedEntity: models[entity],
    });

    if (entity === 'content-libraries' || entity === 'tracks') {
      getSongs(queryString.parse(search));
    }

    onClose();

    notification.success({
      message: `Tracks Updated`,
      description: 'List of tracks was updated',
      duration: 3,
    });
  };

  const onClose = () => {
    hidePane(EDIT_TRACKS);
    cleanState();
  };

  const onTagsLoad = useCallback(async () => {
    try {
      await getTags();
    } catch (error) {
      message.error(
        'We are unable to fetch the Tags. Please contact support',
        4
      );
    }
  }, [getTags]);

  const onAddTagHandler = async (name) => {
    try {
      await createTag({ name });
    } catch (error) {
      message.error(
        'We are unable to create the new tag. Please contact support',
        4
      );
    }
  };

  const onSelectTagHandler = (option) => {
    setTagSelected(option);
  };

  const onLabelsLoad = useCallback(async () => {
    try {
      await getLabels();
    } catch (error) {
      message.error(
        'We are unable to fetch the labels. Please contact support',
        4
      );
    }
  }, [getLabels]);

  const onSearchLabelHandler = async (searchValue) => {
    try {
      await performLabelsFetch(searchValue);
    } catch (error) {
      message.error(
        'We are unable to fetch the labels. Please contact support',
        4
      );
    }
  };

  const onAddLabelHandler = async (name) => {
    try {
      await createLabel({ name });
    } catch (error) {
      message.error(
        'We are unable to create the new label. Please contact support',
        4
      );
    }
  };

  const onLabelSelectHandler = (option) => {
    form.setFieldsValue({ label: option });

    setLabel(option);
  };

  const onLabelClearHandler = () => {
    form.setFieldsValue({ label: '' });

    setLabel('');
  };

  const cleanState = () => {
    setTagSelected([]);
    form.resetFields();
  };

  useEffect(() => {
    if (selectedTracks.length > 0) {
      onTagsLoad();
    }
  }, [onTagsLoad, selectedTracks]);

  useEffect(() => {
    onLabelsLoad();
  }, [onLabelsLoad]);

  useEffect(() => {
    getOrganizations();
  }, [sessionUser, getOrganizations]);

  return (
    <div className="vw-module content-module">
      <div className="content-title view-module-title">
        <CloseOutlined onClick={onClose} />

        <Title level={3}>EDIT SELECTION</Title>

        <span />
      </div>

      <div className="module-container" style={{ padding: 10 }}>
        <div
          className="edit-summary"
          style={{ marginTop: '2rem', marginBottom: '2rem' }}
        >
          {entity !== 'content-libraries' && entity !== 'tracks' && (
            <Title level={4}>
              <span className="u-capitalize">{entity}:</span>{' '}
              {models[entity][entityTitles[entity]]}
            </Title>
          )}

          <Text>
            Tracks:
            {selectedTracks.map((track) => (
              <Tag
                key={track._id || track.code}
                style={{ marginLeft: '5px', marginBottom: '5px' }}
              >
                {track.title || track.code}
              </Tag>
            ))}
          </Text>
        </div>

        <Form
          {...layout}
          form={form}
          name="control-hooks-tracks"
          onFinish={onFinish}
          autoComplete="off"
          initialValues={{ label: '' }}
        >
          <Form.Item name="label" label="Label" style={{ textAlign: 'left' }}>
            <LabelsDropdown
              className="labels-dropdown"
              placeholder="Please Select a name..."
              onSearch={onSearchLabelHandler}
              onAddItem={onAddLabelHandler}
              onChange={onLabelSelectHandler}
              onClear={onLabelClearHandler}
              showSearch
              options={labels}
              value={label}
            />
          </Form.Item>

          <Form.Item name="project_artist" label="Project Artist">
            <Input />
          </Form.Item>

          <Form.Item name="project_title" label="Project Title">
            <Input />
          </Form.Item>

          <Form.Item name="upc" label="UPC">
            <Input />
          </Form.Item>

          <Form.Item name="catalogue_number" label="Catalogue_Number">
            <Input />
          </Form.Item>

          {sessionUser.role === 'administrator' && (
            <Form.Item
              name="organization"
              label="Administrator"
              rules={[{ required: true }]}
            >
              <Select options={organizations} valueKey="_id" labelKey="name" />
            </Form.Item>
          )}

          <Form.Item name="territory" label="Territory">
            <Input />
          </Form.Item>

          <Form.Item name="term" label="Term">
            <Input />
          </Form.Item>

          <Form.Item name="contract_type" label="Contract Type">
            <Input />
          </Form.Item>

          <Form.Item name="tags" label="Tag(s)">
            <TagsDropdown
              placeholder="Please Select a tag..."
              onAddItem={onAddTagHandler}
              onChangeOption={onSelectTagHandler}
              options={tags.map((tag) => tag.name)}
            />
          </Form.Item>

          <Form.Item>
            <Button
              loading={isUpdating}
              type="primary"
              htmlType="submit"
              style={{ marginRight: '10px' }}
            >
              Save Changes
            </Button>

            <Button htmlType="button" onClick={onClose}>
              Cancel
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

const mapStateToProps = ({
  tracks,
  organizations,
  tags,
  projects,
  labels,
  auth,
}) => {
  return {
    selectedProject: projects.selectedProject,
    selectedLabel: labels.selectedLabel,
    labels: labels.labels,
    selectedTracks: tracks.selectedTracks,
    isUpdating: tracks.isUpdatingMultiple,
    organizations: organizations.organizations,
    tags: tags.tags,
    sessionUser: auth.user,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      hidePane,
      getOrganizations,
      updateSelectedTracks,
      getSongs,
      getTags,
      createTag,
      getLabels,
      createLabel,
      quick_search,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditTracksInformation);
