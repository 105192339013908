import React, { PureComponent, Fragment } from 'react';
import { filePaths } from '../../../utils/consts';
import './AudioInput.scss';

export default class AudioInput extends PureComponent {
  render() {
    const { audio, title, uploading } = this.props;
    const inputOptions = { ...this.props, uploading: 'false' };

    return (
      <div className="audio-input">
        {audio ? (
          <audio controls>
            <source src={`${filePaths.audio}/${audio}`} type="audio/mpeg" />
            <source src={`${filePaths.audio}/${audio}`} type="audio/wav" />
          </audio>
        ) : (
          <Fragment>
            <input
              type="file"
              name="file"
              id="file"
              className="inputfile"
              {...inputOptions}
            />
            <label htmlFor="file">{uploading ? 'Uploading..' : title}</label>
          </Fragment>
        )}
      </div>
    );
  }
}
