import React from 'react';
import { Spin, Typography } from 'antd';
import PropTypes from 'prop-types';

const { Text } = Typography;

const EntitySpinner = ({ style, message = 'Loading...' }) => {
  return (
    <div className="entity-spinner" style={style}>
      <Spin size="large" />
      <Text style={{ width: '100%', textAlign: 'center', fontWeight: '500' }}>
        {message}
      </Text>
    </div>
  );
};

EntitySpinner.propTypes = {
  style: PropTypes.object,
  message: PropTypes.string,
};

export default EntitySpinner;
