import { createActions } from 'redux-actions';

export default createActions({
  GET_ANALYTICS_SUMMARY: undefined,
  GET_ANALYTICS_SUMMARY_SUCCESS: undefined,
  GET_ANALYTICS_SUMMARY_FAILED: undefined,

  GET_VENDORS_REVENUE: undefined,
  GET_VENDORS_REVENUE_SUCCESS: undefined,
  GET_VENDORS_REVENUE_FAILED: undefined,

  GET_TERRITORIES_TOP_REVENUE: undefined,
  GET_TERRITORIES_TOP_REVENUE_SUCCESS: undefined,
  GET_TERRITORIES_TOP_REVENUE_FAILED: undefined,

  GET_VENDOR_TOP_CHARTS: undefined,
  GET_VENDOR_TOP_CHARTS_SUCCESS: undefined,
  GET_VENDOR_TOP_CHARTS_FAILED: undefined,

  GET_TOP_CHARTS_FILTERS: undefined,
  GET_TOP_CHARTS_FILTERS_SUCCESS: undefined,
  GET_TOP_CHARTS_FILTERS_FAILED: undefined,

  GET_LABELS_REVENUE: undefined,
  GET_LABELS_REVENUE_SUCCESS: undefined,
  GET_LABELS_REVENUE_FAILED: undefined,

  GET_ASSETS_STATS: undefined,
  GET_ASSETS_STATS_FAILED: undefined,
});
