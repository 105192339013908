import { message } from 'antd';
import moment from 'moment';
import { trackStatus } from '../containers/marketing/data';
import {
  ISRC_CAT_NUMBER_LENGTH,
  ISRC_MAX_CAT_NUMBER,
  ISRC_MIN_LENGTH,
  UPC_ASSET_FORMAT,
  UPC_CAT_NUMBER_LENGTH,
  UPC_MAX_CAT_NUMBER,
  UPC_MIN_LENGTH,
  CDN_URL,
  SERVER_ERRORS,
} from './consts';

// Check if element contains className ==========
export const hasClass = (el, className) => {
  if (el.classList) {
    return el.classList.contains(className);
  }

  return !!el.className.match(new RegExp('(\\s|^)' + className + '(\\s|$)'));
};

// Adds a className to an element ===============
export const addClass = (el, className) => {
  if (el.classList) {
    el.classList.add(className);
  } else if (!hasClass(el, className)) {
    el.className += ` ${className}`;
  }
};

// Removes a className to an element ============
export const removeClass = (el, className) => {
  if (el.classList) {
    el.classList.remove(className);
  } else if (hasClass(el, className)) {
    const reg = new RegExp('(\\s|^)' + className + '(\\s|$)');

    el.className = el.className.replace(reg, ' ');
  }
};

export const sortAlb = (a, b, field) => {
  if (a[field] < b[field]) {
    return -1;
  }
  if (a[field] > b[field]) {
    return 1;
  }

  return 0;
};

export const normFile = (e) => {
  if (Array.isArray(e)) {
    return e;
  }
  return e && e.fileList;
};

export function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
}

export function beforeUpload(file) {
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
  if (!isJpgOrPng) {
    message.error('You can only upload JPG/PNG file!');
  }

  const isLt10M = file.size / 1024 / 1024 <= 10;
  if (!isLt10M) {
    message.error('Image must smaller than 10MB!');
  }
  return isJpgOrPng && isLt10M;
}

export function debounce(func, wait, immediate) {
  var timeout;

  // This is the function that is actually executed when
  // the DOM event is triggered.
  return function executedFunction() {
    // Store the context of this and any
    // parameters passed to executedFunction
    var context = this;
    var args = arguments;

    // The function to be called after
    // the debounce time has elapsed
    var later = function () {
      // null timeout to indicate the debounce ended
      timeout = null;

      // Call function now if you did not on the leading end
      if (!immediate) func.apply(context, args);
    };

    // Determine if you should call the function
    // on the leading or trail end
    var callNow = immediate && !timeout;

    // This will reset the waiting every function execution.
    // This is the step that prevents the function from
    // being executed because it will never reach the
    // inside of the previous setTimeout
    clearTimeout(timeout);

    // Restart the debounce waiting period.
    // setTimeout returns a truthy value (it differs in web vs node)
    timeout = setTimeout(later, wait);

    // Call immediately if you're dong a leading
    // end execution
    if (callNow) func.apply(context, args);
  };
}

export const compareValues = (a, b) => {
  const songA = Number(a.data);
  const songB = Number(b.data);

  let comparison = 0;
  if (songA > songB) {
    comparison = 1;
  } else if (songA < songB) {
    comparison = -1;
  }
  return comparison;
};

export const handleServerError = (error) => {
  let errorMessage = 'Something Went Wrong';

  if (!error || !error.message) {
    return errorMessage;
  }

  return SERVER_ERRORS[error.message];
};

export const notEmpty = function (value, callback) {
  if (!value || String(value).length === 0) {
    callback(false);
  } else {
    callback(true);
  }
};

export const generateDefaultData = (rows, initialValues, blanks) => {
  for (let x = 0; x < rows; x++) {
    initialValues.push(['', '', '', '', '', '', '', '', '', '']);
  }

  return initialValues;
};

export const formatDate = (date) =>
  moment(date).format('MM/DD/YYYY hh:mm A').replace(' ', ' T: ');

export const formatDateCustom = (date, format = 'MM/DD/YYYY') =>
  moment(date).format(format).replace(' ', ' T: ');

export const calculateTotalCodes = (catalogs) => {
  return Object.keys(catalogs).reduce(
    (acc, currentValue) => (acc += Number(catalogs[currentValue])),
    0
  );
};

export const generateISRCCode = (baseCode, year, catalogNumber) => {
  const maxCatalogNumberDigit = ISRC_CAT_NUMBER_LENGTH; // according to isrc stantard
  const baseCodeValidLength = ISRC_MIN_LENGTH;
  const maxCatalogNumber = ISRC_MAX_CAT_NUMBER;

  if (baseCode.length !== baseCodeValidLength) {
    throw new Error(
      `Invalid Base Code. Base code length should be ${baseCodeValidLength} characters.`
    );
  }

  if (year.toString().length !== 4) {
    throw new Error(`Invalid Year Value. Must provide Full year value.`);
  }

  if (catalogNumber > maxCatalogNumber) {
    throw new Error(
      `Max Limit Exceeded. Current Catalog Number${catalogNumber} but max Limit is ${maxCatalogNumber}`
    );
  }

  const formattedYear = year.toString().substr(-2);
  const formattedCatalogNumber = catalogNumber
    .toString()
    .padStart(maxCatalogNumberDigit, '0');

  return `${baseCode}${formattedYear}${formattedCatalogNumber}`;
};

export const generateUPCCode = (baseCode, catalogNumber) => {
  const assetFormat = UPC_ASSET_FORMAT; // hardcodded for the system generation
  const maxCatalogNumberDigit = UPC_CAT_NUMBER_LENGTH; // according to UPC business rule
  const baseCodeValidLength = UPC_MIN_LENGTH;
  const maxCatalogNumber = UPC_MAX_CAT_NUMBER;

  if (baseCode.length !== baseCodeValidLength) {
    throw new Error(
      `Invalid Base Code. Base code length should be ${baseCodeValidLength} characters.`
    );
  }

  if (catalogNumber > maxCatalogNumber) {
    throw new Error(
      `Max Limit Exceeded. Current Catalog Number${catalogNumber} but max Limit is ${maxCatalogNumber}`
    );
  }

  const formattedCatalogNumber = catalogNumber
    .toString()
    .padStart(maxCatalogNumberDigit, '0');
  const unfinishedUPC = `${baseCode}${formattedCatalogNumber}${assetFormat}`;

  let checkSum = 0;
  let arr = unfinishedUPC.split('').reverse();
  let oddTotal = 0;
  let evenTotal = 0;

  for (let i = 0; i < arr.length; i++) {
    if (isNaN(arr[i])) {
      return false;
    } // can't be a valid upc/ean we're checking for

    if (i % 2 === 0) {
      oddTotal += Number(arr[i]) * 3;
    } else {
      evenTotal += Number(arr[i]);
    }
  }

  checkSum = (10 - ((evenTotal + oddTotal) % 10)) % 10;

  return `${unfinishedUPC}${checkSum}`;
};

export const changeTrackStatus = (release_date, currentStatus) => {
  const isPending = currentStatus === trackStatus.PENDING;
  const isUpcoming = currentStatus === trackStatus.UPCOMING;
  const isScheduled = currentStatus === trackStatus.SCHEDULED;
  const isReleased = currentStatus === trackStatus.RELEASED;
  const today = moment().format('YYYY-MM-DD');

  let status;

  // CLICK NOT PENDING
  if (isPending) {
    if (release_date) {
      status = trackStatus.SCHEDULED;
    }
  }

  // CLICK NOT PENDING
  if (isUpcoming) {
    if (release_date) {
      status = trackStatus.SCHEDULED;
    } else {
      status = trackStatus.PENDING;
    }
  }

  // LABEL AS PENDING
  if (isScheduled) {
    if (release_date) {
      status = trackStatus.UPCOMING;
    } else {
      status = trackStatus.PENDING;
    }
  }

  if (isReleased) {
    if (moment(release_date).isAfter(today)) {
      status = trackStatus.SCHEDULED;
    }
  }

  return status;
};

export const buildTrackArtworkURL = (artwork = {}, size = 'small') => {
  if (artwork && artwork[size]) {
    const isNewSongFormat =
      artwork[size].includes('songs') || artwork[size].includes('albums');

    const artworkUrl = `${CDN_URL}${isNewSongFormat ? '' : '/songs'}/${
      artwork[size]
    }`;

    return artworkUrl;
  }

  return '';
};

export const setDefaultImage = ({ currentTarget }) => {
  currentTarget.onerror = null;
  currentTarget.src = `${CDN_URL}/default-song.png`;
};

export const roundNumber = (number, positions = 4) => {
  if (!number) {
    return 0;
  }

  const divider = '1';
  const base = Number(divider.padEnd(positions + 1, '0'));

  return Math.round((number + Number.EPSILON) * base) / base;
};

export const calculateTotal = (values = [], key) => {
  if (!key) {
    throw new Error('Please especify a key.');
  }

  return values.reduce((acc, value) => acc + value[key], 0);
};

export const cleanObject = (obj) => {
  for (let propName in obj) {
    if (obj[propName] === null || obj[propName] === undefined) {
      delete obj[propName];
    }
  }
  return obj;
};

export const formatBig = (word, size = 35) => {
  if (!word) return word;
  word = word.toString();
  return word.length < size ? word : word.substring(0, size) + '...';
};

export const formatCurrency = (value) => {
  return value
    ? Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      }).format(value)
    : '$0';
};
