import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { push } from 'react-router-redux';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import OutsideClickHandler from 'react-outside-click-handler';
import Highlighter from 'react-highlight-words';
import PropTypes from 'prop-types';
import _ from 'lodash';

import Spinner from '../Spinner/Spinner';

import sprite from '../../images/sprite.svg';

import {
  quick_search,
  new_search,
  reset_search,
} from '../../containers/search/actions';
import { hideAllPanes } from '../../store/modules/panes/actions';

const initialState = {
  searched: '',
};

class Search extends Component {
  constructor(props) {
    super(props);
    this.performSearch = _.debounce(this.performSearch, 250);
    this.state = initialState;
  }

  onChange = ({ target: { value } }) => {
    if (value) {
      this.setState({ searched: value });
      this.props.new_search();
      this.props.toggleSearched(true);
      return this.performSearch(value);
    }
    return this.resetSearch();
  };

  onFocus = () => {
    this.props.hideAllPanes();
  };

  performSearch = (string) => {
    this.props.quick_search(string);
  };

  handleKeyDown = ({ keyCode }) => {
    switch (keyCode) {
      case 13:
        return this.onSeeAllResults();
      case 27:
        return this.resetSearch();
      default:
        return;
    }
  };

  handleOutsideClick = () => {
    return this.resetSearch();
  };

  resetSearch = () => {
    this.props.toggleSearched(false);
    this.setState(initialState);
    return this.props.reset_search();
  };

  onSeeAllResults = () => {
    this.resetSearch();
    return this.props.changePage(`/search/${this.state.searched}`);
  };

  render() {
    const { performedSearch, fetching, quick_results } = this.props;
    const { searched } = this.state;

    return (
      <OutsideClickHandler
        onOutsideClick={this.handleOutsideClick}
        disabled={searched ? false : true}
        display="contents"
      >
        <div className="search__wrapper">
          <ResultsContainer
            fetching={fetching}
            performedSearch={performedSearch}
            count={quick_results.length}
            results={quick_results}
            searched={searched}
            onClickOutside={this.handleOutsideClick}
          />

          <input
            type="text"
            className={`search__input ${searched ? 'active' : ''}`}
            ref="searchInput"
            onFocus={this.onFocus}
            onChange={this.onChange}
            onKeyDown={this.handleKeyDown}
            value={searched}
            placeholder="Search a track..."
          />

          {searched && quick_results.length ? (
            <button
              onClick={this.onSeeAllResults}
              className="search__button see-all-results"
            >
              See all results
            </button>
          ) : (
            <button className="search__button">
              <svg className="search__icon">
                <use href={sprite + '#icon-magnifying-glass'}></use>
              </svg>
            </button>
          )}
        </div>
      </OutsideClickHandler>
    );
  }
}

const ResultsContainer = ({
  fetching,
  performedSearch,
  count,
  results,
  searched,
  onClickOutside,
}) => {
  if (!count && fetching) {
    return <LoadingSpinner />;
  } else if (!fetching && count) {
    return (
      <Results
        results={results}
        searched={searched}
        onClickOutside={onClickOutside}
      />
    );
  } else if (!count && performedSearch && !fetching) {
    return <NoResults searched={searched} onClickOutside={onClickOutside} />;
  } else {
    return null;
  }
};

const Results = ({ results, onClickOutside, searched }) => {
  let searchedKeywords = searched.split(' ');

  return (
    <div className="top-search-results" onClick={onClickOutside}>
      <ul>
        {results?.map((song, index) => (
          <li key={index}>
            <Link to={`/tracks/${song._id}`}>
              <Highlighter
                searchWords={searchedKeywords}
                autoEscape={true}
                textToHighlight={song.title}
              />

              <span className="artist">
                <Highlighter
                  searchWords={searchedKeywords}
                  autoEscape={true}
                  textToHighlight={song.artist}
                />
              </span>
              <span className="label">
                <Highlighter
                  searchWords={searchedKeywords}
                  autoEscape={true}
                  textToHighlight={song.label}
                />
              </span>
              <div className="genres">
                {song?.tags?.map((tag, index) => (
                  <span key={`${tag}-${index}`}>
                    {index ? ', ' : ''}{' '}
                    <Highlighter
                      searchWords={searchedKeywords}
                      autoEscape={true}
                      textToHighlight={tag}
                    />
                  </span>
                ))}
              </div>
              {song.one_stop && <span className="onestop">One Stop</span>}
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
};

const NoResults = ({ onClickOutside, searched }) => (
  <div className="top-search-results" onClick={onClickOutside}>
    <div className="no-results">
      <div className="no-results-inner">
        <p>
          Nothing has been found for "<b>{searched}</b>"
        </p>
      </div>
    </div>
  </div>
);

const LoadingSpinner = ({ onClickOutside }) => (
  <div className="top-search-results">
    <Spinner
      style={{
        width: '1.5rem',
        height: '1.5rem',
        display: 'block',
        margin: '1rem auto',
      }}
    ></Spinner>
  </div>
);

const mapStateToProps = ({
  search: { results, performedSearch, fetching, quick_results },
}) => ({
  results,
  performedSearch,
  fetching,
  quick_results,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      quick_search,
      new_search,
      reset_search,
      changePage: (page) => push(page),
      hideAllPanes,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Search);

Search.propTypes = {
  results: PropTypes.array,
  performedSearch: PropTypes.bool,
  fetching: PropTypes.bool,
  quick_search: PropTypes.func,
  new_search: PropTypes.func,
  reset_search: PropTypes.func,
  changePage: PropTypes.func,
  hideAllPanes: PropTypes.func,
};
