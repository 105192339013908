import { handleActions } from 'redux-actions';
import organizationActions from '../actions/actionTypes';

const initialState = {
  organizations: [],
  totalOrganizations: 0,
  sidebarTotalOrganizations: 0,
  selectedOrganization: {},
  isFetching: false,
  isUpdating: false,
};

const organizationsReducer = handleActions(
  {
    [organizationActions.setOrganization.toString()]: (state, action) => {
      return {
        ...state,
        selectedOrganization: action.payload.organization,
      };
    },
    [organizationActions.setTotalOrganizations.toString()]: (state, action) => {
      return {
        ...state,
        sidebarTotalOrganizations: action.payload.totalOrganizations,
      };
    },

    [organizationActions.getOrganizations.toString()]: (state, action) => {
      return {
        ...state,
        isFetching: true,
      };
    },
    [organizationActions.getOrganizationsSuccess.toString()]: (
      state,
      action
    ) => {
      return {
        ...state,
        organizations: action.payload.organizations,
        totalOrganizations: action.payload.totalOrganizations,
        sidebarTotalOrganizations: action.payload.totalOrganizations,
        isFetching: false,
      };
    },
    [organizationActions.getOrganizationsFail.toString()]: (state, action) => {
      return {
        ...state,
        isFetching: false,
      };
    },
    [organizationActions.quickSearchOrganization.toString()]: (
      state,
      action
    ) => {
      return {
        ...state,
        isFetching: true,
      };
    },
    [organizationActions.quickSearchOrganizationSuccess.toString()]: (
      state,
      action
    ) => {
      return {
        ...state,
        organizations: action.payload.organizations,
        totalOrganizations: action.payload.totalOrganizations,
        isFetching: false,
      };
    },
    [organizationActions.quickSearchOrganizationFail.toString()]: (
      state,
      action
    ) => {
      return {
        ...state,
        isFetching: false,
      };
    },
    [organizationActions.createOrganization.toString()]: (state, action) => {
      return {
        ...state,
        isUpdating: true,
      };
    },
    [organizationActions.createOrganizationSuccess.toString()]: (
      state,
      action
    ) => {
      const newOrganizationsArray = [
        action.payload.organization,
        ...state.organizations,
      ];

      const totalOrganizations = state.totalOrganizations;

      return {
        ...state,
        organizations: newOrganizationsArray,
        totalOrganizations: totalOrganizations + 1,
        sidebarTotalOrganizations: state.sidebarTotalOrganizations + 1,
        isUpdating: false,
      };
    },
    [organizationActions.createOrganizationFail.toString()]: (
      state,
      action
    ) => {
      return {
        ...state,
        isUpdating: false,
      };
    },
    [organizationActions.updateOrganization.toString()]: (state, action) => {
      return {
        ...state,
        isUpdating: true,
      };
    },
    [organizationActions.updateOrganizationSuccess.toString()]: (
      state,
      action
    ) => {
      let organizationIndex;
      const organization = state.organizations.find((organization, index) => {
        organizationIndex = index;
        return organization._id === action.payload.organization._id;
      });
      const updatedOrganization = {
        ...organization,
        ...action.payload.organization,
      };

      const updatedOrganizations = [...state.organizations];

      updatedOrganizations[organizationIndex] = updatedOrganization;

      return {
        ...state,
        organizations: updatedOrganizations,
        isUpdating: false,
      };
    },
    [organizationActions.updateOrganizationFail.toString()]: (
      state,
      action
    ) => {
      return {
        ...state,
        isUpdating: false,
      };
    },
    [organizationActions.deleteOrganization.toString()]: (state, action) => {
      return {
        ...state,
        isDeleting: true,
      };
    },
    [organizationActions.deleteOrganizationSuccess.toString()]: (
      state,
      action
    ) => {
      const updatedOrgs = state.organizations.filter(
        (organization) => organization._id !== action.payload.id
      );

      const totalOrganizations = state.totalOrganizations;

      return {
        ...state,
        organizations: updatedOrgs,
        totalOrganizations: totalOrganizations - 1,
        sidebarTotalOrganizations: state.sidebarTotalOrganizations - 1,
        isDeleting: false,
      };
    },
    [organizationActions.deleteOrganizationFail.toString()]: (
      state,
      action
    ) => {
      return {
        ...state,
        isDeleting: false,
      };
    },
  },
  initialState
);

export default organizationsReducer;
