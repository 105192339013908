import { createActions } from 'redux-actions';

export default createActions({
  GET_USERS: undefined,
  GET_USERS_SUCCESS: undefined,
  GET_USERS_FAILED: undefined,
  GET_USER: undefined,
  GET_USER_SUCCESS: undefined,
  GET_USER_FAILED: undefined,
  DELETE_USER: undefined,
  DELETE_USER_FAILED: undefined,
  ADD_USER: undefined,
  ADD_USER_SUCCESS: undefined,
  ADD_USER_FAILED: undefined,
  UPDATE_USER: undefined,
  UPDATE_USER_SUCCESS: undefined,
  UPDATE_USER_FAILED: undefined,
  UPDATE_RECEIVE_REQUESTS: undefined,
  UPDATE_RECEIVE_REQUESTS_FAILED: undefined,
  UPDATE_ACCOUNT_STATUS: undefined,
  UPDATE_ACCOUNT_STATUS_SUCCESS: undefined,
  UPDATE_ACCOUNT_STATUS_FAILED: undefined,
  SAVE_PROFILE_IMAGE: undefined,
  SAVE_PROFILE_IMAGE_SUCCESS: undefined,
  SAVE_PROFILE_IMAGE_FAILED: undefined,
});
