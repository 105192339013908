import axios from 'axios';
import Resizer from 'react-image-file-resizer';

const defaultOptions = [300, 400, 'JPEG', 100, 0];

export const resizeFile = (file, options = defaultOptions) =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      ...options,
      (uri) => {
        resolve(uri);
      },
      'base64'
    );
  });

export const dataURIToBlob = (dataURI) => {
  const splitDataURI = dataURI.split(',');
  const byteString =
    splitDataURI[0].indexOf('base64') >= 0
      ? atob(splitDataURI[1])
      : decodeURI(splitDataURI[1]);
  const mimeString = splitDataURI[0].split(':')[1].split(';')[0];
  const ia = new Uint8Array(byteString.length);
  for (let i = 0; i < byteString.length; i++) ia[i] = byteString.charCodeAt(i);
  return new Blob([ia], { type: mimeString });
};

export const storeImageRequests = (requests) => {
  return requests.map((req) =>
    axios.put(req.signedUrl.url, req.file, {
      headers: {
        'x-amz-acl': 'public-read',
        'Content-Type': req.file.type,
      },
    })
  );
};
