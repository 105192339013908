import React from 'react';
import { Menu } from 'antd';
import { Link } from 'react-router-dom';
import Proptypes from 'prop-types';

const ContextMenu = ({
  onEditAll,
  onRemoveAll,
  onRemoveOne,
  songId,
  checkedListLength = 0,
  isEditSection,
}) => (
  <Menu
    className="options-list context-menu"
    style={{
      textAlign: 'left',
      marginTop: 0,
      fontWeight: 500,
    }}
  >
    <Menu.Item key="1">
      <Link style={{ width: '100%' }} to={`/tracks/${songId}`}>
        View
      </Link>
    </Menu.Item>
    <Menu.Item key="2">
      <Link style={{ width: '100%' }} to={`/tracks/edit/${songId}`}>
        Edit
      </Link>
    </Menu.Item>
    <Menu.Item
      key="3"
      onClick={(e) => {
        e.domEvent.stopPropagation();
        onRemoveOne([songId]);
      }}
      style={{ padding: '10px 15px' }}
    >
      Remove
    </Menu.Item>
    {isEditSection && (
      <Menu.Item
        key="4"
        onClick={(e) => {
          e.domEvent.stopPropagation();

          if (checkedListLength >= 1) {
            onEditAll();
          }
        }}
        style={{ borderTop: '1px solid #e2e2e2', padding: '10px 15px' }}
      >
        Edit Selected
      </Menu.Item>
    )}
    <Menu.Item
      key="5"
      onClick={(e) => {
        e.domEvent.stopPropagation();

        if (checkedListLength >= 1) {
          onRemoveAll();
        }
      }}
      style={{ padding: '10px 15px' }}
    >
      Remove Selected
    </Menu.Item>
  </Menu>
);

ContextMenu.propTypes = {
  onEditAll: Proptypes.func,
  onRemoveAll: Proptypes.func,
  onRemoveOne: Proptypes.func,
  songId: Proptypes.string,
  checkedListLength: Proptypes.number,
  isEditSection: Proptypes.number,
};

export default ContextMenu;
