import { handleActions } from 'redux-actions';
import projectsActions from '../actions/actionTypes';

const initialState = {
  projects: [],
  projectTracks: [],
  newProject: {},
  isFetching: false,
  isCreating: false,
  isUpdating: false,
  isDeleting: false,
  editMode: false,
  totalProjects: 0,
  totalProjectTracks: 0,
  selectedProject: {},
  unregisteredProjects: [],
  totalUnregisteredProjects: 0,
  panelPage: 1,
  sidebarTotal: 0,
};

const projectsReducer = handleActions(
  {
    [projectsActions.setSelectedProject.toString()]: (state, action) => {
      return {
        ...state,
        selectedProject: action.payload.project,
      };
    },
    [projectsActions.setEditMode.toString()]: (state, action) => {
      return {
        ...state,
        editMode: action.payload.isEditMode,
      };
    },
    [projectsActions.setPanelPage.toString()]: (state, action) => {
      return {
        ...state,
        panelPage: action.payload.page,
      };
    },
    [projectsActions.setTotalProjects.toString()]: (state, action) => {
      return {
        ...state,
        sidebarTotal: action.payload.totalProjects,
      };
    },
    [projectsActions.getProjects.toString()]: (state, action) => {
      return {
        ...state,
        isFetching: true,
      };
    },
    [projectsActions.getProjectsSuccess.toString()]: (state, action) => {
      return {
        ...state,
        isFetching: false,
        projects: action.payload.albums,
        totalProjects: action.payload.totalProjects,
        unregisteredProjects: action.payload.unregisteredProjects,
        totalUnregisteredProjects: action.payload.totalUnregisteredProjects,
        sidebarTotal: action.payload.totalProjects,
      };
    },
    [projectsActions.getProjectsFail.toString()]: (state, action) => {
      return {
        ...state,
        isFetching: false,
      };
    },
    [projectsActions.quickSearchProject.toString()]: (state, action) => {
      return {
        ...state,
        isFetching: true,
      };
    },
    [projectsActions.quickSearchProjectSuccess.toString()]: (state, action) => {
      return {
        ...state,
        isFetching: false,
        projects: action.payload.projects,
        totalProjects: action.payload.totalProjects,
      };
    },
    [projectsActions.quickSearchProjectFail.toString()]: (state, action) => {
      return {
        ...state,
        isFetching: false,
      };
    },
    [projectsActions.getProjectTracks.toString()]: (state, action) => {
      return {
        ...state,
        isFetchingSingle: true,
      };
    },
    [projectsActions.getProjectTracksSuccess.toString()]: (state, action) => {
      return {
        ...state,
        isFetchingSingle: false,
        projectTracks: action.payload.tracks,
        totalProjectTracks: action.payload.totalProjectTracks,
      };
    },
    [projectsActions.getProjectTracksFail.toString()]: (state, action) => {
      return {
        ...state,
        isFetchingSingle: false,
      };
    },
    [projectsActions.createProject.toString()]: (state, action) => {
      return {
        ...state,
        isCreating: true,
      };
    },
    [projectsActions.createProjectSuccess.toString()]: (state, action) => {
      const newProjects = [action.payload.project, ...state.projects];

      const totalProjects = state.totalProjects;

      return {
        ...state,
        projects: newProjects,
        isCreating: false,
        newProject: action.payload.project,
        totalProjects: totalProjects + 1,
        sidebarTotal: state.sidebarTotal + 1,
      };
    },
    [projectsActions.createProjectFail.toString()]: (state, action) => {
      return {
        ...state,
        isCreating: false,
      };
    },
    [projectsActions.registerProject.toString()]: (state, action) => {
      return {
        ...state,
        isRegistering: true,
      };
    },
    [projectsActions.registerProjectSuccess.toString()]: (state, action) => {
      const newProjects = [...state.projects, action.payload.project];
      // search by name is done because the name is a unique field
      const updatedUnregisteredProjects = state.unregisteredProjects.filter(
        (proj) => proj.project_title !== action.payload.project.project_title
      );

      const totalProjects = state.totalProjects;
      const totalUnregisteredProjects = state.totalUnregisteredProjects;

      return {
        ...state,
        projects: newProjects,
        isRegistering: false,
        newProject: action.payload.project,
        totalProjects: totalProjects + 1,
        unregisteredProjects: updatedUnregisteredProjects,
        totalUnregisteredProjects: totalUnregisteredProjects - 1,
        sidebarTotal: state.sidebarTotal + 1,
      };
    },
    [projectsActions.registerProjectFail.toString()]: (state, action) => {
      return {
        ...state,
        isRegistering: false,
      };
    },
    [projectsActions.updateProject.toString()]: (state, action) => {
      return {
        ...state,
        isUpdating: true,
      };
    },
    [projectsActions.updateProjectSuccess.toString()]: (state, action) => {
      const projectsCopy = [...state.projects];
      let projectIndex;
      const project = state.projects.find((project, index) => {
        if (project._id === action.payload.project._id) {
          projectIndex = index;
          return project;
        }

        return null;
      });

      const updatedProject = {
        ...project,
        ...action.payload.project,
      };

      projectsCopy[projectIndex] = updatedProject;

      return {
        ...state,
        projects: projectsCopy,
        isUpdating: false,
      };
    },
    [projectsActions.updateProjectFail.toString()]: (state, action) => {
      return {
        ...state,
        isUpdating: false,
      };
    },
    [projectsActions.deleteProject.toString()]: (state, action) => {
      return {
        ...state,
        isDeleting: true,
      };
    },
    [projectsActions.deleteProjectSuccess.toString()]: (state, action) => {
      const updatedProjects = state.projects.filter(
        (proj) => proj._id !== action.payload.deletedProject
      );

      const totalProjects = state.totalProjects;

      return {
        ...state,
        projects: updatedProjects,
        totalProjects: totalProjects - 1,
        sidebarTotal: state.sidebarTotal - 1,
        isDeleting: false,
      };
    },
    [projectsActions.deleteProjectFail.toString()]: (state, action) => {
      return {
        ...state,
        isDeleting: false,
      };
    },
    [projectsActions.updateProjectsNumber.toString()]: (state, action) => {
      return {
        ...state,
        totalProjects: action.payload,
      };
    },
    [projectsActions.saveProjectImage.toString()]: (state, action) => {
      return {
        ...state,
        isSavingImage: true,
      };
    },
    [projectsActions.saveProjectImageSuccess.toString()]: (state, action) => {
      return {
        ...state,
        isSavingImage: false,
      };
    },
    [projectsActions.saveProjectImageFail.toString()]: (state, action) => {
      return {
        ...state,
        isSavingImage: false,
        error: action.error,
      };
    },
    [projectsActions.deleteProjectImage.toString()]: (state, action) => {
      return {
        ...state,
        isSavingImage: true,
      };
    },
    [projectsActions.deleteProjectImageSuccess.toString()]: (state, action) => {
      return {
        ...state,
        isSavingImage: false,
      };
    },
    [projectsActions.deleteProjectImageFail.toString()]: (state, action) => {
      return {
        ...state,
        isSavingImage: false,
        error: action.error,
      };
    },
  },
  initialState
);

export default projectsReducer;
