import React, { useCallback, useEffect } from 'react';
import Proptypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { Typography, Form, Input, Button, notification } from 'antd';
import { CloseOutlined } from '@ant-design/icons';

import { hideAllPanes } from 'store/modules/panes/actions';

import { updateTagCategory, createTagCategory } from '../../../actions';

const layout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
};

const formDefaultValues = {
  name: '',
};

const { Title } = Typography;

const EditTagCategory = ({
  selectedTag: tag,
  hideAllPanes,
  isUpdating,
  createTagCategory,
  updateTagCategory,
}) => {
  const [form] = Form.useForm();
  const createMode = tag.new;

  const onFinish = async (values) => {
    const sanitizedValues = {
      ...values,
    };

    try {
      if (createMode) {
        await createTagCategory(sanitizedValues);
      } else {
        sanitizedValues.id = tag.id;

        await updateTagCategory(sanitizedValues);
      }

      notification.success({
        message: `Tag Updated`,
        description: 'List of tags was updated',
        duration: 4,
      });
      onClose();
    } catch (error) {
      notification.warning({
        message: `Tag update Failed`,
        description:
          'Information was not updated. Please try again, if the problem persist please contact support',
        duration: 4,
      });
    }
  };

  const onClose = () => {
    hideAllPanes();
    cleanState();
  };

  const cleanState = () => {
    form.resetFields();
  };

  // FORM
  const prepareFormValues = useCallback(
    (tag) => {
      const fields = {
        name: tag.name || '',
      };

      form.setFieldsValue(fields);
    },
    [form]
  );

  useEffect(() => {
    prepareFormValues(tag);
  }, [prepareFormValues, tag, form]);

  return (
    <div className="vw-module edit-module content-module">
      <div className="content-title view-module-title">
        <CloseOutlined onClick={onClose} />

        <Title level={3}>{createMode ? 'Create' : 'Edit'} Tag Category</Title>

        <span />
      </div>

      <div className="module-container">
        <Form
          {...layout}
          form={form}
          name="control-hooks-edit-tag-cat"
          initialValues={{ ...formDefaultValues }}
          onFinish={onFinish}
          autoComplete="off"
        >
          <Form.Item name="name" label="Name" rules={[{ required: true }]}>
            <Input placeholder="Category Name" />
          </Form.Item>

          <Form.Item>
            <Button
              className="btn-primary"
              loading={isUpdating}
              type="primary"
              htmlType="submit"
            >
              {createMode ? 'Create' : 'Edit'}
            </Button>

            <Button htmlType="button" onClick={onClose}>
              Cancel
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

const mapStateToProps = ({ tags }) => {
  return {
    selectedTag: tags.selectedTag,
    isUpdating: tags.isUpdating,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      hideAllPanes,
      createTagCategory,
      updateTagCategory,
    },
    dispatch
  );

EditTagCategory.defaultProps = {
  selectedTag: {},
  hideAllPanes: () => {},
  isUpdating: false,
  createTagCategory: () => {},
  updateTagCategory: () => {},
};

EditTagCategory.propTypes = {
  selectedTag: Proptypes.object,
  hideAllPanes: Proptypes.func,
  isUpdating: Proptypes.bool,
  createTagCategory: Proptypes.func,
  updateTagCategory: Proptypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(EditTagCategory);
