export const trackStatus = {
  PENDING: 'pending',
  ARCHIVED: 'archived',
  UPCOMING: 'upcoming',
  SCHEDULED: 'scheduled',
  RELEASED: 'released',
};

export const contractStatus = {
  declined: '#f50',
  voided: 'default',
  'in-process': '#108ee9',
};
