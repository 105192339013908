import { handleActions } from 'redux-actions';
import legalActions from '../actions/actionTypes';

const initialState = {
  signings: [],
  infringements: [],
  templates: [],
  contract: {},
  infringement: {},
  templateUrl: '',
  requestedTemplate: '',
  totalSignings: 0,
  isLogging: false,
  isFetching: true,
  isFetchingView: false,
  isCreating: false,
  isCreatingNote: false,
  isUpdating: false,
  isDeleting: false,
  requests: {
    interpolation: [],
    license: [],
    sample: [],
  },
  licenses: [],
  totalLicenses: 0,
  samples: [],
  totalSamples: 0,
  interpolations: [],
  totalInterpolations: 0,
  history: [],
  newRequests: [],
  totalNewRequests: 0,
  request: {},
  contractInfo: {},
  signing: {},
  license: {},
  interpolation: {},
  sample: {},
  totalNewinterpolation: 0,
  totalNewlicense: 0,
  totalNewsample: 0,

  // TODO: eliminate booleans and use status value
  status: 'idle',
};

const legalReducer = handleActions(
  {
    [legalActions.getInfringements.toString()]: (state, action) => {
      return {
        ...state,
        isFetching: true,
      };
    },
    [legalActions.getInfringementsSuccess.toString()]: (state, action) => {
      return {
        ...state,
        infringements: action.payload.infringements,
        totalInfringements: action.payload.totalInfringements,
        isFetching: false,
      };
    },
    [legalActions.getInfringementsFailed.toString()]: (state, action) => {
      return {
        ...state,
        isFetching: false,
      };
    },
    [legalActions.getInfringement.toString()]: (state, action) => {
      return {
        ...state,
        isFetching: true,
      };
    },
    [legalActions.getInfringementSuccess.toString()]: (state, action) => {
      return {
        ...state,
        infringement: action.payload.infringement,
        isFetching: false,
      };
    },
    [legalActions.getInfringementFailed.toString()]: (state, action) => {
      return {
        ...state,
        isFetching: false,
      };
    },
    [legalActions.updateInfringement.toString()]: (state, action) => {
      return {
        ...state,
        isUpdating: true,
      };
    },
    [legalActions.updateInfringementSuccess.toString()]: (state, action) => {
      return {
        ...state,
        infringement: action.payload.infringement,
        isUpdating: false,
      };
    },
    [legalActions.updateInfringementFailed.toString()]: (state, action) => {
      return {
        ...state,
        isUpdating: false,
      };
    },
    [legalActions.updateInfringementPaid.toString()]: (state, action) => {
      return {
        ...state,
        isUpdating: true,
      };
    },
    [legalActions.updateInfringementPaidSuccess.toString()]: (
      state,
      action
    ) => {
      return {
        ...state,
        infringement: action.payload.infringement,
        isUpdating: false,
      };
    },
    [legalActions.updateInfringementPaidFailed.toString()]: (state, action) => {
      return {
        ...state,
        isUpdating: false,
      };
    },
    [legalActions.createSigning.toString()]: (state, action) => {
      return {
        ...state,
        isCreating: true,
      };
    },
    [legalActions.createSigningSuccess.toString()]: (state, action) => {
      return {
        ...state,
        signing: action.payload.signing,
        isCreating: false,
      };
    },
    [legalActions.createSigningFailed.toString()]: (state, action) => {
      return {
        ...state,
        isCreating: false,
      };
    },
    [legalActions.buildInfringement.toString()]: (state, action) => {
      return {
        ...state,
        isCreating: true,
      };
    },
    [legalActions.buildInfringementSuccess.toString()]: (state, action) => {
      return {
        ...state,
        infringement: action.payload.infringement,
        isCreating: false,
      };
    },
    [legalActions.buildInfringementFailed.toString()]: (state, action) => {
      return {
        ...state,
        isCreating: false,
      };
    },
    [legalActions.getRequests.toString()]: (state, action) => {
      return {
        ...state,
        isFetching: true,
      };
    },
    [legalActions.getRequestsSuccess.toString()]: (state, action) => {
      const { type, requests, totalNewRequests, totalRequests } =
        action.payload;

      return {
        ...state,
        requests: {
          ...state.requests,
          [type]: requests,
        },
        totalRequests,
        [`totalNew${type}`]: totalNewRequests,
        isFetching: false,
      };
    },
    [legalActions.getRequestsFailed.toString()]: (state, action) => {
      return {
        ...state,
        error: action.error,
        isFetching: false,
      };
    },
    [legalActions.getNewRequests.toString()]: (state, action) => {
      return {
        ...state,
        isFetching: true,
      };
    },
    [legalActions.getNewRequestsSuccess.toString()]: (state, action) => {
      return {
        ...state,
        newRequests: action.payload.newRequests,
        totalNewRequests: action.payload.totalNewRequests,
      };
    },
    [legalActions.getNewRequestsFail.toString()]: (state, action) => {
      return {
        ...state,
        error: action.error,
        isFetching: false,
      };
    },
    [legalActions.getRequest.toString()]: (state, action) => {
      return {
        ...state,
        isFetching: true,
        request: {},
        contractInfo: {},
      };
    },
    [legalActions.getRequestSuccess.toString()]: (state, action) => {
      return {
        ...state,
        request: action.payload.request,
        isFetching: false,
      };
    },
    [legalActions.getRequestFailed.toString()]: (state, action) => {
      return {
        ...state,
        error: action.error,
        isFetching: false,
      };
    },
    [legalActions.clearRequest.toString()]: (state, action) => {
      return {
        ...state,
        request: {},
        contractInfo: {},
      };
    },
    //LICENSES
    [legalActions.getLicenses.toString()]: (state, action) => {
      return {
        ...state,
        isFetching: true,
      };
    },
    [legalActions.getLicensesSuccess.toString()]: (state, action) => {
      const { licenses, totalLicenses } = action.payload;

      return {
        ...state,
        licenses: [...licenses],
        totalLicenses,
        isFetching: false,
      };
    },
    [legalActions.getLicensesFailed.toString()]: (state, action) => {
      return {
        ...state,
        error: action.error,
        isFetching: false,
      };
    },
    [legalActions.getLicense.toString()]: (state, action) => {
      return {
        ...state,
        isFetching: true,
      };
    },
    [legalActions.getLicenseSuccess.toString()]: (state, action) => {
      return {
        ...state,
        contractInfo: action.payload.license,
        isFetching: false,
      };
    },
    [legalActions.getLicenseFailed.toString()]: (state, action) => {
      return {
        ...state,
        error: action.error,
        isFetching: false,
      };
    },
    [legalActions.deleteLicense.toString()]: (state, action) => {
      return {
        ...state,
        isFetching: true,
      };
    },
    [legalActions.deleteLicenseSuccess.toString()]: (state, action) => {
      return {
        ...state,
        isFetching: false,
      };
    },
    [legalActions.deleteLicenseFailed.toString()]: (state, action) => {
      return {
        ...state,
        error: action.error,
        isFetching: false,
      };
    },
    [legalActions.startLicense.toString()]: (state, action) => {
      return {
        ...state,
        isCreating: true,
      };
    },
    [legalActions.startLicenseSuccess.toString()]: (state, action) => {
      return {
        ...state,
        license: action.payload.license,
        isCreating: false,
      };
    },
    [legalActions.startLicenseFailed.toString()]: (state, action) => {
      return {
        ...state,
        error: action.error,
        isCreating: false,
      };
    },
    [legalActions.updateLicenseStatus.toString()]: (state, action) => {
      return {
        ...state,
        isUpdating: true,
      };
    },
    [legalActions.updateLicenseStatusSuccess.toString()]: (state, action) => {
      return {
        ...state,
        contractInfo: {
          ...state.contractInfo,
          status: action.payload.license.status,
          paid: action.payload.license.paid,
          paidDate: action.payload.license.paidDate,
        },
        isUpdating: false,
      };
    },
    [legalActions.updateLicenseStatusFailed.toString()]: (state, action) => {
      return {
        ...state,
        error: action.error,
        isUpdating: false,
      };
    },
    [legalActions.cancelRequest.toString()]: (state, action) => {
      return {
        ...state,
        isUpdating: true,
      };
    },
    [legalActions.cancelRequestSuccess.toString()]: (state, action) => {
      return {
        ...state,
        request: action.payload.request,
        isUpdating: false,
      };
    },
    [legalActions.cancelRequestFailed.toString()]: (state, action) => {
      return {
        ...state,
        error: action.error,
        isUpdating: false,
      };
    },
    [legalActions.updateStatusStart.toString()]: (state, action) => {
      return {
        ...state,
        requests: state.requests.map((request) => {
          if (request._id === action.payload.id) {
            request.status = action.payload.status;
            return request;
          }
          return request;
        }),
      };
    },
    [legalActions.addNoteRequestStart.toString()]: (state, action) => {
      return {
        ...state,
        isCreatingNote: true,
      };
    },
    [legalActions.addNoteRequestSuccess.toString()]: (state, action) => {
      return {
        ...state,
        request: {
          ...state.request,
          notes: action.payload.notes,
        },
        isCreatingNote: false,
      };
    },
    [legalActions.addNoteRequestFailed.toString()]: (state, action) => {
      return {
        ...state,
        error: action.error,
        isCreatingNote: false,
      };
    },
    [legalActions.addNoteSigningStart.toString()]: (state, action) => {
      return {
        ...state,
        isCreatingNote: true,
      };
    },
    [legalActions.addNoteSigningSuccess.toString()]: (state, action) => {
      return {
        ...state,
        signing: {
          ...state.signing,
          notes: action.payload.notes,
        },
        isCreatingNote: false,
      };
    },
    [legalActions.addNoteSigningFailed.toString()]: (state, action) => {
      return {
        ...state,
        error: action.error,
        isCreatingNote: false,
      };
    },
    [legalActions.addNoteInfringementStart.toString()]: (state, action) => {
      return {
        ...state,
        isCreatingNote: true,
      };
    },
    [legalActions.addNoteInfringementSuccess.toString()]: (state, action) => {
      return {
        ...state,
        infringement: {
          ...state.infringement,
          notes: action.payload.notes,
        },
        isCreatingNote: false,
      };
    },
    [legalActions.addNoteInfringementFailed.toString()]: (state, action) => {
      return {
        ...state,
        error: action.error,
        isCreatingNote: false,
      };
    },
    [legalActions.addReplyStart.toString()]: (state, action) => {
      return {
        ...state,
        request: {
          ...state.request,
          replies: [action.payload, ...state.request.replies],
        },
      };
    },
    [legalActions.clearInfringement.toString()]: (state, action) => {
      return {
        ...state,
        infringement: {},
      };
    },
    [legalActions.getSignings.toString()]: (state, action) => {
      return {
        ...state,
        isFetching: true,
      };
    },
    [legalActions.getSigningsSuccess.toString()]: (state, action) => {
      return {
        ...state,
        signings: action.payload.signings,
        totalSignings: action.payload.totalSignings,
        isFetching: false,
      };
    },
    [legalActions.getSigningsFailed.toString()]: (state, action) => {
      return {
        ...state,
        isFetching: false,
      };
    },
    [legalActions.getSigning.toString()]: (state, action) => {
      return {
        ...state,
        isFetching: true,
      };
    },
    [legalActions.getSigningSuccess.toString()]: (state, action) => {
      return {
        ...state,
        signing: action.payload.signing,
        isFetching: false,
      };
    },
    [legalActions.getSigningFailed.toString()]: (state, action) => {
      return {
        ...state,
        error: action.error,
        isFetching: false,
      };
    },
    [legalActions.updateSigningPaid.toString()]: (state, action) => {
      return {
        ...state,
        isUpdating: true,
      };
    },
    [legalActions.updateSigningPaidSuccess.toString()]: (state, action) => {
      return {
        ...state,
        signing: {
          ...action.payload.signing,
          tracks: state.signing.tracks || [],
        },
        isUpdating: false,
      };
    },
    [legalActions.updateSigningPaidFailed.toString()]: (state, action) => {
      return {
        ...state,
        error: action.error,
        isUpdating: false,
      };
    },
    [legalActions.sendContract.toString()]: (state, action) => {
      return {
        ...state,
        isUpdating: true,
      };
    },
    [legalActions.sendContractSuccess.toString()]: (state, action) => {
      return {
        ...state,
        signing: {
          ...action.payload.signing,
          tracks: state.signing.tracks || [],
        },
        isUpdating: false,
      };
    },
    [legalActions.sendContractFailed.toString()]: (state, action) => {
      return {
        ...state,
        error: action.error,
        isUpdating: false,
      };
    },
    [legalActions.getInterpolation.toString()]: (state, action) => {
      return {
        ...state,
        isFetching: true,
      };
    },
    [legalActions.getInterpolationSuccess.toString()]: (state, action) => {
      return {
        ...state,
        contractInfo: action.payload.interpolation,
        isFetching: false,
      };
    },
    [legalActions.getInterpolationFailed.toString()]: (state, action) => {
      return {
        ...state,
        error: action.error,
        isFetching: false,
      };
    },
    [legalActions.startInterpolation.toString()]: (state, action) => {
      return {
        ...state,
        isCreating: true,
      };
    },
    [legalActions.startInterpolationSuccess.toString()]: (state, action) => {
      return {
        ...state,
        interpolation: action.payload.interpolation,
        isCreating: false,
      };
    },
    [legalActions.startInterpolationFailed.toString()]: (state, action) => {
      return {
        ...state,
        error: action.error,
        isCreating: false,
      };
    },
    [legalActions.getInterpolations.toString()]: (state, action) => {
      return {
        ...state,
        isFetching: true,
      };
    },
    [legalActions.getInterpolationsSuccess.toString()]: (state, action) => {
      const { interpolations, totalInterpolations } = action.payload;

      return {
        ...state,
        interpolations: [...interpolations],
        totalInterpolations,
        isFetching: false,
      };
    },
    [legalActions.getInterpolationsFailed.toString()]: (state, action) => {
      return {
        ...state,
        error: action.error,
        isFetching: false,
      };
    },
    [legalActions.updateInterpolationStatus.toString()]: (state, action) => {
      return {
        ...state,
        isUpdating: true,
      };
    },
    [legalActions.updateInterpolationStatusSuccess.toString()]: (
      state,
      action
    ) => {
      return {
        ...state,
        contractInfo: action.payload.interpolation,
        isUpdating: false,
      };
    },
    [legalActions.updateInterpolationStatusFailed.toString()]: (
      state,
      action
    ) => {
      return {
        ...state,
        error: action.error,
        isUpdating: false,
      };
    },
    [legalActions.deleteInterpolation.toString()]: (state, action) => {
      return {
        ...state,
        isFetching: true,
      };
    },
    [legalActions.deleteInterpolationSuccess.toString()]: (state, action) => {
      return {
        ...state,
        isFetching: false,
      };
    },
    [legalActions.deleteInterpolationFailed.toString()]: (state, action) => {
      return {
        ...state,
        error: action.error,
        isFetching: false,
      };
    },
    //SAMPLES
    [legalActions.getSamples.toString()]: (state, action) => {
      return {
        ...state,
        isFetching: true,
      };
    },
    [legalActions.getSamplesSuccess.toString()]: (state, action) => {
      const { samples, totalSamples } = action.payload;

      return {
        ...state,
        samples: [...samples],
        totalSamples,
        isFetching: false,
      };
    },
    [legalActions.getSamplesFailed.toString()]: (state, action) => {
      return {
        ...state,
        error: action.error,
        isFetching: false,
      };
    },
    [legalActions.getSample.toString()]: (state, action) => {
      return {
        ...state,
        isFetching: true,
      };
    },
    [legalActions.getSampleSuccess.toString()]: (state, action) => {
      return {
        ...state,
        contractInfo: action.payload.sample,
        isFetching: false,
      };
    },
    [legalActions.getSampleFailed.toString()]: (state, action) => {
      return {
        ...state,
        error: action.error,
        isFetching: false,
      };
    },
    [legalActions.deleteSample.toString()]: (state, action) => {
      return {
        ...state,
        isFetching: true,
      };
    },
    [legalActions.deleteSampleSuccess.toString()]: (state, action) => {
      return {
        ...state,
        isFetching: false,
      };
    },
    [legalActions.deleteSampleFailed.toString()]: (state, action) => {
      return {
        ...state,
        error: action.error,
        isFetching: false,
      };
    },
    [legalActions.startSample.toString()]: (state, action) => {
      return {
        ...state,
        isCreating: true,
      };
    },
    [legalActions.startSampleSuccess.toString()]: (state, action) => {
      return {
        ...state,
        sample: action.payload.sample,
        isCreating: false,
      };
    },
    [legalActions.startSampleFailed.toString()]: (state, action) => {
      return {
        ...state,
        error: action.error,
        isCreating: false,
      };
    },
    [legalActions.updateSampleStatus.toString()]: (state, action) => {
      return {
        ...state,
        isUpdating: true,
      };
    },
    [legalActions.updateSampleStatusSuccess.toString()]: (state, action) => {
      return {
        ...state,
        contractInfo: {
          ...state.contractInfo,
          status: action.payload.sample.status,
          paid: action.payload.sample.paid,
          paidDate: action.payload.sample.paidDate,
        },
        isUpdating: false,
      };
    },
    [legalActions.updateSampleStatusFailed.toString()]: (state, action) => {
      return {
        ...state,
        error: action.error,
        isUpdating: false,
      };
    },
    [legalActions.updateSamplePeriod.toString()]: (state, action) => {
      return {
        ...state,
        isUpdating: true,
      };
    },
    [legalActions.updateSamplePeriodSuccess.toString()]: (state, action) => {
      return {
        ...state,
        contractInfo: {
          ...state.contractInfo,
          periods: action.payload.sample.periods,
        },
        isUpdating: false,
      };
    },
    [legalActions.updateSamplePeriodFailed.toString()]: (state, action) => {
      return {
        ...state,
        error: action.error,
        isUpdating: false,
      };
    },
  },
  initialState
);

export default legalReducer;
