import { createActions } from 'redux-actions';

export default createActions({
  SEARCH_ACTION: undefined,
  QUICK_SEARCH: undefined,
  QUICK_SEARCH_FAIL: undefined,
  RESET_SEARCH: undefined,
  TOGGLE_SEARCH: undefined,
  RESET_RESULTS: undefined,
  NEW_SEARCH: undefined,
});
