import { createActions } from 'redux-actions';

export default createActions({
  GET_SPREADSHEET_START: undefined,
  GET_SPREADSHEET_SUCCESS: undefined,
  GET_SPREADSHEET_FAIL: undefined,
  GET_SPREADSHEETS_START: undefined,
  GET_SPREADSHEETS_SUCCESS: undefined,
  GET_SPREADSHEETS_FAIL: undefined,
  CREATE_SPREADSHEET_START: undefined,
  CREATE_SPREADSHEET_SUCCESS: undefined,
  CREATE_SPREADSHEET_FAIL: undefined,
  SAVE_MASTER_SPREADSHEET_DATA_START: undefined,
  SAVE_MASTER_SPREADSHEET_DATA_SUCCESS: undefined,
  SAVE_MASTER_SPREADSHEET_DATA_FAIL: undefined,
  SAVE_PUBLISHER_SPREADSHEET_DATA_START: undefined,
  SAVE_PUBLISHER_SPREADSHEET_DATA_SUCCESS: undefined,
  SAVE_PUBLISHER_SPREADSHEET_DATA_FAIL: undefined,
  UPDATE_SPREADSHEET_START: undefined,
  UPDATE_SPREADSHEET_SUCCESS: undefined,
  UPDATE_SPREADSHEET_FAIL: undefined,
  DELETE_SPREADSHEET_START: undefined,
  DELETE_SPREADSHEET_SUCCESS: undefined,
  DELETE_SPREADSHEET_FAIL: undefined,
});
