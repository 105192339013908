import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Popconfirm } from 'antd';
import OutsideClickHandler from 'react-outside-click-handler';
import PropTypes from 'prop-types';

import DropdownMenu from 'components/MultiLevelDropdown/MultiLevelDropdown';
import TopSearch from 'components/TopSearch/TopSearch';

import sprite from 'images/sprite.svg';
import { ReactComponent as IconLogout } from 'images/SVG/logout-light.svg';
import { ReactComponent as IconProfile } from 'images/SVG/v-card.svg';

import { CDN_URL } from 'utils/consts';

const Header = ({ user, totalNewRequests, toggleSearched, onBurgerClick }) => {
  const { push } = useHistory();

  const dropdownOptions = [
    {
      title: 'Profile',
      leftIcon: <IconProfile />,
      link: `/users/${user.id}/edit`,
    },
    {
      title: 'Logout',
      leftIcon: <IconLogout />,
      link: '/logout',
    },
  ];

  return (
    <header className="main-header">
      <span className="burger" onClick={onBurgerClick}>
        <svg className="user-nav__icon">
          <use href={sprite + '#icon-menu'}></use>
        </svg>
      </span>

      <div className="search">
        <TopSearch toggleSearched={toggleSearched} />
      </div>

      <nav className="user-nav">
        <Popconfirm
          placement="bottom"
          title={`You have ${totalNewRequests} New Requests`}
          onConfirm={() => push('/legal?tab=3')}
          okText="View"
          cancelText="Cancel"
        >
          <div className="user-nav__icon-box">
            <svg className="user-nav__icon">
              <use href={sprite + '#icon-bell'}></use>
            </svg>

            {totalNewRequests > 0 && (
              <span className="user-nav__notification">{totalNewRequests}</span>
            )}
          </div>
        </Popconfirm>

        <div className="user-nav__user">
          <NavItem
            icon={
              <>
                <img
                  src={`${CDN_URL}/${user.profileImage}`}
                  alt="User"
                  className="user-nav__user-photo"
                />
                <span className="user-nav__user-name">{user.username}</span>
              </>
            }
          >
            <DropdownMenu options={dropdownOptions}></DropdownMenu>
          </NavItem>
        </div>
      </nav>
    </header>
  );
};

export function NavItem(props) {
  const [open, setOpen] = useState(false);

  const handleClickOutside = () => {
    setOpen(false);
  };

  return (
    <OutsideClickHandler onOutsideClick={handleClickOutside} display="contents">
      <li className="nav-item">
        <span className="icon-button" onClick={() => setOpen(!open)}>
          {props.icon}
        </span>

        {open && props.children}
      </li>
    </OutsideClickHandler>
  );
}

Header.propTypes = {
  user: PropTypes.object,
  totalNewRequests: PropTypes.number,
  toggleSearched: PropTypes.func,
  onBurgerClick: PropTypes.func,
};

export default Header;
