import { handleActions } from 'redux-actions';
import catalogActions from '../actions/actionTypes';

const initalState = {
  isFetchingMetadata: false,
  isFetching: false,
  isCreating: false,
};

const catalogReducer = handleActions(
  {
    [catalogActions.getCatalogMetadata.toString()]: (state, action) => {
      return {
        ...state,
        isFetchingMetadata: true,
      };
    },
    [catalogActions.getCatalogMetadataSuccess.toString()]: (state, action) => {
      return {
        ...state,
        isFetchingMetadata: false,
      };
    },
    [catalogActions.getCatalogMetadataFail.toString()]: (state, action) => {
      return {
        ...state,
        isFetchingMetadata: false,
        error: action.error,
      };
    },
  },
  initalState
);

export default catalogReducer;
