import { createActions } from 'redux-actions';

export default createActions({
  SET_SELECTED_PROJECT: undefined,
  SET_EDIT_MODE: undefined,
  SET_PANEL_PAGE: undefined,
  SET_TOTAL_PROJECTS: undefined,
  GET_PROJECTS: undefined,
  GET_PROJECTS_SUCCESS: undefined,
  GET_PROJECTS_FAIL: undefined,
  GET_PROJECT_TRACKS: undefined,
  GET_PROJECT_TRACKS_SUCCESS: undefined,
  GET_PROJECT_TRACKS_FAIL: undefined,
  QUICK_SEARCH_PROJECT: undefined,
  QUICK_SEARCH_PROJECT_SUCCESS: undefined,
  QUICK_SEARCH_PROJECT_FAIL: undefined,
  UPDATE_PROJECTS_NUMBER: undefined,
  CREATE_PROJECT: undefined,
  CREATE_PROJECT_SUCCESS: undefined,
  CREATE_PROJECT_FAIL: undefined,
  REGISTER_PROJECT: undefined,
  REGISTER_PROJECT_SUCCESS: undefined,
  REGISTER_PROJECT_FAIL: undefined,
  UPDATE_PROJECT: undefined,
  UPDATE_PROJECT_SUCCESS: undefined,
  UPDATE_PROJECT_FAIL: undefined,
  DELETE_PROJECT: undefined,
  DELETE_PROJECT_SUCCESS: undefined,
  DELETE_PROJECT_FAIL: undefined,
  SAVE_PROJECT_IMAGE: undefined,
  SAVE_PROJECT_IMAGE_SUCCESS: undefined,
  SAVE_PROJECT_IMAGE_FAIL: undefined,
  DELETE_PROJECT_IMAGE: undefined,
  DELETE_PROJECT_IMAGE_SUCCESS: undefined,
  DELETE_PROJECT_IMAGE_FAIL: undefined,
});
