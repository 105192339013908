import * as Sentry from '@sentry/react';

const errorReporter = (store) => (next) => (action) => {
  if (action.error && !action.error.isSafeError) {
    const state = store.getState();

    console.log('action.error', action);

    Sentry.withScope((scope) => {
      scope.setExtra('currentuserState', state.auth.user);
      scope.setExtra('actionType', action.type);

      Object.entries(action.payload).forEach(([key, value]) =>
        scope.setExtra(key, value)
      );

      scope.setTag('actionType', action.type);
      Sentry.captureException(action.payload);
    });
  }

  return next(action);
};

export default errorReporter;
