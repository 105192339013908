import { axios } from 'services/axios';
import { show_error } from 'utils/consts';

import actionTypes from './actionTypes';

const {
  searchAction,
  quickSearch,
  quickSearchFail,
  resetSearch,
  toggleSearch,
  newSearch,
  resetResults,
} = actionTypes;

export const searchString = (string) => {
  return (dispatch) => {
    axios
      .get(`quick_search/${string}`)
      .then((response) => {
        dispatch(searchAction({ results: response.data }));
      })
      .catch((err) => {
        return show_error({ message: err?.response?.data });
      });
  };
};

export const quick_search = (string) => {
  return async (dispatch, getState) => {
    try {
      const response = await axios.get(`quick_search/${string}`);

      if (!getState().search.fetching) {
        // Ugly hack
        // This fixed the issue where in the middle of fetching
        // from quick search the user has requested the detailed results search page
        return;
      }
      dispatch(quickSearch({ results: response.data }));
    } catch (err) {
      dispatch(quickSearchFail(err));

      return show_error({ message: err?.response?.data });
    }
  };
};

export const reset_search = () => {
  return resetSearch();
};

export const toggle_search = () => {
  return toggleSearch();
};

export const new_search = () => {
  return newSearch();
};

export const reset_results = () => {
  return resetResults();
};
