import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useHistory } from 'react-router-dom';

import {
  Typography,
  Form,
  Input,
  Button,
  notification,
  message,
  DatePicker,
  Select,
} from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import moment from 'moment';

import ImageUploader from 'components/FormElements/ImageUploader/ImageUploader';

import { hideAllPanes } from 'store/modules/panes/actions';
import { normFile } from 'utils';
import { Api } from 'utils/consts';

import { createPartner, savePartnerImage, updatePartner } from '../../actions';

const layout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
};

const formDefaultValues = {
  name: '',
  firstName: '',
  lastName: '',
  biography: '',
  reporting_period: 'semi-annual',
  debut: '',
  email: '',
  phone: '',
};

const { Title } = Typography;
const PartnerForm = ({
  editMode,
  partner,
  hideAllPanes,
  isCreating,
  isSavingImage,
  showHeading,
  createPartner,
  updatePartner,
  savePartnerImage,
}) => {
  const [form] = Form.useForm();
  const [imageConfig, setImageConfig] = useState({});
  const { push } = useHistory();

  const onFinish = async (values) => {
    const sanitizedValues = {
      ...values,
    };

    // logo config is empty when the user does not selects an image
    if (imageConfig.signedUrlSettings && imageConfig.signedUrlSettings.url) {
      sanitizedValues.imageUrl = imageConfig.signedUrlSettings.key;

      try {
        await savePartnerImage(
          imageConfig.signedUrlSettings.url,
          imageConfig.file
        );
      } catch (error) {
        message.error('Partner Image was not saved. Please contact support', 4);
      }
    }

    try {
      if (editMode) {
        await updatePartner({
          ...sanitizedValues,
          partnerId: partner.partnerId,
        });

        notification.success({
          message: `Partner List Updated`,
          description: 'The List of Partners was updated',
          duration: 4,
        });
      } else {
        await createPartner(sanitizedValues);

        notification.success({
          message: `Partner Created`,
          description: 'The List of Partners was updated',
          duration: 4,
        });
      }

      if (editMode) {
        push(`/partners/${partner.partnerId}`);
      } else {
        onClose();
      }
    } catch (error) {
      notification.warning({
        message: `Action Failed`,
        description: 'List of Partners was not updated',
        duration: 4,
      });
    }
  };

  const onClose = () => {
    hideAllPanes();
    cleanState();
  };

  const cleanState = () => {
    setImageConfig({});
    form.resetFields();
  };

  const prepareFormValues = useCallback(
    (partner) => {
      const fields = {
        firstName: partner.firstName,
        lastName: partner.lastName,
        name: partner.name,
        email: partner.email,
        ipi_cae: partner.ipi_cae,
        phone: partner.phone,
        biography: partner.biography,
        username: partner.username,
        reporting_period: partner.reporting_period || 'semi-annual',
        debut: partner.debut ? moment(partner.debut) : '',
      };

      form.setFieldsValue(fields);
    },
    [form]
  );

  useEffect(() => {
    if (editMode) {
      prepareFormValues(partner);
    } else {
      form.setFieldsValue(formDefaultValues);
    }
  }, [editMode, form, partner, prepareFormValues]);

  useEffect(() => {
    return () => {
      setImageConfig({});
    };
  }, []);

  return (
    <div className="vw-module edit-module content-module">
      {showHeading && (
        <div className="content-title">
          <CloseOutlined onClick={onClose} />

          <Title level={3}>Create New Rights Holder</Title>

          <span />
        </div>
      )}

      <div className="module-container">
        <Form
          {...layout}
          form={form}
          name="control-hooks-edit-partner"
          initialValues={{ ...formDefaultValues }}
          onFinish={onFinish}
          autoComplete="off"
        >
          <Form.Item className="image-field">
            <Form.Item
              name="partnerImage"
              valuePropName="partnerImage"
              getValueFromEvent={normFile}
              noStyle
            >
              <ImageUploader
                onImageSelect={setImageConfig}
                imageField="imageUrl"
                endpoint={`${Api}partner/image?id=${partner._id}`}
                section="partners"
                entity={partner}
              />
            </Form.Item>
          </Form.Item>
          <Form.Item
            name="firstName"
            label="First Name"
            rules={[{ required: true }]}
          >
            <Input placeholder="First Name" />
          </Form.Item>

          <Form.Item
            name="lastName"
            label="Last Name"
            rules={[{ required: true }]}
          >
            <Input placeholder="Last Name" />
          </Form.Item>

          <Form.Item name="username" label="Username">
            <Input placeholder="Username" />
          </Form.Item>

          <Form.Item name="reporting_period" label="Payment Period">
            <Select>
              <Select.Option value="semi-annual">Semi Annual</Select.Option>
              <Select.Option value="quarterly">Quarterly</Select.Option>
            </Select>
          </Form.Item>

          <Form.Item name="name" label="Alias">
            <Input placeholder="Partner Alias" />
          </Form.Item>

          <Form.Item
            name="email"
            label="Email"
            rules={[{ type: 'email', message: 'Invalid Email.' }]}
          >
            <Input placeholder="Email Address" />
          </Form.Item>

          <Form.Item name="ipi_cae" label="IPI/CAE">
            <Input placeholder="Partner IPI/CAE" />
          </Form.Item>

          <Form.Item name="debut" label="Debut Date">
            <DatePicker />
          </Form.Item>

          <Form.Item name="address" label="Address">
            <Input placeholder="Partner Address" />
          </Form.Item>

          <Form.Item name="phone" label="Phone Number">
            <Input style={{ width: '100%' }} placeholder="Phone Number" />
          </Form.Item>

          <Form.Item name="biography" label="Biography">
            <Input.TextArea rows={10} placeholder="Please enter biography" />
          </Form.Item>

          <Form.Item>
            <Button
              loading={isCreating || isSavingImage}
              type="primary"
              htmlType="submit"
              size="large"
              className="btn-primary"
            >
              {editMode ? 'Edit' : 'Create'}
            </Button>

            {!editMode && (
              <Button htmlType="button" onClick={onClose} size="large">
                Cancel
              </Button>
            )}
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

const mapStateToProps = ({ partners }) => {
  return {
    isCreating: partners.isCreating,
    isSavingImage: partners.isSavingImage,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      hideAllPanes,
      createPartner,
      savePartnerImage,
      updatePartner,
    },
    dispatch
  );

PartnerForm.defaultProps = {
  editMode: false,
  partner: {},
  hideAllPanes: () => {},
  isCreating: false,
  isSavingImage: false,
  showHeading: true,
  createPartner: () => {},
  updatePartner: () => {},
  savePartnerImage: () => {},
};

PartnerForm.propTypes = {
  editMode: PropTypes.bool,
  partner: PropTypes.object,
  hideAllPanes: PropTypes.func,
  isCreating: PropTypes.bool,
  showHeading: PropTypes.bool,
  isSavingImage: PropTypes.bool,
  createPartner: PropTypes.func,
  updatePartner: PropTypes.func,
  savePartnerImage: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(PartnerForm);
