import { axios } from 'services/axios';

import organizationActions from './actionTypes';

export const setOrganization = (organization) =>
  organizationActions.setOrganization({ organization });

export const setTotalOrganizations = (totalOrganizations) =>
  organizationActions.setOrganization({ totalOrganizations });

export const getOrganizations = (search = '', pagination) => {
  return async (dispatch) => {
    dispatch(organizationActions.getOrganizations());
    let defaultOptions = {
      current: 1,
      limit: 'full',
    };
    let queryOptions = pagination;

    if (!pagination) {
      queryOptions = defaultOptions;
    }

    try {
      const response = await axios.get('/organizations', {
        params: {
          page: queryOptions.current,
          pageSize: queryOptions.limit,
          search,
          order: queryOptions.order,
          field: queryOptions.field || '',
        },
      });

      dispatch(
        organizationActions.getOrganizationsSuccess({
          organizations: response.data.organizations,
          totalOrganizations: response.data.totalOrganizations,
        })
      );
    } catch (error) {
      dispatch(organizationActions.getOrganizationsFail(error));

      throw error;
    }
  };
};

export const quickSearchOrganization = (string, type) => {
  return async (dispatch, getState) => {
    dispatch(organizationActions.quickSearchOrganization());

    try {
      const response = await axios.get(
        `/organizations/quick_search?string=${string}`
      );

      dispatch(
        organizationActions.quickSearchOrganizationSuccess({
          organizations: response.data.organizations,
          totalOrganizations: response.data.totalOrganizations,
        })
      );
    } catch (err) {
      dispatch(organizationActions.quickSearchOrganizationFail(err));

      // return show_error({ message: err?.response?.data });
    }
  };
};

export const createOrganization = (fields) => {
  return async (dispatch) => {
    dispatch(organizationActions.createOrganization());
    try {
      const response = await axios.post('/organization/', fields);

      dispatch(
        organizationActions.createOrganizationSuccess({
          organization: response.data.organization,
        })
      );
    } catch (error) {
      dispatch(organizationActions.createOrganizationFail(error));

      throw error;
    }
  };
};

export const updateOrganization = (fields) => {
  return async (dispatch) => {
    dispatch(organizationActions.updateOrganization());
    try {
      const response = await axios.post('/organization/', fields);

      dispatch(
        organizationActions.updateOrganizationSuccess({
          organization: response.data.organization,
        })
      );
    } catch (error) {
      dispatch(organizationActions.updateOrganizationFail(error));

      throw error;
    }
  };
};

export const deleteOrganization = (id) => {
  return async (dispatch) => {
    dispatch(organizationActions.deleteOrganization());
    try {
      const response = await axios.delete('/organization/', { data: { id } });

      dispatch(
        organizationActions.deleteOrganizationSuccess({ id: response.data.id })
      );
    } catch (error) {
      dispatch(organizationActions.deleteOrganizationFail(error));

      throw error;
    }
  };
};
