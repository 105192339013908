import React from 'react';

import { CDN_URL } from '../../utils/consts';

const AppError = () => (
  <div id="page-not-found">
    <div id="page-not-found-bg"></div>
    <div id="page-not-found-inner">
      <div id="page-not-found-content">
        <img src={`${CDN_URL}/peter_brown.jpg`} alt="404" />
        <h1>Something bad happened and we've been notified</h1>
        <p>
          In the mean time, search for{' '}
          <a href="https://www.keepinspiring.me/quotes-about-happiness/">
            happiness
          </a>
        </p>
      </div>
    </div>
  </div>
);

export default AppError;
