import React from 'react';

import {
  VIEW_PROJECT,
  SPOTIFY_IMPORTER,
  VIEW_LABEL,
  EDIT_TRACKS,
  EDIT_TAG,
  EDIT_TAG_CATEGORY,
  ADD_PARTNER,
  EDIT_ORGANIZATION,
  EDIT_ARTICLE,
} from './paneTypes';

import ViewProject from 'containers/projects/components/ViewProject/ViewProject';
import SpotifyImporter from 'containers/../components/SpotifyImporter/SpotifyImporter';
import ViewLabel from 'containers/labels/components/ViewLabel/ViewLabel';
import EditTracksInformation from 'containers/tracks/components/EditTracksInformation/EditTracksInformation';
import EditTag from 'containers/tags/Tags/components/EditTag/EditTag';
import EditTagCategory from 'containers/tags/TagCategories/components/EditTagCategory/EditTagCategory';
import EditOrganization from 'containers/organizations/Organizations/components/EditOrganization/EditOrganization';
import PartnerForm from 'containers/partners/components/PartnerForm/PartnerForm';
import EditPress from 'containers/press/components/EditPress';

import routes from '../AppRoutes/routes';

const panes = [
  {
    title: 'View Project',
    component: (
      <ViewProject validRoutes={[routes.projects.path, routes.tracks.path]} />
    ),
    style: { minHeight: '100vh' },
    direction: 'w',
    openFlag: VIEW_PROJECT,
  },
  {
    title: 'View Label',
    component: (
      <ViewLabel validRoutes={[routes.labels.path, routes.tracks.path]} />
    ),
    style: { minHeight: '100vh' },
    direction: 'w',
    openFlag: VIEW_LABEL,
  },
  {
    title: 'Spotify Importer',
    component: <SpotifyImporter />,
    style: { minHeight: '100vh' },
    direction: 'w',
    openFlag: SPOTIFY_IMPORTER,
  },
  {
    title: 'Edit Tracks',
    component: <EditTracksInformation />,
    style: { minHeight: '100vh' },
    direction: 'w',
    openFlag: EDIT_TRACKS,
  },
  {
    title: 'Edit Tag',
    component: <EditTag />,
    style: { minHeight: '100vh' },
    direction: 'w',
    openFlag: EDIT_TAG,
  },
  {
    title: 'Edit Tag Category',
    component: <EditTagCategory />,
    style: { minHeight: '100vh' },
    direction: 'w',
    openFlag: EDIT_TAG_CATEGORY,
  },
  {
    title: 'Add Partner',
    component: <PartnerForm />,
    style: { minHeight: '100vh' },
    direction: 'w',
    openFlag: ADD_PARTNER,
  },

  {
    title: 'Edit Administrator',
    component: <EditOrganization />,
    style: { minHeight: '100vh' },
    direction: 'w',
    openFlag: EDIT_ORGANIZATION,
  },
  {
    title: 'Edit Article',
    component:<EditPress />,
    style: { minHeight: '100vh' },
    direction: 'w',
    openFlag: EDIT_ARTICLE,
  },
];

export default panes;
