import { createActions } from 'redux-actions';

export default createActions({
  GET_SONGS_FROM_SPOTIFY: undefined,
  GET_SONGS_FROM_SPOTIFY_SUCCESS: undefined,
  GET_SONGS_FROM_SPOTIFY_FAILED: undefined,
  STORE_SPOTIFY_SONGS: undefined,
  STORE_SPOTIFY_SONGS_SUCCESS: undefined,
  STORE_SPOTIFY_SONGS_FAILED: undefined,
  IMPORT_SONG_DATA_FROM_SPOTIFY: undefined,
  IMPORT_SONG_DATA_FROM_SPOTIFY_SUCCESS: undefined,
  IMPORT_SONG_DATA_FROM_SPOTIFY_FAILED: undefined,
  IMPORT_SONGS_DATA_FROM_SPOTIFY: undefined,
  IMPORT_SONGS_DATA_FROM_SPOTIFY_SUCCESS: undefined,
  IMPORT_SONGS_DATA_FROM_SPOTIFY_FAILED: undefined,
});
