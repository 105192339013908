import { handleActions } from 'redux-actions';
import actionTypes from '../actions/actionTypes';

const initialState = {
  user: {},
  message: null,
  loggedIn: false,
};

const authReducer = handleActions(
  {
    [actionTypes.authStart.toString()]: (state, action) => {
      return {
        ...state,
        loggedIn: false,
        isFetching: true,
        message: '',
      };
    },
    [actionTypes.authSuccess.toString()]: (state, action) => {
      return {
        ...state,
        user: action.payload,
        loggedIn: true,
        isFetching: false,
      };
    },
    [actionTypes.authFail.toString()]: (state, action) => {
      return {
        ...state,
        user: {},
        loggedIn: false,
        isFetching: false,
        error: true,
        message: action.payload,
      };
    },
    [actionTypes.logout.toString()]: (state, action) => {
      return {
        ...state,
        user: {},
        loggedIn: false,
        isFetching: false,
      };
    },
    [actionTypes.logoutFail.toString()]: (state, action) => {
      return {
        ...state,
        user: {},
        loggedIn: false,
        isFetching: false,
        error: action.error,
      };
    },
  },
  initialState
);

export default authReducer;
