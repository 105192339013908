import React from 'react';
import PropTypes from 'prop-types';

import './Input.scss';

/**
 * @desc custom designed input
 * @param string title - title for the label
 * @param string type - type of the input (default: text)
 * @param string name - name of the input (usually using the key of the object)
 * @param function onChange - method to call when the input value changes
 * @param string value - preset value of the input
 * @param string placeholder - placeholder of the input
 * @param boolean disabled - should the input be disabled (defaults to false)
 * @return dom element
 **/
const Input = ({
  title,
  type,
  name,
  onChange,
  value,
  placeholder,
  disabled,
  ...props
}) => (
  <div className="forms-input">
    {title && <label htmlFor={name}>{title}</label>}
    <input
      type={type}
      name={name}
      onChange={onChange}
      value={value || ''}
      disabled={disabled}
      placeholder={placeholder}
      {...props}
    />
  </div>
);

Input.propTypes = {
  title: PropTypes.string,
  type: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
};

Input.defaultProps = {
  title: '',
  type: 'text',
  value: '',
  disabled: false,
};

export default Input;
