import React from 'react';
import { Link } from 'react-router-dom';

import { CDN_URL } from '../../utils/consts';

const GenericNotFound = () => (
  <div id="page-not-found">
    <div id="page-not-found-bg"></div>
    <div id="page-not-found-inner">
      <div id="page-not-found-content">
        <img src={`${CDN_URL}/peter_brown.jpg`} alt="404" />
        <h1>
          Young man, <br></br> you've hit a dead end.
        </h1>
        <Link to="/tracks">Back to the catalog</Link>
      </div>
    </div>
  </div>
);

export default GenericNotFound;
