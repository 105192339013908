import { createActions } from 'redux-actions';

export default createActions({
  GET_CODES: undefined,
  GET_CODES_SUCCESS: undefined,
  GET_LATEST_CODES_SUCCESS: undefined,
  GET_CODES_FAILED: undefined,
  GET_CODES_METADATA: undefined,
  GET_CODES_METADATA_SUCCESS: undefined,
  GET_CODES_METADATA_FAILED: undefined,
  GET_CODES_LOG: undefined,
  GET_CODES_LOG_SUCCESS: undefined,
  GET_CODES_LOG_FAILED: undefined,
  QUICK_SEARCH_CODE: undefined,
  QUICK_SEARCH_CODE_SUCCESS: undefined,
  QUICK_SEARCH_CODE_FAILED: undefined,
  GET_CODE_METADATA: undefined,
  GET_CODE_METADATA_SUCCESS: undefined,
  GET_CODE_METADATA_FAILED: undefined,
  SAVE_CODE_METADATA: undefined,
  SAVE_CODE_METADATA_SUCCESS: undefined,
  SAVE_CODE_METADATA_FAILED: undefined,
  UPDATE_CODE_METADATA: undefined,
  UPDATE_CODE_METADATA_SUCCESS: undefined,
  UPDATE_CODE_METADATA_FAILED: undefined,
  DELETE_CODE_METADATA: undefined,
  DELETE_CODE_METADATA_SUCCESS: undefined,
  DELETE_CODE_METADATA_FAILED: undefined,
});
