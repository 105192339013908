import panesActions from './actionTypes';

export const showPane = (paneName) => {
  return panesActions.showPane({ paneName });
};

export const hidePane = (paneName) => {
  return panesActions.hidePane({ paneName });
};

export const hideAllPanes = () => {
  return panesActions.hideAllPanes();
};
