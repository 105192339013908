import { handleActions } from 'redux-actions';
import ingestionActions from '../actions/actionTypes';

const initialState = {
  codes: [],
  codesMetadata: [],
  codeMetadata: {},
  latestCodes: [],
  codesLog: [],
  isFetching: false,
  isFetchingLogs: false,
  totalCodes: 0,
};

const ingestionReducer = handleActions(
  {
    [ingestionActions.getCodes.toString()]: (state, action) => {
      return {
        ...state,
        isFetching: true,
      };
    },
    [ingestionActions.getCodesSuccess.toString()]: (state, action) => {
      return {
        ...state,
        codes: action.payload.codes,
        totalCodes: action.payload.totalCodes,
        isFetching: false,
      };
    },
    [ingestionActions.getLatestCodesSuccess.toString()]: (state, action) => {
      return {
        ...state,
        latestCodes: action.payload.latestCodes,
        isFetching: false,
      };
    },
    [ingestionActions.getCodesFailed.toString()]: (state, action) => {
      return {
        ...state,
        isFetching: false,
      };
    },
    [ingestionActions.quickSearchCode.toString()]: (state, action) => {
      return {
        ...state,
        isFetching: true,
      };
    },
    [ingestionActions.quickSearchCodeSuccess.toString()]: (state, action) => {
      return {
        ...state,
        isFetching: false,
        codes: action.payload.codes,
        totalCodes: action.payload.totalCodes,
      };
    },
    [ingestionActions.quickSearchCodeFailed.toString()]: (state, action) => {
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };
    },
    [ingestionActions.getCodesMetadata.toString()]: (state, action) => {
      return {
        ...state,
        isFetching: true,
      };
    },
    [ingestionActions.getCodesMetadataSuccess.toString()]: (state, action) => {
      return {
        ...state,
        codesMetadata: action.payload.codesMetadata,
        isFetching: false,
      };
    },
    [ingestionActions.getCodesMetadataFailed.toString()]: (state, action) => {
      return {
        ...state,
        isFetching: false,
      };
    },
    [ingestionActions.getCodesLog.toString()]: (state, action) => {
      return {
        ...state,
        isFetchingLogs: true,
      };
    },
    [ingestionActions.getCodesLogSuccess.toString()]: (state, action) => {
      return {
        ...state,
        codesLog: action.payload.codesLog,
        isFetchingLogs: false,
      };
    },
    [ingestionActions.getCodesLogFailed.toString()]: (state, action) => {
      return {
        ...state,
        isFetchingLogs: false,
      };
    },
    [ingestionActions.getCodeMetadata.toString()]: (state, action) => {
      return {
        ...state,
        isFetching: true,
      };
    },
    [ingestionActions.getCodeMetadataSuccess.toString()]: (state, action) => {
      return {
        ...state,
        codeMetadata: action.payload.codeMetadata,
        isFetching: false,
      };
    },
    [ingestionActions.getCodeMetadataFailed.toString()]: (state, action) => {
      return {
        ...state,
        isFetching: false,
      };
    },
    [ingestionActions.saveCodeMetadata.toString()]: (state, action) => {
      return {
        ...state,
        isSaving: true,
      };
    },
    [ingestionActions.saveCodeMetadataSuccess.toString()]: (state, action) => {
      const { codeMetadata } = action.payload;

      return {
        ...state,
        codesMetadata: [...state.codesMetadata, codeMetadata],
        isSaving: false,
      };
    },
    [ingestionActions.saveCodeMetadataFailed.toString()]: (state, action) => {
      return {
        ...state,
        isSaving: false,
      };
    },
    [ingestionActions.updateCodeMetadata.toString()]: (state, action) => {
      return {
        ...state,
        isUpdating: true,
      };
    },
    [ingestionActions.updateCodeMetadataSuccess.toString()]: (
      state,
      action
    ) => {
      const { codeMetadata } = action.payload;
      let codeIndex;

      const code = state.codesMetadata.find((code, index) => {
        codeIndex = index;
        return code.code === codeMetadata.code;
      });

      const updatedCode = {
        ...code,
        ...codeMetadata,
      };

      const updatedCodes = [...state.codesMetadata];

      updatedCodes[codeIndex] = updatedCode;

      return {
        ...state,
        codesMetadata: updatedCodes,
        isUpdating: false,
      };
    },
    [ingestionActions.updateCodeMetadataFailed.toString()]: (state, action) => {
      return {
        ...state,
        isUpdating: false,
      };
    },
    [ingestionActions.deleteCodeMetadata.toString()]: (state, action) => {
      return {
        ...state,
        isDeleting: true,
      };
    },
    [ingestionActions.deleteCodeMetadataSuccess.toString()]: (
      state,
      action
    ) => {
      const updatedCodesMetadataArray = state.codesMetadata.filter(
        (metadata) => metadata.code !== action.payload.code
      );

      return {
        ...state,
        codesMetadata: updatedCodesMetadataArray,
        isDeleting: false,
      };
    },
    [ingestionActions.deleteCodeMetadataFailed.toString()]: (state, action) => {
      return {
        ...state,
        isDeleting: false,
      };
    },
  },
  initialState
);

export default ingestionReducer;
