import { createActions } from 'redux-actions';

export default createActions({
  SET_ARTICLE: undefined,
  SET_TOTAL_ARTICLES: undefined,
  GET_ARTICLES: undefined,
  GET_ARTICLES_SUCCESS: undefined,
  GET_ARTICLES_FAIL: undefined,
  QUICK_SEARCH_ARTICLE: undefined,
  QUICK_SEARCH_ARTICLE_SUCCESS: undefined,
  QUICK_SEARCH_ARTICLE_FAIL: undefined,
  CREATE_ARTICLE: undefined,
  CREATE_ARTICLE_SUCCESS: undefined,
  CREATE_ARTICLE_FAIL: undefined,
  UPDATE_ARTICLE: undefined,
  UPDATE_ARTICLE_SUCCESS: undefined,
  UPDATE_ARTICLE_FAIL: undefined,
  DELETE_ARTICLE: undefined,
  DELETE_ARTICLE_SUCCESS: undefined,
  DELETE_ARTICLE_FAIL: undefined,
});
