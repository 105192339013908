import { handleActions } from 'redux-actions';
import spotifyActions from '../actions/actionTypes';

const initialState = {
  track: {},
  tracks: [],
  isCreating: false,
  isFetching: false,
  isFetchingSingle: false,
  isFetchingMultiple: false,
};

const spotifyReducer = handleActions(
  {
    [spotifyActions.getSongsFromSpotify.toString()]: (state, action) => {
      return {
        ...state,
        isFetching: true,
      };
    },
    [spotifyActions.getSongsFromSpotifySuccess.toString()]: (state, action) => {
      return {
        ...state,
        tracks: action.payload,
        isFetching: false,
      };
    },
    [spotifyActions.getSongsFromSpotifyFailed.toString()]: (state, action) => {
      return {
        ...state,
        error: action.error,
        isFetching: false,
      };
    },
    [spotifyActions.storeSpotifySongs.toString()]: (state, action) => {
      return {
        ...state,
        isCreating: true,
      };
    },
    [spotifyActions.storeSpotifySongsSuccess.toString()]: (state, action) => {
      return {
        ...state,
        isCreating: false,
      };
    },
    [spotifyActions.storeSpotifySongsFailed.toString()]: (state, action) => {
      return {
        ...state,
        error: action.error,
        isCreating: false,
      };
    },
    [spotifyActions.importSongDataFromSpotify.toString()]: (state, action) => {
      return {
        ...state,
        isFetchingSingle: true,
      };
    },
    [spotifyActions.importSongDataFromSpotifySuccess.toString()]: (
      state,
      action
    ) => {
      return {
        ...state,
        isFetchingSingle: false,
      };
    },
    [spotifyActions.importSongDataFromSpotifyFailed.toString()]: (
      state,
      action
    ) => {
      return {
        ...state,
        error: action.error,
        isFetchingSingle: false,
      };
    },
    [spotifyActions.importSongsDataFromSpotify.toString()]: (state, action) => {
      return {
        ...state,
        isFetchingMultiple: true,
      };
    },
    [spotifyActions.importSongsDataFromSpotifySuccess.toString()]: (
      state,
      action
    ) => {
      return {
        ...state,
        isFetchingMultiple: false,
      };
    },
    [spotifyActions.importSongsDataFromSpotifyFailed.toString()]: (
      state,
      action
    ) => {
      return {
        ...state,
        error: action.error,
        isFetchingMultiple: false,
      };
    },
  },
  initialState
);

export default spotifyReducer;
