import { axios } from 'services/axios';

import articleActions from './actionTypes';

export const setArticle = (article) =>
  articleActions.setArticle({ article });

export const setTotalArticles = (totalArticles) =>
  articleActions.setArticle({ totalArticles });

export const getArticles = (search = '', pagination) => {
  return async (dispatch) => {
    dispatch(articleActions.getArticles());
    let defaultOptions = {
      current: 1,
      limit: 'full',
    };
    let queryOptions = pagination;

    if (!pagination) {
      queryOptions = defaultOptions;
    }

    try {
      const response = await axios.get('/posts', {
        params: {
          page: queryOptions.current,
          pageSize: queryOptions.limit,
          search,
          order: queryOptions.order,
          field: queryOptions.field || '',
        },
      });

      dispatch(
        articleActions.getArticlesSuccess({
          articles: response.data.posts,
          totalArticles: response.data.totalPosts,
        })
      );
    } catch (error) {
      dispatch(articleActions.getArticlesFail(error));

      throw error;
    }
  };
};

export const quickSearchArticle = (string, type) => {
  return async (dispatch, getState) => {
    dispatch(articleActions.quickSearchArticle());

    try {
      const response = await axios.get(
        `/articles/quick_search?string=${string}`
      );

      dispatch(
        articleActions.quickSearchArticleSuccess({
          articles: response.data.articles,
          totalArticles: response.data.totalArticles,
        })
      );
    } catch (err) {
      dispatch(articleActions.quickSearchArticleFail(err));

      // return show_error({ message: err?.response?.data });
    }
  };
};

export const createArticle = (fields) => {
  return async (dispatch) => {
    dispatch(articleActions.createArticle());
    try {
      const response = await axios.post('/posts/', fields);

      dispatch(
        articleActions.createArticleSuccess({
          article: response.data.post,
        })
      );
    } catch (error) {
      dispatch(articleActions.createArticleFail(error));

      throw error;
    }
  };
};

export const updateArticle = (fields) => {
  return async (dispatch) => {
    dispatch(articleActions.updateArticle());
    try {
      const response = await axios.post('/posts/', fields);

      dispatch(
        articleActions.updateArticleSuccess({
          article: response.data.post,
        })
      );
    } catch (error) {
      dispatch(articleActions.updateArticleFail(error));

      throw error;
    }
  };
};

export const deleteArticle = (id) => {
  return async (dispatch) => {
    dispatch(articleActions.deleteArticle());
    try {
      const response = await axios.delete('/posts/', { data: { id } });

      dispatch(
        articleActions.deleteArticleSuccess({ id: response.data.id })
      );
    } catch (error) {
      dispatch(articleActions.deleteArticleFail(error));

      throw error;
    }
  };
};
