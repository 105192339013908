import React from 'react';
import './TextArea.scss';

/**
 * @desc custom designed textarea
 * @param string title - title used for the label
 * @param string name - name used for input (usually using the key of the object)
 * @param function onChange - method to call when the textarea value changes
 * @param string value - the preset / default value of the textarea
 * @param string placeholder - placeholder of the input
 * @return dom element
 **/
const TextArea = ({ title, name, onChange, value, placeholder = '' }) => (
  <div className="forms-input">
    <label htmlFor={name}>{title}</label>
    <textarea
      name={name}
      rows="5"
      cols="50"
      onChange={onChange}
      placeholder={placeholder}
      value={value}
    ></textarea>
  </div>
);

export default TextArea;
