import React from 'react';
import Proptypes from 'prop-types';
import { Menu } from 'antd';
import {
  PlusOutlined,
  ReloadOutlined,
  CloseOutlined,
  EditOutlined,
} from '@ant-design/icons';

const ModuleDropdown = ({
  onEditItem,
  onDeleteItem,
  onCreateItem,
  onRefresh,
  module = '',
  type = 'multiple',
}) => {
  if (type === 'multiple') {
    return (
      <Menu className="options-list context-menu project-menu">
        <Menu.Item onClick={onCreateItem}>
          <PlusOutlined />
          <span className="project-item">New {module}</span>
        </Menu.Item>
        <Menu.Item onClick={onRefresh}>
          <ReloadOutlined />
          <span className="project-item">Refresh</span>
        </Menu.Item>
      </Menu>
    );
  }

  return (
    <Menu className="options-list context-menu project-menu">
      <Menu.Item onClick={onCreateItem}>
        <PlusOutlined />
        <span className="project-item">New {module}</span>
      </Menu.Item>
      <Menu.Item onClick={onEditItem}>
        <EditOutlined />
        <span className="project-item">Edit</span>
      </Menu.Item>
      <Menu.Item onClick={onDeleteItem}>
        <CloseOutlined />
        <span className="project-item">Delete</span>
      </Menu.Item>
    </Menu>
  );
};

ModuleDropdown.propTypes = {
  onCreateItem: Proptypes.func,
  onDeleteItem: Proptypes.func,
  onEditItem: Proptypes.func,
  onRefresh: Proptypes.func,
  type: Proptypes.string,
};

export default ModuleDropdown;
