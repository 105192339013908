import * as externalAxios from 'axios';
import { axios } from 'services/axios';
import { show_error } from 'utils/consts';
import { handleServerError } from 'utils';

import labelsActions from './actionTypes';

export const getLabels = (search = '', pagination) => {
  return async (dispatch) => {
    dispatch(labelsActions.getLabels());
    let defaultOptions = {
      current: 1,
      pageSize: 'full',
    };
    let queryOptions = pagination;

    if (!pagination) {
      queryOptions = defaultOptions;
    }

    try {
      const response = await axios.get(`labels`, {
        params: {
          page: queryOptions.current,
          limit: queryOptions.limit,
          search,
          order: queryOptions.order,
          field: queryOptions.field || '',
        },
      });
      dispatch(
        labelsActions.getLabelsSuccess({
          labels: response.data.labels,
          totalLabels: response.data.totalLabels,
          unregisteredLabels: response.data.unregisteredLabels,
          totalUnregisteredLabels: response.data.totalUnregisteredLabels,
        })
      );
    } catch (error) {
      dispatch(labelsActions.getLabelsFail(error));

      throw error;
    }
  };
};

export const getLabelTracks = (labelName = '', filterOptions = {}) => {
  return async (dispatch) => {
    dispatch(labelsActions.getLabelTracks());

    try {
      const response = await axios.get(`labels/songs`, {
        params: {
          name: labelName,
          limit: filterOptions.limit || 10,
          page: filterOptions.page || 1,
        },
      });
      dispatch(
        labelsActions.getLabelTracksSuccess({
          tracks: response.data.labelTracks,
          totalLabelTracks: response.data.totalLabelSongs,
        })
      );
    } catch (error) {
      dispatch(labelsActions.getLabelTracksFail(error));

      throw error;
    }
  };
};

export const quick_search = (string) => {
  return async (dispatch, getState) => {
    dispatch(labelsActions.quickSearchLabel());

    try {
      const response = await axios.get(`/labels/quick_search?string=${string}`);

      dispatch(
        labelsActions.quickSearchLabelSuccess({
          labels: response.data.labels,
          totalLabels: response.data.totalLabels,
        })
      );
    } catch (err) {
      dispatch(labelsActions.quickSearchLabelFail(err));

      // return show_error({ message: err?.response?.data });
    }
  };
};

export const createLabel = (fields) => {
  return async (dispatch) => {
    dispatch(labelsActions.createLabel());

    try {
      const response = await axios.post(`/labels`, fields);

      dispatch(
        labelsActions.createLabelSuccess({ label: response.data.label })
      );
    } catch (error) {
      dispatch(labelsActions.createLabelFail(error));

      const errorMessage = handleServerError(error.response.data);

      show_error({ message: errorMessage });

      throw error;
    }
  };
};

export const updateLabel = (id, fields) => {
  return async (dispatch) => {
    dispatch(labelsActions.updateLabel());

    try {
      const response = await axios.patch(`/labels`, { ...fields, labelId: id });

      dispatch(
        labelsActions.updateLabelSuccess({ label: response.data.label })
      );
    } catch (error) {
      dispatch(labelsActions.updateLabelFail(error));

      const errorMessage = handleServerError(error.response.data);

      show_error({ message: errorMessage });

      throw error;
    }
  };
};

export const deleteLabel = (labelId) => {
  return async (dispatch) => {
    dispatch(labelsActions.deleteLabel());

    try {
      const response = await axios.delete(`/labels/?labelId=${labelId}`);

      dispatch(
        labelsActions.deleteLabelSuccess({
          deletedLabel: response.data.deletedLabel,
        })
      );
    } catch (error) {
      dispatch(labelsActions.deleteLabelFail(error));
      throw error;
    }
  };
};

export const registerLabel = (name) => {
  return async (dispatch) => {
    dispatch(labelsActions.registerLabel());
    try {
      const response = await axios.post(`labels/register`, { name });

      dispatch(
        labelsActions.registerLabelSuccess({ label: response.data.label })
      );
    } catch (error) {
      dispatch(labelsActions.registerLabelFail(error));

      throw error;
    }
  };
};

export const saveLabelLogo = (signedUrl, file) => {
  return async (dispatch) => {
    dispatch(labelsActions.saveLabelLogo());
    try {
      await externalAxios.put(signedUrl, file, {
        headers: {
          'x-amz-acl': 'public-read',
          'Content-Type': file.type,
        },
      });

      dispatch(labelsActions.saveLabelLogoSuccess());
    } catch (error) {
      dispatch(labelsActions.saveLabelLogoFail(error));

      throw error;
    }
  };
};

export const deleteLabelLogo = (image) => {
  return async (dispatch) => {
    dispatch(labelsActions.deleteLabelLogo());
    try {
      await axios.post('/labels/image/delete', { image });

      dispatch(labelsActions.deleteLabelLogoSuccess());
    } catch (error) {
      dispatch(labelsActions.deleteLabelLogoFail(error));

      throw error;
    }
  };
};

export const setSelectedLabel = (label) =>
  labelsActions.setSelectedLabel({ label });

export const setEditMode = (isEditMode) =>
  labelsActions.setEditMode({ isEditMode });

export const setPanelPage = (page) => labelsActions.setPanelPage({ page });

export const setTotalLabels = (totalLabels) =>
  labelsActions.setTotalLabels({ totalLabels });
