import * as externalAxios from 'axios';
import { axios } from 'services/axios';
import { handleServerError } from 'utils';
import { show_error } from 'utils/consts';

import projectsActions from './actionTypes';

export const getProjects = (search = '', pagination, showSingles = true) => {
  return async (dispatch) => {
    dispatch(projectsActions.getProjects());
    let defaultOptions = {
      current: 1,
      pageSize: 'full',
    };
    let queryOptions = pagination;

    if (!pagination) {
      queryOptions = defaultOptions;
    }

    try {
      const response = await axios.get('/albums', {
        params: {
          page: queryOptions.current,
          limit: queryOptions.limit,
          search,
          order: queryOptions.order,
          field: queryOptions.field || '',
          singles: showSingles,
        },
      });

      const {
        albums,
        totalAlbums,
        unregisteredAlbums,
        totalUnregisteredAlbums,
      } = response.data;

      dispatch(
        projectsActions.getProjectsSuccess({
          albums,
          totalProjects: totalAlbums,
          unregisteredProjects: unregisteredAlbums,
          totalUnregisteredProjects: totalUnregisteredAlbums,
        })
      );
    } catch (error) {
      dispatch(projectsActions.getProjectsFail(error));

      throw error;
    }
  };
};

export const quick_search = (string) => {
  return async (dispatch, getState) => {
    dispatch(projectsActions.quickSearchProject());

    try {
      const response = await axios.get(`/albums/quick_search?string=${string}`);

      dispatch(
        projectsActions.quickSearchProjectSuccess({
          projects: response.data.albums,
          totalProjects: response.data.totalAlbums,
        })
      );
    } catch (error) {
      dispatch(projectsActions.quickSearchProjectFail(error));

      // return show_error({ message: err?.response?.data });
    }
  };
};

export const getProjectTracks = (project, filterOptions = {}) => {
  return async (dispatch) => {
    dispatch(projectsActions.getProjectTracks());

    try {
      const response = await axios.get('/album/songs', {
        params: {
          project_title: project,
          page: filterOptions.page,
          limit: filterOptions.limit,
        },
      });

      dispatch(
        projectsActions.getProjectTracksSuccess({
          tracks: response.data.songs,
          totalProjectTracks: response.data.totalAlbumSongs,
        })
      );
    } catch (error) {
      dispatch(projectsActions.getProjectTracksFail(error));

      throw error;
    }
  };
};

export const updateProjectsNumber = (totalProjects) =>
  projectsActions.updateProjectsNumber(totalProjects);

export const createProject = (project) => {
  return async (dispatch, getState) => {
    const author = getState().auth.user.username;
    dispatch(projectsActions.createProject());

    try {
      const response = await axios.post('/album/', { ...project, author });

      dispatch(
        projectsActions.createProjectSuccess({ project: response.data.album })
      );
    } catch (error) {
      dispatch(projectsActions.createProjectFail(error));

      const errorMessage = handleServerError(error.response.data);

      show_error({ message: errorMessage });

      throw error;
    }
  };
};

export const registerProject = (project) => {
  return async (dispatch, getState) => {
    const author = getState().auth.user.username;
    dispatch(projectsActions.registerProject());

    try {
      const response = await axios.post('/album/', { ...project, author });

      dispatch(
        projectsActions.registerProjectSuccess({ project: response.data.album })
      );
    } catch (error) {
      dispatch(projectsActions.registerProjectFail(error));

      const errorMessage = handleServerError(error.response.data);

      show_error({ message: errorMessage });

      throw error;
    }
  };
};

export const updateProject = (projectId, projectFields) => {
  return async (dispatch) => {
    dispatch(projectsActions.updateProject());

    try {
      const response = await axios.patch('/album/', {
        ...projectFields,
        albumId: projectId,
      });

      dispatch(
        projectsActions.updateProjectSuccess({ project: response.data.album })
      );
    } catch (error) {
      dispatch(projectsActions.updateProjectFail(error));

      const errorMessage = handleServerError(error.response.data);

      show_error({ message: errorMessage });

      throw error;
    }
  };
};

export const deleteProject = (projectId) => {
  return async (dispatch) => {
    dispatch(projectsActions.deleteProject());

    try {
      const response = await axios.delete(`/album/?albumId=${projectId}`);

      dispatch(
        projectsActions.deleteProjectSuccess({
          deletedProject: response.data.deletedAlbum,
        })
      );
    } catch (error) {
      dispatch(projectsActions.deleteProjectFail(error));

      throw error;
    }
  };
};

export const saveProjectImage = (signedUrl, file) => {
  return async (dispatch) => {
    dispatch(projectsActions.saveProjectImage());
    try {
      await externalAxios.put(signedUrl, file, {
        headers: {
          'x-amz-acl': 'public-read',
          'Content-Type': file.type,
        },
      });

      dispatch(projectsActions.saveProjectImageSuccess());
    } catch (error) {
      dispatch(projectsActions.saveProjectImageFail(error));

      throw error;
    }
  };
};

export const deleteProjectImage = (image) => {
  return async (dispatch) => {
    dispatch(projectsActions.deleteProjectImage());
    try {
      await axios.post('/album/image/delete', { image });

      dispatch(projectsActions.deleteProjectImageSuccess());
    } catch (error) {
      dispatch(projectsActions.deleteProjectImageFail(error));

      throw error;
    }
  };
};

export const setSelectedProject = (project) =>
  projectsActions.setSelectedProject({ project });

export const setEditMode = (isEditMode) =>
  projectsActions.setEditMode({ isEditMode });

export const setPanelPage = (page) => projectsActions.setPanelPage({ page });
