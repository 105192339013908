import React from 'react';
import { Upload, Button } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import { getBase64 } from '../../../utils';
import './FileUploader.scss';

class FileUploader extends React.Component {
  state = {
    fileList: [],
    uploading: false,
    file: {},
  };

  onChangeHandler = (info) => {
    if (info?.file?.status === 'removed') {
      return;
    }

    getBase64(info.file, (b64) => {
      if (b64) {
        const fileObj = {
          file: b64,
        };

        this.props.onChange(fileObj.file.split('base64,')[1]);
        this.setState({ file: fileObj });
      }
    });
  };

  render() {
    const {
      // uploading,
      fileList,
    } = this.state;
    const props = {
      onRemove: (file) => {
        this.setState((state) => {
          const index = state.fileList.indexOf(file);
          const newFileList = state.fileList.slice();
          newFileList.splice(index, 1);
          return {
            fileList: newFileList,
          };
        });
      },
      beforeUpload: (file) => {
        this.setState({ fileList: [file] });
        return false;
      },
      fileList,
    };

    const {
      fileExtensions = '.pdf',
      buttonText = 'Click or drag a .pdf file to this area to upload',
    } = this.props;

    return (
      <>
        <Upload
          {...props}
          onChange={this.onChangeHandler}
          accept={fileExtensions}
          disabled={this.props.loading}
          className={this.props.full ? 'full-size-upload' : ''}
          showUploadList={
            this.props.showUploadList === 'undefined'
              ? true
              : this.props.showUploadList
          }
        >
          <Button
            loading={this.props.loading}
            style={this.props.full ? { width: '100%', height: 200 } : {}}
            icon={<UploadOutlined />}
          >
            {buttonText}
          </Button>
        </Upload>
      </>
    );
  }
}

FileUploader.propTypes = {
  onChange: PropTypes.func,
};

FileUploader.defaultProps = {
  onChange: () => {},
};

export default FileUploader;
