import React, { useCallback, useEffect } from 'react';
import Proptypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { Typography, Form, Input, Button, notification } from 'antd';
import { CloseOutlined } from '@ant-design/icons';

import { hideAllPanes } from 'store/modules/panes/actions';

import { updateOrganization, createOrganization } from '../../../actions';

const layout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
};

const formDefaultValues = {
  name: '',
};

const { Title } = Typography;

const EditOrganization = ({
  selectedOrganization: organization,
  hideAllPanes,
  isUpdating,
  createOrganization,
  updateOrganization,
}) => {
  const [form] = Form.useForm();

  const createMode = organization.new;

  const onFinish = async (values) => {
    const sanitizedValues = {
      ...values,
    };

    try {
      if (createMode) {
        await createOrganization(sanitizedValues);
      } else {
        sanitizedValues.id = organization.id;

        await updateOrganization(sanitizedValues);
      }

      notification.success({
        message: `Administrator Updated`,
        description: 'List of administrators was updated',
        duration: 4,
      });
      onClose();
    } catch (error) {
      notification.warning({
        message: `Administrator update Failed`,
        description:
          'Information was not updated. Please try again, if the problem persist please contact support',
        duration: 4,
      });
    }
  };

  const onClose = () => {
    hideAllPanes();
    cleanState();
  };

  const cleanState = () => {
    form.resetFields();
  };

  // FORM
  const prepareFormValues = useCallback(
    (organization) => {
      const fields = {
        name: organization.name || '',
      };

      form.setFieldsValue(fields);
    },
    [form]
  );

  useEffect(() => {
    prepareFormValues(organization);
  }, [prepareFormValues, organization, form]);

  return (
    <div className="vw-module edit-module content-module">
      <div className="content-title view-module-title">
        <CloseOutlined onClick={onClose} />

        <Title level={3}>{createMode ? 'Create' : 'Edit'} Administrator</Title>

        <span />
      </div>

      <div className="module-container">
        <Form
          {...layout}
          form={form}
          name="control-hooks-edit-organization"
          initialValues={{ ...formDefaultValues }}
          onFinish={onFinish}
          autoComplete="off"
        >
          <Form.Item name="name" label="Name" rules={[{ required: true }]}>
            <Input placeholder="Administrator Name" />
          </Form.Item>

          <Form.Item>
            <Button
              loading={isUpdating}
              type="primary"
              htmlType="submit"
              className="btn-primary"
            >
              {createMode ? 'Create' : 'Edit'}
            </Button>

            <Button htmlType="button" onClick={onClose}>
              Cancel
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

const mapStateToProps = ({ organizations }) => {
  return {
    selectedOrganization: organizations.selectedOrganization,
    isUpdating: organizations.isUpdating,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      hideAllPanes,
      createOrganization,
      updateOrganization,
    },
    dispatch
  );

EditOrganization.defaultProps = {
  selectedOrganization: {},
  hideAllPanes: () => {},
  isUpdating: false,
  createOrganization: () => {},
  updateOrganization: () => {},
};

EditOrganization.propTypes = {
  selectedOrganization: Proptypes.object,
  hideAllPanes: Proptypes.func,
  isUpdating: Proptypes.bool,
  createOrganization: Proptypes.func,
  updateOrganization: Proptypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(EditOrganization);
