import { axios } from 'services/axios';
import * as externalAxios from 'axios';
import { handleServerError } from 'utils';
import { show_error } from 'utils/consts';

import partnersActions from './actionTypes';

export const setPartner = (partner) => partnersActions.setPartner({ partner });

export const setTotalPartners = (totalPartners) =>
  partnersActions.setTotalPartners({ totalPartners });

export const getPartners = (search = '', pagination) => {
  return async (dispatch) => {
    dispatch(partnersActions.getPartners());

    let defaultOptions = {
      current: 1,
      limit: 'full',
    };
    let queryOptions = pagination;

    if (!pagination) {
      queryOptions = defaultOptions;
    }

    try {
      const response = await axios.get(`/partners`, {
        params: {
          page: queryOptions.current,
          pageSize: queryOptions.limit,
          search,
          order: queryOptions.order,
          field: queryOptions.field || '',
        },
      });

      dispatch(
        partnersActions.getPartnersSuccess({
          partners: response.data.partners,
          totalPartners: response.data.totalPartners,
        })
      );
    } catch (error) {
      dispatch(partnersActions.getPartnersFail(error));

      throw error;
    }
  };
};

export const quickSearchPartner = (string, type) => {
  return async (dispatch, getState) => {
    dispatch(partnersActions.quickSearchPartner());

    try {
      const response = await axios.get(
        `/partners/quick_search?string=${string}`
      );

      dispatch(
        partnersActions.quickSearchPartnerSuccess({
          partners: response.data.partners,
          totalPartners: response.data.totalPartners,
        })
      );
    } catch (err) {
      dispatch(partnersActions.quickSearchPartnerFail(err));

      // return show_error({ message: err?.response?.data });
    }
  };
};

export const getPartner = (partnerId) => {
  return async (dispatch) => {
    dispatch(partnersActions.getPartner());

    try {
      const response = await axios.get(`/partner/${partnerId}`);

      dispatch(
        partnersActions.getPartnerSuccess({ partner: response.data.partner })
      );
    } catch (error) {
      dispatch(partnersActions.getPartnerFail(error));

      throw error;
    }
  };
};

export const getPartnerData = (id, partnerId) => {
  return async (dispatch) => {
    dispatch(partnersActions.getPartnerData());

    try {
      const response = await axios.get(
        `/partner/songs?id=${id}&partnerId=${partnerId}`
      );

      dispatch(
        partnersActions.getPartnerDataSuccess({
          relatedSongs: response.data.relatedSongs,
        })
      );
    } catch (error) {
      dispatch(partnersActions.getPartnerDataFail(error));

      throw error;
    }
  };
};

export const cleanPartnerData = () => partnersActions.cleanPartnerData();

export const createPartner = (fields) => {
  return async (dispatch) => {
    dispatch(partnersActions.createPartner());

    try {
      const response = await axios.post('/partner', fields);

      dispatch(
        partnersActions.createPartnerSuccess({ partner: response.data.partner })
      );
    } catch (error) {
      dispatch(partnersActions.createPartnerFail(error));

      const errorMessage = handleServerError(error.response.data);

      show_error({ message: errorMessage });

      throw error;
    }
  };
};

export const updatePartner = (fields) => {
  return async (dispatch) => {
    dispatch(partnersActions.updatePartner());

    try {
      const response = await axios.patch('/partner', fields);

      dispatch(
        partnersActions.updatePartnerSuccess({ partner: response.data.partner })
      );
    } catch (error) {
      dispatch(partnersActions.updatePartnerFail(error));

      const errorMessage = handleServerError(error.response.data);

      show_error({ message: errorMessage });

      throw error;
    }
  };
};

export const deletePartner = (id) => {
  return async (dispatch) => {
    dispatch(partnersActions.deletePartner());

    try {
      const response = await axios.delete('/partner/', {
        data: { id },
      });

      dispatch(
        partnersActions.deletePartnerSuccess({
          deletedPartner: response.data.deletedPartner,
        })
      );
    } catch (error) {
      dispatch(partnersActions.deletePartnerFail(error));

      throw error;
    }
  };
};

export const savePartnerImage = (signedUrl, file) => {
  return async (dispatch) => {
    dispatch(partnersActions.savePartnerImage());
    try {
      await externalAxios.put(signedUrl, file, {
        headers: {
          'x-amz-acl': 'public-read',
          'Content-Type': file.type,
        },
      });

      dispatch(partnersActions.savePartnerImageSuccess());
    } catch (error) {
      dispatch(partnersActions.savePartnerImageFail(error));

      throw error;
    }
  };
};

export const deletePartnerImage = (image) => {
  return async (dispatch) => {
    dispatch(partnersActions.deletePartnerImage());
    try {
      await axios.post('/partner/image/delete', { image });

      dispatch(partnersActions.deletePartnerImageSuccess());
    } catch (error) {
      dispatch(partnersActions.deletePartnerImageFail(error));

      throw error;
    }
  };
};

export const createPartnerAccount = (partnerId) => {
  return async (dispatch) => {
    dispatch(partnersActions.createPartnerAccount());

    try {
      const response = await axios.post('/partner/signup', { partnerId });

      const { partner } = response.data;

      dispatch(partnersActions.createPartnerAccountSuccess({ partner }));
    } catch (error) {
      dispatch(partnersActions.createPartnerAccountFailed(error));

      const errorMessage = handleServerError(error.response.data);

      show_error({ message: errorMessage });
    }
  };
};

export const activatePartnerAccount = (partnerId) => {
  return async (dispatch) => {
    dispatch(partnersActions.activatePartnerAccount());

    try {
      const response = await axios.post('/partner/activate', { partnerId });

      const { partner } = response.data;

      dispatch(partnersActions.activatePartnerAccountSuccess({ partner }));
    } catch (error) {
      dispatch(partnersActions.activatePartnerAccountFailed(error));

      const errorMessage = handleServerError(error.response.data);

      show_error({ message: errorMessage });
    }
  };
};

export const disablePartnerAccount = (partnerId) => {
  return async (dispatch) => {
    dispatch(partnersActions.disablePartnerAccount());

    try {
      const response = await axios.post('/partner/disable', { partnerId });

      const { partner } = response.data;

      dispatch(partnersActions.disablePartnerAccountSuccess({ partner }));
    } catch (error) {
      dispatch(partnersActions.disablePartnerAccountFailed(error));

      const errorMessage = handleServerError(error.response.data);

      show_error({ message: errorMessage });
    }
  };
};

export const setPartnerCredentials = (data) => {
  return async (dispatch) => {
    dispatch(partnersActions.setPartnerCredentials());

    const { credentials, callbackSuccess } = data;

    try {
      const response = await axios.post('/partner/setCredentials', {
        ...credentials,
      });

      const { partner } = response.data;

      if (callbackSuccess) callbackSuccess();

      dispatch(partnersActions.setPartnerCredentialsSuccess({ partner }));
    } catch (error) {
      dispatch(partnersActions.setPartnerCredentialsFailed(error));

      const errorMessage = handleServerError(error.response.data);

      show_error({ message: errorMessage });
    }
  };
};
