import { handleActions } from 'redux-actions';
import accountingActions from '../actions/actionTypes';

const initialState = {
  trackExpenses: {},
  newExpense: {},
  vendors: [],
  vendorsExpenses: [],
  statement: {},
  expensesStatement: {},
  pendingStatements: [],
  statements: [],
  expenseStatements: [],
  results: [],
  filters: {},
  status: 'IDLE',
  salesDownload: {},
  isFetching: true,
  isCreating: false,
  isUpdating: false,
  isDeleting: false,
  isFetchingPending: true,
  lastTotalRefresh: 'Never',
};

const accountingReducer = handleActions(
  {
    [accountingActions.getTrackExpenses.toString()]: (state, action) => {
      return {
        ...state,
        status: 'FETCHING',
        isFetching: true,
      };
    },
    [accountingActions.getTrackExpensesSuccess.toString()]: (state, action) => {
      return {
        ...state,
        trackExpenses: action.payload.expenses,
        status: 'IDLE',
        isFetching: false,
      };
    },
    [accountingActions.getTrackExpensesFailed.toString()]: (state, action) => {
      return {
        ...state,
        status: 'IDLE',
        isFetching: false,
        error: action.error,
      };
    },
    [accountingActions.getExpensesDetails.toString()]: (state, action) => {
      return {
        ...state,
        status: 'FETCHING_EXPENSES_DETAILS',
        isFetchingExpensesDetails: true,
      };
    },
    [accountingActions.getExpensesDetailsSuccess.toString()]: (
      state,
      action
    ) => {
      const { statement, expenses } = action.payload;
      const expensesDetails = expenses[0]?.items;
      const totalExpensesDetails = expenses[0]?.pageInfo[0]?.count;

      return {
        ...state,
        expensesDetails,
        expensesStatement: statement,
        totalExpensesDetails,
        status: 'IDLE',
        isFetchingExpensesDetails: false,
      };
    },
    [accountingActions.getExpensesDetailsFailed.toString()]: (
      state,
      action
    ) => {
      return {
        ...state,
        status: 'IDLE',
        isFetchingExpensesDetails: false,
        error: action.error,
      };
    },
    [accountingActions.saveTrackExpenses.toString()]: (state, action) => {
      return {
        ...state,
        isCreating: true,
      };
    },
    [accountingActions.saveTrackExpensesSuccess.toString()]: (
      state,
      action
    ) => {
      const { expense } = action.payload;
      const { vendor } = expense;
      const updatedVendorExpenses = [
        ...(state.trackExpenses[vendor]?.expenses || []),
        expense,
      ];

      return {
        ...state,
        newExpense: expense,
        trackExpenses: {
          ...state.trackExpenses,
          [vendor]: {
            ...state.trackExpenses[vendor],
            expenses: updatedVendorExpenses,
          },
        },
        isCreating: false,
      };
    },
    [accountingActions.saveTrackExpensesFailed.toString()]: (state, action) => {
      return {
        ...state,
        isCreating: false,
        error: action.error,
      };
    },
    [accountingActions.updateTrackExpenses.toString()]: (state, action) => {
      return {
        ...state,
        isUpdating: true,
      };
    },
    [accountingActions.updateTrackExpensesSuccess.toString()]: (
      state,
      action
    ) => {
      const { expense } = action.payload;
      const { vendor } = expense;

      const vendorExpenses = state.trackExpenses[vendor].expenses;
      const updatedVendorExpenses = vendorExpenses.map((exp) => {
        if (exp._id === expense._id) {
          return expense;
        }

        return exp;
      });

      return {
        ...state,
        trackExpenses: {
          ...state.trackExpenses,
          [vendor]: {
            ...state.trackExpenses[vendor],
            expenses: updatedVendorExpenses,
          },
        },
        isUpdating: false,
      };
    },
    [accountingActions.updateTrackExpensesFailed.toString()]: (
      state,
      action
    ) => {
      return {
        ...state,
        isUpdating: false,
        error: action.error,
      };
    },
    [accountingActions.deleteTrackExpense.toString()]: (state, action) => {
      return {
        ...state,
        isDeleting: true,
      };
    },
    [accountingActions.deleteTrackExpenseSuccess.toString()]: (
      state,
      action
    ) => {
      const { expense } = action.payload;
      const { vendor } = expense;

      const vendorExpenses = state.trackExpenses[vendor].expenses.filter(
        (exp) => exp._id !== expense._id
      );

      return {
        ...state,
        trackExpenses: {
          ...state.trackExpenses,
          [vendor]: {
            ...state.trackExpenses[vendor],
            expenses: vendorExpenses,
          },
        },
        isDeleting: false,
      };
    },
    [accountingActions.deleteTrackExpenseFailed.toString()]: (
      state,
      action
    ) => {
      return {
        ...state,
        isDeleting: false,
        error: action.error,
      };
    },
    [accountingActions.deleteTrackExpenses.toString()]: (state, action) => {
      return {
        ...state,
        isDeleting: true,
      };
    },
    [accountingActions.deleteTrackExpensesSuccess.toString()]: (
      state,
      action
    ) => {
      const { vendor } = action.payload;

      const trackExpenses = { ...state.trackExpenses };

      // pending fix, muting state
      delete trackExpenses[vendor];

      return {
        ...state,
        isDeleting: false,
        trackExpenses,
      };
    },
    [accountingActions.deleteTrackExpensesFailed.toString()]: (
      state,
      action
    ) => {
      return {
        ...state,
        isDeleting: false,
        error: action.error,
      };
    },
    [accountingActions.requestExpensesFile.toString()]: (state, action) => {
      return {
        ...state,
        isDownloadingExpense: true,
      };
    },
    [accountingActions.requestExpensesFileSuccess.toString()]: (
      state,
      action
    ) => {
      return {
        ...state,
        expensesStatement: {
          ...state.expensesStatement,
          cloudStatus: 'REQUESTED',
        },
        isDownloadingExpense: false,
      };
    },
    [accountingActions.requestExpensesFileFailed.toString()]: (
      state,
      action
    ) => {
      return {
        ...state,
        isDownloadingExpense: false,
        error: action.error,
      };
    },
    [accountingActions.downloadExpenses.toString()]: (state, action) => {
      return {
        ...state,
        isDownloadingExpenses: true,
      };
    },
    [accountingActions.downloadExpensesSuccess.toString()]: (state, action) => {
      return {
        ...state,
        isDownloadingExpenses: false,
      };
    },
    [accountingActions.downloadExpensesFailed.toString()]: (state, action) => {
      return {
        ...state,
        isDownloadingExpenses: false,
        error: action.error,
      };
    },
    [accountingActions.getVendors.toString()]: (state, action) => {
      return {
        ...state,
        status: 'FETCHING',
        isFetching: true,
      };
    },
    [accountingActions.getVendorsSuccess.toString()]: (state, action) => {
      return {
        ...state,
        vendors: action.payload.vendors,
        status: 'IDLE',
        isFetching: false,
      };
    },
    [accountingActions.getVendorsFailed.toString()]: (state, action) => {
      return {
        ...state,
        status: 'IDLE',
        isFetching: false,
        error: action.error,
      };
    },
    [accountingActions.saveVendors.toString()]: (state, action) => {
      return {
        ...state,
        isCreating: true,
      };
    },
    [accountingActions.saveVendorsSuccess.toString()]: (state, action) => {
      return {
        ...state,
        vendors: [...state.vendors, action.payload.vendor],
        isCreating: false,
      };
    },
    [accountingActions.saveVendorsFailed.toString()]: (state, action) => {
      return {
        ...state,
        isCreating: false,
        error: action.error,
      };
    },
    [accountingActions.updateVendors.toString()]: (state, action) => {
      return {
        ...state,
        isUpdating: true,
      };
    },
    [accountingActions.updateVendorsSuccess.toString()]: (state, action) => {
      return {
        ...state,
        vendor: action.payload.vendor,
        isUpdating: false,
      };
    },
    [accountingActions.updateVendorsFailed.toString()]: (state, action) => {
      return {
        ...state,
        isUpdating: false,
        error: action.error,
      };
    },
    [accountingActions.deleteVendors.toString()]: (state, action) => {
      return {
        ...state,
        isCreating: true,
      };
    },
    [accountingActions.deleteVendorsSuccess.toString()]: (state, action) => {
      return {
        ...state,
        vendors: state.vendors.filter(
          (vendor) => vendor._id !== action.payload.vendorId
        ),
        isCreating: false,
      };
    },
    [accountingActions.deleteVendorsFailed.toString()]: (state, action) => {
      return {
        ...state,
        isCreating: false,
        error: action.error,
      };
    },
    [accountingActions.saveVendorCriteria.toString()]: (state, action) => {
      return {
        ...state,
        isCreating: true,
      };
    },
    [accountingActions.saveVendorCriteriaSuccess.toString()]: (
      state,
      action
    ) => {
      const { vendor: updatedVendor } = action.payload;
      const vendors = state.vendors.map((vendor) => {
        if (vendor._id === updatedVendor._id) {
          return updatedVendor;
        }

        return vendor;
      });

      return {
        ...state,
        vendors,
        isCreating: false,
      };
    },
    [accountingActions.saveVendorCriteriaFailed.toString()]: (
      state,
      action
    ) => {
      return {
        ...state,
        isCreating: false,
        error: action.error,
      };
    },
    [accountingActions.deleteVendorCriteria.toString()]: (state, action) => {
      return {
        ...state,
        isDeleting: true,
      };
    },
    [accountingActions.deleteVendorCriteriaSuccess.toString()]: (
      state,
      action
    ) => {
      const { vendor: updatedVendor } = action.payload;
      const vendors = state.vendors.map((vendor) => {
        if (vendor._id === updatedVendor._id) {
          return updatedVendor;
        }

        return vendor;
      });

      return {
        ...state,
        vendors,
        isDeleting: false,
      };
    },
    [accountingActions.deleteVendorCriteriaFailed.toString()]: (
      state,
      action
    ) => {
      return {
        ...state,
        isDeleting: false,
        error: action.error,
      };
    },
    [accountingActions.addVendor.toString()]: (state, action) => {
      const { vendor } = action.payload;
      const { _id, name, criterias } = vendor;

      return {
        ...state,
        trackExpenses: {
          ...state.trackExpenses,
          [_id]: {
            vendor: name,
            criterias,
            expenses: [],
          },
        },
      };
    },

    //VENDORS EXPENSES
    [accountingActions.getVendorExpenses.toString()]: (state, action) => {
      return {
        ...state,
        status: 'FETCHING',
        isFetching: true,
      };
    },
    [accountingActions.getVendorExpensesSuccess.toString()]: (
      state,
      action
    ) => {
      return {
        ...state,
        vendorsExpenses: action.payload.vendorsExpenses,
        status: 'IDLE',
        isFetching: false,
      };
    },
    [accountingActions.getVendorExpensesFailed.toString()]: (state, action) => {
      return {
        ...state,
        status: 'IDLE',
        isFetching: false,
        error: action.error,
      };
    },

    [accountingActions.saveVendorExpenses.toString()]: (state, action) => {
      return {
        ...state,
        isCreating: true,
      };
    },
    [accountingActions.saveVendorExpensesSuccess.toString()]: (
      state,
      action
    ) => {
      return {
        ...state,
        vendorsExpenses: [...state.vendorsExpenses, action.payload.vendor],
        isCreating: false,
      };
    },
    [accountingActions.saveVendorExpensesFailed.toString()]: (
      state,
      action
    ) => {
      return {
        ...state,
        isCreating: false,
        error: action.error,
      };
    },
    [accountingActions.updateVendorExpenses.toString()]: (state, action) => {
      return {
        ...state,
        isUpdating: true,
      };
    },
    [accountingActions.updateVendorExpensesSuccess.toString()]: (
      state,
      action
    ) => {
      return {
        ...state,
        vendorsExpenses: action.payload.vendor,
        isUpdating: false,
      };
    },
    [accountingActions.updateVendorExpensesFailed.toString()]: (
      state,
      action
    ) => {
      return {
        ...state,
        isUpdating: false,
        error: action.error,
      };
    },
    [accountingActions.deleteVendorExpenses.toString()]: (state, action) => {
      return {
        ...state,
        isCreating: true,
      };
    },
    [accountingActions.deleteVendorExpensesSuccess.toString()]: (
      state,
      action
    ) => {
      return {
        ...state,
        vendorsExpenses: state.vendorsExpenses.filter(
          (vendor) => vendor._id !== action.payload.vendorId
        ),
        isCreating: false,
      };
    },
    [accountingActions.deleteVendorExpensesFailed.toString()]: (
      state,
      action
    ) => {
      return {
        ...state,
        isCreating: false,
        error: action.error,
      };
    },
    [accountingActions.saveVendorExpenseCriteria.toString()]: (
      state,
      action
    ) => {
      return {
        ...state,
        isCreating: true,
      };
    },
    [accountingActions.saveVendorExpenseCriteriaSuccess.toString()]: (
      state,
      action
    ) => {
      const { vendor: updatedVendor } = action.payload;
      const vendorsExpenses = state.vendorsExpenses.map((vendor) => {
        if (vendor._id === updatedVendor._id) {
          return updatedVendor;
        }

        return vendor;
      });

      return {
        ...state,
        vendorsExpenses,
        isCreating: false,
      };
    },
    [accountingActions.saveVendorExpenseCriteriaFailed.toString()]: (
      state,
      action
    ) => {
      return {
        ...state,
        isCreating: false,
        error: action.error,
      };
    },
    [accountingActions.deleteVendorExpenseCriteria.toString()]: (
      state,
      action
    ) => {
      return {
        ...state,
        isDeleting: true,
      };
    },
    [accountingActions.deleteVendorExpenseCriteriaSuccess.toString()]: (
      state,
      action
    ) => {
      const { vendor: updatedVendor } = action.payload;
      const vendorsExpenses = state.vendorsExpenses.map((vendor) => {
        if (vendor._id === updatedVendor._id) {
          return updatedVendor;
        }

        return vendor;
      });

      return {
        ...state,
        vendorsExpenses,
        isDeleting: false,
      };
    },
    [accountingActions.deleteVendorExpenseCriteriaFailed.toString()]: (
      state,
      action
    ) => {
      return {
        ...state,
        isDeleting: false,
        error: action.error,
      };
    },
    [accountingActions.addVendorExpense.toString()]: (state, action) => {
      const { vendor } = action.payload;
      const { _id, name, criterias } = vendor;

      return {
        ...state,
        trackExpenses: {
          ...state.trackExpenses,
          [_id]: {
            vendor: name,
            criterias,
            expenses: [],
          },
        },
      };
    },
    [accountingActions.getStatement.toString()]: (state, action) => {
      return {
        ...state,
        status: 'FETCHING',
        isFetching: true,
      };
    },
    [accountingActions.getStatementSuccess.toString()]: (state, action) => {
      const { statement } = action.payload;

      return {
        ...state,
        statement,
        totalRevenue: statement.revenue,
        status: 'IDLE',
        isFetching: false,
      };
    },
    [accountingActions.getStatementFailed.toString()]: (state, action) => {
      return {
        ...state,
        status: 'IDLE',
        isFetching: false,
        error: action.error,
      };
    },
    [accountingActions.getStatementsPending.toString()]: (state, action) => {
      return {
        ...state,
        status: 'FETCHING',
        isFetchingPending: true,
      };
    },
    [accountingActions.getStatementsPendingSuccess.toString()]: (
      state,
      action
    ) => {
      const { statements } = action.payload;

      return {
        ...state,
        pendingStatements: statements,
        status: 'IDLE',
        isFetchingPending: false,
      };
    },
    [accountingActions.getStatementsPendingFailed.toString()]: (
      state,
      action
    ) => {
      return {
        ...state,
        status: 'IDLE',
        isFetchingPending: false,
        error: action.error,
      };
    },
    [accountingActions.getStatements.toString()]: (state, action) => {
      return {
        ...state,
        status: 'FETCHING',
        isFetching: true,
      };
    },
    [accountingActions.getStatementsSuccess.toString()]: (state, action) => {
      const statements = action.payload.statements?.items;
      const totalStatements = action.payload.statements?.pageInfo[0]?.count;

      return {
        ...state,
        statements,
        totalStatements,
        status: 'IDLE',
        isFetching: false,
      };
    },
    [accountingActions.getStatementsFailed.toString()]: (state, action) => {
      return {
        ...state,
        status: 'IDLE',
        isFetching: false,
        error: action.error,
      };
    },
    [accountingActions.getStatementSales.toString()]: (state, action) => {
      return {
        ...state,
        isFetchingSales: true,
      };
    },
    [accountingActions.getStatementSalesSuccess.toString()]: (
      state,
      action
    ) => {
      const sales = action.payload?.sales?.items || [];
      const totalSales = action.payload?.sales?.total || 0;
      return {
        ...state,
        sales,
        totalSales,
        isFetchingSales: false,
      };
    },
    [accountingActions.getStatementSalesFailed.toString()]: (state, action) => {
      return {
        ...state,
        isFetchingSales: false,
        error: action.error,
      };
    },
    [accountingActions.getSalesFilters.toString()]: (state, action) => {
      return {
        ...state,
        isFetchingFilters: true,
      };
    },
    [accountingActions.getSalesFiltersSuccess.toString()]: (state, action) => {
      return {
        ...state,
        filters: action.payload.filters,
        isFetchingFilters: false,
      };
    },
    [accountingActions.getSalesFiltersFailed.toString()]: (state, action) => {
      return {
        ...state,
        isFetchingFilters: false,
        error: action.error,
      };
    },
    [accountingActions.quickSearchSales.toString()]: (state, action) => {
      return {
        ...state,
        isFetchingSales: true,
      };
    },
    [accountingActions.quickSearchSalesSuccess.toString()]: (state, action) => {
      return {
        ...state,
        results: action.payload.results,
        isFetchingSales: false,
      };
    },
    [accountingActions.quickSearchSalesFailed.toString()]: (state, action) => {
      return {
        ...state,
        isFetchingSales: false,
        error: action.error,
      };
    },
    [accountingActions.getSalesSummary.toString()]: (state, action) => {
      return {
        ...state,
        status: 'FETCHING_SUMMARY',
        isFetchingSummary: true,
      };
    },
    [accountingActions.getSalesSummarySuccess.toString()]: (state, action) => {
      return {
        ...state,
        summary: action.payload.summary,
        status: 'IDLE',
        isFetchingSummary: false,
      };
    },
    [accountingActions.getSalesSummaryFailed.toString()]: (state, action) => {
      return {
        ...state,
        status: 'IDLE',
        isFetchingSummary: false,
        error: action.error,
      };
    },
    [accountingActions.getSalesDetails.toString()]: (state, action) => {
      return {
        ...state,
        status: 'FETCHING_SALES_DETAILS',
        isFetchingSalesDetails: true,
      };
    },
    [accountingActions.getSalesDetailsSuccess.toString()]: (state, action) => {
      const { sales, download } = action.payload;
      const salesDetails = sales?.items;
      const totalSalesDetails = sales?.pageInfo[0]?.count;
      console.log('salesDownload', download);
      return {
        ...state,
        salesDetails,
        salesDownload: download,
        totalSalesDetails,
        status: 'IDLE',
        isFetchingSalesDetails: false,
      };
    },
    [accountingActions.getSalesDetailsFailed.toString()]: (state, action) => {
      return {
        ...state,
        status: 'IDLE',
        isFetchingSalesDetails: false,
        error: action.error,
      };
    },
    [accountingActions.requestSalesFile.toString()]: (state, action) => {
      return {
        ...state,
        isDownloadingSale: true,
      };
    },
    [accountingActions.requestSalesFileSuccess.toString()]: (state, action) => {
      return {
        ...state,
        salesDownload: {
          cloudStatus: 'REQUESTED',
        },
        isDownloadingSale: false,
      };
    },
    [accountingActions.requestSalesFileFailed.toString()]: (state, action) => {
      return {
        ...state,
        isDownloadingSale: false,
        error: action.error,
      };
    },
    [accountingActions.downloadSales.toString()]: (state, action) => {
      return {
        ...state,
        isDownloadingSales: true,
      };
    },
    [accountingActions.downloadSalesSuccess.toString()]: (state, action) => {
      return {
        ...state,
        isDownloadingSales: false,
      };
    },
    [accountingActions.downloadSalesFailed.toString()]: (state, action) => {
      return {
        ...state,
        isDownloadingSales: false,
        error: action.error,
      };
    },
    [accountingActions.requestStatementFile.toString()]: (state, action) => {
      return {
        ...state,
        isDownloading: action.payload.statementId,
      };
    },
    [accountingActions.requestStatementFileSuccess.toString()]: (
      state,
      action
    ) => {
      const { statementId } = action.payload;

      return {
        ...state,
        statements: state.statements.map((statement) => {
          if (statement.internalId === statementId) {
            return {
              ...statement,
              cloudStatus: 'REQUESTED',
            };
          }

          return statement;
        }),
        statement: {
          ...state.statement,
          cloudStatus: 'REQUESTED',
        },
        isDownloading: '',
      };
    },
    [accountingActions.requestStatementFileFailed.toString()]: (
      state,
      action
    ) => {
      return {
        ...state,
        isDownloading: '',
        error: action.error,
      };
    },
    [accountingActions.downloadStatement.toString()]: (state, action) => {
      return {
        ...state,
        isDownloading: action.payload.statementId,
      };
    },
    [accountingActions.downloadStatementSuccess.toString()]: (
      state,
      action
    ) => {
      return {
        ...state,
        isDownloading: '',
      };
    },
    [accountingActions.downloadStatementFailed.toString()]: (state, action) => {
      return {
        ...state,
        isDownloading: '',
        error: action.error,
      };
    },
    [accountingActions.storeStatement.toString()]: (state, action) => {
      return {
        ...state,
        isCreating: true,
      };
    },
    [accountingActions.storeStatementSuccess.toString()]: (state, action) => {
      return {
        ...state,
        sales: action.payload.sales,
        isCreating: false,
      };
    },
    [accountingActions.storeStatementFailed.toString()]: (state, action) => {
      return {
        ...state,
        isCreating: false,
        error: action.error,
      };
    },
    [accountingActions.startUploadProgress.toString()]: (state, action) => {
      return {
        ...state,
        uploadProgress: 0,
      };
    },
    [accountingActions.updateUploadProgress.toString()]: (state, action) => {
      return {
        ...state,
        uploadProgress: action.payload.progress,
      };
    },
    [accountingActions.updateSales.toString()]: (state, action) => {
      return {
        ...state,
        isUpdating: true,
      };
    },
    [accountingActions.updateSalesSuccess.toString()]: (state, action) => {
      return {
        ...state,
        isUpdating: false,
      };
    },
    [accountingActions.updateSalesFailed.toString()]: (state, action) => {
      return {
        ...state,
        isUpdating: false,
        error: action.error,
      };
    },
    [accountingActions.allocateAlbumSales.toString()]: (state, action) => {
      return {
        ...state,
        isAllocating: true,
      };
    },
    [accountingActions.allocateAlbumSalesSuccess.toString()]: (
      state,
      action
    ) => {
      return {
        ...state,
        isAllocating: false,
      };
    },
    [accountingActions.allocateAlbumSalesFailed.toString()]: (
      state,
      action
    ) => {
      return {
        ...state,
        isAllocating: false,
        error: action.error,
      };
    },
    [accountingActions.deleteStatement.toString()]: (state, action) => {
      return {
        ...state,
        isDeleting: true,
      };
    },
    [accountingActions.deleteStatementSuccess.toString()]: (state, action) => {
      return {
        ...state,
        statement: action.payload.statement,
        isDeleting: false,
      };
    },
    [accountingActions.deleteStatementFailed.toString()]: (state, action) => {
      return {
        ...state,
        isDeleting: false,
        error: action.error,
      };
    },
    [accountingActions.deleteSongSales.toString()]: (state, action) => {
      return {
        ...state,
        isDeleting: true,
      };
    },
    [accountingActions.deleteSongSalesSuccess.toString()]: (state, action) => {
      return {
        ...state,
        sale: action.payload.sale,
        isDeleting: false,
      };
    },
    [accountingActions.deleteSongSalesFailed.toString()]: (state, action) => {
      return {
        ...state,
        isDeleting: false,
        error: action.error,
      };
    },
    [accountingActions.getExpenseStatements.toString()]: (state, action) => {
      return {
        ...state,
        status: 'FETCHING',
        isFetching: true,
      };
    },
    [accountingActions.getExpenseStatementsSuccess.toString()]: (
      state,
      action
    ) => {
      const statements = action.payload.statements?.items;
      const totalExpenseStatements =
        action.payload.statements?.pageInfo[0]?.count;

      return {
        ...state,
        expenseStatements: statements,
        totalExpenseStatements,
        status: 'IDLE',
        isFetching: false,
      };
    },
    [accountingActions.getExpenseStatementsFailed.toString()]: (
      state,
      action
    ) => {
      return {
        ...state,
        status: 'IDLE',
        isFetching: false,
        error: action.error,
      };
    },
    [accountingActions.getStatementExpenses.toString()]: (state, action) => {
      return {
        ...state,
        isFetchingExpenses: true,
      };
    },
    [accountingActions.getStatementExpensesSuccess.toString()]: (
      state,
      action
    ) => {
      const expenses = action.payload?.expenses?.items || [];
      const totalExpenses = action.payload?.expenses?.pageInfo[0]?.count || 0;

      return {
        ...state,
        expenses,
        totalExpenses,
        isFetchingExpenses: false,
      };
    },
    [accountingActions.getStatementExpensesFailed.toString()]: (
      state,
      action
    ) => {
      return {
        ...state,
        isFetchingExpenses: false,
        error: action.error,
      };
    },
    [accountingActions.getLastTotalRefresh.toString()]: (state, action) => {
      return {
        ...state,
      };
    },
    [accountingActions.getLastTotalRefreshSuccess.toString()]: (
      state,
      action
    ) => {
      const lastTotalRefresh = action.payload?.lastUpdate;

      return {
        ...state,
        lastTotalRefresh,
      };
    },
    [accountingActions.getLastTotalRefreshFailed.toString()]: (
      state,
      action
    ) => {
      return {
        ...state,
        error: action.error,
      };
    },
    [accountingActions.refreshTotal.toString()]: (state) => {
      return {
        ...state,
      };
    },
    [accountingActions.refreshTotalSuccess.toString()]: (state) => {
      return {
        ...state,
      };
    },
    [accountingActions.refreshTotalFailed.toString()]: (state, action) => {
      return {
        ...state,
        error: action.error,
      };
    },
  },
  initialState
);

export default accountingReducer;
