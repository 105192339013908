import { createActions } from 'redux-actions';

export default createActions({
  SET_TRACKS: undefined,
  SET_TOTAL_TRACKS: undefined,
  CLEAR_TRACKS: undefined,
  CLEAR_TRACK: undefined,
  GET_SONGS: undefined,
  GET_SONGS_FAILED: undefined,
  QUICK_SEARCH_TRACK: undefined,
  QUICK_SEARCH_TRACK_FAILED: undefined,
  DELETE_SONG: undefined,
  DELETE_SONG_FAILED: undefined,
  GET_DISTINCTS: undefined,
  GET_DISTINCTS_FAILED: undefined,
  ADD_FILTER: undefined,
  ADD_FILTER_FAILED: undefined,
  ADD_ARRAY_FILTER: undefined,
  ADD_FILTERS: undefined,
  REMOVE_FILTER: undefined,
  REMOVE_FILTER_FAILED: undefined,
  UPDATE_PAGE: undefined,
  UPDATE_PAGE_FAILED: undefined,
  SET_FETCHING: undefined,
  SET_NOT_FETCHING: undefined,
  GET_TRACK_START: undefined,
  GET_TRACK: undefined,
  GET_TRACK_FAIL: undefined,
  UPLOAD_AUDIO: undefined,
  UPLOAD_AUDIO_FAILED: undefined,
  REMOVE_AUDIO: undefined,
  REMOVE_AUDIO_FAILED: undefined,
  TOGGLE_BOOL: undefined,
  RESET_FILTERS: undefined,
  RESET_FILTERS_FAILED: undefined,
  UPDATE_FAVORITE: undefined,
  UPDATE_FAVORITE_FAILED: undefined,
  ADD_TRACK: undefined,
  ADD_TRACK_SUCCESS: undefined,
  ADD_TRACK_FAIL: undefined,
  UPDATE_TRACK: undefined,
  UPDATE_TRACK_SUCCESS: undefined,
  UPDATE_TRACK_FAIL: undefined,
  SAVE_TRACK_ARTWORK: undefined,
  SAVE_TRACK_ARTWORK_SUCCESS: undefined,
  SAVE_TRACK_ARTWORK_FAIL: undefined,
  DELETE_TRACK_ARTWORK: undefined,
  DELETE_TRACK_ARTWORK_SUCCESS: undefined,
  DELETE_TRACK_ARTWORK_FAIL: undefined,
  ADD_TRACKS_TO_ENTITY: undefined,
  ADD_TRACKS_TO_ENTITY_SUCCESS: undefined,
  ADD_TRACKS_TO_ENTITY_FAIL: undefined,
  REMOVE_TRACKS_FROM_ENTITY: undefined,
  REMOVE_TRACKS_FROM_ENTITY_SUCCESS: undefined,
  REMOVE_TRACKS_FROM_ENTITY_FAIL: undefined,
  UPDATE_SELECTED_TRACKS: undefined,
  UPDATE_SELECTED_TRACKS_SUCCESS: undefined,
  UPDATE_SELECTED_TRACKS_FAIL: undefined,
  DELETE_SELECTED_TRACKS: undefined,
  DELETE_SELECTED_TRACKS_SUCCESS: undefined,
  DELETE_SELECTED_TRACKS_FAIL: undefined,
  GET_FILTERED_SONGS_FAILED: undefined,
  ASSOCIATE_TRACK: undefined,
  ASSOCIATE_TRACK_SUCCESS: undefined,
  ASSOCIATE_TRACK_FAIL: undefined,
  MASS_ASSOCIATE_TRACK: undefined,
  MASS_ASSOCIATE_TRACK_SUCCESS: undefined,
  MASS_ASSOCIATE_TRACK_FAIL: undefined,
  GENERATE_CSV_DATA: undefined,
  GENERATE_CSV_DATA_SUCCESS: undefined,
  GENERATE_CSV_DATA_FAIL: undefined,
  SET_COPY_SPLIT: undefined,
  CLEAR_COPY_SPLIT: undefined,
  PASTE_SPLITS: undefined,
  PASTE_SPLITS_SUCCESS: undefined,
  PASTE_SPLITS_FAIL: undefined,
  COPY_SONG_DATA: undefined,
  COPY_SONG_DATA_SUCCESS: undefined,
  COPY_SONG_DATA_FAIL: undefined,
  GET_SIMILAR_SONGS: undefined,
  GET_SIMILAR_SONGS_SUCCESS: undefined,
  GET_SIMILAR_SONGS_FAIL: undefined,
  SET_COPY_SONG: undefined,
  CLEAR_COPY_SONG: undefined,
});
