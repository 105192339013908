import React, { Component } from 'react';
import './TableNavigator.scss';

class TableNavigator extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 0,
      jump: 0,
    };
  }

  UNSAFE_componentWillReceiveProps = (nextProps) =>
    this.setState({
      page: Number(nextProps.page),
      jump: Number(nextProps.page),
    });

  next = () => {
    let page = this.state.page;

    this.setState({ page: page++ }, () => {
      this.nav(page);
    });
  };

  previous = () => {
    let page = this.state.page;

    this.setState({ page: page-- }, () => {
      this.nav(page);
    });
  };

  nav = (page) => {
    this.props.navigate(page);
    window.scrollTo(0, 0);
  };

  render() {
    const { page } = this.state;
    const { pages } = this.props;

    return (
      <div>
        <div id="table-navigator">
          <div id="col">
            <p>
              Page <b>{page}</b> of <b>{pages}</b>
            </p>
          </div>

          <div id="col">
            <button onClick={this.previous} disabled={page === 0}>
              &larr;
            </button>
            <button onClick={this.next} disabled={page === Number(pages)}>
              &rarr;
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default TableNavigator;
