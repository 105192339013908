import { axios } from '../../../../services/axios';
import catalogActions from './actionTypes';
import labelActions from '../../../../containers/labels/actions/actionTypes';
import trackActions from '../../../../containers/tracks/actions/actionTypes';
import projectActions from '../../../../containers/projects/actions/actionTypes';
import tagActions from '../../../../containers/tags/actions/actionTypes';
import partnerActions from '../../../../containers/partners/actions/actionTypes';
import organizationActions from '../../../../containers/organizations/actions/actionTypes';

export const getCatalogMetadata = () => {
  return async (dispatch) => {
    dispatch(catalogActions.getCatalogMetadata());
    try {
      const response = await axios.get('/analytics/catalog/metadata');
      const {
        totalLabels,
        totalClMasterSongs,
        totalClPublishingSongs,
        totalClPhysicalSongs,
        totalSongs,
        totalAlbums,
        totalTags,
        totalTagCategories,
        totalPartners,
        totalOrganizations,
        totalVendors,
      } = response.data.metadata;

      dispatch(catalogActions.getCatalogMetadataSuccess());
      dispatch(labelActions.setTotalLabels({ totalLabels }));
      dispatch(
        trackActions.setTotalTracks({
          totalTracks: totalSongs,
          totalClMasterTracks: totalClMasterSongs,
          totalClPublishingTracks: totalClPublishingSongs,
          totalClPhysicalTracks: totalClPhysicalSongs,
          totalVendors: totalVendors,
        })
      );
      dispatch(projectActions.setTotalProjects({ totalProjects: totalAlbums }));
      dispatch(tagActions.setTotalTags({ totalTags }));
      dispatch(tagActions.setTotalTagCategories({ totalTagCategories }));
      dispatch(partnerActions.setTotalPartners({ totalPartners }));
      dispatch(
        organizationActions.setTotalOrganizations({ totalOrganizations })
      );
    } catch (error) {
      dispatch(catalogActions.getCatalogMetadataFail(error));
    }
  };
};
