import React from 'react';
import { Descriptions, Tag } from 'antd';
import Proptypes from 'prop-types';
import {
  CustomerServiceOutlined,
  InstagramOutlined,
  YoutubeOutlined,
  FacebookOutlined,
  TeamOutlined,
} from '@ant-design/icons';

const socialMedia = {
  facebook: {
    icon: <FacebookOutlined />,
    color: '#3b5999',
  },
  instagram: {
    icon: <InstagramOutlined />,
    color: '#3f729b',
  },
  youtube: {
    icon: <YoutubeOutlined />,
    color: '#cd201f',
  },
  spotify: {
    icon: <CustomerServiceOutlined />,
    color: '#1DB954',
  },
};

const LabelMetadata = ({ label }) => {
  const socialNetworksArray = Object.keys(label.socials || {});

  return (
    <Descriptions bordered>
      <Descriptions.Item label="Label Name" span={3}>
        {label.name || ''}
      </Descriptions.Item>
      <Descriptions.Item label="Slug" span={3}>
        {label.slug || 'Not Set'}
      </Descriptions.Item>

      <Descriptions.Item label="Social Networks" span={3}>
        {socialNetworksArray.map((social) =>
          label?.socials[social] ? (
            <a
              key={social}
              href={label.socials[social]}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Tag
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginBottom: '5px',
                  textTransform: 'capitalize',
                  cursor: 'pointer',
                }}
                icon={socialMedia[social].icon || <TeamOutlined />}
                color={socialMedia[social].color || '#1890ff'}
              >
                {social || ''}
              </Tag>
            </a>
          ) : null
        )}
      </Descriptions.Item>
      <Descriptions.Item label="Description" span={3}>
        {label.description || ''}
      </Descriptions.Item>
      <Descriptions.Item label="Date">{label.date || ''} </Descriptions.Item>
    </Descriptions>
  );
};

LabelMetadata.defaultProps = {
  label: {},
};

LabelMetadata.propTypes = {
  label: Proptypes.object,
};

export default LabelMetadata;
