import { handleActions } from 'redux-actions';
import youtubeActions from '../actions/actionTypes';

const initialState = {
  videos: [],
  searching: false,
  noResults: false,
};

const youtubeReducer = handleActions(
  {
    [youtubeActions.searchVideos.toString()]: (state, action) => {
      return {
        ...state,
        videos: action.payload,
        searching: false,
        noResults: action.payload.length ? false : true,
      };
    },
    [youtubeActions.searchVideosFailed.toString()]: (state, action) => {
      return {
        ...state,
        searching: false,
        error: action.error,
      };
    },
    [youtubeActions.toggleBoolean.toString()]: (state, action) => {
      return {
        ...state,
        [action.payload.key]: action.payload.value,
      };
    },
    [youtubeActions.resetState.toString()]: (state, action) => {
      return initialState;
    },
  },
  initialState
);

export default youtubeReducer;
