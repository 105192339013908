import React, { Suspense } from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';

import LoadingComponent from 'components/LoadingComponent/LoadingComponent';
import GenericNotFound from 'components/404/GenericNotFound';

import routes from './routes';

const AppRoutes = (props) => {
  const { isAdmin, isOrgAdmin } = props;

  const droppableProps = {
    selectedTaskIds: props.selectedTaskIds,
    toggleSelection: props.toggleSelection,
    toggleSelectionInGroup: props.toggleSelectionInGroup,
    multiSelectTo: props.multiSelectTo,
  };

  const buildRoutes = (routes) => {
    return Object.keys(routes).map((route) => {
      const dndProps = routes[route].dndSection ? { ...droppableProps } : {};

      if (
        routes[route].admin &&
        !isAdmin &&
        routes[route].orgAdmin &&
        !isOrgAdmin
      ) {
        return null;
      }

      if (routes[route].private) {
        return (
          <PrivateRoute
            key={routes[route].translationKey}
            exact={routes[route].exact}
            path={routes[route].path}
            component={routes[route].component}
            componentProps={{ ...dndProps }}
          />
        );
      }

      return (
        <Route
          key={routes[route].translationKey}
          exact={routes[route].exact}
          path={routes[route].path}
          component={routes[route].component}
        />
      );
    });
  };

  return (
    <Suspense fallback={<LoadingComponent />}>
      <Switch>
        <Route
          exact
          path="/"
          render={() =>
            localStorage.getItem('loggedIn') ? (
              <Redirect to="/tracks" />
            ) : (
              <Redirect to="/login" />
            )
          }
        />

        {buildRoutes(routes)}
        <PrivateRoute component={GenericNotFound} />
      </Switch>
    </Suspense>
  );
};

function PrivateRoute({ component: Component, componentProps = {}, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) =>
        localStorage.getItem('loggedIn') ? (
          <Component {...props} {...componentProps} />
        ) : (
          <Redirect
            to={{ pathname: `/login`, state: { from: props.location } }}
          />
        )
      }
    />
  );
}

export default AppRoutes;
