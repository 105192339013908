import React, { useState, useCallback, useEffect } from 'react';
import Proptypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { Typography, Form, Input, Button, notification, message } from 'antd';
import { CloseOutlined } from '@ant-design/icons';

import { SelectWithInput } from 'components/FormElements';
import ImageUploader from 'components/FormElements/ImageUploader/ImageUploader';

import { hideAllPanes } from 'store/modules/panes/actions';
import { normFile } from 'utils';
import { Api } from 'utils/consts';

import {
  updateTag,
  saveTagImage,
  createTag,
  getTagCategories,
  createTagCategory,
  deleteTagCategory,
} from '../../../actions';

const layout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
};

const formDefaultValues = {
  name: '',
  category: '',
  imageURl: '',
};

const { Title } = Typography;

const EditTag = ({
  selectedTag: tag,
  tagCategories,
  hideAllPanes,
  isUpdating,
  isSavingImage,
  createTag,
  updateTag,
  saveTagImage,
  getTagCategories,
  createTagCategory,
  deleteTagCategory,
}) => {
  const [form] = Form.useForm();
  const [imageConfig, setImageConfig] = useState({});
  const [tagCategorySelected, setTagCategorySelected] = useState('');
  const [elementCategory, setElementCategory] = useState(null);

  const createMode = tag.new;

  const onFinish = async (values) => {
    const sanitizedValues = {
      ...values,
      category: tagCategorySelected.label,
    };

    // logo config is empty when the user does not selects an image
    if (imageConfig.signedUrlSettings && imageConfig.signedUrlSettings.url) {
      sanitizedValues.imageUrl = imageConfig.signedUrlSettings.key;

      try {
        await saveTagImage(imageConfig.signedUrlSettings.url, imageConfig.file);
      } catch (error) {
        message.error('Tag Image was not saved. Please contact support', 4);
      }
    }

    try {
      if (createMode) {
        await createTag(sanitizedValues);
      } else {
        sanitizedValues.id = tag.id;

        await updateTag(sanitizedValues);
      }

      notification.success({
        message: `Tag Updated`,
        description: 'List of tags was updated',
        duration: 4,
      });
      onClose();
    } catch (error) {}
  };

  const onClose = () => {
    hideAllPanes();
    cleanState();
  };

  const cleanState = () => {
    setImageConfig({});
    form.resetFields();
  };

  // TAGS CATEGORIES
  const onTagCategoriesLoad = useCallback(async () => {
    try {
      await getTagCategories();
    } catch (error) {
      message.error(
        'We are unable to fetch the tag categories. Please contact support',
        4
      );
    }
  }, [getTagCategories]);

  const onAddTagCategoryHandler = async (name) => {
    try {
      await createTagCategory({ name });
    } catch (error) {
      throw error;
    }
  };

  const onSelectTagCategoryHandler = (option) => {
    setTagCategorySelected(option);
  };

  // FORM
  const prepareFormValues = useCallback(
    (tag) => {
      const fields = {
        name: tag.name || '',
        category: tag.category || '',
      };

      form.setFieldsValue(fields);
    },
    [form]
  );

  useEffect(() => {
    if (tag.id) {
      onTagCategoriesLoad();
    }
  }, [onTagCategoriesLoad, tag]);

  useEffect(() => {
    prepareFormValues(tag);
    return () => {
      setImageConfig({});
    };
  }, [prepareFormValues, tag, form]);

  useEffect(() => {
    setElementCategory(
      tagCategories.find((tagCat) => {
        return tagCat.name === tag.category;
      })
    );
    // eslint-disable-next-line
  }, [tagCategories]);

  return (
    <div className="vw-module edit-module content-module">
      <div className="content-title view-module-title">
        <CloseOutlined onClick={onClose} />

        <Title level={3}>{createMode ? 'Create' : 'Edit'} Tag</Title>

        <span />
      </div>

      <div className="module-container">
        <Form
          {...layout}
          form={form}
          name="control-hooks-edit-tag"
          initialValues={{ ...formDefaultValues }}
          onFinish={onFinish}
          autoComplete="off"
        >
          <Form.Item label="Image" className="image-field">
            <Form.Item
              name="tagImage"
              valuePropName="tagImage"
              getValueFromEvent={normFile}
              noStyle
            >
              <ImageUploader
                onImageSelect={setImageConfig}
                imageField="imageUrl"
                endpoint={`${Api}tag/image?id=${tag.id}`}
                section="tags"
                entity={tag}
              />
            </Form.Item>
          </Form.Item>

          <Form.Item name="category" label="Category">
            <SelectWithInput
              items={tagCategories}
              labelKey="name"
              onLoad={onTagCategoriesLoad}
              onAddItem={onAddTagCategoryHandler}
              onChangeOption={onSelectTagCategoryHandler}
              delete={deleteTagCategory}
              initialValue={elementCategory?._id}
            />
          </Form.Item>

          <Form.Item
            name="name"
            label="Sub Category"
            rules={[{ required: true, message: 'Sub Category is required.' }]}
          >
            <Input placeholder="Sub Category" />
          </Form.Item>

          <Form.Item>
            <Button
              loading={isUpdating || isSavingImage}
              type="primary"
              htmlType="submit"
              className="btn-primary"
            >
              {createMode ? 'Create' : 'Edit'}
            </Button>

            <Button htmlType="button" onClick={onClose}>
              Cancel
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

const mapStateToProps = ({ tags }) => {
  return {
    tagCategories: tags.tagCategories,
    selectedTag: tags.selectedTag,
    isUpdating: tags.isUpdating,
    isSavingImage: tags.isSavingImage,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      hideAllPanes,
      createTag,
      updateTag,
      saveTagImage,
      getTagCategories,
      createTagCategory,
      deleteTagCategory,
    },
    dispatch
  );

EditTag.defaultProps = {
  selectedTag: {},
  tagCategories: [],
  hideAllPanes: () => {},
  isUpdating: false,
  createTag: () => {},
  updateTag: () => {},
  getTagCategories: () => {},
  createTagCategory: () => {},
};

EditTag.propTypes = {
  selectedTag: Proptypes.object,
  tagCategories: Proptypes.array,
  hideAllPanes: Proptypes.func,
  isUpdating: Proptypes.bool,
  createTag: Proptypes.func,
  updateTag: Proptypes.func,
  getTagCategories: Proptypes.func,
  createTagCategory: Proptypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(EditTag);
